import styled from '@emotion/styled';
import { FifthImageContainer, FifthMediumText, FifthTitle } from '../../../../../../../GalleryView/GalleryCovers/GalleryFifth/styled';

export const FifthTitlePreview = styled(FifthTitle)`
  font-size: 7px;
  line-height: 10px;
`;

export const FifthMediumTextPreview = styled(FifthMediumText)`
  font-size: 4px;
  line-height: 140%;
`;

export const FifthMediumTextMessagePreview = styled(FifthMediumTextPreview)`
  text-align: center;

  & p {
    display: flex;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    margin: 0;
  }
`;

export const FifthImageContainerPreview = styled(FifthImageContainer)`
  width: 220px;
  height: 147px;
`;
