import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const LandingPricingInvitationContainer = styled.div`
  padding: 60px 0px;
  background: #f5f5f5;
  margin-top: 20px;
  margin-bottom: 88px;
`;

export const LandingPricingInvitationWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LandingPricingInvitationTitle = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.colorBlack};
  margin-bottom: 24px;
`;

export const LandingPricingInvitationText = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: ${variables.colorBlack};
  margin-bottom: 40px;
  max-width: 662px;
`;
