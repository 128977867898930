import { combineReducers } from '@reduxjs/toolkit';
import notificationsSlice from './notifications/notificationsSlice';
import statisticsSlice from './sales/statistics/statisticsSlice';
import supportSlice from './support/supportSlice';
import salesSlice from './sales/sales/salesSlice';
import signUpCreatorSlice from './userCreator/signUp/signUpCreatorSlice';
import signInCreatorSlice from './userCreator/signIn/signInCreatorSlice';
import resetPasswordCreatorSlice from './userCreator/resetPassword/resetPasswordCreatorSlice';
import creatorSlice from './userCreator/creator/creatorSlice';
import commentsSlice from '../../GalleryView/redux/comments/commentsSlice';
import uploadFilesSlice from './gallery/uploadFiles/uploadFilesSlice';
import { galleryApi } from './gallery/galleryAPI';

export const rootReducer = combineReducers({
  comments: commentsSlice,
  uploadFiles: uploadFilesSlice,
  notifications: notificationsSlice,
  statistics: statisticsSlice,
  support: supportSlice,
  sales: salesSlice,
  signUpCreator: signUpCreatorSlice,
  signInCreator: signInCreatorSlice,
  resetPassword: resetPasswordCreatorSlice,
  creator: creatorSlice,
  [galleryApi.reducerPath]: galleryApi.reducer,
});
