import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import * as variables from '../../../../constants/styles';

export const LandingHeaderWrapper = styled.div<{ isMainPage: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 40px;
  background: transparent;
  position: relative;
  background: ${({ isMainPage }) => (isMainPage
    ? `
  linear-gradient(274.28deg, #E6934F -34.21%, #E186B0 45%, #78C0EA 128.62%)
  `
    : variables.colorGreyLight)};
`;

export const LandingNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
`;

export const LandingNavInsideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-right: 38px;

  & > * {
    margin-right: 60px;
  }
`;

export const LandingNavToCreatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & > * {
    margin-left: 32px;
  }
`;

export const LandingHeaderPinkBtn = styled.button<{ isGradient: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  background: transparent;
  border-radius: 51px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  ${({ isGradient }) => (isGradient ? '' : 'box-shadow: -2px -2px 3px #FFA3A5, 2px 2px 3px #BF7073;')}
  ${({ isGradient }) => (isGradient
    ? `
  background: linear-gradient(274.36deg, #E6934F -13.34%, #E186B0 48.12%, #78C0EA 114.29%);
  `
    : '')}

  & * {
    color: ${variables.colorGreyLight};
  }

  &:hover {
    ${({ isGradient }) => (isGradient
    ? ''
    : 'box-shadow: -3px -3px 6px #FFA3A5, 3px 3px 6px #BF7073;')}
    transition: all 0.2s ease-in-out;
  }

  &:active {
    ${({ isGradient }) => (isGradient
    ? ''
    : 'box-shadow: inset -2px -2px 3px #FFA3A5, inset 2px 2px 3px #BF7073;')}
    transition: all 0.2s ease-in-out;
  }
`;

export const HeaderMenuItem = styled.div<{
  isMainPage: boolean;
  fontWeight?: number;
  isUppercase?: boolean;
}>`
  cursor: pointer;

  & a {
    font-size: 14px;
    line-height: 150%;
    font-weight: ${({ fontWeight }) => (fontWeight || '400')};
    color: ${({ isMainPage }) => (isMainPage ? variables.colorGreyLight : variables.textBlack)};
    text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'none')};
  }

  & a.active {
    font-weight: 600;
    color: ${variables.colorPink};
  }
`;

export const LandingHeaderLogoLink = styled(NavLink)`
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  z-index: 2;
`;
