import styled from '@emotion/styled';
import { theme } from '../GlobalContainer/styled';

export const ButtonMoveToTopWrapper = styled.div`
  position: sticky;
  bottom: 39px;
  left: 100%;
  z-index: 1000;
  width: fit-content;
  margin: 0;

  & button {
    margin: 0;
    box-shadow: none;

    &:hover,
    &:active {
      box-shadow: none;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    left: calc(100% - 40px);
    padding: 0px 16px;
    
    & button {
      max-width: 40px;
      max-height: 40px;
    }
  }
`;
