import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TitleAndPriceWrapper } from './styled';
import { PlanTypes } from '../../../../../../../../shared/types';
import { SimpleBlackText } from '../../../../../../../../shared/styles';
import { getPlanTitle } from '../getPlanTitle';

type PlanCardTitleAndPriceProps = {
  isAnnually: boolean;
  planType: PlanTypes;
  priceMonthly: number;
  priceAnnually: number;
};

export const PlanCardTitleAndPrice: React.FC<PlanCardTitleAndPriceProps> = ({
  isAnnually,
  planType,
  priceMonthly,
  priceAnnually,
}) => {
  const { t } = useTranslation('settings');

  const planTitle = useMemo(
    () => getPlanTitle(planType),
    [planType],
  );

  return (
    <TitleAndPriceWrapper>
      <span>{planTitle}</span>
      {' '}
      {planType !== 'Basic' && (
      <SimpleBlackText fontWeight={500}>
        /
        {' '}
        {!!priceMonthly && !isAnnually
          ? (
            <Trans t={t} i18nKey="perMonth">
              $
              {{ perMonth: priceMonthly }}
              {' '}
              per month
            </Trans>
          )
          : (
            <Trans t={t} i18nKey="perYear">
              $
              {{ perYear: priceAnnually }}
              {' '}
              per year
            </Trans>
          )}
      </SimpleBlackText>
      )}
    </TitleAndPriceWrapper>
  );
};
