import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as urls from '../../../constants/urls';
import { LandingMainPage } from '../containers/LandingMainPage/LandingMainPage';
import { DemoGalleries } from '../containers/DemoGalleries/DemoGalleries';
import { DemoGallery } from '../containers/DemoGalleries/DemoGallery/DemoGallery';
import { LandingPricing } from '../containers/LandingPricing/LandingPricing';
import { LandingTerms } from '../containers/LandingTermsAndPrivicyPolicy/LandingTerms';
import { LandingPrivacy } from '../containers/LandingTermsAndPrivicyPolicy/LandingPrivacy';
import { ScrollToTop } from '../../../shared/components/ScrollToTop/ScrollToTop';

export const LandingRoutes: React.FC = () => (
  <>
    <Route component={ScrollToTop} />
    <Switch>
      <Route exact path={urls.rootUrl}>
        <LandingMainPage />
      </Route>
      <Route exact path={urls.demoGalleriesUrl}>
        <DemoGalleries />
      </Route>
      <Route exact path={urls.demoGalleryUrl}>
        <DemoGallery />
      </Route>
      <Route exact path={urls.termsUrl}>
        <LandingTerms />
      </Route>
      <Route exact path={urls.privacyUrl}>
        <LandingPrivacy />
      </Route>
      <Route exact path={urls.pricingUrl}>
        <LandingPricing />
      </Route>
    </Switch>
  </>
);
