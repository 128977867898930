import React, { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../../../../constants/urls';
import {
  AlignCenterNavLink,
  UserCardWrapper,
  UserInfoWrapper,
  UserName,
  UserPhoto,
} from './styled';
import { NotificationIcon } from '../../../../../shared/icons';
import { RoundButton } from '../../../../../shared/styles';
import { useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';
import { getFullName } from '../../../../../shared/utils';
import { CustomBadge } from '../../../../../shared/components/CustomBadge/CustomBadge';
import { fetchGetNotReadNotifications } from '../../../redux/notifications/notificationsThunk';
import { RoundAvatar } from '../../../../../shared/components/RoundAvatar/RoundAvatar';

export const UserCard: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const numberNotReadNotifications = useTypedSelectorCreator(
    (state) => state.notifications.numberNotRead,
  );
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const fullName = useMemo(
    () => getFullName(currentUser?.firstName, currentUser?.lastName),
    [currentUser],
  );

  useEffect(() => {
    dispatch(fetchGetNotReadNotifications());
  }, []);

  return (
    <UserCardWrapper>
      <Link to={urls.accountUrl}>
        <UserPhoto>
          <RoundAvatar size="large" userName={fullName} />
        </UserPhoto>
      </Link>
      <UserInfoWrapper>
        <UserName>{fullName}</UserName>
        <div>
          <CustomBadge badgeContent={numberNotReadNotifications}>
            <AlignCenterNavLink to={urls.notificationsUrl}>
              <RoundButton
                className={pathname.includes(urls.notificationsUrl) ? ' active ' : ''}
                color="grey"
              >
                <NotificationIcon />
              </RoundButton>
            </AlignCenterNavLink>
          </CustomBadge>
        </div>
      </UserInfoWrapper>
    </UserCardWrapper>
  );
};
