import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../constants/styles';
import { theme } from '../components/GlobalContainer/styled';
import { BaseSizes } from '../types';

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FlexStartWrapper = styled(RowWrapper)`
  justify-content: flex-start;
  margin-top: 16px;
`;

export const PositionWrapper = styled.div`
  position: relative;

  & button {
    position: absolute;
    left: 358px;
    max-width: 101px;
    top: 20px;

    & svg {
      top: 20px;
    }

    & > span {
      width: 100px;
    }
  }
`;

export const CustomGrid = styled(Grid)`
  padding: 0 10px !important;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0 !important;
    width: 100% !important;
  }
`;
export const CustomGridWithoutPadding = styled(Grid)`
  padding: 0 !important;
`;

const getRotationDegrees = (direction: string): string => {
  switch (direction) {
    case 'up':
      return '180';
    case 'right':
      return '-90';
    case 'left':
      return '90';
    default: return '0';
  }
};

export const ArrowRotation = styled.div<{direction: 'up' | 'down' | 'left' | 'right'}>`
  transform: rotate(${(props) => getRotationDegrees(props.direction)}deg);
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const TabWrapper = styled.div`
  margin: 24px 0;
`;

export const TabContentWrapper = styled.div`
  margin-top: 30px;
`;

export const RoundItem = styled.div`
  background: ${variables.colorGreyLight};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalCloseButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 40px;
`;

export const FileIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GreyLightWrapper = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
`;

export const ErrorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  text-align: center;

  & > div,
  & > span {
    left: 0;
    right: 0;
    top: -150%;
  }
`;

export const LoaderFullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
