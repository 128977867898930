import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemWithIcon } from '../../../../../shared/components/ListItemWithPinkTick/ListItemWithIcon';
import { LandingPriceExplanationsItem } from './LandingPriceExplanationsItem';
import { LandingPriceExplanationsLists, LandingPriceExplanationsWrp, LandingPricingIcon } from './styled';
import { LandingContentContainer } from '../../LandingContentContainer';

export const useListTexts = (
  listName: 'whatWeDoList' | 'whatYouGetList',
): string[] => {
  const { t } = useTranslation('landing');

  if (listName === 'whatWeDoList') {
    return [
      t('pricing.explanations.whatWeDoList.support'),
      t('pricing.explanations.whatWeDoList.learning'),
      t('pricing.explanations.whatWeDoList.building'),
    ];
  }

  return [
    t('pricing.explanations.whatYouGetList.fees'),
    t('pricing.explanations.whatYouGetList.access'),
    t('pricing.explanations.whatYouGetList.storage'),
    t('pricing.explanations.whatYouGetList.galleries'),
    t('pricing.explanations.whatYouGetList.sell'),
    t('pricing.explanations.whatYouGetList.commission'),
    t('pricing.explanations.whatYouGetList.reminder'),
    t('pricing.explanations.whatYouGetList.premium'),
  ];
};

const getListReactElements = (
  items: string[],
  icon?: ReactElement,
): ReactElement[] => items.map((item) => <ListItemWithIcon item={item} icon={icon} />);

export const LandingPriceExplanations: React.FC = () => {
  const { t } = useTranslation('landing');

  const firstTextsList = useListTexts('whatWeDoList');
  const secondTextsList = useListTexts('whatYouGetList');

  const firstList = getListReactElements(firstTextsList, <LandingPricingIcon />);
  const secondList = getListReactElements(secondTextsList);

  return (
    <LandingPriceExplanationsWrp>
      <LandingContentContainer>
        <LandingPriceExplanationsLists>
          <LandingPriceExplanationsItem
            list={firstList}
            text={t('pricing.explanations.whatWeDoText')}
            title={t('pricing.explanations.whatWeDoTitle')}
          />
          <LandingPriceExplanationsItem
            list={secondList}
            text={t('pricing.explanations.whatYouGetText')}
            title={t('pricing.explanations.whatYouGetTitle')}
          />
        </LandingPriceExplanationsLists>
      </LandingContentContainer>
    </LandingPriceExplanationsWrp>
  );
};
