import { MenuProps } from '@mui/material';
import * as variables from '../../../../constants/styles';

export const getStyle = (): Partial<MenuProps> => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  PaperProps: {
    style: {
      fontFamily: variables.mainFont,
      padding: '0px 10px 0px 10px',
      boxSizing: 'border-box',
      boxShadow: '-5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8',
      borderRadius: '4px',
      background: '#F8F8F8',
      borderColor: '#e0e0e0',
      top: '10px',
    },
  },
  // disableScrollLock: true,
  // getContentAnchorEl: null,
});
