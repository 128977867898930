import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { useChooseItems } from '../../../../../../../../shared/hooks/useChooseItems';
import { ReminderEmailFormCheckboxes } from './styled';

export const ReminderEmailCheckboxesGroup: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  const { handleSelect, isItemSelected, selected } = useChooseItems();
  return (
    <ReminderEmailFormCheckboxes>
      <div>
        <CommonCheckbox
          isChecked={isItemSelected('1')}
          name="1"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.oneWeekBefore')}
          size="small"
          id="1"
          littleText
        />
        <CommonCheckbox
          isChecked={isItemSelected('2')}
          name="2"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.threeDaysBefore')}
          size="small"
          id="2"
          littleText
        />
      </div>
      <div>
        <CommonCheckbox
          isChecked={isItemSelected('3')}
          name="3"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.twoDaysBefore')}
          size="small"
          id="3"
          littleText
        />
        <CommonCheckbox
          isChecked={isItemSelected('4')}
          name="4"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.oneDayBefore')}
          size="small"
          id="4"
          littleText
        />
      </div>
    </ReminderEmailFormCheckboxes>
  );
};
