import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gallery, RegisteredEmail } from '../../../../../../../shared/types';
import { ModalWindowContainer } from '../../../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { SalesCard } from './SalesCard';
import { GalleryInfo } from './GalleryInfo';
import { GalleryTabsDetails } from './GalleryTabsDetails/GalleryTabsDetails';
import { DashboardHeader } from './DashboardHeader/DashboardHeader';
import { WhiteButtonWithoutShadow } from '../../../../../../../shared/styles';
import { GalleryInfoWrapper } from './styled';

type DashboardProps = {
  gallery: Gallery;
};

const registeredEmails: RegisteredEmail[] = [
  { id: '2', lastVisit: `${new Date()}`, email: 'example5@gmail.com' },
  { id: '3', lastVisit: `${new Date((new Date()).setDate((new Date()).getDate() - 1))}`, email: 'example4@gmail.com' },
  { id: '1', lastVisit: `${new Date(2022, 2, 2)}`, email: 'example@gmail.com' },
];

export const Dashboard: React.FC<DashboardProps> = ({ gallery }) => {
  const { t } = useTranslation('activeGalleries');
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);

  const {
    id,
    name,
    galleryUrl,
    sales,
    comments,
    likes,
    shares,
    views,
    downloads,
    ideas,
    salesPerWeek,
    password,
    expiryDate,
    status,
  } = gallery;

  const handleCloseDashboard = () => {
    setIsOpenDashboard(false);
  };

  const handleOpenDashboard = () => {
    setIsOpenDashboard(true);
  };

  return (
    <>
      <WhiteButtonWithoutShadow type="button" onClick={handleOpenDashboard}>
        {t('enterDashboard')}
      </WhiteButtonWithoutShadow>
      <ModalWindowContainer
        size="large"
        isOpen={isOpenDashboard}
        handleClose={handleCloseDashboard}
      >
        <DashboardHeader
          galleryLink={galleryUrl}
          title={name}
          idGallery={id}
          handleCloseDashboard={handleCloseDashboard}
        />
        <GalleryInfoWrapper>
          <SalesCard sales={sales} salesPerWeek={salesPerWeek} />
          <GalleryInfo
            galleryStatus={status}
            galleryId={id}
            password={password}
            likes={likes?.numberLikes}
            shares={shares}
            views={views}
            downloads={downloads?.numberDownloads}
            expireDate={expiryDate}
          />
        </GalleryInfoWrapper>
        <GalleryTabsDetails
          registeredEmails={registeredEmails}
          comments={comments}
          likes={likes}
          downloads={downloads}
          ideas={ideas}
        />
      </ModalWindowContainer>
    </>
  );
};
