import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCover } from '../../../../shared/types';
import {
  FifthImageContainer,
  FifthMediumText,
  FifthMediumTextMessage,
  FifthTitle,
  GridWithBorderL,
  GridWithBorderR,
} from './styled';
import { useGalleryContent } from '../../hooks/useGalleryContent';

export const GalleryCoverFifth: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    galleryNumber: 'fifth',
  });

  return (
    <Grid
      style={{ marginTop: '20px', marginBottom: '150px' }}
      container
      item
      sm={10}
      spacing={4}
      justifyContent="center"
    >
      <Grid container item sm={12} justifyContent="space-between" alignItems="center">
        <GridWithBorderR item sm={3} container alignItems="center">
          <FifthMediumText>{data.date}</FifthMediumText>
        </GridWithBorderR>
        <Grid item sm={6} container alignItems="center">
          <FifthTitle>{data.name}</FifthTitle>
        </Grid>
        <GridWithBorderL container item sm={3} justifyContent="flex-end" alignItems="center">
          <FifthMediumText>{data.userName}</FifthMediumText>
        </GridWithBorderL>
      </Grid>
      <Grid container item sm={12} justifyContent="center">
        <FifthImageContainer>{data.file}</FifthImageContainer>
      </Grid>
      <Grid item sm={6}>
        <FifthMediumTextMessage>{data.message}</FifthMediumTextMessage>
      </Grid>
    </Grid>
  );
};
