import React from 'react';
import { SpotsPopup } from '../SpotsPopup/SpotsPopup';
import { LandingPriceExplanations } from './LandingPriceExplanations/LandingPriceExplanations';
import { LandingPricingDescription } from './LandingPricingDescription/LandingPricingDescription';
import { LandingPricingInvitation } from './LandingPricingInvitation/LandingPricingInvitation';
import { LandingPricingPlans } from './LandingPricingPlans/LandingPricingPlans';
import { LandingPricingQuestions } from './LandingPricingQuestions/LandingPricingQuestions';

export const LandingPricing: React.FC = () => (
  <>
    <LandingPricingDescription />
    <LandingPricingPlans />
    <LandingPriceExplanations />
    <LandingPricingInvitation />
    <LandingPricingQuestions />
    <SpotsPopup />
  </>
);
