import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { GalleryFile, GalleryIdea } from '../../../../shared/types';
import { isIdea } from '../../../../shared/utils';
import { GallerySwiperButtonsDown } from '../GallerySwiperButtonsDown/GallerySwiperButtonsDown';
import { GallerySwiperButtonsRight } from '../GallerySwiperButtonsRight/GallerySwiperButtonsRight';
import { GallerySwiperContentDescription } from './GallerySwiperContentDescription';
import { GallerySwiperButtonsAndDescriptionWrapper } from './styled';

type ButtonsAndDescriptionProps = {
  currentFile: GalleryFile | GalleryIdea;
}

export const GallerySwiperButtonsAndDescription: React.FC<ButtonsAndDescriptionProps> = ({
  currentFile,
}) => {
  const { t } = useTranslation('gallery');
  const { screenSize } = useCheckWindowSize();

  const isAllowCommenting = useMemo(
    () => !!currentFile.allowCommenting,
    [currentFile],
  );
  const isInstantlyDownloadable = useMemo(
    () => !!(currentFile as GalleryFile).instantlyDownloadable,
    [currentFile],
  );
  const ideaFiles = useMemo(
    () => (currentFile as GalleryIdea)?.files,
    [currentFile],
  );

  const {
    id, price, description, name,
  } = currentFile;

  if (isIdea(currentFile) && screenSize !== 'mobile') {
    return null;
  }

  return (
    <>
      <GallerySwiperButtonsRight
        id={id}
        isAllowCommenting={isAllowCommenting}
        isInstantDownloadable={isInstantlyDownloadable}
      />
      <GallerySwiperButtonsAndDescriptionWrapper>
        <GallerySwiperContentDescription
          name={name}
          price={+(price || 0)}
          description={description || t('noDescription')}
          ideaFiles={ideaFiles}
          isIdea={!!isIdea(currentFile)}
        />
        <GallerySwiperButtonsDown
          isAllowCommenting={isAllowCommenting}
          isInstantDownloadable={isInstantlyDownloadable}
          id={id}
        />
      </GallerySwiperButtonsAndDescriptionWrapper>
    </>
  );
};
