import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../../../../api/base/instance';
import { gallery } from '../../../../../api/gallery/gallery';

export const fetchUploadFiles = createAsyncThunk(
  'gallery/fetchUploadFiles',
  async (
    { data, id, filesIds }: { data: any; id: string; filesIds: string[] },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await instance.post(`gallery/upload/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          dispatch({
            type: 'gallery/fetchUploadFilesProgress',
            percent: progress,
          });
        },
      });
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
