import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { ArrowIcon } from '../../../../shared/icons';
import { MediumGreyText } from '../../../../shared/styles';
import { GallerySwiperDetails } from './styled';

export const GallerySwiperDetailsBtn: React.FC = () => {
  const { t } = useTranslation('gallery');
  const { screenSize } = useCheckWindowSize();

  if (screenSize !== 'mobile') {
    return null;
  }

  const handleClick = () => {
    const container = document?.querySelector('#scrolled-container');
    const containerHeight = container?.scrollHeight || 0;
    container?.scrollTo({
      top: containerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <GallerySwiperDetails
      type="button"
      onClick={handleClick}
    >
      <ArrowIcon direction="down" />
      <MediumGreyText>
        {t('details')}
      </MediumGreyText>
    </GallerySwiperDetails>
  );
};
