import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emailNotificationsAndBrandingValues } from '../../../../../../../constants/initialValues';
import {
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { SettingsWrapper, TextMgB } from '../styled';
import { EmailNotificationsAndBranding as EmailNotAndBranding } from '../../../../../../../shared/types';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import { ListItemContainer } from '../../../../../../../shared/components/FormElements/CommonSwitch/ListItemContainer';

export const EmailNotificationsAndBranding: React.FC = () => {
  const { t } = useTranslation('settings');

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik<EmailNotAndBranding>({
    // validationSchema: clientSchema,
    initialValues: emailNotificationsAndBrandingValues,
    onSubmit: (data) => onSubmit(data),
  });

  return (
    <>
      <SettingsWrapper>
        <TextMgB>
          <SimpleBlackText>{t('emailNotifications')}</SimpleBlackText>
        </TextMgB>
        <Grid container>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.downloadCart}
              handleChange={handleChange}
              name="downloadCart"
              label={t('switches.downloadCart')}
              helpText={t('helpTips.cart')}
              positionHelpText="top"
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.downloadSingleDelivrable}
              handleChange={handleChange}
              name="downloadSingleDelivrable"
              label={t('switches.downloadSingleDelivrable')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.createNewFavouritesList}
              handleChange={handleChange}
              name="createNewFavouritesList"
              label={t('switches.createNewFavouritesList')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.leaveComment}
              handleChange={handleChange}
              name="leaveComment"
              label={t('switches.leaveComment')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.makePurchase}
              handleChange={handleChange}
              name="makePurchase"
              label={t('switches.makePurchase')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.purchaseIdea}
              handleChange={handleChange}
              name="purchaseIdea"
              label={t('switches.purchaseIdea')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.reminderIdea}
              handleChange={handleChange}
              name="reminderIdea"
              label={t('switches.reminderIdea')}
              helpText={t('helpTips.reminder')}
              positionHelpText="top"
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.emailInvite}
              handleChange={handleChange}
              name="emailInvite"
              label={t('switches.emailInvite')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.accountStorageIsFull}
              handleChange={handleChange}
              name="accountStorageIsFull"
              label={t('switches.accountStorageIsFull')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.expireAlert}
              handleChange={handleChange}
              name="expireAlert"
              label={t('switches.expireAlert')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.newFeature}
              handleChange={handleChange}
              name="newFeature"
              label={t('switches.newFeature')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={values.monthlySales}
                handleChange={handleChange}
                name="monthlySales"
                label={t('switches.monthlySales')}
                spaceBetween
                withoutMargins
                disabled
                littleText
              />
            </ListItemContainer>
          </Grid>
          <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={values.receiveReferralReward}
                handleChange={handleChange}
                name="receiveReferralReward"
                label={t('switches.receiveReferralReward')}
                spaceBetween
                withoutMargins
                disabled
                littleText
              />
            </ListItemContainer>
          </Grid>
        </Grid>
      </SettingsWrapper>
      <SettingsWrapper>
        <SimpleBlackText>{t('branding')}</SimpleBlackText>
        <Grid container>
          <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={values.removeBranding}
                handleChange={handleChange}
                name="removeBranding"
                label={t('switches.removeBranding')}
                spaceBetween
                disabled
                littleText
              />
            </ListItemContainer>
          </Grid>
        </Grid>
      </SettingsWrapper>
    </>
  );
};
