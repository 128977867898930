import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyLinkIcon } from '../../icons';
import { useCopyValue } from '../CommonCopyInput/useCopyValue';
import { SimpleTooltip } from '../HelpTip/SimpleTooltip';
import { CopyIconButtonWrapper, CopyLinkButton, TextLinkCopied } from './styled';

type CopyIconButtonProps = {
  value: string;
}

export const CopyIconButton: React.FC<CopyIconButtonProps> = ({
  value,
}) => {
  const { t } = useTranslation('gallery');

  const { isCopied, handleCopy, wasCopiedText } = useCopyValue();

  return (
    <CopyIconButtonWrapper>
      <TextLinkCopied isVisible={isCopied}>
        {wasCopiedText}
      </TextLinkCopied>
      <CopyLinkButton type="button" onClick={handleCopy(value)}>
        <CopyLinkIcon />
        <SimpleTooltip relativeWidth={24} text={t('copyGalleryLink')} />
      </CopyLinkButton>
    </CopyIconButtonWrapper>
  );
};
