import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelectorCommon } from '../../../shared/hooks/useTypedSelector';
import { CustomFile } from '../../../shared/types';
import { fetchGetComments } from '../redux/comments/commentsThunk';
import { FileAndCommentsWindow } from './FileAndCommentsWindow';

type FileAndCommentsWindowContainerProps = {
  file?: CustomFile | null;
  isOpen: boolean;
  handleClose: () => void;
}

export const FileAndCommentsWindowContainer: React.FC<FileAndCommentsWindowContainerProps> = ({
  file,
  isOpen,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const comments = useTypedSelectorCommon((state) => state.comments.comments);

  useEffect(() => {
    dispatch(fetchGetComments());
  }, []);

  if (file) {
    return (
      <FileAndCommentsWindow
        file={file}
        comments={comments}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    );
  }
  return null;
};
