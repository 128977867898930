import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Grid } from '@mui/material';
import * as urls from '../../../../constants/urls';
import {
  FacebookIcon, InstagramIcon, MainLogoNoWhiteSpace, YouTubeIcon,
} from '../../../../shared/icons';
import { TextHeartIcon } from '../../../../shared/icons/Landing';
import { LittleGreyText } from '../../../../shared/styles';
import {
  LandingFooterWrapper,
  LandingFooterLogoWrp,
  LandingFooterTextsWrp,
  LandingFooterLinkWrp,
  LandingFooterRightsWrp,
  LandingFooterLogoText,
  LandingFooterMediaWrp,
} from './styled';
import * as landingLinks from '../../../../constants/urls/landingUrls';

export const LandingFooter: React.FC = () => {
  const { t } = useTranslation(['landing', 'basicComponents']);

  return (
    <LandingFooterWrapper>
      <Grid container spacing={0}>
        <Grid item md={4}>
          <LandingFooterTextsWrp>
            <LandingFooterLinkWrp>
              <a href={urls.termsUrl}><LittleGreyText>{t('basicComponents:terms')}</LittleGreyText></a>
            </LandingFooterLinkWrp>
            <LandingFooterLinkWrp>
              <a href={urls.privacyUrl}><LittleGreyText>{t('basicComponents:privacy')}</LittleGreyText></a>
            </LandingFooterLinkWrp>
            <LandingFooterRightsWrp>
              {t('landing:footer.rights')}
            </LandingFooterRightsWrp>
          </LandingFooterTextsWrp>
        </Grid>
        <Grid item md={4}>
          <LandingFooterLogoWrp>
            <MainLogoNoWhiteSpace />
            <LandingFooterLogoText>
              <Trans t={t} i18nKey="landing:footer.builtWith">
                built with
                {' '}
                <span><TextHeartIcon /></span>
                {' '}
                by creators, for creators
              </Trans>
            </LandingFooterLogoText>
          </LandingFooterLogoWrp>
        </Grid>
        <Grid item md={4}>
          <LandingFooterMediaWrp>
            <NavLink to={landingLinks.instagram}>
              <InstagramIcon color="grey" />
            </NavLink>
            <NavLink to={landingLinks.youtube}>
              <YouTubeIcon color="grey" />
            </NavLink>
            <NavLink to={landingLinks.facebook}>
              <FacebookIcon color="grey" />
            </NavLink>
          </LandingFooterMediaWrp>
        </Grid>
      </Grid>
    </LandingFooterWrapper>
  );
};
