import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { BaseSizes } from '../../../types';

export const CustomCheckbox = styled.label<{ size?: BaseSizes }>`
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 24px;
  ${({ size }) => {
    if (size === 'small') return 'height: 20px; width: 20px;';
    return 'height: 25px; width: 25px;';
  }}
  align-items: center;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ #checkbox::after {
      display: block;
    }
  }

  & #checkbox {
    background: ${variables.colorGreyLight};
    box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    position: absolute;
    left: 0;
    ${({ size }) => {
    if (size === 'small') return 'height: 18px; width: 18px;';
    return 'height: 25px; width: 25px; top: 0;';
  }}

    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      display: none;
    }
  }

  & #checkbox {
    &::after {
      ${({ size }) => {
    if (size === 'small') return 'left: 5px; top: 1px;';
    return 'left: 9px; top: 5px;';
  }}
      width: 5px;
      height: 10px;
      border: solid ${variables.colorPink};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  & > div {
    font-size: 14px;
    line-height: 150%;
    margin-left: 30px;
    color: rgba(5, 27, 44, 0.5);
  }
`;

export const CheckboxInput = styled.label<{size?: BaseSizes, positioned?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: ${({ positioned }) => (positioned ? 'absolute' : 'relative')};
  cursor: pointer;
  ${({ size }) => {
    if (size === 'large') return 'height: 25px; width: 25px;';
    return 'height: 17px; width: 17px;';
  }}
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ #checkbox:after {
      display: block;
    }
  }

  & #checkbox {
    z-index: 10;
    border: 0.5px solid ${variables.textGrey};
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 2.36364px;

    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      display: none;
    }
  }

  & #checkbox {
    &::after {
      left: 5px;
      top: 3px;
      width: 4px;
      height: 7px;
      border: solid ${variables.textGrey};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

export const CheckboxWrapper = styled.div<{size?: BaseSizes}>`
  margin-right: 12px;
  width: fit-content;
  height: fit-content;
`;
