import styled from '@emotion/styled';

export const SignInForm = styled.div`
  margin-top: 28px;
  width: 100%;

  & form {
  display: flex;
  flex-direction: ${({ theme }: { theme: 'row' | 'column' }) => theme};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  }
`;
