import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const FilterButtonElement = styled.button<{chosen: boolean}>`
  padding: 6px 12px;
  border: 1px solid ${variables.colorBlue};
  border-radius: 16px;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  color: ${({ chosen }) => (chosen ? variables.textBlack : variables.textGrey)};
  background: ${({ chosen }) => (chosen ? '#AADEF3' : variables.colorGreyLight)};
  transition: all 0.2s ease-in-out;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    &:hover {
      background: ${variables.colorBlueLight};
      transition: all 0.2s ease-in-out;
    }
  }
`;
