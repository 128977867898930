import React from 'react';
import NumberFormat from 'react-number-format';
import { SetFieldValue } from '../../../types';
import { ErrorMessage } from '../../styled';
import {
  InputAndErrorWrapper, InputAndLabelWrapper,
} from '../styled';
import { TextLabel } from '../TextLabel';
import { PriceInputErrorWrapper } from './styled';

type CommonPriceInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  touched?: boolean | any;
  label?: string;
  direction?: 'row' | 'column';
  disabled?: boolean;
  helpText?: string;
  id?: string;
  withoutMargins?: boolean;
  setFieldValue: SetFieldValue;
};

export const CommonPriceInput: React.FC<CommonPriceInputProps> = ({
  error,
  value,
  name,
  touched,
  direction = 'row',
  disabled,
  label,
  helpText,
  id,
  withoutMargins,
  setFieldValue,
}) => (
  <InputAndLabelWrapper direction={direction} id={id || name} withoutMargins={withoutMargins}>
    <TextLabel name={name} label={label} helpText={helpText} />
    <InputAndErrorWrapper>
      <PriceInputErrorWrapper hasErrors={error && touched}>
        <NumberFormat
          prefix="$"
          placeholder="$0.00"
          thousandSeparator=" "
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          autoComplete="off"
          value={value}
          name={name}
          disabled={disabled}
          onValueChange={({ floatValue }) => setFieldValue(name, floatValue)}
        />
      </PriceInputErrorWrapper>
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </InputAndErrorWrapper>
  </InputAndLabelWrapper>
);
