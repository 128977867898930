import React, { useState } from 'react';
import { gallery } from '../../../../../../../api/gallery/gallery';
import {
  GalleryDataForm,
  GalleryStatus,
} from '../../../../../../../shared/types';
import { getGalleryDataForRequest } from '../../../../../utils/getGalleryDataForRequest';

type ReturnObj = {
  asyncHandleUpdateGallery: (
    data: GalleryDataForm,
    status: GalleryStatus,
    galleryId: string
  ) => Promise<string[]>;
  isSuccess: boolean;
  isLoading: boolean;
  errors: string[];
};

export const useAsyncUpdateGallery = (): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const asyncHandleUpdateGallery = async (
    data: GalleryDataForm,
    status: GalleryStatus,
    galleryId: string,
  ) => {
    setIsLoading(true);
    const dataForRequest = getGalleryDataForRequest(data, status);
    try {
      const response = await gallery.updateGallery(galleryId, dataForRequest);
      setIsLoading(false);
      setIsSuccess(true);
      setErrors([]);
      return errors;
    } catch (err: any) {
      setIsLoading(false);
      setIsSuccess(false);
      setErrors(err.response.data);
      return errors;
    }
  };

  return {
    asyncHandleUpdateGallery,
    isLoading,
    isSuccess,
    errors,
  };
};
