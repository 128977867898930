import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const CartHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 60px;
    margin-bottom: 20px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    & button {
      padding: 0px;
    }
  }
`;

export const CartRemoveChosenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const CartDeleteButtonWrapper = styled.div`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 12px;
  }
`;

export const CartSelectedFilesWrapper = styled.div`
  margin-right: 20px;
`;
