import React, { useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { GalleryFile, GalleryIdea } from '../../../../shared/types';
import { GallerySwiperContent } from '../GallerySwiperContent/GallerySwiperContent';
import { Slide, SwiperContentWrapper } from './styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GallerySwiperWrapper } from '../styled';
import { GallerySwiperArrowBtn } from './GallerySwiperArrowBtn';

type GallerySwiperCarouselProps = {
  handleChange: (index: number) => void;
  files: (GalleryFile | GalleryIdea)[];
  initialSlideIndex: number;
  currentIndex: number;
}

export const GallerySwiperCarousel: React.FC<GallerySwiperCarouselProps> = ({
  handleChange,
  files,
  initialSlideIndex,
  currentIndex,
}) => {
  const carouselRef = useRef<Carousel | null>(null);

  const handleRightClick = () => {
    carouselRef.current?.onClickNext();
  };
  const handleLeftClick = () => {
    carouselRef.current?.onClickPrev();
  };

  return (
    <GallerySwiperWrapper>
      <GallerySwiperArrowBtn
        direction="right"
        handleClick={handleRightClick}
        id="client-go-to-next-piece-step"
        disabled={files.length - 1 === currentIndex}
      />
      <SwiperContentWrapper>
        <Carousel
          ref={carouselRef}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          onChange={handleChange}
          selectedItem={initialSlideIndex}
        >
          {files.map((item) => (
            <Slide key={item.id}>
              <GallerySwiperContent file={item} />
            </Slide>
          ))}
        </Carousel>
      </SwiperContentWrapper>
      <GallerySwiperArrowBtn
        direction="left"
        handleClick={handleLeftClick}
        id="client-go-to-prev-piece-step"
        disabled={currentIndex === 0}
      />
    </GallerySwiperWrapper>
  );
};
