import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { BaseTextCopied } from '../../../../../../../../shared/components/styled';

export const CopyPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CopyPasswordInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & > button {
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
  }

  & svg {
    color: ${variables.colorPink};
  }
`;

export const EyeButton = styled.button`
  display: flex;
  padding: 0;
`;

export const CopyInputAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CopyPasswordButtonWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  position: relative;
`;

export const CopyPasswordTextCopied = styled(BaseTextCopied)`
  top: -25px;
  left: calc(20% - 22px);
`;
