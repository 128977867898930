import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import * as urls from '../../../../../constants/urls';
import { SignUpFirstStep } from './SignUpFirstStep';
import { SignUpSecondStep } from './SignUpSecondStep';
import { SignUpErrors, SignUpInProcess } from '../../../../../shared/types';
import { SignUpSchema } from '../../../../../shared/validation/signInUp/creator/signInUpSchemas';
import {
  ErrorWrapper,
  FormBase,
  SignInUpCard,
  TitleSimple,
} from '../../../../../shared/styles';
import { ProgressBar } from '../styled';
import { useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';
import { signUpInitialValues } from '../../../../../constants/initialValues';
import { getSubmittedSignUp } from '../../../../../shared/utils/signUp/getSubmittedSignUp';
import { ErrorMessage } from '../../../../../shared/components/styled';
import { fetchSignUpCreator } from '../../../redux/userCreator/signUp/signUpCreatorThunk';

const firstStepFields = ['firstName', 'lastName', 'businessName', 'password'];

export const SignUpSteps: React.FC = () => {
  const [step, setStep] = useState(1);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { t } = useTranslation('signUp');
  const { search } = useLocation();
  const dispatch = useDispatch();

  const signUpErrors = useTypedSelectorCreator((state) => state.signUpCreator.error);
  const isSignUp = useTypedSelectorCreator((state) => state.signUpCreator.isSignUp);

  const schema = useMemo(() => SignUpSchema(step), [step]);

  const onSubmit = (
    data: SignUpInProcess,
    bag: FormikHelpers<SignUpInProcess>,
  ) => {
    if (step === 2) {
      setWasSubmitted(true);
      dispatch(fetchSignUpCreator(getSubmittedSignUp(data, search)));
    } else {
      setStep((prev) => prev + 1);
      bag.setSubmitting(false);
      if (signUpErrors?.errors?.length === 0 || !signUpErrors) {
        bag.setTouched({});
      }
    }
  };

  const onBack = () => {
    setStep((prev) => prev - 1);
  };

  const formik = useFormik({
    initialValues: signUpInitialValues,
    validationSchema: schema,
    onSubmit: (data, bag) => onSubmit(data, bag),
  });

  useEffect(() => {
    if (signUpErrors && signUpErrors?.errorType === 'wrongData') {
      signUpErrors.errors.forEach((item) => {
        formik.setFieldError(item.field, item.message);
        if (firstStepFields.includes(item.field) && step >= 2 && wasSubmitted) {
          setStep(1); // back to the 1st step, when just receive an error from the 1st step
        }
        setWasSubmitted(false);
      });
    }
  }, [signUpErrors, step]);

  const notDataErrors = useMemo(() => {
    if (signUpErrors?.errorType === 'other' && signUpErrors.errors.length > 0) {
      return signUpErrors.errors[0].message;
    }
    return null;
  }, [signUpErrors]);

  if (isSignUp) {
    return <Redirect to={urls.creatorSignInUrl} />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCard theme="signup">
          <ProgressBar theme={33 * (wasSubmitted ? step + 1 : step)}>
            <div />
          </ProgressBar>
          <TitleSimple>
            {step === 1 ? t('someMoreDetails') : t('almostDone')}
          </TitleSimple>
          <FormBase theme="column">
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container justifyContent="center">
                <Grid item sm={9}>
                  <ErrorWrapper>
                    {notDataErrors && (
                      <ErrorMessage style={{ maxWidth: '100%', width: '100%' }}>
                        {notDataErrors}
                      </ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid>
                {step === 1 ? (
                  <SignUpFirstStep
                    values={formik.values}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    touched={formik.touched}
                  />
                ) : (
                  <SignUpSecondStep
                    setFieldValue={formik.setFieldValue}
                    onBack={onBack}
                    values={formik.values}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    touched={formik.touched}
                  />
                )}
              </Grid>
            </form>
          </FormBase>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
