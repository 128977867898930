import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const DemoGalleryButtonWrp = styled.button`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
`;

export const DemoGalleryButtonTitle = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 40px;
`;

export const DemoGalleryButtonThumbnail = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    object-fit: contain;
  }
`;
