import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as urls from '../../../constants/urls';
import { ActiveGalleries } from '../containers/CreatorAccount/Tabs/ActiveGalleries/ActiveGalleries';
import { DraftGalleries } from '../containers/CreatorAccount/Tabs/DraftGalleries/DraftGalleries';
import { MainDashboard } from '../containers/CreatorAccount/Tabs/MainDashboard/MainDashboard';
import { Notifications } from '../containers/CreatorAccount/Tabs/Notifications/Notifications';
import { Sales } from '../containers/CreatorAccount/Tabs/Sales/Sales';
import { Settings } from '../containers/CreatorAccount/Tabs/Settings/Settings';
import { Support } from '../containers/CreatorAccount/Tabs/Support/Support';
import { ResetPassword } from '../containers/SignIn/ResetPassword';
import { ResetSuccess } from '../containers/SignIn/ResetSuccess';
import { SignInCard } from '../containers/SignIn/SignInCard';
import { EmailWasConfirmedCard } from '../containers/SignUp/EmailWasConfirmedCard';
import { SignUpEmailConfirmation } from '../containers/SignUp/SignUpEmailConfirmation';
import { SignUpSteps } from '../containers/SignUp/SignUpSteps/SignUpSteps';
import { CreatorPrivateRoute } from './CreatorPrivateRoute';
import { NewGalleryCreatorContainer } from '../containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorContainer';

export const CreatorRoutes: React.FC = () => (
  <Switch>
    <Route path={urls.signUpInitialUrl}>
      <SignUpEmailConfirmation />
    </Route>
    <Route path={urls.creatorSignInUrl}>
      <SignInCard />
    </Route>
    <Route path={urls.resetUrl}>
      <ResetPassword />
    </Route>
    <Route path={urls.signUpConfirmWasSentUrl}>
      <EmailWasConfirmedCard />
    </Route>
    <Route path={urls.resetSuccessUrl}>
      <ResetSuccess />
    </Route>
    <Route path={urls.signUpConfirmUrl}>
      <SignUpSteps />
    </Route>
    <CreatorPrivateRoute path={`${urls.newGalleryUrl}/:id`} component={NewGalleryCreatorContainer} />
    <CreatorPrivateRoute exact path={urls.notificationsUrl} component={Notifications} />
    <CreatorPrivateRoute exact path={urls.salesUrl} component={Sales} />
    <CreatorPrivateRoute exact path={urls.supportUrl} component={Support} />
    <CreatorPrivateRoute path={urls.settingsUrl} component={Settings} />
    <CreatorPrivateRoute exact path={urls.activeGalleriesUrl} component={ActiveGalleries} />
    <CreatorPrivateRoute exact path={urls.draftGalleriesUrl} component={DraftGalleries} />
    <CreatorPrivateRoute exact path={urls.creatorRootUrl} component={MainDashboard} />
    <CreatorPrivateRoute exact path={urls.accountUrl} component={MainDashboard} />
  </Switch>
);
