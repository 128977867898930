import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '../../../../../../../../../shared/icons';
import { MediumGreyText, SimpleBlackText } from '../../../../../../../../../shared/styles';
import { RegisteredEmail } from '../../../../../../../../../shared/types';
import { RegisteredEmailRow } from './RegisteredEmailRow';
import {
  RegisteredEmailExportButton,
  RegisteredEmailTitle,
  TitleMediumBlackTextMgB,
  TitleMediumBlackTextMgBFlexEnd,
} from './styled';

type RegisteredEmailsProps = {
  registeredEmails: RegisteredEmail[];
};

export const RegisteredEmailsTab: React.FC<RegisteredEmailsProps> = ({
  registeredEmails,
}) => {
  const { t } = useTranslation('gallery');

  if (!registeredEmails?.length) return <SimpleBlackText>{t('noEmailRegistrations')}</SimpleBlackText>;

  return (
    <>
      <RegisteredEmailTitle>
        <SimpleBlackText>{t('registeredEmails.title')}</SimpleBlackText>
        <RegisteredEmailExportButton>
          <DownloadIcon />
          <MediumGreyText>{t('registeredEmails.export')}</MediumGreyText>
        </RegisteredEmailExportButton>
      </RegisteredEmailTitle>
      <Grid container spacing={0}>
        <Grid container item sm={12}>
          <Grid item sm={6}>
            <TitleMediumBlackTextMgB fontWeight={500}>
              {t('registeredEmails.visitorEmail')}
            </TitleMediumBlackTextMgB>
          </Grid>
          <Grid item sm={4}>
            <TitleMediumBlackTextMgB fontWeight={500}>
              {t('registeredEmails.lastVisit')}
            </TitleMediumBlackTextMgB>
          </Grid>
          <Grid item sm={2}>
            <TitleMediumBlackTextMgBFlexEnd fontWeight={500}>
              {t('registeredEmails.actions')}
            </TitleMediumBlackTextMgBFlexEnd>
          </Grid>
        </Grid>
        {registeredEmails.map((item) => (
          <RegisteredEmailRow key={item.id} row={item} />
        ))}
      </Grid>
    </>
  );
};
