import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { PrimaryWhiteButton, TextTotalBlackLarge } from '../../../../shared/styles';
import { GalleryIdea } from '../../../../shared/types';
import { getFileInContainer } from '../../../../shared/utils/gallery/getFileInContainer';
import { useAddToCart } from '../../hooks/buttons/useAddToCart';
import { useComment } from '../../hooks/buttons/useComment';
import { GallerySwiperRoundButtons } from '../GallerySwiperButtonsDown/GallerySwiperRoundButtons';
import { IdeaTooltip } from './IdeaTooltip';
import {
  IdeaBigCardContainer,
  IdeaBigCardWrapper,
  IdeaCoverWrapper,
  IdeaDescriptionButtons,
  IdeaFileCardWrapper,
  IdeaFilesSlider,
  IdeaPriceAndButtonWrapper,
} from './styled';

type IdeaBigCardProps = {
  idea: GalleryIdea;
};

export const IdeaBigCard: React.FC<IdeaBigCardProps> = ({ idea }) => {
  const {
    files, cover, name, description, price, id, allowCommenting,
  } = idea;
  const coverInContainer = getFileInContainer(cover);
  const ideaFiles = files.map((item) => getFileInContainer(item));

  const { t } = useTranslation('gallery');
  const { isInCart, handleClickCart } = useAddToCart(false);
  const {
    handleOpenComments, commentArea, isCommentsOpen, numberOfComments,
  } = useComment({});

  return (
    <>
      <IdeaBigCardContainer>
        <IdeaBigCardWrapper>
          <IdeaCoverWrapper>{coverInContainer}</IdeaCoverWrapper>
          <IdeaFilesSlider>
            <div>
              {ideaFiles.map((item) => (
                <IdeaFileCardWrapper>{item}</IdeaFileCardWrapper>
              ))}
            </div>
          </IdeaFilesSlider>
        </IdeaBigCardWrapper>
        <IdeaDescriptionButtons>
          <TextTotalBlackLarge>{name}</TextTotalBlackLarge>
          <IdeaTooltip />
          <TextTotalBlackLarge>{description}</TextTotalBlackLarge>
          <IdeaPriceAndButtonWrapper>
            <TextTotalBlackLarge>
              <CommonPriceInputReadOnly maxWidth={112} value={price} textAlign="left" />
            </TextTotalBlackLarge>
            <PrimaryWhiteButton type="button" onClick={handleClickCart(id)}>
              {t('addToCart')}
            </PrimaryWhiteButton>
          </IdeaPriceAndButtonWrapper>
          <GallerySwiperRoundButtons
            id={id}
            isAllowCommenting={!!allowCommenting}
            numberOfComments={numberOfComments}
            handleOpenComments={handleOpenComments}
            isCommentsOpen={isCommentsOpen}
            isInstantDownloadable={false}
          />
        </IdeaDescriptionButtons>
      </IdeaBigCardContainer>
      {commentArea}
    </>
  );
};
