import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import { fetchResetPasswordCreator, fetchResetPasswordInitialCreator } from './resetPasswordCreatorThunk';

type initialState = {
  isSignIn: boolean;
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  isSignIn: false,
  loading: true,
  error: null,
};

const resetPasswordCreatorSlice = createSlice({
  name: 'resetPasswordCreator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResetPasswordInitialCreator.fulfilled, (state) => {
      state.isSignIn = true;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchResetPasswordInitialCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchResetPasswordInitialCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResetPasswordCreator.fulfilled, (state) => {
      state.isSignIn = false;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchResetPasswordCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchResetPasswordCreator.pending, (state) => {
      state.loading = true;
    });
  },
});

export default resetPasswordCreatorSlice.reducer;
