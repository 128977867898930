import styled from '@emotion/styled';

export const LandingMainFunctionalitiesWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 184px;
  box-sizing: border-box;
  padding: 0px 40px;
  max-height: 850px;

  & .slide {
    max-height: 848px;
  }
`;

export const FunctionalitiesSwiperGifWrapper = styled.div`
  display: flex;
  filter: drop-shadow(-8.17708px -8.17708px 16.3542px #FFFFFF) drop-shadow(8.17708px 8.17708px 16.3542px #E8E8E8);
  border-radius: 26.1667px;
  margin-top: 26px;
  min-width: 797px;
  height: 628px;

  & img {
    object-fit: cover !important;
    border-radius: 26.1667px;
  }
`;
