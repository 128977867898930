import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';

type LandingContentContainerProps = {
  children: ReactElement;
  id?: string;
}

export const LandingContentContainer: React.FC<LandingContentContainerProps> = ({
  children, id = '',
}) => (
  <Grid container spacing={0} justifyContent="center" id={id}>
    <Grid item md={10}>
      {children}
    </Grid>
  </Grid>
);
