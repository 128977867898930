import React from 'react';
import { RoundAvatar } from '../../../shared/components/RoundAvatar/RoundAvatar';
import { CommentTextarea } from './CommentTextarea';
import { CommentItemContainerInput } from './styled';

type CommentItemWithTextareaProps = {
  urlUserAvatar: string;
}

export const CommentItemWithTextarea: React.FC<CommentItemWithTextareaProps> = ({
  urlUserAvatar,
}) => (
  <CommentItemContainerInput>
    <RoundAvatar userName="John Doe" urlPhoto={urlUserAvatar} size="medium" />
    <CommentTextarea />
  </CommentItemContainerInput>
);
