import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../shared/types';
import { Cart, CartSection } from '../../../../shared/types/client';
import {
  fetchAddToCart,
  fetchGetClientCart,
  fetchGetTotalNumberFiles,
  fetchRemoveFromCart,
} from './cartClientThunk';

type initialState = {
  totalFiles: number;
  sections: CartSection[];
  subTotal: number;
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  totalFiles: 0,
  sections: [],
  subTotal: 0,
  loading: false,
  error: null,
};

const cartClientSlice = createSlice({
  name: 'cartClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientCart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.totalFiles = action.payload.totalFiles;
        state.subTotal = action.payload.subTotal;
        state.error = null;
        state.loading = false;
        state.sections = action.payload.sections;
      },
    );
    builder.addCase(
      fetchGetClientCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetClientCart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAddToCart.fulfilled,
      (state, action: PayloadAction<Cart>) => {
        state.totalFiles = action.payload.totalFiles;
        state.subTotal = action.payload.subTotal;
        state.error = null;
        state.loading = false;
        state.sections = action.payload.sections;
      },
    );
    builder.addCase(
      fetchAddToCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchAddToCart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetTotalNumberFiles.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.totalFiles = action.payload.totalFiles;
        state.error = null;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchGetTotalNumberFiles.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetTotalNumberFiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchRemoveFromCart.fulfilled,
      (state, action: PayloadAction<Cart>) => {
        state.totalFiles = action.payload.totalFiles;
        state.subTotal = action.payload.subTotal;
        state.error = null;
        state.loading = false;
        state.sections = action.payload.sections;
      },
    );
    builder.addCase(
      fetchRemoveFromCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchRemoveFromCart.pending, (state) => {
      state.loading = true;
    });
  },
});

export default cartClientSlice.reducer;
