import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  YAxis,
  Area,
  XAxis,
  ResponsiveContainer,
} from 'recharts';
import { GiantText, SalesChartWrapper } from './styled';

const toDollar = (value: number) => `$${value}`;

type ChartProps = {
  data: any[];
  height?: number;
}

export const Chart: React.FC<ChartProps> = ({ data, height = 250 }) => {
  const { t } = useTranslation('sales');

  if (data.length === 0) {
    return <GiantText>{t('noData')}</GiantText>;
  }
  return (
    <SalesChartWrapper>
      <ResponsiveContainer width="100%" height={height + 10}>
        <AreaChart
          height={height}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: -10,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#F180B2" stopOpacity={1} />
              <stop offset="95%" stopColor="#F180B2" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" minTickGap={0} />
          <YAxis tickFormatter={toDollar} />
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#F180B2"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </SalesChartWrapper>
  );
};
