import React, { ReactElement } from 'react';
import {
  ComparingListWrapper,
  ComparingSecondTitle,
  ComparingTitleWrapper,
  LandingMainComparingListWrp,
} from './styled';

type LandingMainComparingListProps = {
  itemsList: ReactElement[];
  title: ReactElement;
  secondTitleText: string;
}

export const LandingMainComparingList: React.FC<LandingMainComparingListProps> = ({
  itemsList,
  title,
  secondTitleText,
}) => (
  <LandingMainComparingListWrp>
    <ComparingTitleWrapper>{title}</ComparingTitleWrapper>
    <ComparingSecondTitle>{secondTitleText}</ComparingSecondTitle>
    <ComparingListWrapper>{itemsList}</ComparingListWrapper>
  </LandingMainComparingListWrp>
);
