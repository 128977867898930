import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { PrimaryWhiteButton, TitleText } from '../../../../../../../../shared/styles';

export const IdeaContainer = styled.div`
  background: ${variables.colorGreyLight};
  padding: 40px 50px 50px 50px;
  position: relative;
  max-width: 674px;
  box-sizing: border-box;
  border-radius: 18px;
`;

export const TextareaHeight = styled.div`
  padding-bottom: 10px;
  height: 220px;
  position: relative;
`;
export const IdeaWrapper = styled.div`
  min-height: 430px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-between;
`;
export const IdeaFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 440px;
`;

export const MiniTitleText = styled(TitleText)`
  font-size: 18px;
  line-height: 17px;
`;

export const CoverContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
`;

export const CoverWrapper = styled.div`
  height: 180px;
  background: #f0f1f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  max-width: 171px;

  & p {
    text-align: center;
    padding: 0 19px;
    color: ${variables.textGrey};
    font-size: 18px;
    line-height: 130%;
  }

  & div {
    margin: 0;
    border-radius: inherit;
  }
`;

export const MiniFileCardsContainer = styled.div<{width: number}>`
  overflow-x: scroll;
  width: 171px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: ${(props) => props.width}px;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;

export const IdeaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IdeaHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const PrimaryWhiteButtonMgT = styled(PrimaryWhiteButton)`
  margin-top: 12px;
`;
