export const isFileAlignedByHeight = (
  containerWidth: number,
  containerHeight: number,
  fileWidth: number,
  fileHeight: number,
): boolean => {
  const widthToWidthCropper = Math.round(fileWidth / containerWidth);

  if (fileHeight / widthToWidthCropper < containerHeight) {
    return true;
  }
  return false;
};
