import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { GalleryDataForm } from '../../../../../../../shared/types';
import { ModalWindowGalleryPreview } from '../NewGalleryCreatorFifthStep/ModalWindowGalleryPreview';
import { SaveAndCloseGallery } from './SaveAndCloseGallery/SaveAndCloseGallery';
import { CreatorOnboardingContext } from '../../../../../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../../../../../context/CreatorOnboardingStepIndexContext';
import { NextAndBackButtons } from './NextAndBackButtons';
import { NewGalleryCreatorButtonsWrapper } from './styled';

type NewGalleryCreatorButtonsProps = {
  step: number;
};

const goToNextStageDuringOnboarding = (stepIndex: number) => (
  stepIndex === 9 || stepIndex === 16 || stepIndex === 22);
const goToPrevStageDuringOnboarding = (stepIndex: number) => (
  stepIndex === 8 || stepIndex === 15 || stepIndex === 21);
const openGalleryPreviewDuringOnboarding = (
  stepIndex: number,
  isOpen: boolean,
) => (stepIndex === 29 || stepIndex === 31) && !isOpen;
const closeGalleryPreviewDuringOnboarding = (
  stepIndex: number,
  isOpen: boolean,
) => (stepIndex === 28 || stepIndex === 32) && isOpen;

export const NewGalleryCreatorButtons: React.FC<NewGalleryCreatorButtonsProps> = ({
  step,
}) => {
  const [isOpenGalleryPreview, setIsOpenGalleryPreview] = useState(false);
  const { values, setFieldValue, handleSubmit } = useFormikContext<GalleryDataForm>();

  const { isStudy } = useContext(CreatorOnboardingContext);
  const { stepIndex } = useContext(CreatorOnboardingStepIndexContext);

  useEffect(() => {
    if (isStudy) {
      if (goToNextStageDuringOnboarding(stepIndex)) {
        const nextStage = step + 1;
        setFieldValue('step', nextStage);
        handleSubmit();
      }
      if (goToPrevStageDuringOnboarding(stepIndex)) {
        const prevStage = step - 1;
        setFieldValue('step', prevStage);
        handleSubmit();
      }
      if (openGalleryPreviewDuringOnboarding(stepIndex, isOpenGalleryPreview)) {
        setIsOpenGalleryPreview(true);
      }
      if (
        closeGalleryPreviewDuringOnboarding(stepIndex, isOpenGalleryPreview)
      ) {
        setIsOpenGalleryPreview(false);
      }
    }
  }, [stepIndex]);

  const handleLeftClick = () => {
    if (values.step === 5) {
      setIsOpenGalleryPreview(true);
    } else if (values.step > 1) {
      const prevStage = step - 1;
      setFieldValue('step', prevStage);
      handleSubmit();
    }
  };
  const handleRightClick = () => {
    if (values.step === 4) {
      setIsOpenGalleryPreview(true);
    } else if (values.step < 5) {
      const nextStage = step + 1;
      setFieldValue('step', nextStage);
      handleSubmit();
    }
  };

  return (
    <>
      <NewGalleryCreatorButtonsWrapper>
        <NextAndBackButtons
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
        <SaveAndCloseGallery />
      </NewGalleryCreatorButtonsWrapper>
      <ModalWindowGalleryPreview
        setIsOpen={setIsOpenGalleryPreview}
        isOpen={isOpenGalleryPreview}
      />
    </>
  );
};
