import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoinEarlyProgramButton } from '../JoinEarlyProgramButton/JoinEarlyProgramButton';
import {
  LandingMainComparingListsContainer,
  LandingMainComparingTitle,
  LandingMainComparingWrp,
} from './styled';
import { LandingMainComparingList } from './LandingMainComparingList';
import { useGetNewWayComparingList, useGetOldWayComparingList } from './useGetComparingList';

export const LandingMainComparing: React.FC = () => {
  const { t } = useTranslation('landing');
  const { listItems: listItemsOld, title: titleOldWay } = useGetOldWayComparingList();
  const { listItems: listItemsNew, title: titleNewWay } = useGetNewWayComparingList();

  return (
    <LandingMainComparingWrp>
      <LandingMainComparingTitle>
        {t('comparing.title')}
      </LandingMainComparingTitle>
      <LandingMainComparingListsContainer>
        <LandingMainComparingList
          itemsList={listItemsOld}
          title={titleOldWay}
          secondTitleText={t('comparing.oldWay.secondTitle')}
        />
        <LandingMainComparingList
          itemsList={listItemsNew}
          title={titleNewWay}
          secondTitleText={t('comparing.newWay.secondTitle')}
        />
      </LandingMainComparingListsContainer>
      <JoinEarlyProgramButton btnTheme="dark" shadowTheme="dark" />
    </LandingMainComparingWrp>
  );
};
