import styled from '@emotion/styled';
import { theme } from '../../../shared/components/GlobalContainer/styled';

export const ContentWrapper = styled.div`
  margin-top: 40px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    box-sizing: border-box;
    padding: 0px 16px;
    margin-top: 0px;
  }
`;
