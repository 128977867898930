import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { GalleryCover } from '../../../../shared/types';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import {
  SixthMediumTextMobile,
  SixthTitleMobile,
  ImageContainerSixthMobile,
  SixthInfoCardCoverMobile,
  SixthDateAndNameWrapper,
} from './styled';

export const GalleryDemoSixthMobile: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const refCardMobile = useRef<HTMLDivElement>(null);
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'sixth',
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ paddingLeft: '47px', paddingRight: '47px', height: '100%' }}
    >
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <ImageContainerSixthMobile>
          {data.file}
          <SixthInfoCardCoverMobile
            ref={refCardMobile}
            currentHeight={refCardMobile?.current?.offsetHeight || 0}
          >
            <div>
              <SixthTitleMobile>{data.name}</SixthTitleMobile>
              <SixthMediumTextMobile>{data.message}</SixthMediumTextMobile>
            </div>
            <SixthDateAndNameWrapper>
              <SixthMediumTextMobile>{data.userName}</SixthMediumTextMobile>
              <SixthMediumTextMobile>{data.date}</SixthMediumTextMobile>
            </SixthDateAndNameWrapper>
          </SixthInfoCardCoverMobile>
        </ImageContainerSixthMobile>
      </Grid>
    </Grid>
  );
};
