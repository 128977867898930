import { Grid } from '@material-ui/core';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { CommonInput } from '../../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { PrimaryWhiteButton } from '../../../../../../../../shared/styles';
import { InvisibleButton } from '../styled';
import { ConfirmCurrentPasswordWindow } from './ConfirmCurrentPasswordWindow';
import { changePassword } from '../../../../../../../../shared/validation/creatorSettings/settingsSchemas';
import { SuccessNotification } from '../../../../../../../../shared/components/SuccessNotification/SuccessNotification';
import { ChangePasswordForm } from './styled';

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false);
  const [isConfirmCurrentPassword, setIsConfirmCurrentPassword] = useState(false);
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);

  const onSubmit = (data: any) => {
    console.log('submit');
    setIsNotificationOpened(true);
    setIsConfirmCurrentPassword(false);
  };

  const {
    values, handleChange, handleBlur, handleSubmit, errors, touched,
  } = useFormik<{ password: string; confirmPassword: string }>({
    validationSchema: changePassword,
    initialValues: { password: '', confirmPassword: '' },
    onSubmit: (data, actions) => { onSubmit(data); actions.resetForm(); },
  });

  const handleConfirm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
  };

  return (
    <Grid item sm={9} style={{ position: 'relative' }}>
      <ChangePasswordForm onSubmit={handleConfirm}>
        <CommonInput
          label={t('changePassword')}
          value={values.password}
          name="password"
          handleChange={handleChange}
          handleBlur={handleBlur}
          direction="column"
          placeholder={t('placeholders.newPassword')}
          error={errors?.password}
          touched={touched?.password}
          type="password"
          disabled={!isConfirmCurrentPassword}
        />
        <div style={{ paddingBottom: '1px' }} />
        <CommonInput
          value={values.confirmPassword}
          name="confirmPassword"
          handleChange={handleChange}
          handleBlur={handleBlur}
          direction="column"
          placeholder={t('placeholders.confirmPassword')}
          error={errors?.confirmPassword}
          touched={touched?.confirmPassword}
          type="password"
          disabled={!isConfirmCurrentPassword}
        />
        <InvisibleButton
          type="button"
          onClick={() => setIsOpenConfirmationWindow(true)}
          isDisplay={!isConfirmCurrentPassword}
        />
        <PrimaryWhiteButton
          style={{ marginTop: '20px' }}
          disabled={!!(!values.confirmPassword || errors?.confirmPassword)}
          type="submit"
        >
          {t('updatePassword')}
        </PrimaryWhiteButton>
      </ChangePasswordForm>
      <ConfirmCurrentPasswordWindow
        isOpen={isOpenConfirmationWindow}
        setIsOpen={setIsOpenConfirmationWindow}
        setIsConfirmed={setIsConfirmCurrentPassword}
      />
      <SuccessNotification
        isOpen={isNotificationOpened}
        text={t('passwordWasChanged')}
        setIsOpen={setIsNotificationOpened}
        longTime
      />
    </Grid>
  );
};
