import React, { ReactElement } from 'react';
import { useCheckWindowSize } from '../../../shared/hooks/useCheckWindowSize';
import { GalleryCover } from '../../../shared/types';
import { GalleryCoverFifth } from '../GalleryCovers/GalleryFifth/GalleryCoverFifth';
import { GalleryCoverFirst } from '../GalleryCovers/GalleryFirst/GalleryCoverFirst';
import { GalleryCoverFourth } from '../GalleryCovers/GalleryFourth/GalleryCoverFourth';
import { GalleryCoverSixth } from '../GalleryCovers/GallerySixth/GalleryCoverSixth';
import { GalleryDemoFirstMobile } from '../GalleryCovers/GalleryFirst/GalleryDemoFirstMobile';
import { GalleryDemoSixthMobile } from '../GalleryCovers/GallerySixth/GalleryDemoSixthMobile';
import { GalleryDemoFifthMobile } from '../GalleryCovers/GalleryFifth/GalleryDemoFifthMobile';
import { GalleryDemoFourthMobile } from '../GalleryCovers/GalleryFourth/GalleryDemoFourthMobile';

export const useGetGalleryCover = (
  props: GalleryCover,
  galleryDesign?: number | null,
): ReactElement => {
  const { screenSize } = useCheckWindowSize();

  switch (galleryDesign) {
    case 1:
      if (screenSize === 'mobile') {
        return <GalleryDemoFirstMobile {...props} />;
      }
      return <GalleryCoverFirst {...props} />;
    // case 4:
    //   if (screenSize === 'mobile') {
    //     return <GalleryDemoFourthMobile {...props} />;
    //   }
    //   return <GalleryCoverFourth {...props} />;
    case 5:
      if (screenSize === 'mobile') {
        return <GalleryDemoFifthMobile {...props} />;
      }
      return <GalleryCoverFifth {...props} />;
    case 6:
      if (screenSize === 'mobile') {
        return <GalleryDemoSixthMobile {...props} />;
      }
      return <GalleryCoverSixth {...props} />;
    default:
      if (screenSize === 'mobile') {
        return <GalleryDemoFirstMobile {...props} />;
      }
      return <GalleryCoverFirst {...props} />;
  }
};
