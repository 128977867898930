import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../../../constants/urls';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import {
  GreyLink,
  MediumBlackTextAndLink,
  SignInUpCard,
  TitleSimple,
  CustomGrid,
  ErrorWrapper,
  PrimaryWhiteButtonBlueTextNoMg,
} from '../../../../shared/styles';
import { SignInSchema } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import { SignIn } from '../../../../shared/types';
import { CreatorStateContext } from '../../context/CreatorStateContext';
import { signInInitialValues } from '../../../../constants/initialValues';
import { useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../shared/components/styled';
import { fetchSignInCreator } from '../../redux/userCreator/signIn/signInCreatorThunk';
import { SignInForm } from './styled';

export const SignInCard: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const { isCreatorSignIn } = useContext(CreatorStateContext);

  const dispatch = useDispatch();
  const signInError = useTypedSelectorCreator((state) => state.signInCreator.error);
  const isLoading = useTypedSelectorCreator((state) => state.signInCreator.loading);

  const onSubmit = (data: SignIn, bag: FormikHelpers<SignIn>) => {
    bag.setSubmitting(false);
    dispatch(fetchSignInCreator(data));
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: signInInitialValues,
    validationSchema: SignInSchema,
    onSubmit: (data: SignIn, bag) => onSubmit(data, bag),
  });

  if (isCreatorSignIn) {
    return <Redirect to={urls.creatorRootUrl} />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCard theme="signin">
          <TitleSimple>{t('basicComponents:signIn')}</TitleSimple>
          <MediumBlackTextAndLink>
            {t('dontHaveAccount')}
            <Link to={urls.signUpInitialUrl}>
              {t('basicComponents:signUp')}
            </Link>
          </MediumBlackTextAndLink>
          <SignInForm theme="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container justifyContent="center">
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.password}
                    value={values.password}
                    name="password"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Password"
                    touched={touched.password}
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <ErrorWrapper>
                    {signInError && <ErrorMessage>{signInError}</ErrorMessage>}
                  </ErrorWrapper>
                </CustomGrid>
              </Grid>
              <PrimaryWhiteButtonBlueTextNoMg type="submit" width="307" disabled={isLoading}>
                {t('basicComponents:signIn')}
              </PrimaryWhiteButtonBlueTextNoMg>
            </form>
          </SignInForm>
          <GreyLink>
            <Link to={urls.resetUrl}>{t('forgotPassword')}</Link>
          </GreyLink>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
