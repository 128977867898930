import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  GalleryDataForm,
  GallerySection,
} from '../../../../../../../shared/types';
import {
  CardsWrapper,
  SectionWrapper,
  TextWithLines,
} from './styled';
import { FileCard } from './FileCard';
import { SectionAddContent } from './SectionAddContent';
import { SectionHeader } from './SectionHeader';
import { DocumentCard } from './DocumentCard';
import { SectionFooter } from './SectionFooter';
import { IdeaCard } from './IdeaCard';
import {
  isDocument,
  isImage,
  isVideo,
} from '../../../../../../../shared/utils/commonFunctions';
import { getSectionErrorsAndTouched } from '../../../../../../../shared/utils/createGallery';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { getIndex } from '../../../../../../../shared/utils/createGallery/getIndex';

type SectionProps = {
  section: GallerySection;
};

export const Section: React.FC<SectionProps> = ({ section }) => {
  const { t } = useTranslation('createNewGallery');

  const [isExpanded, setIsExpanded] = useState(false);
  const {
    setFieldValue, values, errors, touched,
  } = useFormikContext<GalleryDataForm>();

  const indexSection = getIndex(values.sections, section?.id);

  const handleDeleteFile = (id: string) => {
    setFieldValue(
      `sections[${indexSection}].files`,
      section.files.filter((item) => item.id !== id),
    );
  };
  const handleDeleteIdea = (id: string) => {
    setFieldValue(
      `sections[${indexSection}].ideas`,
      section.ideas.filter((item) => item.id !== id),
    );
  };

  const arrImagesAndVideos = useMemo(
    () => section.files
      ?.filter((item) => isImage(item) || isVideo(item))
      .map((item) => (
        <FileCard
          key={item.id}
          handleDeleteFile={handleDeleteFile}
          file={item}
          id={item.id}
        />
      )),
    [section.files],
  );

  const arrIdeas = useMemo(
    () => section?.ideas?.map((item, index) => (
      <IdeaCard
        key={item.name}
        handleDeleteIdea={handleDeleteIdea}
        indexSection={indexSection}
        indexIdea={index}
        idea={item}
      />
    )),
    [section?.ideas],
  );

  const arrImagesIdeas = useMemo(() => {
    const arr = [...arrImagesAndVideos, ...arrIdeas];
    if (!isExpanded) {
      return arr?.slice(0, 2);
    }
    return arr;
  }, [isExpanded, arrImagesAndVideos, arrIdeas]);

  const arrDocuments = useMemo(
    () => section.files?.filter((item) => isDocument(item)),
    [section.files],
  );

  const sectionErrorsAndTouched = useMemo(
    () => getSectionErrorsAndTouched(errors, touched, indexSection),
    [errors, touched, indexSection],
  );

  return (
    <SectionWrapper>
      <SectionHeader section={section} />
      <CardsWrapper>
        <Grid container item sm={12}>
          <SectionAddContent section={section} indexSection={indexSection} />
          {arrImagesIdeas}
        </Grid>
      </CardsWrapper>
      <ErrorMessage>
        {sectionErrorsAndTouched?.error?.files
        && sectionErrorsAndTouched?.wasTouched?.files
          ? sectionErrorsAndTouched?.error?.files
          : null}
      </ErrorMessage>
      {isExpanded && (
        <>
          <TextWithLines>{t('secondStep.otherFiles')}</TextWithLines>
          {' '}
          <CardsWrapper>
            {arrDocuments?.map((item) => (
              <DocumentCard
                file={item}
                handleDelete={handleDeleteFile}
                key={item.id}
              />
            ))}
          </CardsWrapper>
        </>
      )}
      <SectionFooter
        showViewMore={!!(section.files.length + section.ideas.length > 2 || arrDocuments.length)}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        indexSection={indexSection}
      />
    </SectionWrapper>
  );
};
