import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, Statistics } from '../../../../../shared/types';
import { fetchGetStatistics } from './statisticsThunk';

type InitialState = {
  loading: boolean;
  error: ErrorType;
  statistics: Statistics | null;
};

export const initialState: InitialState = {
  statistics: null,
  loading: true,
  error: null,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchGetStatistics.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetStatistics.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default statisticsSlice.reducer;
