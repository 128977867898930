import React, { ReactElement } from 'react';
import {
  CustomSelect,
  PlaceholderText,
  SelectWithErrorMessage,
} from './styled';
import { getStyle } from './getStyleForSelect';
import { ErrorMessage } from '../../styled';
import { SetFieldValue } from '../../../types';
import { TextLabel } from '../TextLabel';

type CommonSelectProps = {
  error?: string;
  value: string | string[];
  name: string;
  handleChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  touched?: boolean;
  label?: string;
  placeholder?: string;
  menuItems: ReactElement[];
  multiple?: boolean;
  setFieldValue?: SetFieldValue;
  disabled?: boolean;
};

function renderValue(option: any, placeholder?: string) {
  if (option.length === 0 || option == null) {
    return <PlaceholderText>{placeholder || ''}</PlaceholderText>;
  }
  if (Array.isArray(option)) {
    const result = option.length > 1 ? `${option[0]} + ${option.length - 1}` : option[0];
    return <span>{result}</span>;
  }
  return option;
}

export const CommonSelect: React.FC<CommonSelectProps> = ({
  error,
  value,
  name,
  handleChange,
  touched,
  label,
  placeholder,
  menuItems,
  multiple,
  setFieldValue,
  disabled,
}) => {
  const onClick = (newValue: typeof value) => {
    if (setFieldValue) {
      setFieldValue(name, newValue);
    }
  };

  return (
    <SelectWithErrorMessage>
      <TextLabel name={name} label={label} />
      <CustomSelect
        id={name}
        name={name}
        value={value}
        onChange={
          !multiple
            ? (e: any, child) => handleChange(e, child)
            : (e) => onClick(e.target.value as string[])
        }
        error={Boolean(error && touched)}
        MenuProps={getStyle()}
        multiple={multiple}
        variant="standard"
        disableUnderline
        renderValue={(v) => renderValue(v, placeholder)}
        displayEmpty
        disabled={disabled}
      >
        {menuItems}
      </CustomSelect>
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </SelectWithErrorMessage>
  );
};
