import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';

export const WatermarkInputWrapper = styled.div`
  width: 40%;
`;

export const WatermarkFileWrapper = styled.div`
  margin-left: 32px;
`;

export const WatermarkTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 7px;
`;

export const WatermarkTooltipText = styled.div`
  max-width: 501px;
  margin-left: 8px;
  font-size: 12px;
  color: ${variables.textGrey};
  line-height: 18px;
`;
