import { GalleryFile } from '../../../shared/types';
import {
  FileAndSectionId,
  Cart,
  CartSection,
} from '../../../shared/types/client';
import { galleryForCLient } from '../../gallery/fakeGalleryRequests';

const cart: Cart = {
  sections: [
    {
      sectionId: 'section1',
      title: 'Wedding',
      files: [],
      filesNumber: 0,
      totalSum: 0,
    },
    {
      sectionId: 'section2',
      title: 'Raw footage',
      files: [],
      filesNumber: 0,
      totalSum: 0,
    },
  ],
  subTotal: 0,
  totalFiles: 0,
};

const addFileToCart = (data: FileAndSectionId): Cart | Error => {
  try {
    const section = galleryForCLient?.sections?.find(
      (item) => [...item.files, ...item.ideas].find((file) => file.id === data.fileId),
    );

    if (section) {
      const file = [...section?.files, ...section?.ideas].find(
        (item) => item.id === data.fileId,
      );
      const currentSectionInCart = cart?.sections?.find(
        (item) => item.sectionId === (data.sectionId || section.id),
      );

      if (currentSectionInCart && file) {
        const {
          sectionId, files, title, totalSum, filesNumber,
        } = currentSectionInCart;

        const changedSection: CartSection = {
          sectionId,
          title,
          filesNumber: filesNumber + 1,
          totalSum:
            totalSum
            + +((file as GalleryFile).instantlyDownloadable
              ? 0
              : file.price || 0),
          files: [...files, file],
        };
        cart.totalFiles += 1;

        const indexOfSection: number = cart.sections.findIndex(
          (item) => item.sectionId === (data.sectionId || section.id),
        );

        const coptOfCartSections = [...cart.sections];
        coptOfCartSections.splice(indexOfSection, 1, changedSection);
        cart.sections = [...coptOfCartSections];
        return cart;
      }
      return new Error('No such file');
    }

    return new Error('No such section');
  } catch (e) {
    return new Error('Smth goes wrong');
  }
};

const sleep = (ms: number) => new Promise(
  (resolve) => setTimeout(resolve, ms),
);

export const fakeCartRequests = {
  getCartNumberFiles: () => Promise.resolve({
    data: { status: 200, totalNumber: cart.totalFiles },
  }),
  getCart: (): Promise<Cart | null> => Promise.resolve(cart),
  addFileToCart: (data: FileAndSectionId): Promise<{ data: Cart }> => {
    try {
      const res = addFileToCart(data);
      if ('sections' in res) {
        return Promise.resolve({ data: res });
      }
      return Promise.reject(res);
    } catch (e) {
      return Promise.reject(new Error('Smth goes wrong'));
    }
  },
  removeItemsFromCart: (
    fileIds: string[],
  ): Promise<{ data: Cart }> => {
    try {
      const changeableSections = [...cart.sections];
      cart.totalFiles -= fileIds.length;

      const newSections = changeableSections.map((section) => {
        const localFiles = section.files.filter((file) => fileIds.includes(file.id));

        let newTotalSum = 0;

        localFiles.forEach((item) => {
          newTotalSum += +((item as GalleryFile).instantlyDownloadable
            ? 0
            : item.price || 0);
        });
        const copySection = section;
        const copyFiles = [...copySection.files];
        const remainingFiles = copyFiles.filter((item) => !fileIds.includes(item.id));

        const {
          sectionId, title, totalSum, filesNumber,
        } = section;

        const newSection: CartSection = {
          sectionId,
          title,
          filesNumber: filesNumber - localFiles.length,
          totalSum: totalSum - newTotalSum,
          files: remainingFiles,
        };

        return newSection;
      });

      cart.sections = [...newSections];

      return Promise.resolve({ data: cart });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  addToCartMultipleItems: async (ids: string[]): Promise<{ data: Cart }> => {
    try {
      await sleep(2000);
      ids.forEach((item) => addFileToCart({ fileId: item }));
      return Promise.resolve({ data: cart });
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
