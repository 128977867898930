import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const HelpTipButton = styled.button<{ position: 'top' | 'left', withMgLeft?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  ${({ withMgLeft }) => (withMgLeft ? 'margin-left: 12px;' : '')}
  padding: 0;

  & > svg {
    color: #D5D5D5;
  }

  &::after {
    content: '';
    width: 50px;
    height: 50px;
    margin: -50px;
    position: absolute;
  }

  &:hover {
    & > div {
      display: flex;
    }
    & > svg {
      color: ${variables.colorBleak};
    }
  }
`;

export const HelpTipText = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  max-width: 193px;
  width: 193px;
  min-width: 172px;
  background: #fff;
  box-shadow: 2px 2px 3px #e7e7e7;
  border-radius: 4px;
  padding: 6px 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;
  display: none;
  line-height: 120%;
  color: rgba(5, 27, 44, 0.8);
  z-index: 5;
  white-space: pre-wrap;
  justify-content: center;
`;

export const SimpleTooltipWrapper = styled.div`
  position: absolute;
`;

export const BaseTooltip = styled.div`
  position: absolute;
  top: -110%;
  width: fit-content;
  background: #fff;
  box-shadow: 2px 2px 3px #e7e7e7;
  border-radius: 4px;
  padding: 6px 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;
  display: block;
  opacity: 0;
  line-height: 120%;
  color: rgba(5, 27, 44, 0.8);
  z-index: 5;
  white-space: pre;
`;

export const SimpleTooltipText = styled.div<{relativeWidth: number}>`
  position: absolute;
  top: -110%;
  width: fit-content;
  background: #fff;
  box-shadow: 2px 2px 3px #e7e7e7;
  border-radius: 4px;
  padding: 6px 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;
  display: block;
  opacity: 0;
  line-height: 120%;
  color: rgba(5, 27, 44, 0.8);
  z-index: 5;
  white-space: pre;
  transform: translateX(calc(-50% + ${({ relativeWidth }) => relativeWidth / 2}px));
`;

export const SimpleTooltipButton = styled.button`
  padding: 0px;
  position: relative;

  &:hover {
    & > #tooltip {
      display: flex;
    }
  }
`;

export const SimpleTooltipHover = styled.div`
  &:hover {
    & > #tooltip {
      display: flex;
    }
  }
`;
