import React from 'react';
import mainImg from '../../../../assets/image/landing/mainImg.png';
import mainImgShadows from '../../../../assets/image/landing/mainImgShadows.png';
import { PositionedImg } from './styled';

export const LandingMainImg: React.FC = () => (
  <>
    <PositionedImg src={mainImgShadows} alt="main-shadows" />
    <PositionedImg src={mainImg} alt="main" />
  </>
);
