import React, { ReactNode } from 'react';
import { DocumentIcon } from '../../../icons';
import { GalleryFile } from '../../../types';
import { FileLoader } from '../../Loaders/FileLoader/FileLoader';
import { TextCard } from './styled';

type BasicDocumentCardProps = {
  file: GalleryFile;
  children?: ReactNode;
  isSmall?: boolean;
};

export const BasicDocumentCard: React.FC<BasicDocumentCardProps> = ({
  file,
  children,
  isSmall,
}) => (
  <>
    <TextCard inheritSize={!!isSmall} withChildren={!!children} id="basic-doc">
      <DocumentIcon />
      <span>{file.name}</span>
      {children}
      <FileLoader isLoading={!!(file?.id && !file.url)} />
    </TextCard>
  </>
);
