import { AxiosResponse } from 'axios';
import { GalleryForRequest } from '../../shared/types';
import { instance } from '../base/instance';

const galleryBaseUrl = 'gallery';

export const gallery = {
  deleteGallery: (id: string): Promise<AxiosResponse<any>> => instance.delete(`${galleryBaseUrl}/${id}`),
  createGallery: (data: { id: string }): Promise<AxiosResponse<any>> => instance.post(`${galleryBaseUrl}`, data),
  uploadFiles: (form: FormData, id: string): Promise<AxiosResponse<any>> => instance.post(`${galleryBaseUrl}/upload/${id}`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 50;
    },
  }),
  getGalleries: (): Promise<AxiosResponse<any>> => instance.get(`${galleryBaseUrl}`),
  updateGallery: (id: string, data: GalleryForRequest): Promise<AxiosResponse<any>> => instance.put(`${galleryBaseUrl}/${id}`, data),
  confirmFileWasUploaded: (id: string): Promise<AxiosResponse<any>> => instance.get(`${galleryBaseUrl}/urltest/${id}`),
};
