import styled from '@emotion/styled';
import { BaseColors } from '../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../shared/utils/style/getBaseColor';

export const IconsTopRightWrapper = styled.div<{color: BaseColors}>`
  position: absolute;
  right: 12px;
  top: 10px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  z-index: 3;

  & > * {
    padding: 0;
    margin-left: 5px;
  }

  & > svg {
    color: ${(props) => getBaseColor(props.color)};
  }
`;

export const IconsBottomRightWrapper = styled(IconsTopRightWrapper)`
  top: calc(100% - 34px);
`;
