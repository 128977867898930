import { useFormikContext } from 'formik';
import React from 'react';
import { CustomFile, GalleryDataForm } from '../../../shared/types';
import {
  getFilteredUploadingFiles,
  getFormDataAndFileObjects,
  getFilesWithoutUrl,
} from '../utils/getGalleryDataForRequest';
import { useUploadForm } from './useUploadForm';

type ReturnObj = {
  sendAndSetFiles: (
    fieldName: string,
    alreadyUploadedFiles: CustomFile[],
    uploadingFiles: File[],
  ) => void;
};

export const useSendAndSetFiles = (): ReturnObj => {
  const { setFieldValue, values } = useFormikContext<GalleryDataForm>();
  const { uploadForm } = useUploadForm();

  const sendAndSetFiles = async (
    fieldName: string,
    alreadyUploadedFiles: CustomFile[],
    uploadingFiles: File[],
  ) => {
    if (uploadingFiles?.length) {
      const filteredFiles = getFilteredUploadingFiles(uploadingFiles, alreadyUploadedFiles);
      const { formData, ids } = getFormDataAndFileObjects(filteredFiles);
      const preUploadingFiles = getFilesWithoutUrl(filteredFiles, ids);
      setFieldValue(fieldName, [
        ...alreadyUploadedFiles,
        ...preUploadingFiles,
      ]);
      const uploadedFiles = await uploadForm(formData, values.id, ids);
      setFieldValue(fieldName, [
        ...alreadyUploadedFiles,
        ...uploadedFiles,
      ]);
    }
  };

  return {
    sendAndSetFiles,
  };
};
