import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  LoaderFullWidthContainer,
  TabContentWrapper,
  TabWrapper,
  TitleText,
} from '../../../../../../shared/styles';
import { ActiveGalleriesCards } from './ActiveGalleriesCards';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useGetAllGalleriesQuery } from '../../../../redux/gallery/galleryAPI';
import { Gallery } from '../../../../../../shared/types';

export const ActiveGalleries: React.FC = () => {
  const { t } = useTranslation('activeGalleries');
  const { data, isLoading } = useGetAllGalleriesQuery(undefined);
  const galleries = (data?.activeGalleries || []) as unknown as Gallery[];

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {isLoading ? (
          <LoaderFullWidthContainer><PinkLoader /></LoaderFullWidthContainer>
        ) : (
          <Grid container spacing={4}>
            <ActiveGalleriesCards galleries={galleries} />
          </Grid>
        )}
      </TabContentWrapper>
    </TabWrapper>
  );
};
