import React from 'react';
import { LandingSwiperBase } from '../LandingSwiperBase/LandingSwiperBase';
import {
  LandingMainFeaturesSwiperWrp,
} from './styled';
import { useGetFeaturesSlides } from './useGetFeaturesSlides';

export const LandingMainFeaturesSwiper: React.FC = () => {
  const { slides } = useGetFeaturesSlides();

  return (
    <LandingMainFeaturesSwiperWrp>
      <LandingSwiperBase slides={slides} autoPlay />
    </LandingMainFeaturesSwiperWrp>
  );
};
