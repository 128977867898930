import * as Yup from 'yup';
import { maxPriceLength, messageMaxLength } from '../../../constants/validationValues';
import { errorMessages } from '../../utils/errorsMessages';

export const galleryFileInformationSchema = Yup.object().shape({
  description: Yup.string().max(messageMaxLength, 'Too long'),
  price: Yup.string()
    .required(errorMessages.requiredField)
    .max(maxPriceLength, errorMessages.tooLong),
});
