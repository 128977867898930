import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { invalidPasswordOrEmail } from '../../../../../constants/validationValues';
import { ErrorType } from '../../../../../shared/types';
import { fetchLogOutCreator, fetchSignInCreator } from './signInCreatorThunk';

type initialState = {
  isSignIn: boolean;
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  isSignIn: false,
  loading: false,
  error: null,
};

const signInCreatorSlice = createSlice({
  name: 'signInCreator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignInCreator.fulfilled, (state) => {
      state.isSignIn = true;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchSignInCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = invalidPasswordOrEmail;
        state.loading = false;
      },
    );
    builder.addCase(fetchSignInCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLogOutCreator.fulfilled, (state) => {
      state.isSignIn = false;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchLogOutCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.isSignIn = false;
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchLogOutCreator.pending, (state) => {
      state.loading = true;
    });
  },
});

export default signInCreatorSlice.reducer;
