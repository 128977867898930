import React, { Dispatch, SetStateAction, useState } from 'react';
import { GalleryFileInformation, GallerySection } from '../../../../../../../shared/types';
import { errorMessages } from '../../../../../../../shared/utils';

type ReturnObj = {
  formError: string;
  setFormError: Dispatch<SetStateAction<string>>;
  handleApplySettings: (e: any, currentSection: GallerySection) => void;
  settingsApplied: boolean;
};

type ApplyFileSettingsProps = {
  isValid: boolean;
  values: GalleryFileInformation;
  handleSubmit: () => void;
  isItemSelected: (id: string) => boolean;
  removeAllSelected: () => void;
};

export const useApplyFileSettings = ({
  isValid,
  values,
  handleSubmit,
  isItemSelected,
  removeAllSelected,
}: ApplyFileSettingsProps): ReturnObj => {
  const [formError, setFormError] = useState<string>('');
  const [settingsApplied, setSettingsApplied] = useState(false);

  const handleApplySettings = (e: any, currentSection: GallerySection) => {
    e.preventDefault();
    handleSubmit();
    const ideas = [...currentSection.ideas];
    if (isValid) {
      setSettingsApplied(true);
      setTimeout(() => {
        try {
          currentSection?.files?.forEach((item) => {
            if (isItemSelected(item.id)) {
              Object.assign(item, values);
            }
          });
          ideas.forEach((item) => {
            if (isItemSelected(item.id)) {
              Object.assign(item, {
                instantlyDownloadable: values.instantlyDownloadable,
                watermark: values.watermark,
                allowCommenting: values.allowCommenting,
              });
            }
          });
        } catch (err: any) {
          setFormError(errorMessages.wasNotSaved);
          console.log(err);
        }
        removeAllSelected();
        setSettingsApplied(false);
      }, 1000);
    }
  };

  return ({
    formError,
    setFormError,
    handleApplySettings,
    settingsApplied,
  });
};
