import styled from '@emotion/styled';
import { CalendarContainer } from 'react-datepicker';
import * as variables from '../../../../constants/styles';

export const DatePickerCustom = styled.div<{withPlaceholder: boolean}>`
  font-family: ${variables.mainFont};
  font-size: 15px;
  line-height: 110%;
  color: ${(props) => (props.withPlaceholder ? variables.colorBleak : variables.textGrey)};
  height: 43px !important;
  cursor: pointer;
  width: 100%;
  position: relative;
  margin: 0 12px 0 0;
  border-radius: 50px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  border: 0.5px solid #e0e0e0;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  padding: 12px 10px 12px 24px;
  ${({ theme }) => (theme === 'error' ? `box-shadow: none; border: 0.5px solid ${variables.colorError};` : '')}
  & svg {
    color: ${variables.colorBleak};
    position: absolute;
    right: 20px;
    bottom: 11.5px;
  }

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;

    & svg {
      color: ${variables.textGrey};
    }
  }

  & .react-datepicker-wrapper {
    height: 43px !important;
  }
`;
export const CalendarContainerCustom = styled(CalendarContainer)`
  font-size: 12px;
  line-height: 18px;
  margin-top: -10px;
  font-family: ${variables.mainFont};

  & .react-datepicker__navigation {
    top: 10px;
  }

  & .react-datepicker__month-container {
    font-family: ${variables.mainFont};
    background: ${variables.colorGreyLight} !important;
    box-shadow: -5px -5px 10px #fff, 5px 5px 10px #e8e8e8 !important;
    border-radius: 10px !important;
    color: ${variables.colorBlack};
    width: 256px;
    height: 292px;
    font-size: 12px !important;
    line-height: 18px;
    padding-bottom: 15px;

    & .react-datepicker__header {
      font-family: ${variables.mainFont};
      background: ${variables.colorGreyLight} !important;
      padding-right: 10px;
      padding-left: 10px;
      border-bottom: none;
      font-size: 12px !important;
    }

    & .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-family: ${variables.mainFont};
      margin-bottom: 20px;
      margin-top: 10px;
      font-weight: 500;
      font-size: 12px !important;
    }

    & .react-datepicker__day--outside-month {
      font-family: ${variables.mainFont};
      color: ${variables.colorBleak};
      font-size: 12px !important;
    }

    & .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      font-family: ${variables.mainFont};
      background: ${variables.colorPink};
      border-radius: 100px;
      border: none;
      outline: none;
      font-size: 12px !important;
    }

    & .react-datepicker__day {
      font-family: ${variables.mainFont};
      box-sizing: border-box;
      vertical-align: middle;
      font-weight: normal;
      border: none;
      font-size: 12px !important;
    }

    & .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-family: ${variables.mainFont};
      width: 28px;
      height: 28px;
      position: relative;
      border: none;
      font-size: 12px !important;

      &:hover {
        border-radius: 100px;
      }
    }
  }
`;
export const CustomDay = styled.span`
  position: absolute;
  width: 16px;
  text-align: center;
  left: 50%;
  top: -3px;
  margin-left: -8px;
  border: none;
  font-size: 12px !important;
  font-family: ${variables.mainFont};
`;
export const CustomSelectYearContainer = styled.div`
  font-family: ${variables.mainFont};
  position: absolute;
  z-index: 100;
  background: ${({ theme }) => (theme === 'expanded' ? '#f8f8f8' : 'none')};
  margin-top: 15px;
  height: 240px;
  width: 255px;
  border-radius: 10px;
  left: -20px;
  border: none;
  padding: 0 7px 14px;
  box-sizing: border-box;
  font-size: 12px !important;

  & div {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    padding-right: 7px;
    font-size: 12px !important;
    font-family: ${variables.mainFont};

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 100px;
      margin-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${variables.colorBleak};
      border-radius: 100px;
      margin-right: 10px;
    }
  }
`;
export const YearButton = styled.button`
  font-family: ${variables.mainFont};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 11px 4px;
  background: ${({ theme }) => (theme === 'selected' ? variables.colorPink : 'transparent')};
  color: ${({ theme }) => (theme === 'selected' ? 'white' : '')};
  position: relative;
  border-radius: 100px;
  width: 52px;
  height: 28px;
  font-size: 12px !important;
`;
export const CustomHeaderDatePicker = styled.div`
  font-family: ${variables.mainFont};
  margin: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 14px;
`;
export const ButtonSelectArrow = styled.button`
  font-size: 14px !important;
  font-family: ${variables.mainFont};

  &::after {
    content: "";
    position: absolute;
    left: 100%;
    border: 4px solid transparent;
    bottom: ${({ theme }) => (theme !== 'expanded' ? '5px' : '10px')};
    ${({ theme }) => (theme !== 'expanded'
    ? 'border-top: 4px solid black;'
    : 'border-bottom: 4px solid black;')}
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  width: 38px;
  justify-content: space-between;

  & button {
    position: relative;
  }
`;
