import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SimpleBlackText,
  TextBlackLarge,
} from '../../../../../../shared/styles';
import { HeaderGalleryCard } from './styled';
import { ViewAllLink } from '../../../../../../shared/components/ViewAllLink/ViewAllLink';
import {
  GalleryCardCover,
  GalleryCardWrapper,
} from '../../../../../../shared/components/GalleryCard/styled';
import { CustomFile, Gallery, GalleryForRequest } from '../../../../../../shared/types';
import { getFileInContainer } from '../../../../../../shared/utils/gallery/getFileInContainer';

type GalleryCardCustomProps = {
  gallery: Gallery | null | GalleryForRequest;
  galleriesNumber: number;
  type: 'Active' | 'Draft';
  toUrl: string;
};

export const GalleryCardCustom: React.FC<GalleryCardCustomProps> = ({
  gallery,
  galleriesNumber = 0,
  type,
  toUrl,
}) => {
  const { t } = useTranslation(['gallery', 'basicComponents']);

  const coverImageUrl = gallery ? gallery.coverFile : '';
  const fileElement = coverImageUrl ? (
    getFileInContainer({
      name: 'cover',
      url: coverImageUrl,
      type: 'image',
      id: coverImageUrl,
    })
  ) : (
    <SimpleBlackText>{t('gallery:noCoverPhoto')}</SimpleBlackText>
  );

  return (
    <Grid item sm={6}>
      <GalleryCardWrapper>
        <HeaderGalleryCard>
          <TextBlackLarge>
            <Trans t={t} i18nKey="gallery:galleriesWithNumber">
              {{ type }}
              {' '}
              galleries (
              {{ galleries: galleriesNumber }}
              )
            </Trans>
          </TextBlackLarge>
          {galleriesNumber ? (
            <ViewAllLink label={t('basicComponents:viewAll')} to={toUrl} />
          ) : null}
        </HeaderGalleryCard>
        <GalleryCardCover>
          {galleriesNumber ? (
            fileElement
          ) : (
            <SimpleBlackText>{t('gallery:noGalleries')}</SimpleBlackText>
          )}
        </GalleryCardCover>
      </GalleryCardWrapper>
    </Grid>
  );
};
