import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import {
  ModalWindowFull,
  FadeFull,
  FadeMedium,
  ModalWindowMedium,
  ModalWindowLarge,
  FadeLarge,
  SimpleFade,
  SimpleModalWindow,
} from './styled';
import { ModalWindowSize } from '../../types';

type ContainerProps = {
  children: ReactNode;
  isOpen: boolean;
};

export const getContainerBySize = (
  props: ContainerProps,
  size?: ModalWindowSize,
  style?: CSSProperties,
): ReactElement => {
  switch (size) {
    case 'full':
      return (
        <FadeFull in={props.isOpen}>
          <ModalWindowFull id="scrolled-container" style={style}>
            {props.children}
          </ModalWindowFull>
        </FadeFull>
      );
    case 'large':
      return (
        <FadeLarge in={props.isOpen}>
          <ModalWindowLarge>{props.children}</ModalWindowLarge>
        </FadeLarge>
      );
    case 'medium':
      return (
        <FadeMedium in={props.isOpen}>
          <ModalWindowMedium>{props.children}</ModalWindowMedium>
        </FadeMedium>
      );
    default:
      return (
        <SimpleFade in={props.isOpen}>
          <SimpleModalWindow id="simple-window" style={{ borderRadius: '0', ...style }}>{props.children}</SimpleModalWindow>
        </SimpleFade>
      );
  }
};
