import React from 'react';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { GalleryFile, GalleryIdea } from '../../../../shared/types';
import { getFileOrIdeaForSwiper } from '../../utils/getFileInWrapper';
import { GalleryContentContainer, GallerySwiperFileWrapper } from './styled';

type GallerySwiperContentProps = {
  file: GalleryFile | GalleryIdea;
};

export const GallerySwiperContent: React.FC<GallerySwiperContentProps> = ({
  file,
}) => {
  const { screenSize } = useCheckWindowSize();
  const fileInWrapper = getFileOrIdeaForSwiper(file, screenSize);

  return (
    <GalleryContentContainer id="gallery-content-container">
      <GallerySwiperFileWrapper>
        {fileInWrapper}
      </GallerySwiperFileWrapper>
    </GalleryContentContainer>
  );
};
