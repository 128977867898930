import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { GalleryCard } from '../../../../../../shared/components/GalleryCard/GalleryCard';
import { Dashboard } from './Dashboard/Dashboard';
import { Gallery } from '../../../../../../shared/types';

type ActiveGalleriesCardsProps = {
  galleries: Gallery[];
}

export const ActiveGalleriesCards: React.FC<ActiveGalleriesCardsProps> = ({ galleries }) => {
  const { t } = useTranslation('activeGalleries');

  return (
    <>
      {galleries.length
        ? (
          galleries.map((item) => (
            <Grid
              key={item?.id}
              item
              sm={6}
            >
              <GalleryCard
                title={item?.name}
                coverFile={{
                  id: item?.coverFile,
                  type: 'image',
                  name: item?.coverFile,
                  url: item?.coverFile,
                }}
                wasWatchedByClient={item?.wasWatched}
                galleryId={item?.id}
              >
                <Dashboard
                  gallery={item}
                />
              </GalleryCard>
            </Grid>
          ))
        )
        : (
          <Grid item sm={6}>
            <SimpleBlackText>{t('noActiveGalleries')}</SimpleBlackText>
          </Grid>
        )}
    </>
  );
};
