import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';
import { SimpleGreyText } from '../../../../../../../shared/styles';

export const AccordionExpandIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: -43px;
  top: -15px;
  background: ${variables.colorGreyLight};
  box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;

  & div {
    width: 10px;
    height: 10px;

    &:after,
    &:before {
      background: linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
    }
    &:after {
      width: 10px;
      height: 1px;
      top: 5px;
    }
    &:before {
      width: 1px;
      height: 11px;
      left: 4.5px;
    }
  }
`;

export const AccordionsWrapper = styled.div`
  padding-left: 30px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const SimpleGreyTextCustomColor = styled(SimpleGreyText)`
  color: #45525B;
`;
