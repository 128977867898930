import { useFormikContext } from 'formik';
import React from 'react';
import { GalleryData } from '../../../../../../../shared/types';
import { FormGalleryWrapper } from '../styled';
import { SectionThirdStep } from './SectionThirdStep';

export const NewGalleryCreatorThirdStep: React.FC = () => {
  const { values } = useFormikContext<GalleryData>();

  return (
    <FormGalleryWrapper>
      {values?.sections?.map((item) => (
        <SectionThirdStep key={item.id} section={item} />
      ))}
    </FormGalleryWrapper>
  );
};
