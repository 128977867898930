import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShowHideButton } from './styled';
import { PinkLink } from '../../../../../../../../shared/styles';
import { ListItemWithIcon } from '../../../../../../../../shared/components/ListItemWithPinkTick/ListItemWithIcon';

type PlanCardDetailsProps = {
  details: string[];
};

export const PlanCardDetails: React.FC<PlanCardDetailsProps> = ({
  details,
}) => {
  const { t } = useTranslation('settings');
  const [showDetails, setShowDetails] = useState(false);

  const handleShowHideDetails = () => {
    setShowDetails((prev) => !prev);
  };

  return (
    <>
      <div>
        <ShowHideButton
          style={{ padding: 0 }}
          type="button"
          onClick={handleShowHideDetails}
        >
          <PinkLink>
            {showDetails ? t('hideDetails') : t('showDetails')}
          </PinkLink>
        </ShowHideButton>
      </div>
      {showDetails && (
      <div>
        {details.map((item) => (
          <ListItemWithIcon item={item} />
        ))}
      </div>
      )}
    </>
  );
};
