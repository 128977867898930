import React from 'react';
import { GalleryComment, CustomFile } from '../../../shared/types';
import { ModalWindowContainer } from '../../../shared/components/ModalWindow/ModalWindowContainer';
import {
  FileAndCommentsWindowWrapper,
  FileContainer,
  GalleryCommentsArea,
} from './styled';
import { CommentContainer } from './CommentContainer';

type FileAndCommentsWindowProps = {
  file: CustomFile;
  comments: GalleryComment[];
  isOpen: boolean;
  handleClose: () => void;
};

export const FileAndCommentsWindow: React.FC<FileAndCommentsWindowProps> = ({
  file,
  comments,
  isOpen,
  handleClose,
}) => (
  <ModalWindowContainer size="full" isOpen={isOpen} handleClose={handleClose}>
    <FileAndCommentsWindowWrapper>
      <FileContainer>
        <img src={file?.url || ''} data-src={file?.url || ''} alt={file.name} />
      </FileContainer>
      <GalleryCommentsArea>
        <CommentContainer comments={comments} handleClose={handleClose} />
      </GalleryCommentsArea>
    </FileAndCommentsWindowWrapper>
  </ModalWindowContainer>
);
