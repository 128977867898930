import styled from '@emotion/styled';
import { DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';
import * as variables from '../../../constants/styles';
import { RowWrapper } from '../../styles';
import { theme } from '../GlobalContainer/styled';

export const getAlertStyles = (confirmation: boolean) => ({
  border: `0.5px solid ${confirmation ? '#eoeoeo' : 'red'}`,
});

export const CustomTitleDialog = styled(DialogTitle)<{confirmation: boolean}>`
  & h2 {
    font-weight: normal;
    font-family: ${variables.mainFont} !important;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    color: ${(props) => (props.confirmation ? variables.textBlack : variables.colorOrange)};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 16px 24px 6px;

    & h2 {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const CustomDialogContentText = styled(DialogContentText)`
  font-weight: normal;
  font-family: ${variables.mainFont} !important;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textBlack};
`;
export const CustomDialogActions = styled(DialogActions)`
  justify-content: flex-start;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;

  & svg {
    color: ${variables.textBlack};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
`;
export const ButtonsWrapper = styled(RowWrapper)`
  padding: 0 16px;
  display: flex;
  flex-direction: row;

  & * {
    margin-right: 40px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0px 0px 0px 16px;

    & * {
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }
    }
  }
`;
