import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalContainer } from '../../../shared/components/GlobalContainer/GlobalContainer';
import { LandingContainer } from '../../Landing/containers/LandingContainer';
import * as urls from '../../../constants/urls';
import { CreatorContainer } from './CreatorContainer';

export const CreatorAndLandingContainer: React.FC = () => (
  <Router basename="/">
    <Switch>
      <Route path={urls.creatorRootUrl}>
        <GlobalContainer>
          <CreatorContainer />
        </GlobalContainer>
      </Route>
      <Route path={urls.rootUrl}>
        <LandingContainer />
      </Route>
    </Switch>
  </Router>
);
