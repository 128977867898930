import React, { useEffect, useState } from 'react';
import { theme } from '../components/GlobalContainer/styled';
import { ScreenSizeNames } from '../types';

type ReturnObj = {
  screenSize: ScreenSizeNames;
}

export const useCheckWindowSize = (): ReturnObj => {
  const [screenSize, setScreenSize] = useState<ScreenSizeNames>('desktop');

  useEffect(() => {
    if (window.screen.width <= theme.breakpoints.values.sm) {
      setScreenSize('mobile');
    } else {
      setScreenSize('desktop');
    }
  }, [window.screen.width]);

  function detectWindowSize() {
    if (window.screen.width <= theme.breakpoints.values.sm) {
      setScreenSize('mobile');
    } else {
      setScreenSize('desktop');
    }
  }

  window.onresize = detectWindowSize;

  return ({
    screenSize,
  });
};
