import styled from '@emotion/styled';
import { StylesConfig } from 'react-select';
import { Option } from '../../../types';
import * as variables from '../../../../constants/styles';
import { errorMessages } from '../../../utils/errorsMessages';

export const InputChipsWrapper = styled.div`
  & svg {
    color: ${variables.textBlack} !important;
    width: 12px;
    height: 12px;
  }

  margin-top: -10px;
`;

type IsMulti = true;
export const getInputChipsStyles = (error: string, touched: boolean) => {
  const selectStyle: StylesConfig<Option, IsMulti> = {
    control: () => ({
      overflowY: 'auto',

      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#dbdbdb',
        borderRadius: '163px',
      },
      '::-webkit-scrollbar-thumb': {
        background: variables.textGrey,
        borderRadius: '163px',
      },
      height: 120,
      background: 'transparent',
      fontSize: 15,
      boxShadow: 'none',
      borderRadius: 16,
      paddingRight: '12px',
      marginTop: '12px',
      fontFamily: variables.mainFont,
      boxSizing: 'border-box',
      border: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: () => ({
      color: variables.colorBleak,
      gridArea: '1/1/2/3',
      boxSizing: 'border-box',
      fontSize: 15,
    }),
    input: (provided) => ({
      ...provided,
      color: variables.textGrey,
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: `1px solid ${error ? variables.colorError : variables.colorBleak}`,
      boxSizing: 'border-box',
      borderRadius: 4,
      width: '100%',
      marginRight: '42px',
    }),
    multiValueLabel: () => ({
      background: variables.colorGreyLight,
      padding: '6px 8px 6px 12px',
      border: 'none',
      fontSize: 14,
      lineHeight: '150%',
      color: variables.textBlack,
      width: '100%',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      background: variables.colorGreyLight,
      color: `${variables.textBlack} !important`,
      cursor: 'pointer',
      paddingLeft: 4,
      paddingRight: 8,
      ':hover': {
        backgroundColor: variables.colorGreyLight,
      },
    }),
  };
  return selectStyle;
};
