import React, { ReactElement } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CloseIcon } from '../../../../../shared/icons';
import {
  GradientColorText,
  MediumWhiteText,
} from '../../../../../shared/styles';
import {
  ComparingListTitle,
  ComparingListItem,
  ComparingWhiteTick,
  ComparingBlueText,
  ComparingPinkText,
} from './styled';
import {
  LittleTickIcon,
} from '../../../../../shared/icons/CommonIcons';

type ReturnObj = {
  listItems: ReactElement[];
  title: ReactElement;
};

export const useGetOldWayComparingList = (): ReturnObj => {
  const { t } = useTranslation('landing');

  const titleOldWay: ReactElement = (
    <ComparingListTitle>{t('comparing.oldWay.title')}</ComparingListTitle>
  );

  const listItemsText = [
    <Trans t={t} i18nKey="comparing.oldWay.forFree">
      <span style={{ fontWeight: 600 }}>
        Give away too much value for free.
      </span>
      {' '}
      Without an easy way to upsell your work that doesn’t make you feel
      ‘salesy’ you’ll probably just let your client have those extra photos, raw
      files or “bonus edit” for nothing in return…stop doing that.
    </Trans>,
    <Trans t={t} i18nKey="comparing.oldWay.mentalStrain">
      <span style={{ fontWeight: 600 }}>
        Mental strain from a clunky workflow.
      </span>
      {' '}
      If you’re delivering both photos and videos you send photos from one
      platform and video from another. Reviewing content and keeping track of
      multiple links is annoying for you and your client.
    </Trans>,
    <Trans t={t} i18nKey="comparing.oldWay.wasteTime">
      <span style={{ fontWeight: 600 }}>Waste your time on emails.</span>
      {' '}
      If
      you’ve ever wanted to upsell additional content to your client after a
      shoot you can expect a long email thread negotiating pricing and
      describing what you could offer. Then comes generating that extra invoice
      and waiting to get paid.
    </Trans>,
    <Trans t={t} i18nKey="comparing.oldWay.amateur">
      <span style={{ fontWeight: 600 }}>Look like an amateur.</span>
      {' '}
      Most
      client’s receive the work they’ve paid for with a download link in an
      email and some written instructions. This takes away from your customer
      experience and doesn’t do justice to the epic visual creations you’ve
      crafted for your client.
    </Trans>,
    <Trans t={t} i18nKey="comparing.oldWay.feelFrustrated">
      <span style={{ fontWeight: 600 }}>
        Feel frustrated that you’re leaving money on the table.
      </span>
      {' '}
      There’s no easy way for content creators to upsell additional work at the
      time of project delivery…until now.
    </Trans>,
  ];

  const listItems = listItemsText.map((item) => (
    <ComparingListItem isIconTick={false} key={item.key}>
      <CloseIcon color="white" />
      <MediumWhiteText style={{ display: 'inline-block' }}>
        {item}
      </MediumWhiteText>
    </ComparingListItem>
  ));

  return {
    listItems,
    title: titleOldWay,
  };
};

export const useGetNewWayComparingList = (): ReturnObj => {
  const { t } = useTranslation('landing');

  const titleNewWay: ReactElement = (
    <ComparingListTitle>
      <GradientColorText fontWeight={600}>
        {t('comparing.newWay.title')}
      </GradientColorText>
    </ComparingListTitle>
  );

  const listItemsText = [
    <Trans t={t} i18nKey="comparing.newWay.wow">
      <span style={{ fontWeight: 600 }}>“Wow” your clients.</span>
      {' '}
      Our
      {' '}
      <ComparingPinkText>modern UI</ComparingPinkText>
      {' '}
      not only presents your
      creative work beautifully, it also gives your clients tools to provide
      feedback, make purchases and easily download the content you’ve created.
    </Trans>,
    <Trans t={t} i18nKey="comparing.newWay.keepOrganized">
      <span style={{ fontWeight: 600 }}>
        Keep everything in one place to feel
        {' '}
        <ComparingPinkText>organized</ComparingPinkText>
        {' '}
        and
        {' '}
        <ComparingBlueText>professional</ComparingBlueText>
        .
      </span>
      {' '}
      Send your clients a single link that gives them access to a gallery that
      contains all their photos, videos + more. Add more content to the gallery
      over time whenever you need to.
    </Trans>,
    <Trans t={t} i18nKey="comparing.newWay.likeShopify">
      <span style={{ fontWeight: 600 }}>
        <ComparingPinkText>It’s like Shopify</ComparingPinkText>
        {' '}
        but for content
        creators.
      </span>
      {' '}
      Each file you upload to a Delivrable gallery can be thought of as a
      digital product where you can set pricing and download settings. Your
      clients view and interact with your work in a familiar e-commerce style.
    </Trans>,
    <Trans t={t} i18nKey="comparing.newWay.automateSales">
      <span style={{ fontWeight: 600 }}>
        Automate sales so you never feel ‘salesy’ again.
      </span>
      {' '}
      Set an
      {' '}
      <ComparingBlueText>automated email</ComparingBlueText>
      {' '}
      that reminds
      your client to download their files and shows them that fresh content
      could be just a few clicks away.
    </Trans>,
    <Trans t={t} i18nKey="comparing.newWay.getPaidEasily">
      <span style={{ fontWeight: 600 }}>
        Get paid easily and quickly for the value that you bring to your
        clients.
      </span>
      {' '}
      With our Stripe integration we can provide near
      {' '}
      <ComparingPinkText>instant payouts</ComparingPinkText>
      {' '}
      for the content you
      sell.
    </Trans>,
  ];

  const listItems = listItemsText.map((item) => (
    <ComparingListItem key={item.key} isIconTick>
      <ComparingWhiteTick>
        <LittleTickIcon />
      </ComparingWhiteTick>
      <MediumWhiteText style={{ display: 'inline-block' }}>
        {item}
      </MediumWhiteText>
    </ComparingListItem>
  ));

  return {
    listItems,
    title: titleNewWay,
  };
};
