import React, { ReactElement } from 'react';
import {
  LandingPriceExplanationsItemList,
  LandingPriceExplanationsItemText,
  LandingPriceExplanationsItemTitle,
  LandingPriceExplanationsItemWrapper,
} from './styled';

type LandingPriceExplanationsItemProps = {
  list: ReactElement[];
  text: string;
  title: string;
};

export const LandingPriceExplanationsItem: React.FC<
  LandingPriceExplanationsItemProps
> = ({ list, text, title }) => (
  <LandingPriceExplanationsItemWrapper>
    <LandingPriceExplanationsItemTitle>
      {title}
    </LandingPriceExplanationsItemTitle>
    <LandingPriceExplanationsItemText>{text}</LandingPriceExplanationsItemText>
    <LandingPriceExplanationsItemList>{list}</LandingPriceExplanationsItemList>
  </LandingPriceExplanationsItemWrapper>
);
