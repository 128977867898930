import styled from '@emotion/styled';
import { theme } from '../../../../../../shared/components/GlobalContainer/styled';
import { TextTotalBlackLarge } from '../../../../../../shared/styles';

export const ClientEmptyPageButtonWrapper = styled.div`
  margin-top: 28px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    & > button {
      width: 100%;
    }
  }
`;

export const EmptyPageWrapper = styled.div`
  margin-top: 10px;
`;

export const EmptyPageText = styled(TextTotalBlackLarge)`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 16px;
    line-height: 150%;
  }
`;
