import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRemoveFromFavourites, fetchAddToFavourites } from '../../../Client/redux/favourites/cartClientThunk';

type ReturnObj = {
  isLiked: boolean;
  handleLike: (id: string) => () => void;
}

export const useLike = (isFileLiked: boolean): ReturnObj => {
  const [isLiked, setIsLiked] = useState(!!isFileLiked); // temporary
  const dispatch = useDispatch();

  const handleLike = useCallback((id: string) => () => {
    if (isLiked) {
      dispatch(fetchRemoveFromFavourites([id]));
      setIsLiked(false);
    } else {
      dispatch(fetchAddToFavourites(id));
      setIsLiked(true);
    }
  },
  [isLiked, isFileLiked]);

  return ({
    isLiked,
    handleLike,
  });
};
