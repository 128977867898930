import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddToCart } from '../../hooks/buttons/useAddToCart';
import {
  ContentActionsContainer,
  GallerySwiperBtnDownWrpMobile,
  PrimaryWhiteButtonMgR,
} from './styled';
import { useComment } from '../../hooks/buttons/useComment';
import { GallerySwiperRoundButtons } from './GallerySwiperRoundButtons';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { useDownload } from '../../hooks/buttons/useDownload';
import { InstantDownload } from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import { PrimaryWhiteButton } from '../../../../shared/styles/Buttons';

type GallerySwiperButtonsDownProps = {
  id: string;
  isInstantDownloadable: boolean;
  isAllowCommenting: boolean;
};

export const GallerySwiperButtonsDown: React.FC<
  GallerySwiperButtonsDownProps
> = ({ id, isInstantDownloadable, isAllowCommenting }) => {
  const { t } = useTranslation('gallery');
  const { screenSize } = useCheckWindowSize();
  const { handleDownload } = useDownload();
  const { isInCart, handleClickCart } = useAddToCart(false);
  const {
    handleOpenComments, commentArea, isCommentsOpen, numberOfComments,
  } = useComment({});

  if (screenSize === 'mobile') {
    return (
      <GallerySwiperBtnDownWrpMobile>
        <PrimaryWhiteButton
          type="button"
          onClick={handleClickCart(id)}
          id="client-download-cart-button-step"
        >
          {t('addToCart')}
        </PrimaryWhiteButton>
        <RoundButton color="grey" onClick={handleDownload} id="client-instantly-downloadable-step">
          <InstantDownload />
        </RoundButton>
      </GallerySwiperBtnDownWrpMobile>
    );
  }

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item sm={9}>
          <ContentActionsContainer>
            <PrimaryWhiteButtonMgR type="button" onClick={handleClickCart(id)}>
              {t('addToCart')}
            </PrimaryWhiteButtonMgR>
            <GallerySwiperRoundButtons
              id={id}
              isInstantDownloadable={isInstantDownloadable}
              isAllowCommenting={isAllowCommenting}
              numberOfComments={numberOfComments}
              handleOpenComments={handleOpenComments}
              isCommentsOpen={isCommentsOpen}
            />
          </ContentActionsContainer>
        </Grid>
      </Grid>
      {commentArea}
    </>
  );
};
