import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { TextBlackLarge } from '../../../../../../../shared/styles';
import { ChangePlanButton } from '../ChangePlanPage/styled';
import { SettingsWrapper } from '../styled';
import * as variables from '../../../../../../../constants/styles';
import * as urls from '../../../../../../../constants/urls';
import { StripeComponent } from './StripeComponent';

export const PaymentDetails: React.FC = () => {
  const { t } = useTranslation('settings');
  const history = useHistory();

  const handleClickToBack = () => {
    history.push(urls.settingsUrl);
  };

  return (
    <SettingsWrapper>
      <ChangePlanButton type="button" onClick={handleClickToBack}>
        <TextBlackLarge>
          <ArrowSimpleIcon color="black" direction="left" />
          <span style={{ marginLeft: '16px' }}>{t('paymentDetails.title')}</span>
        </TextBlackLarge>
      </ChangePlanButton>
      <Grid container>
        <StripeComponent />
      </Grid>
    </SettingsWrapper>
  );
};
