import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CreatorStateContext } from '../context/CreatorStateContext';
import * as urls from '../../../constants/urls';

type PrivateRouteProps = {
  path: string;
  exact?: boolean | undefined;
  component: React.FC;
};

export const CreatorPrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  exact,
  component: RouteComponent,
}) => {
  const { isCreatorSignIn } = useContext(CreatorStateContext);
  if (!isCreatorSignIn) {
    return <Redirect from={path} to={urls.signUpInitialUrl} />;
  }

  return (
    <Route exact={exact} path={path}>
      <RouteComponent />
    </Route>
  );
};
