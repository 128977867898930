import React from 'react';
import { useHistory } from 'react-router-dom';
import { DemoGalleryButtonThumbnail, DemoGalleryButtonTitle, DemoGalleryButtonWrp } from './styled';
import * as urls from '../../../../constants/urls/landingUrls';

type DemoGalleryButtonProps = {
  title: string;
  thumbnail: string;
  galleryDesignNumber: number;
};

export const DemoGalleryButton: React.FC<DemoGalleryButtonProps> = ({
  title,
  thumbnail,
  galleryDesignNumber,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(urls.demoGalleryUrl, { galleryDesignNumber });
  };

  return (
    <DemoGalleryButtonWrp type="button" onClick={handleClick}>
      <DemoGalleryButtonTitle>
        {title}
      </DemoGalleryButtonTitle>
      <DemoGalleryButtonThumbnail>
        <img src={thumbnail} alt={title} />
      </DemoGalleryButtonThumbnail>
    </DemoGalleryButtonWrp>
  );
};
