import React from 'react';
import { useParams } from 'react-router-dom';
import { NewGalleryCreator } from './NewGalleryCreator';
import { useGetAllGalleriesQuery } from '../../../../redux/gallery/galleryAPI';

export const NewGalleryCreatorContainer: React.FC = () => {
  const { id: galleryId } = useParams<{ id: string }>();
  const { gallery, isLoading } = useGetAllGalleriesQuery(undefined, {
    selectFromResult: (res) => ({
      gallery: res.data?.galleries.find((item) => item.id === galleryId),
      isLoading: res.isLoading,
    }),
  });

  return <NewGalleryCreator galleryId={galleryId} currentGallery={gallery} />;
};
