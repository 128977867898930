import { GalleryData, GalleryFile, GalleryIdea } from '../../types';

export const getAllFiles = (gallery: GalleryData) => {
  let allFiles: (GalleryFile | GalleryIdea)[] = [];
  const sections = gallery?.sections?.forEach((item) => {
    allFiles = [...allFiles, ...item.files, ...item.ideas];
  });

  return allFiles;
};
