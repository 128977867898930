import React from 'react';
import { useTranslation } from 'react-i18next';
import { GalleryIdea } from '../../../../../../../../../shared/types';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { Idea } from './Idea';

type IdeasProps = {
  ideas: GalleryIdea[];
};

const arr = [
  {
    id: 1,
    title: 'Title',
    description: 'Ipsum laborum aliquip irure ullamco sunt enim adipisicing duis sit adipisicing. Et exercitation consectetur ea sit commodo incididunt. Duis anim anim consectetur ut dolor dolore eiusmod ipsum minim irure Lorem.Culpa id anim tempor ullamco esse ad magna veniam do exercitation non amet amet ipsum.',
    isPurchased: true,
    date: `${new Date(2022, 2, 2)}`,
  },
  {
    id: 2,
    title: 'Title 2',
    description: 'Description',
    isPurchased: true,
    date: `${new Date(2022, 2, 3)}`,
  },
  {
    id: 3,
    title: 'Title 3',
    description: 'Description',
    isPurchased: true,
    date: `${new Date(2022, 2, 1)}`,
  },
];

export const IdeasTab: React.FC<IdeasProps> = ({ ideas }) => {
  const { t } = useTranslation('gallery');

  if (!ideas?.length) return <SimpleBlackText>{t('noIdeas')}</SimpleBlackText>;

  return (
    <>
      {ideas.map((item) => (
        <Idea idea={item} />
      ))}
    </>
  );
};
