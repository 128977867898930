import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ClientContainer } from './modules/Client/containers/ClientContainer';
import clientStore from './modules/Client/redux/configureStore';
import creatorStore from './modules/Creator/redux/configureStore';
import { CreatorAndLandingContainer } from './modules/Creator/containers/CreatorAndLandingContainer';

if (process.env.REACT_APP_BUILD_TARGET === 'creator') {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={creatorStore}>
        <CreatorAndLandingContainer />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Router basename="/">
        <Provider store={clientStore}>

          <ClientContainer />

        </Provider>
      </Router>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

reportWebVitals();
