import { v4 as uuidv4 } from 'uuid';
import {
  GalleryForRequest,
  GalleryDataForm,
  GalleryFileInformation,
  GallerySection,
  GalleryFile,
} from '../../shared/types';
import { dateRegExp } from '../validationValues/regExp';

const section = {
  id: uuidv4(),
  title: 'Section 1',
  files: [],
  ideas: [],
  hasFreeFiles: false,
  numberFreeFiles: 0,
};

export const galleryInitialValues = (
  id: string,
  currentGallery?: GalleryForRequest,
): GalleryDataForm => ({
  step: 1,
  id,
  name: currentGallery?.name || '',
  coverFile: {
    original: { file: null },
    mobile: { file: null },
    tablet: { file: null },
    desktop: { file: null },
  },
  date: currentGallery?.date?.match(dateRegExp)
    ? new Date(currentGallery?.date)
    : null,
  message: currentGallery?.message || '',
  galleryUrl: currentGallery?.galleryUrl || '',
  galleryDesign: +(currentGallery?.galleryDesign || 0),
  sections: currentGallery?.sections?.length
    ? [...currentGallery?.sections]
    : [section],
  hasRegistrationEmail: !!currentGallery?.hasRegistrationEmail,
  registrationEmail: currentGallery?.registrationEmail?.length
    ? currentGallery?.registrationEmail
    : [],
  password: currentGallery?.password || '',
  sharing: !!currentGallery?.sharing,
  assist: !!currentGallery?.assist,
  hasExpiryDate: !!currentGallery?.hasExpiryDate,
  expiryDate: currentGallery?.expiryDate?.match(dateRegExp)
    ? new Date(currentGallery?.expiryDate)
    : null,
  autoAlert: !!currentGallery?.autoAlert,
  branding: !!currentGallery?.branding,
  testimonialCall: !!currentGallery?.testimonialCall,
  status: currentGallery?.status || 'draft',
  // new fields
  hasWhitelistEmails: false,
  hasReminder: false,
  whenRemind: [],
  whoRecipients: [],
  whoFromWhitelisted: [],
  isAllRegisteredEmails: false,
  removeBranding: false,
});

export const galleryFileInfo: GalleryFileInformation = {
  description: '',
  price: '',
  instantlyDownloadable: false,
  watermark: false,
  allowCommenting: false,
  fullWidth: false,
};

export const getGalleryFileInfo = (
  currentSection: GallerySection,
  selectedFilesIds: string[],
): GalleryFileInformation => {
  if (selectedFilesIds.length) {
    const allFiles = [...currentSection.files, ...currentSection.ideas];
    const firstSelectedFileId = selectedFilesIds[0];
    const firstSelectedFile = allFiles.find((item) => item.id === firstSelectedFileId);
    const result: GalleryFileInformation = {
      description: firstSelectedFile?.description || '',
      price: firstSelectedFile?.price || '',
      instantlyDownloadable: !!(firstSelectedFile as GalleryFile)?.instantlyDownloadable,
      watermark: !!firstSelectedFile?.watermark,
      allowCommenting: !!firstSelectedFile?.allowCommenting,
      fullWidth: !!firstSelectedFile?.fullWidth,
    };

    return result;
  }
  return galleryFileInfo;
};
