import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { httpUrl, delivrableUrl } from '../../../../../../../constants/urls';
import { creatorInitialValues } from '../../../../../../../constants/initialValues';
import { CommonCopyInput } from '../../../../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import {
  FacebookIcon,
  InstagramIcon,
  YouTubeIcon,
  VimeoIcon,
  LinkedInIcon,
  PinterestIcon,
} from '../../../../../../../shared/icons';
import { PrimaryWhiteButton, SimpleBlackText } from '../../../../../../../shared/styles';
import { CreatorFull } from '../../../../../../../shared/types';
import { RowsWrapper } from '../styled';
import { CancelPanel } from './CancelPanel';
import { DevicesAndBrowsers } from './DevicesAndBrowsers';
import { ProfilePicture } from './ProfilePicture/ProfilePicture';
import {
  LabelWrapper, SiteAddressWrapper, SocialLinksTitle, TabSettingsWrapper,
} from './styled';
import { ChangePassword } from './ChangePassword/ChangePassword';
import {
  dateFormats,
  getTimezonesList,
} from '../../../../../../../constants/selectors';
import { useGetSelectionItems } from '../../../../../../../shared/hooks/useGetSelectionItems';
import { CommonSelect } from '../../../../../../../shared/components/FormElements/CommonSelect/CommonSelect';

export const ProfileTab: React.FC = () => {
  const { t } = useTranslation('settings');
  const getSelectionItems = useGetSelectionItems();

  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const menuItemsTimezones = useMemo(
    () => getSelectionItems(getTimezonesList()),
    [],
  );

  const menuItemsDateFormats = useMemo(
    () => getSelectionItems(dateFormats),
    [],
  );

  const {
    values, handleChange, handleBlur, handleSubmit, errors, touched,
  } = useFormik<CreatorFull>({
    // validationSchema: clientSchema,
    initialValues: creatorInitialValues(currentUser),
    onSubmit: (data) => onSubmit(data),
  });

  const address = `${httpUrl}${currentUser?.businessName}.${delivrableUrl}` || '';

  return (
    <TabSettingsWrapper>
      <RowsWrapper>
        <Grid container item sm={6}>
          <ProfilePicture />
          <Grid style={{ marginTop: '20px' }} item sm={9}>
            <CommonInput
              label={t('yourFirstName')}
              value={values.firstName}
              name="firstName"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.firstName}
              touched={touched?.firstName}
              type="text"
            />
          </Grid>
          <Grid item sm={9}>
            <CommonInput
              label={t('yourSecondName')}
              value={values.lastName}
              name="lastName"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.lastName}
              touched={touched?.lastName}
              type="text"
            />
          </Grid>
          <Grid item sm={9}>
            <CommonInput
              label={t('businessName')}
              value={values.businessName}
              name="businessName"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.businessName}
              touched={touched?.businessName}
              type="text"
            />
          </Grid>
          <Grid item sm={9}>
            <SiteAddressWrapper>
              <CommonCopyInput
                label={t('address')}
                value={address}
                name="address"
                readonly
              />
            </SiteAddressWrapper>
          </Grid>
          <Grid item sm={9}>
            <CommonInput
              label={t('changeEmail')}
              value={values.email}
              name="email"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              placeholder={t('placeholders.newEmailAddress')}
              error={errors?.email}
              touched={touched?.email}
              type="email"
            />
            <PrimaryWhiteButton
              style={{ marginTop: '20px' }}
              type="button"
              onClick={() => console.log('update email')}
            >
              {t('updateEmail')}
            </PrimaryWhiteButton>
          </Grid>
          <ChangePassword />
        </Grid>
        <Grid style={{ height: 'fit-content' }} container item sm={6}>
          <Grid item sm={10}>
            <CommonSelect
              error={errors.timezone}
              value={values.timezone}
              name="timezone"
              placeholder="(GMT-8:00) America, Los Angeles"
              handleChange={handleChange}
              touched={touched.timezone}
              label={t('timezone')}
              menuItems={menuItemsTimezones}
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSelect
              error={errors.dateFormat}
              value={values.dateFormat}
              name="dateFormat"
              placeholder="mm/dd/yyyy"
              handleChange={handleChange}
              touched={touched.dateFormat}
              label={t('dateFormat')}
              menuItems={menuItemsDateFormats}
            />
          </Grid>
          <Grid item sm={10}>
            <SocialLinksTitle>
              <SimpleBlackText>{t('socialLinks')}</SimpleBlackText>
            </SocialLinksTitle>
          </Grid>
          <Grid item sm={10}>
            <CommonInput
              label={(
                <LabelWrapper>
                  <FacebookIcon />
                  {t('socialMedias.facebook')}
                </LabelWrapper>
              )}
              value={values.facebook}
              name="facebook"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.facebook}
              touched={touched?.facebook}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid
            item
            sm={10}
          >
            <CommonInput
              label={(
                <LabelWrapper>
                  <InstagramIcon />
                  {t('socialMedias.instagram')}
                </LabelWrapper>
              )}
              value={values.instagram}
              name="instagram"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.instagram}
              touched={touched?.instagram}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid
            item
            sm={10}
          >
            <CommonInput
              label={(
                <LabelWrapper>
                  <YouTubeIcon />
                  {t('socialMedias.youtube')}
                </LabelWrapper>
              )}
              value={values.youtube}
              name="youtube"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.youtube}
              touched={touched?.youtube}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid
            item
            sm={10}
          >
            <CommonInput
              label={(
                <LabelWrapper>
                  <VimeoIcon />
                  {t('socialMedias.vimeo')}
                </LabelWrapper>
              )}
              value={values.vimeo}
              name="vimeo"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.vimeo}
              touched={touched?.vimeo}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid
            item
            sm={10}
          >
            <CommonInput
              label={(
                <LabelWrapper>
                  <LinkedInIcon />
                  {t('socialMedias.linkedin')}
                </LabelWrapper>
              )}
              value={values.linkedin}
              name="linkedin"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.linkedin}
              touched={touched?.linkedin}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid
            item
            sm={10}
          >
            <CommonInput
              label={(
                <LabelWrapper>
                  <PinterestIcon />
                  {t('socialMedias.pintrest')}
                </LabelWrapper>
              )}
              value={values.pinterest}
              name="pinterest"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.pinterest}
              touched={touched?.pinterest}
              placeholder={t('placeholders.link')}
              type="text"
              withoutMargins
            />
          </Grid>
        </Grid>
      </RowsWrapper>
      <DevicesAndBrowsers />
      <CancelPanel />
    </TabSettingsWrapper>
  );
};
