import { createSlice } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';

type InitialState = {
  loading: boolean;
  error: ErrorType;
  sales: null;
};

export const initialState: InitialState = {
  sales: null,
  loading: true,
  error: null,
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
});

export default salesSlice.reducer;
