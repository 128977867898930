import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { BaseColors } from '../../types';
import { getBaseColor } from '../../utils/style/getBaseColor';

export const TextIconButtonWithHoverWrp = styled.button<{
  hoverColor: BaseColors;
  originalColor: BaseColors;
}>`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  & #hover-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    
    & svg, & path {
      stroke: ${({ originalColor }) => getBaseColor(originalColor)} !important;
      color: ${({ originalColor }) => getBaseColor(originalColor)} !important;
      transition: 0.05s ease-in-out;  
    }
  }

  & #hover-button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    & * {
      color: ${({ originalColor }) => getBaseColor(originalColor)} !important;
      transition: 0.05s ease-in-out;  
    }
  }

  &:hover {
    & #hover-button-icon {
      & svg, & path {
        stroke: ${({ hoverColor }) => getBaseColor(hoverColor)} !important;
        color: ${({ hoverColor }) => getBaseColor(hoverColor)} !important;
        transition: 0.05s ease-in-out;  
      }
    }
    & #hover-button-text {
      & * {
        color: ${({ hoverColor }) => getBaseColor(hoverColor)} !important;
        transition: 0.05s ease-in-out;   
      }
    }
  }
`;
