import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GradientFlameIcon } from '../../../../../../../shared/icons';
import * as urls from '../../../../../../../constants/urls';
import {
  GradientColorText,
  MediumBlackText,
  TextBlackLarge,
} from '../../../../../../../shared/styles';
import { WhiteButtonFitContent } from '../../Support/styled';
import { SettingsWrapper, TextMgB } from '../styled';
import { ButtonMgT, UpgradeToPremiumWrapper } from './styled';

export const UpgradeToPremium: React.FC = () => {
  const { t } = useTranslation('settings');
  const history = useHistory();

  const handleClick = () => {
    history.push(`${urls.settingsUrl}${urls.changePlanUrl}`);
  };

  return (
    <SettingsWrapper>
      <UpgradeToPremiumWrapper>
        <TextMgB>
          <TextBlackLarge>
            <span
              style={{
                marginRight: '13px',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              <GradientFlameIcon />
            </span>
            {t('getMonthFree')}
          </TextBlackLarge>
        </TextMgB>
        <MediumBlackText>{t('payAnnually')}</MediumBlackText>
        <ButtonMgT>
          <WhiteButtonFitContent type="button" onClick={handleClick}>
            <GradientColorText fontWeight={600}>{t('upgradeToPremium')}</GradientColorText>
          </WhiteButtonFitContent>
        </ButtonMgT>
      </UpgradeToPremiumWrapper>
    </SettingsWrapper>
  );
};
