import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { GalleryDataForm } from '../../../../../../../shared/types';
import { useAsyncUpdateGallery } from './useAsyncUpdateGallery';

type ReturnObj = {
    onSubmit: (
      data: GalleryDataForm,
      bag: FormikHelpers<GalleryDataForm>,
    ) => void;
    isLoading: boolean;
    step: number;
  };

export const useSubmitGalleryObj = (): ReturnObj => {
  const [step, setStep] = useState(1);

  const { asyncHandleUpdateGallery, isLoading } = useAsyncUpdateGallery();

  const onSubmit = async (
    data: GalleryDataForm,
    bag: FormikHelpers<GalleryDataForm>,
  ) => {
    const errors = await asyncHandleUpdateGallery(data, 'draft', data.id);
    const hasErrors = errors?.length > 0;
    if (!hasErrors) {
      bag.setSubmitting(false);
      bag.setTouched({});
      setStep(data.step);
    } else {
      errors.forEach((item) => {
        bag.setFieldError(item, 'Wrong field');
      });
    }
  };

  return ({
    onSubmit,
    isLoading,
    step,
  });
};
