import { createAsyncThunk } from '@reduxjs/toolkit';
import { creatorSignUp } from '../../../../../api/auth/creatorAuth';
import {
  emailExistsErrorObj,
  smthGoesWrongErrorObj,
  fieldTaken,
  wrongOrOldTokenErrorObj,
} from '../../../../../constants/validationValues';
import { SignUpFields, SignUpRequest } from '../../../../../shared/types';
import { createObjectForErrors } from '../../../../../shared/utils/signUp/createObjectForErrors';
import { getSignUpFieldFullName } from '../../../../../shared/utils/signUp/getSignUpFieldFullName';

const emailExistError = createObjectForErrors('wrongData', [emailExistsErrorObj]);
const smthGoesWrongError = createObjectForErrors('other', [smthGoesWrongErrorObj]);

export const fetchSignUpInitialCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpInitialCreator',
  async (email: string, thunkApi) => {
    try {
      const response = await creatorSignUp.signUpInitial(email);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 409) {
        return thunkApi.rejectWithValue(emailExistError);
      }
      return thunkApi.rejectWithValue(smthGoesWrongError);
    }
  },
);

export const fetchSignUpInitialResendCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpInitialCreator',
  async (email: string, thunkApi) => {
    try {
      const response = await creatorSignUp.signUpInitialResend(email);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 409) {
        return thunkApi.rejectWithValue(emailExistError);
      }
      return thunkApi.rejectWithValue(smthGoesWrongError);
    }
  },
);

export const fetchSignUpCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpCreator',
  async (data: SignUpRequest, thunkApi) => {
    try {
      const response = await creatorSignUp.signUp(data);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 409) {
        const errors = error.response.data.field.map((item: SignUpFields) => ({
          field: item,
          message: fieldTaken(getSignUpFieldFullName(item)),
        }));
        return thunkApi.rejectWithValue(
          createObjectForErrors('wrongData', errors),
        );
      }
      if (error.response.status === 401) {
        return thunkApi.rejectWithValue(
          createObjectForErrors('other', [wrongOrOldTokenErrorObj]),
        );
      }
      return thunkApi.rejectWithValue(smthGoesWrongError);
    }
  },
);
