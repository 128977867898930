import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';

export const FullHeightWidth = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const GridWithMgBt = styled(Grid)`
  margin-bottom: 10px !important;
`;

export const HeaderGalleryCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const GridMgTop = styled(Grid)`
  margin-top: 50px !important;
`;
