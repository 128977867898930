import React, { useContext, useEffect, useRef } from 'react';
import { GalleryData, GalleryFile, GalleryIdea } from '../../../../shared/types';
import { FileOrCoverWrapper } from '../styled';
import { ElementsOverFile } from './ElementsOverFile';
import { ImageOrVideoCardWrapper } from './styled';
import { useMasonry } from '../../../../shared/hooks/useMasonry';
import { FileForMasonry } from '../../../../shared/components/FileForMasonry/FileForMasonry';
import { ClientOnboardingContext } from '../../../Client/context/CreatorOnboardingContext';
import { ClientOnboardingStepIndexContext } from '../../../Client/context/ClientOnboardingStepIndexContext';

type ImageCardProps = {
  fileOrIdea: GalleryFile | GalleryIdea;
  sectionId: string;
  fileIndex: number;
  gallery: GalleryData;
};

export const ImageOrVideoCard: React.FC<ImageCardProps> = ({
  fileOrIdea,
  sectionId,
  fileIndex,
  gallery,
}) => {
  const fileRef = useRef<any>();
  const { spans, handleSetSpans } = useMasonry(fileRef);
  const { isStudy: isStudyClient } = useContext(ClientOnboardingContext);
  const { stepIndex } = useContext(ClientOnboardingStepIndexContext);

  const currentFile = (fileOrIdea as GalleryIdea).cover || (fileOrIdea as GalleryFile);
  const {
    fullWidth,
    allowCommenting,
    instantlyDownloadable,
    name,
    id,
    isLiked,
    isInCart,
  } = (fileOrIdea as GalleryFile) || (fileOrIdea as GalleryIdea);

  useEffect(() => {
    if (fileRef.current) {
      handleSetSpans(fileRef);
    }
  }, [fullWidth]);

  return (
    <ImageOrVideoCardWrapper spans={spans} fullWidth={!!fullWidth}>
      <FileOrCoverWrapper
        fullWidth={!!fullWidth}
        areButtonsVisible={fileIndex === 0 && isStudyClient && stepIndex > 5 && stepIndex < 12}
      >
        <ElementsOverFile
          gallery={gallery}
          fileIndex={fileIndex}
          file={fileOrIdea}
          sectionId={sectionId}
          id={id}
          fileType={'cover' in fileOrIdea ? 'ideas' : 'files'}
          fullWidth={!!fullWidth}
          instantlyDownloadable={!!instantlyDownloadable}
          allowCommenting={!!allowCommenting}
          isFileLiked={!!isLiked}
          isFileInCart={!!isInCart}
        >
          <div
            style={{ width: '100%', height: '100%', overflow: 'hidden' }}
            id="client-click-item-step"
          >
            {currentFile && (
              <FileForMasonry
                fileRef={fileRef}
                currentFile={currentFile}
                currentFileUrl={currentFile?.url || ''}
                fileId={id}
                fileName={name}
                handleSetSpans={handleSetSpans}
              />
            )}
          </div>
        </ElementsOverFile>
      </FileOrCoverWrapper>
    </ImageOrVideoCardWrapper>
  );
};
