import React, { ReactElement } from 'react';
import { BasicDocumentCard } from '../../../shared/components/Files/BasicDocumentCard/BasicDocumentCard';
import { VideoContainer } from '../../../shared/components/Files/VideoContainer/VideoContainer';
import {
  CustomFile,
  GalleryFile,
  GalleryIdea,
  ScreenSizeNames,
} from '../../../shared/types';
import { isIdea } from '../../../shared/utils';
import { IdeaBigCard } from '../GallerySwiper/IdeaBigCard/IdeaBigCard';
import {
  SwiperImageCard,
  SwiperDocumentCardWrapper,
} from '../GallerySwiper/styled';

export const getFileInWrapperForCart = (file: CustomFile): ReactElement => {
  switch (file.type) {
    case 'image':
      return (
        <SwiperImageCard>
          <img
            src={file?.url || ''}
            data-src={file?.url || ''}
            alt={file.name}
          />
        </SwiperImageCard>
      );
    case 'video':
      return <VideoContainer videoUrl={file?.url || ''} />;
    default:
      return (
        <SwiperDocumentCardWrapper>
          <BasicDocumentCard file={file} />
        </SwiperDocumentCardWrapper>
      );
  }
};

export const getFileOrIdeaForSwiper = (
  fileOrIdea: GalleryFile | GalleryIdea,
  screenSize: ScreenSizeNames,
): ReactElement => {
  if (isIdea(fileOrIdea)) {
    if (screenSize !== 'mobile') {
      return <IdeaBigCard idea={fileOrIdea as GalleryIdea} />;
    }
    if ((fileOrIdea as GalleryIdea).cover) {
      return getFileInWrapperForCart((fileOrIdea as GalleryIdea).cover as CustomFile);
    }
    return <div>Idea</div>;
  }
  return getFileInWrapperForCart(fileOrIdea as GalleryFile);
};
