import { Grid } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MediumBlackText } from '../../../../../shared/styles';
import { LandingContentContainer } from '../../LandingContentContainer';
import { LandingPricingDescriptionSpots } from './LandingPricingDescriptionSpots';
import {
  LandingPricingDescriptionWrp,
  PricingDescriptionReason,
  PricingDescriptionReasonNumber,
  PricingDescriptionReasons,
  PricingDescriptionText,
  PricingDescriptionTitle,
} from './styled';

export const LandingPricingDescription: React.FC = () => {
  const { t } = useTranslation('landing');

  return (
    <LandingPricingDescriptionWrp>
      <LandingContentContainer>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item md={5}>
            <PricingDescriptionTitle>
              <Trans t={t} i18nKey="pricing.payOnceTitle">
                Pay once. Get
                <br />
                {' '}
                Delivrable for life.
              </Trans>
            </PricingDescriptionTitle>
            <PricingDescriptionText>
              <Trans t={t} i18nKey="pricing.payOnceText">
                We’re giving our early adopters a chance to
                {' '}
                <span className="pink">pay once</span>
                {' '}
                and
                {' '}
                <span className="pink-highlight">get lifetime access</span>
                {' '}
                to Delivrable’s premium membership tiers.
              </Trans>
            </PricingDescriptionText>
            <PricingDescriptionReasons>
              <PricingDescriptionReason>
                <PricingDescriptionReasonNumber>1</PricingDescriptionReasonNumber>
                <MediumBlackText>{t('pricing.reason1')}</MediumBlackText>
              </PricingDescriptionReason>
              <PricingDescriptionReason>
                <PricingDescriptionReasonNumber>2</PricingDescriptionReasonNumber>
                <MediumBlackText>{t('pricing.reason1')}</MediumBlackText>
              </PricingDescriptionReason>
            </PricingDescriptionReasons>
          </Grid>
          <Grid item md={5} style={{ position: 'relative' }}>
            <LandingPricingDescriptionSpots />
          </Grid>
        </Grid>
      </LandingContentContainer>
    </LandingPricingDescriptionWrp>
  );
};
