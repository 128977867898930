import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { DeleteIcon } from '../../../../../../../shared/icons';
import { FileCardWrapper, CardContainer } from './styled';
import { getFileInContainer } from '../../../../../../../shared/utils/gallery/getFileInContainer';
import { CustomFile } from '../../../../../../../shared/types';
import { FileLoader } from '../../../../../../../shared/components/Loaders/FileLoader/FileLoader';

type FileCardProps = {
  file: CustomFile | null;
  handleDeleteFile: (name: string) => void;
  id: string;
};

export const FileCard: React.FC<FileCardProps> = ({
  file,
  handleDeleteFile,
  id,
}) => {
  const { t } = useTranslation('createNewGallery');
  const imageOrVideo = useMemo(() => getFileInContainer(file), [file]);

  const { setIsOpen, dialogWindow } = useDialog(
    id,
    handleDeleteFile,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  return (
    <>
      <CardContainer item sm={3}>
        <FileCardWrapper>
          {imageOrVideo}
          <button className="delete-button" type="button" onClick={() => setIsOpen(true)}>
            <DeleteIcon />
          </button>
          <FileLoader isLoading={!!(file?.id && !file.url)} />
        </FileCardWrapper>
      </CardContainer>
      {dialogWindow}
    </>
  );
};
