import React from 'react';
import { Grid } from '@material-ui/core';
import {
  ImageGalleryFourthMobile,
  TextGalleryFourthMobile,
  DateCellMobile,
  ImageSecondCellMobile,
  CreatorCellMobile,
  MessageCellMobile,
  SquareBoxesMobile,
  ImageFirstCellMobile,
} from './styled';
import { GalleryCover } from '../../../../shared/types';
import { useExtraFilesGalleryFourth } from '../../hooks/useExtraFilesGalleryFourth';
import { MobileWrapper } from '../../MobileWrapper';
import { CommonMobilePaddingWrapper } from '../../styled';
import { useGalleryContent } from '../../hooks/useGalleryContent';

export const GalleryDemoFourthMobile: React.FC<GalleryCover> = ({
  file, name, date, userName, message, withCropper = false,
}) => {
  const { extraFile1, extraFile2 } = useExtraFilesGalleryFourth(withCropper, 'mobile');

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'fourth',
  });

  return (
    <Grid
      container
      item
      sm={12}
      direction="column"
      style={{ paddingTop: '21px' }}
    >
      <Grid item sm={12}>
        <TextGalleryFourthMobile>
          {data.name}
        </TextGalleryFourthMobile>
      </Grid>
      <Grid container item sm={12} alignItems="center">
        <ImageGalleryFourthMobile>
          {data.file}
        </ImageGalleryFourthMobile>
      </Grid>
      <Grid item sm={12}>
        <SquareBoxesMobile>
          <ImageFirstCellMobile>
            {extraFile1}
          </ImageFirstCellMobile>
          <MessageCellMobile>
            {data.message}
          </MessageCellMobile>
          <CreatorCellMobile>
            {data.userName}
          </CreatorCellMobile>
          <ImageSecondCellMobile>
            {extraFile2}
          </ImageSecondCellMobile>
          <DateCellMobile>
            {data.date}
          </DateCellMobile>
        </SquareBoxesMobile>
      </Grid>
    </Grid>
  );
};
