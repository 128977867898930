import React, { useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ProgressBar } from './ProgressBar';
import { getNewGallerySchema } from '../../../../../../shared/validation/gallery/newGallerySchema';
import {
  GalleryDataForm,
  GalleryForRequest,
} from '../../../../../../shared/types';
import { StepSwitcher } from './StepSwitcher/StepSwitcher';
import { galleryInitialValues } from '../../../../../../constants/initialValues';
import { NewGalleryCreatorButtons } from './NewGalleryCreatorButtons/NewGalleryCreatorButtons';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { fakeGallery } from '../../../../../../constants/walkThroughCards/fakeGalleryData';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CoverWindow } from '../../../../../../shared/components/LoadingForRequest/styled';
import { useSubmitGalleryObj } from './hooks/useSubmitGalleryObj';

type NewGalleryCreatorProps = {
  galleryId: string;
  currentGallery?: GalleryForRequest;
};

export const NewGalleryCreator: React.FC<NewGalleryCreatorProps> = ({
  galleryId,
  currentGallery,
}) => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { onSubmit, isLoading, step } = useSubmitGalleryObj();

  const initialValues = isStudy ? fakeGallery : galleryInitialValues(galleryId, currentGallery);
  const validationSchema = isStudy ? null : getNewGallerySchema(step);

  return (
    <>
      <Formik<GalleryDataForm>
        initialValues={initialValues}
        onSubmit={(
          data: GalleryDataForm,
          bag: FormikHelpers<GalleryDataForm>,
        ) => onSubmit(data, bag)}
        validationSchema={validationSchema}
      >
        <Form>
          <ProgressBar step={step} />
          <StepSwitcher step={step} />
          <NewGalleryCreatorButtons step={step} />
        </Form>
      </Formik>
      {isLoading && (
      <CoverWindow>
        <PinkLoader />
      </CoverWindow>
      )}
    </>
  );
};
