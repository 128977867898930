import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { baseURL, getHeader } from './instance';

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => getHeader(headers),
  credentials: 'include',
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      `${baseURL}auth/refresh`,
      api,
      extraOptions,
    );

    if (
      refreshResult.meta?.response?.status === 200
      || refreshResult.meta?.response?.status === 201
    ) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.log('not auth');
    }
  }
  return result;
};
