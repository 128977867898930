import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as urls from '../../../../../../../../constants/urls';
import {
  MediumBlackText, MediumGreyText, PinkLink, SimpleBlackText,
} from '../../../../../../../../shared/styles';
import { TextMgB } from '../../styled';

export const PaymentSetupWindowText: React.FC = () => {
  const { t } = useTranslation('settings');

  return (
    <Grid item sm={11} style={{ marginTop: '30px' }}>
      <TextMgB>
        <SimpleBlackText>{t('taxHandledTitle')}</SimpleBlackText>
      </TextMgB>
      <TextMgB>
        <MediumBlackText>{t('taxHandledText')}</MediumBlackText>
      </TextMgB>
      <TextMgB>
        <MediumGreyText>
          <Trans t={t} i18nKey="setupYourAddress">
            Setup your address in
            {' '}
            <PinkLink href={urls.settingsUrl}>Settings</PinkLink>
          </Trans>
        </MediumGreyText>
      </TextMgB>
    </Grid>
  );
};
