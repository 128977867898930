import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCover } from '../../../../shared/types';
import { MobileWrapper } from '../../MobileWrapper';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import {
  FifthMediumTextMessageMobile,
  FifthMediumTextMobile,
  FifthTitleMobile,
  GridWithBorderR,
  ImageContainerFifthMobile,
} from './styled';

export const GalleryDemoFifthMobile: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'fifth',
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ padding: '0 47px 0', height: '100%' }}
    >
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FifthTitleMobile>{data.name}</FifthTitleMobile>
        </Grid>
        <Grid item xs={12}>
          <ImageContainerFifthMobile>
            {data.file}
          </ImageContainerFifthMobile>
        </Grid>
        <Grid item xs={12}>
          <FifthMediumTextMessageMobile>{data.message}</FifthMediumTextMessageMobile>
        </Grid>
        <Grid container item xs={12}>
          <GridWithBorderR item xs={5} style={{ border: '1' }} container alignItems="center">
            <FifthMediumTextMobile>{data.date}</FifthMediumTextMobile>
          </GridWithBorderR>
          <Grid container item xs={7} justifyContent="flex-end" alignItems="center">
            <FifthMediumTextMobile>{data.userName}</FifthMediumTextMobile>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
