import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { InputChips } from '../../../../../../../../shared/components/FormElements/InputChips/InputChips';
import { LittleBlackText, LittleGreyText } from '../../../../../../../../shared/styles';
import { GalleryData } from '../../../../../../../../shared/types';
import { PreviewEmail } from '../PreviewEmail/PreviewEmail';
import { MediumBlackTextMg, ReminderEmailFormChipsWrapper } from './styled';

export const ReminderEmailRegisteredEmails: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const {
    values, errors, handleChange, touched, setFieldValue,
  } = useFormikContext<GalleryData>();

  return (
    <div>
      <MediumBlackTextMg>
        {t('fourthStep.reminder.who.registeredEmails')}
      </MediumBlackTextMg>
      <CommonCheckbox
        isChecked={values.isAllRegisteredEmails}
        name="isAllRegisteredEmails"
        handleChange={handleChange}
        label={<LittleBlackText>{t('fourthStep.reminder.who.allEmails')}</LittleBlackText>}
        size="small"
        id="isAllRegisteredEmails"
        helpTipText={t('fourthStep.helpTexts.reminderWhoAll')}
      />
      <LittleGreyText>{t('fourthStep.reminder.who.or')}</LittleGreyText>
      <ReminderEmailFormChipsWrapper>
        <InputChips
          values={values.whoRecipients}
          name="whoRecipients"
          placeholder={t('fourthStep.reminder.who.emailsPlaceholder')}
          error={errors.whoRecipients}
          touched={touched.whoRecipients}
          setFieldValue={setFieldValue}
          disabled={values.isAllRegisteredEmails}
        />
      </ReminderEmailFormChipsWrapper>
      <PreviewEmail />
    </div>
  );
};
