import styled from '@emotion/styled';
import walkthroughCards from '../../../../../../assets/image/landing/swipers/features/walkthroughCards.png';

export const LandingMainFeaturesSwiperWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 104px;
  box-sizing: border-box;
  padding: 0px 40px;

`;

export const FeaturesSwiperPrivacySlide = styled.div`
  position: relative;
  padding-left: 150px;
  padding-top: 80px;
  box-sizing: border-box; 
`;

export const PrivacySlideImgPositioned = styled.img`
  position: absolute;
  top: -37%;
  left: -25%;
`;

export const FeaturesWalkthroughSlide = styled.div`
  background-image: url(${walkthroughCards});
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto;
  width: 870px;
  height: 627px;
  min-width: 870px;
  min-height: 627px;
  display: flex;
  justify-content: flex-end;

  & img {
    height: 100%;
    object-fit: contain !important;
    width: fit-content !important;
    border-radius: 41.2021px;
    margin-right: 30px;
  }
`;
