import styled from '@emotion/styled';
import { PrimaryWhiteButton, GradientColorText } from '../../../../../shared/styles';
import * as variables from '../../../../../constants/styles';
import { ButtonTheme, ShadowTheme } from './types';
import { BaseColors } from '../../../../../shared/types';
import { getBaseColor } from '../../../../../shared/utils/style/getBaseColor';

export const JoinEarlyProgramBtn = styled(PrimaryWhiteButton)<{btnTheme: ButtonTheme, shadowTheme: ShadowTheme}>`
background: ${({ btnTheme }) => {
    if (btnTheme === 'dark') {
      return variables.textBlack;
    }
    return variables.colorGreyLight;
  }};
box-shadow: ${({ shadowTheme }) => {
    if (shadowTheme === 'dark') {
      return '19px 19px 27px #041421, -19px -19px 27px #062237';
    }
    if (shadowTheme === 'pink') {
      return '-2px -2px 3px #FFA3A5, 2px 2px 3px #BF7073';
    }
    if (shadowTheme === 'white') {
      return '-5px -5px 10px #fff, 5px 5px 10px #e8e8e8';
    }
    return '';
  }};
transition: all 0.2s ease-in-out;

&:hover {
  box-shadow: ${({ shadowTheme }) => {
    if (shadowTheme === 'dark') {
      return '19px 19px 27px #041421, -19px -19px 27px #062237';
    }
    if (shadowTheme === 'pink') {
      return '-3px -3px 6px #FFA3A5, 3px 3px 6px #BF7073';
    }
    return '-5px -5px 10px #fff, 7px 7px 14px #e6e6e6';
  }};
  transition: all 0.2s ease-in-out;
}

&:active {
  box-shadow: ${({ shadowTheme }) => {
    if (shadowTheme === 'dark') {
      return '19px 19px 27px #041421, -19px -19px 27px #062237';
    }
    if (shadowTheme === 'pink') {
      return 'inset -2px -2px 3px #FFA3A5, inset 2px 2px 3px #BF7073';
    }
    return 'inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8';
  }};
  transition: all 0.2s ease-in-out;
}
`;

export const LandingMainGradientColorText = styled(GradientColorText)`
display: flex;
flex-direction: row;
align-items: center;

& svg {
  margin-right: 8px;
}
`;

export const LandingMainBtnAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LandingMainLittleText = styled.div<{textColor: BaseColors}>`
  font-size: 12px;
  line-height: 150%;
  color: ${({ textColor }) => getBaseColor(textColor)};
  margin-top: 16px;
`;
