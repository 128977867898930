import React, { ChangeEvent } from 'react';
import { ErrorMessage } from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { TextLabel } from '../TextLabel';
import { CustomSwitch } from './CustomStyledSwitch';
import { SwitchAndLabelWrapper } from './styled';

type CommonInputProps = {
  error?: string | any;
  isChecked: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  touched?: boolean | any;
  shortLabel?: boolean;
  label?: string | Element;
  helpText?: string;
  positionHelpText?: 'top' | 'left';
  disabled?: boolean;
  spaceBetween?: boolean;
  withoutMargins?: boolean;
  littleText?: boolean;
  blueButton?: boolean;
  id?: string;
};

export const CommonSwitch: React.FC<CommonInputProps> = ({
  error,
  name,
  touched,
  label,
  isChecked,
  shortLabel,
  helpText,
  handleChange,
  positionHelpText = 'left',
  disabled,
  spaceBetween,
  withoutMargins,
  littleText,
  blueButton,
  id,
}) => {
  const theme = {
    width: '100%',
    flexDirection: 'row',
  };

  return (
    <InputAndLabelWrapper
      direction="row"
      id={id || name}
      withoutMargins={withoutMargins}
    >
      <InputAndErrorWrapper>
        <SwitchAndLabelWrapper spaceBetween={spaceBetween}>
          <TextLabel
            name={name}
            label={label}
            helpText={helpText}
            short={shortLabel}
            disabled={disabled}
            wrapperDirection="row"
            littleText={littleText}
          />
          <div>
            <CustomSwitch
              id={name}
              checked={isChecked}
              onChange={handleChange}
              name={name}
              disabled={disabled}
              bluebutton={!!blueButton}
            />
          </div>
        </SwitchAndLabelWrapper>
        <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
      </InputAndErrorWrapper>
    </InputAndLabelWrapper>
  );
};
