import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import {
  PrimaryWhiteButton,
  SimpleBlackText,
  SimpleGreyText,
  TextBlackLarge,
} from '../../../../../../../../shared/styles';
import { HrLine } from '../../../../../../../../shared/styles/Elements';
import { ChosenPlan } from '../../../../../../../../shared/types';
import { ButtonMgT } from '../../UsageAndBilling/styled';
import { SummaryRow, SummaryTable } from './styled';

type SummaryProps = {
  plan: ChosenPlan;
};

export const Summary: React.FC<SummaryProps> = ({ plan }) => {
  const { t } = useTranslation('settings');

  const handleProceed = () => {
    console.log('click');
  };

  return (
    <div>
      <TextBlackLarge>{t('summary.title')}</TextBlackLarge>
      <SummaryTable>
        <SummaryRow>
          <div>
            <SimpleGreyText>{t('summary.plan')}</SimpleGreyText>
          </div>
          <div>
            <SimpleBlackText>{plan.planType}</SimpleBlackText>
          </div>
        </SummaryRow>
        <SummaryRow>
          <div>
            <SimpleGreyText>{t('summary.cost')}</SimpleGreyText>
          </div>
          <div>
            <SimpleGreyText>
              <CommonPriceInputReadOnly
                value={plan.isAnnually ? +plan.monthlyCost * 12 : +plan.cost}
              />
            </SimpleGreyText>
          </div>
        </SummaryRow>
        {plan.isAnnually && (
          <SummaryRow>
            <div>
              <SimpleGreyText>{t('summary.discount')}</SimpleGreyText>
            </div>
            <div>
              <SimpleBlackText>{t('summary.discountText')}</SimpleBlackText>
            </div>
          </SummaryRow>
        )}
        <HrLine />
      </SummaryTable>
      <SummaryRow>
        <div>
          <TextBlackLarge fontWeight={600}>{t('summary.total')}</TextBlackLarge>
        </div>
        <div>
          <CommonPriceInputReadOnly
            value={+plan.cost}
          />
        </div>
      </SummaryRow>
      <ButtonMgT>
        <PrimaryWhiteButton type="button" onClick={handleProceed}>
          {t('summary.proceedToCheckout')}
        </PrimaryWhiteButton>
      </ButtonMgT>
    </div>
  );
};
