import React, { useContext, useEffect, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import {
  TabWrapper,
  TitleText,
  TabContentWrapper,
  SimpleBlackText,
  SimplePinkText,
  GradientColorText,
  GradientLink,
} from '../../../../../../shared/styles';
import { FAQAccordion } from './FAQAccordion/FAQAccordion';
import { ColumnWrapper, VideoColumnWrapper, WhiteButtonFitContent } from './styled';
import { VideoFromYouTube } from './VideoFromYouTube';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import * as urls from '../../../../../../constants/urls';
import { CreatorOnboardingStepIndexContext } from '../../../../context/CreatorOnboardingStepIndexContext';
import { fetchGetSupport } from '../../../../redux/support/supportThunk';

export const Support: React.FC = () => {
  const { t } = useTranslation('faq');
  const history = useHistory();
  const { setIsStudy } = useContext(CreatorOnboardingContext);
  const { setStepIndex, stepIndex } = useContext(CreatorOnboardingStepIndexContext);
  const dispatch = useDispatch();

  const questions = useTypedSelectorCreator((state) => state.support.questions);
  const isLoading = useTypedSelectorCreator((state) => state.support.loading);

  useEffect(() => {
    dispatch(fetchGetSupport());
  }, []);

  const handleStartTour = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (stepIndex > 4) {
      setStepIndex(0);
    }
    if (stepIndex < 4) {
      setStepIndex(0);
    }
    setIsStudy(true);
    history.push(urls.creatorRootUrl);
  };

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <FAQAccordion questions={questions} />
        )}
        <ColumnWrapper id="training-course">
          <TitleText>{t('trainingCourse')}</TitleText>
          <SimpleBlackText style={{ marginBottom: '20px' }}>
            <Trans t={t} i18nKey="trainingCourseQuestion">
              Didn’t find the answer to your question? Go through the training
              course again or
              {' '}
              <SimplePinkText>ask our bot</SimplePinkText>
            </Trans>
          </SimpleBlackText>
          <WhiteButtonFitContent type="button" onClick={handleStartTour}>
            <GradientColorText fontWeight={600}>
              {t('viewTraining')}
            </GradientColorText>
          </WhiteButtonFitContent>
        </ColumnWrapper>
        <VideoColumnWrapper>
          <TitleText>
            <Trans t={t} i18nKey="checkYouTubeTitle">
              Check our
              {' '}
              <GradientLink
                target="_blank"
                href={urls.youtubeChannel}
                fontWeight={500}
              >
                YouTube channel
              </GradientLink>
            </Trans>
          </TitleText>
          <SimpleBlackText>{t('checkYouTubeText')}</SimpleBlackText>
        </VideoColumnWrapper>
        <Grid container item sm={12}>
          <VideoFromYouTube />
        </Grid>
      </TabContentWrapper>
    </TabWrapper>
  );
};
