import React, { useContext } from 'react';
import { GridMgTop } from './styled';
import * as url from '../../../../../../constants/urls';
import { GalleryCardCustom } from './GalleryCardCustom';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { fakeGalleries } from '../../../../../../constants/walkThroughCards/fakeGalleryData';
import { useGetAllGalleriesQuery } from '../../../../redux/gallery/galleryAPI';

export const GalleriesList: React.FC = () => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { data } = useGetAllGalleriesQuery(undefined);
  const draftGalleries = data?.draftGalleries;
  const activeGalleries = data?.activeGalleries;

  const checkedStudyActiveGalleries = isStudy ? fakeGalleries : activeGalleries;
  const checkedStudyDraftGalleries = isStudy ? fakeGalleries : draftGalleries;

  return (
    <GridMgTop container spacing={4}>
      <GalleryCardCustom
        gallery={
          checkedStudyActiveGalleries?.length
            ? checkedStudyActiveGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyActiveGalleries?.length || 0}
        type="Active"
        toUrl={url.activeGalleriesUrl}
      />
      <GalleryCardCustom
        gallery={
          checkedStudyDraftGalleries?.length
            ? checkedStudyDraftGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyDraftGalleries?.length || 0}
        type="Draft"
        toUrl={url.draftGalleriesUrl}
      />
    </GridMgTop>
  );
};
