import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoinEarlyProgramButton } from '../JoinEarlyProgramButton/JoinEarlyProgramButton';
import {
  LandingMainFirstSectionWrp,
  LandingMainTitle,
  LandingMainText,
} from './styled';

export const LandingMainFirstSection: React.FC = () => {
  const { t } = useTranslation('landing');

  return (
    <LandingMainFirstSectionWrp>
      <LandingMainTitle>{t('firstSection.mainTitle')}</LandingMainTitle>
      <LandingMainText>{t('firstSection.mainText')}</LandingMainText>
      <div style={{ zIndex: 100 }}>
        <JoinEarlyProgramButton btnTheme="white" shadowTheme="pink" />
      </div>
    </LandingMainFirstSectionWrp>
  );
};
