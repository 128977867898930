import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { CustomPositionCheckbox } from '../../../../../shared/components/styled';

export const BaseCartFile = styled.div`
  position: relative;
  width: 195px;
  height: 199px;
  min-width: 195px;
  min-height: 195px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 181px;
    height: 181px;
    min-width: 181px;
    min-height: 181px;
  }
`;

export const CartFileWrapper = styled(BaseCartFile)<{isChecked: boolean}>`
  border: 1px solid ${({ isChecked }) => (isChecked ? variables.colorPink : variables.colorGreyLight)};

  & #basic-doc {
    border-radius: 16px;
    font-size: 14px;
    padding: 17.5px;

    & svg {
      width: 31px;
      height: 36px;
    }
  }

  & div {
    & img {
      max-width: none !important;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    & #basic-doc {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
`;

export const CartItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  width: 100%;

  & img {
    max-width: inherit !important;
  }
`;

export const CartItemDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0 20px;

    & div {
      font-size: 14px;
      line-height: 19.6px;
      margin-bottom: 10px;

      &:last-child {
        margin-top: 4px;
      }
    }
  }
`;

export const CartItemTextDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const CustomPositionCheckboxCart = styled(CustomPositionCheckbox)`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    & > label {
      & > span, & #checkbox {
        top: 10px;
        left: 11px;
        margin-top: 0;
      }
    }
  }
`;
