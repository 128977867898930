import React, { ReactNode } from 'react';
import { isDocument, isImage, isVideo } from '..';
import { ImageContainer } from '../../components/Files/ImageContainer/ImageContainer';
import { VideoContainer } from '../../components/Files/VideoContainer/VideoContainer';
import { BasicDocumentCard } from '../../components/Files/BasicDocumentCard/BasicDocumentCard';
import { CustomFile } from '../../types';
import { FileLoader } from '../../components/Loaders/FileLoader/FileLoader';

type ImageAndVideoProps = {
  autoPlay?: boolean;
  widthStretch?: boolean;
};

export const getFileInContainer = (
  file: CustomFile | null,
  imageAndVideoProps?: ImageAndVideoProps,
  showLoading?: boolean,
): ReactNode | null => {
  if (file?.url) {
    if (isDocument(file)) {
      return (
        <BasicDocumentCard isSmall file={file} />
      );
    }
    if (isImage(file)) {
      return (
        <ImageContainer
          imageUrl={file?.url || ''}
          widthStretch={imageAndVideoProps?.widthStretch}
        />
      );
    }
    if (isVideo(file)) {
      return (
        <VideoContainer
          videoUrl={file?.url || ''}
          widthStretch={imageAndVideoProps?.widthStretch}
          autoPlay={imageAndVideoProps?.autoPlay}
        />
      );
    }
  } else if (showLoading && file?.id) {
    return <FileLoader isLittle isLoading />;
  }
  return null;
};
