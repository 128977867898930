import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCoverFifth } from '../../../../../../../GalleryView/GalleryCovers/GalleryFifth/GalleryCoverFifth';
import { GalleryDemoFifthMobile } from '../../../../../../../GalleryView/GalleryCovers/GalleryFifth/GalleryDemoFifthMobile';
import { GalleryDemoFifthTablet } from '../../../../../../../GalleryView/GalleryCovers/GalleryFifth/GalleryDemoFifthTablet';
import {
  GridWithBorderL,
  GridWithBorderR,
} from '../../../../../../../GalleryView/GalleryCovers/GalleryFifth/styled';
import { useGalleryContent } from '../../../../../../../GalleryView/hooks/useGalleryContent';
import { GalleryCover } from '../../../../../../../../shared/types';
import { ModalWindow } from '../ModalWindow';
import { PreviewCard, PreviewWrapper } from '../styled';
import { SelectedPreview } from '../types';
import {
  FifthMediumTextPreview,
  FifthTitlePreview,
  FifthImageContainerPreview,
  FifthMediumTextMessagePreview,
} from './styled';
import { MobileWrapper } from '../../../../../../../GalleryView/MobileWrapper';

export const PreviewFifth: React.FC<GalleryCover & SelectedPreview> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCard theme={selected ? 'selected' : ''} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          item
          sm={12}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '-15px' }}
        >
          <Grid
            container
            item
            sm={10}
            justifyContent="space-between"
            alignItems="center"
          >
            <GridWithBorderR item sm={3} container alignItems="center">
              <FifthMediumTextPreview>{data.date}</FifthMediumTextPreview>
            </GridWithBorderR>
            <Grid item sm={6} container alignItems="center">
              <FifthTitlePreview>{data.name}</FifthTitlePreview>
            </Grid>
            <GridWithBorderL
              container
              item
              sm={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <FifthMediumTextPreview>{data.userName}</FifthMediumTextPreview>
            </GridWithBorderL>
          </Grid>
          <Grid container item sm={12} justifyContent="center" alignItems="center">
            <FifthImageContainerPreview>{data.file}</FifthImageContainerPreview>
          </Grid>
          <Grid item sm={6}>
            <FifthMediumTextMessagePreview>
              {data.message}
            </FifthMediumTextMessagePreview>
          </Grid>
        </Grid>
      </PreviewCard>
      <ModalWindow>
        <>
          <GalleryCoverFifth
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
          />
          <Grid container item sm={10} justifyContent="space-between">
            <GalleryDemoFifthTablet
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
            <MobileWrapper>
              <GalleryDemoFifthMobile
                name={name}
                date={date}
                file={file}
                userName={userName}
                message={message}
                withCropper
              />
            </MobileWrapper>
          </Grid>
        </>
      </ModalWindow>
    </PreviewWrapper>
  );
};
