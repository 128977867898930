import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SimpleBlackText,
  TabContentWrapper,
  TabWrapper,
  WhiteCardWithShadows,
} from '../../../../../../shared/styles';
import {
  BlueWrapper,
  LimitTextWrapper,
  PercentText,
  SalesCardWrapper,
  SalesMonthContainer,
  SalesMonthText,
  TitleTextBoldWithMgBt,
  TotalSalesWrapper,
} from './styled';
import { HelpTip } from '../../../../../../shared/components/HelpTip/HelpTip';
import { SalesChart } from './SalesChart/SalesChart';
import { getDataByScale } from '../../../../../../shared/utils/sales/getDataByScale';
import { BaseTabs } from '../../../../../../shared/components/Tabs/BaseTabs/BaseTabs';
import { TabsWrapper } from './SalesChart/styled';
import { useGetTabsSales } from './useGetTabsSales';

export const Sales: React.FC = () => {
  const { t } = useTranslation('sales');

  const [value, setValue] = useState(0);
  const tabs = useGetTabsSales();
  const dataForChart = useMemo(() => getDataByScale(value), [value]);

  const sales = 2564;
  const totalSales = 7598;

  return (
    <TabWrapper>
      <TabContentWrapper>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <WhiteCardWithShadows>
              <SalesChart dataForChart={dataForChart}>
                <TabsWrapper>
                  <BaseTabs tabs={tabs} value={value} setValue={setValue} />
                </TabsWrapper>
              </SalesChart>
            </WhiteCardWithShadows>
          </Grid>
          <Grid item sm={6}>
            <WhiteCardWithShadows>
              <SalesCardWrapper>
                <SalesMonthContainer>
                  <SalesMonthText>
                    <Trans t={t} i18nKey="salesSum">
                      $
                      {{ sales }}
                    </Trans>
                  </SalesMonthText>
                  <div style={{ position: 'relative' }}>
                    <HelpTip text="Something" isPinkBackground />
                  </div>
                </SalesMonthContainer>
                <LimitTextWrapper>
                  <SimpleBlackText>{t('salesGrowth')}</SimpleBlackText>
                </LimitTextWrapper>
                <SalesMonthContainer>
                  <PercentText>+6% ($177.6)</PercentText>
                </SalesMonthContainer>
              </SalesCardWrapper>
            </WhiteCardWithShadows>
          </Grid>
          <Grid item sm={5}>
            <WhiteCardWithShadows>
              <TotalSalesWrapper>
                <LimitTextWrapper style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <TitleTextBoldWithMgBt style={{ textAlign: 'center' }}>
                    {t('totalSales')}
                  </TitleTextBoldWithMgBt>
                </LimitTextWrapper>
                <BlueWrapper>
                  <Trans t={t} i18nKey="salesSum">
                    $
                    {{ sales: totalSales }}
                  </Trans>
                </BlueWrapper>
              </TotalSalesWrapper>
            </WhiteCardWithShadows>
          </Grid>
        </Grid>
      </TabContentWrapper>
    </TabWrapper>
  );
};
