import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { LandingContentContainer } from '../../LandingContentContainer';
import { LandingPricingQuestionsTitle, LandingPricingQuestionsWrp } from './styled';
import { FAQAccordion } from '../../../../Creator/containers/CreatorAccount/Tabs/Support/FAQAccordion/FAQAccordion';
import { SupportQuestions } from '../../../../../shared/types';

const useGetAccordionList = (): SupportQuestions[] => {
  const { t } = useTranslation('landing');

  return ([
    {
      id: uuidv4(),
      question: t('pricing.questions.questCost'),
      answer: t('pricing.questions.answerCost'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questGetRefund'),
      answer: t('pricing.questions.answerGetRefund'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questHowMakeMoney'),
      answer: t('pricing.questions.answerHowMakeMoney'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questJustSendContent'),
      answer: t('pricing.questions.answerJustSendContent'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questOfficiallyLaunching'),
      answer: t('pricing.questions.answerOfficiallyLaunching'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questFacebook'),
      answer: t('pricing.questions.answerFacebook'),
    },
    {
      id: uuidv4(),
      question: t('pricing.questions.questDifferent'),
      answer: t('pricing.questions.answerDifferent'),
    },
  ]);
};

export const LandingPricingQuestions: React.FC = () => {
  const { t } = useTranslation('landing');
  const list = useGetAccordionList();

  return (
    <LandingContentContainer>
      <LandingPricingQuestionsWrp>
        <LandingPricingQuestionsTitle>
          {t('pricing.questions.title')}
        </LandingPricingQuestionsTitle>
        <FAQAccordion questions={list} />
      </LandingPricingQuestionsWrp>
    </LandingContentContainer>
  );
};
