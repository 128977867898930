import React from 'react';
import { GlobalContainer } from '../../../shared/components/GlobalContainer/GlobalContainer';
import { LandingRoutes } from '../routers/LandingRoutes';
import { LandingFooter } from './LandingFooter/LandingFooter';
import { LandingHeader } from './LandingHeader/LandingHeader';
import { LandingContainerWrp } from './styled';

export const LandingContainer: React.FC = () => (
  <GlobalContainer withoutMUIContainer>
    <LandingContainerWrp>
      <div>
        <LandingHeader />
        <LandingRoutes />
      </div>
      <LandingFooter />
    </LandingContainerWrp>
  </GlobalContainer>
);
