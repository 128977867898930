import styled from '@emotion/styled';
import * as variables from '../../constants/styles';
import { theme } from '../components/GlobalContainer/styled';

export const PlusCssIcon = styled.div<{color?: 'black' | 'pink'}>`
position: relative;
width: 16px;
height: 16px;

&:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background: ${(props) => (props.color === 'pink' ? variables.colorPink : variables.textBlack)};
  top: 7px;
  left: 0;
}
&:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background: ${(props) => (props.color === 'pink' ? variables.colorPink : variables.textBlack)};
  top: 0;
  left: 7px;
}
`;

export const HrLine = styled.hr`
  height: 0;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0.5px solid ${variables.colorBleak};
`;

export const AfterAddToCart = styled.div<{isVisible: boolean}>`
  position: absolute;
  top: 100%;
  left: -50%;
  z-index: 200;
  margin-left: -135px;
  white-space: pre;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  color: #F8F8F8;
  background: linear-gradient(90deg, #AAA5CF 0%, #D88CB6 100%);
  border-radius: 2px; 
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'none')};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    max-width: 150px;
    text-align: center;
    white-space: normal;
  }
`;
