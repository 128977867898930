import React, { ReactElement } from 'react';
import { css, Global } from '@emotion/react';
import { Container, ThemeProvider } from '@material-ui/core';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { getConfig as getI18nextConfig } from '../../../translations';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  GlobalStyle,
  theme,
} from './styled';
import { useCheckWindowSize } from '../../hooks/useCheckWindowSize';

i18next.init(getI18nextConfig());

type GlobalContainerProps = {
  children: ReactElement;
  withoutMUIContainer?: boolean;
}

export const GlobalContainer: React.FC<GlobalContainerProps> = ({
  children,
  withoutMUIContainer,
}) => {
  const { screenSize } = useCheckWindowSize();
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
              ${GlobalStyle}
            `}
        />
        {withoutMUIContainer ? children
          : (
            <Container maxWidth="xl" disableGutters={screenSize === 'mobile'}>
              {children}
            </Container>
          )}
      </ThemeProvider>
    </I18nextProvider>
  );
};
