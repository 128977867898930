import styled from '@emotion/styled';
import { SimpleBlackText, WhiteCardWithShadows } from '../../../../../../../shared/styles';
import * as variables from '../../../../../../../constants/styles';

export const SalesCardWrapper = styled(WhiteCardWithShadows)`
  flex-direction: column;
  width: 219px;
  padding: 14px 24px 19px;
  min-width: 200px;
  height: fit-content;
  border: 1px solid rgba(235, 235, 235, 0.8);
`;
export const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  text-align: center;
`;

export const SimpleBlackTextLittleLineHeight = styled(SimpleBlackText)`
  line-height: 15px !important;
`;

export const GalleryInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
`;

export const StatusAndDateWrapper = styled.div<{isExpire: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  & > svg {
    color: ${({ isExpire }) => (isExpire ? variables.colorBleak : variables.colorPink)};
    margin-right: 8px;
  }
`;

export const PasswordAndDateWrapper = styled.div`
  margin-left: 20px;
`;
