import styled from '@emotion/styled';
import * as variables from '../../constants/styles';
import { BaseColors } from '../types';

export const PrimaryWhiteButton = styled.button<{fullWidth?: boolean, width?: string}>`
  box-shadow: -5px -5px 10px #fff, 5px 5px 10px #e8e8e8;
  border-radius: 51px;
  background: ${variables.colorGreyLight};
  text-transform: uppercase;
  font-weight: 600;
  line-height: 150%;
  font-size: 16px;
  color: ${variables.textBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 28px;
  height: fit-content;
  white-space: pre;
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: -5px -5px 10px #fff, 7px 7px 14px #e6e6e6;
    transition: all 0.2s ease-in-out;
  }

  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8;
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    color: ${variables.colorBleak};
    cursor: auto;
    transition: all 0.2s ease-in-out;
  }
`;

export const PrimaryWhiteButtonBlueText = styled(PrimaryWhiteButton)`
  height: 50px;
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  color: ${variables.colorBlue};
  margin-top: 26px;
  padding: 0 28px;

  &:hover {
    box-shadow: -5px -5px 10px #fff, 7px 7px 14px #e6e6e6;
  }

  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8;
  }

  &:disabled {
    color: ${variables.colorBlueLight};
    cursor: auto;
  }
`;

export const PrimaryWhiteButtonBlueTextNoMg = styled(PrimaryWhiteButtonBlueText)`
  margin: 0;
`;

export const ButtonWithoutShadow = styled(PrimaryWhiteButtonBlueText)`
  box-shadow: none;
  border-radius: 12px;
  width: 56px;
  margin-top: 0;
  margin-right: 20px;
  color: ${variables.textGrey};
  padding: 0 28px;
  background: transparent;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;
export const FileInputBase = styled.div`
  position: relative;
  width: 100%;

  & input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
  }
`;
export const ColorfulButton = styled(PrimaryWhiteButtonBlueText)`
  color: ${variables.colorGreyLight};
  box-shadow: -2px -2px 3px #bfb9eb, 2px 2px 3px #ba789d;
  background: linear-gradient(90deg, #aaa5cf 0%, #d88cb6 100%);

  &:hover {
    box-shadow: -3px -3px 6px #bfb9eb, 3px 3px 6px #ba789d;
  }

  &:active {
    box-shadow: inset -3px -3px 6px #d7a5d2, inset 3px 3px 6px #a094be;
  }
`;

export const BlackTextWhiteButton = styled(PrimaryWhiteButtonBlueText)`
  color: ${variables.textBlack};
`;

export const WhiteButtonWithoutShadow = styled.button`
  background: ${variables.colorGreyLight};
  border-radius: 51px;
  padding: 13px 28px;
  color: ${variables.textBlack};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  width: fit-content;
  position: relative;
`;

export const RoundButton = styled.button<{large?: boolean, color?: BaseColors}>`
  position: relative;
  box-shadow: -5px -5px 10px #fff, 5px 5px 10px #e8e8e8;
  width: ${(props) => (props.large ? '54px' : '38px')};
  height: ${(props) => (props.large ? '54px' : '38px')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${variables.colorGreyLight};
  transition: all 0.2s ease-in-out;

  & svg {
    color: ${(props) => {
    if (props.color === 'pink') return variables.colorPink;
    if (props.color === 'black') return variables.textBlack;
    if (props.color === 'grey') return variables.textGrey;
    if (props.color === 'white') return 'white';
    return variables.textBlack;
  }}
  }

  &:hover {
    box-shadow: -5px -5px 10px #fff, 7px 7px 14px #e6e6e6;
    transition: all 0.2s ease-in-out;

    & svg {
      color: ${variables.colorPink};
    }
  }

  &.active {
    transition: all 0.2s ease-in-out;
    box-shadow: inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8;
    color: ${variables.colorPink};

    & svg {
      color: ${variables.colorPink};
    }
  }

  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8;
    transition: box-shadow 0.2s ease-in-out;
  }

  &::after {
    content: '';
  }

  &:disabled {
    box-shadow: -5px -5px 10px #fff, 5px 5px 10px #e8e8e8;
    cursor: auto;

    &:hover {}
    &:active {}
    & svg {
      color: ${(props) => {
    if (props.color === 'pink') return variables.colorPink;
    if (props.color === 'black') return variables.textBlack;
    if (props.color === 'grey') return variables.textGrey;
    if (props.color === 'white') return 'white';
    return variables.textBlack;
  }
}}
  }
`;

export const InsideRoundButtonWrapper = styled.div`
  & > div {
    box-sizing: border-box;
    min-height: 18px;
    min-width: 18px;
    padding: 3px 6px;
    position: absolute;
    top: -7px;
    left: 70%;
    border-radius: 50%;
    background: ${variables.colorPink};
    display: flex;
    align-items: center;
    text-align: center;

    & > span {
      color: white;
      font-size: 10px;
      line-height: 9px;
    }
  }
`;

export const CloseButton = styled.button<{color?: 'pink' | 'black'}>`
  margin-left: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${(props) => (props.color === 'pink' ? variables.colorPink : variables.textBlack)};
`;

export const CloseButtonPositioned = styled.button<{color?: 'black' | 'white'}>`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
  padding: 0;
  
  & svg {
    width: 25px;
    height: 25px;
    color: ${(props) => (props.color === 'white' ? variables.colorGreyLight : variables.textBlack)};
  }
`;

export const BaseSecondaryButton = styled.button`
  padding: 10px 16px;
  background: #F8F8F8;
  box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
  border-radius: 51px;
  color: ${variables.colorPink};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  height: fit-content;
  white-space: pre;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: -5px -5px 10px #FFFFFF, 7px 7px 14px #E6E6E6;
    transition: all 0.2s ease-in-out;
  }

  &:active {
    box-shadow: inset -5px -5px 10px #FFFFFF, inset 5px 5px 10px #E8E8E8;
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
    color: ${variables.colorPinkLight};
    cursor: auto;
    transition: all 0.2s ease-in-out;
  }
`;

export const ColorfulButtonWhiteBack = styled.button`
  padding: 13px 28px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${variables.colorGreyLight};
  background: linear-gradient(274.36deg, #E6934F -13.34%, #E186B0 48.12%, #78C0EA 114.29%);
  box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
  border-radius: 51px;

  &:hover {
    box-shadow: -5px -5px 10px #FFFFFF, 7px 7px 14px #E6E6E6;
  }
  
  &:disabled {
    opacity: 0.6;
  }
`;

export const BaseWithoutShadowButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${variables.colorBlue};

  &:disabled {
    color: ${variables.colorBlueLight};
  }
`;

export const ButtonWithIcon = styled.button<{color?: BaseColors}>`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: auto;
  }

  & svg {
    color: ${(props) => {
    if (props.color === 'pink') return variables.colorPink;
    if (props.color === 'black') return variables.textBlack;
    if (props.color === 'grey') return variables.textGrey;
    if (props.color === 'white') return 'white';
    return variables.textBlack;
  }}
  }
`;
