import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getIdeaCoverOrFile, isArrayIncludeValue, isIdea } from '../../../../../../../shared/utils/commonFunctions';
import { getFileInContainer } from '../../../../../../../shared/utils/createGallery';
import { GradientCoverWithActions } from '../../../../../../../shared/components/GradientCoverWithActions/GradientCoverWithActions';
import { SimpleGreyText } from '../../../../../../../shared/styles';
import { GalleryFileInformation, GallerySection } from '../../../../../../../shared/types';
import previewImage from '../../../../../../../assets/image/previewImage.png';
import {
  EmptyFilePreview,
  FilePreviewTextUpperWrapper,
  FilePreviewWrapper,
  FilePreviewImageContainer,
  TextAlignRight,
} from './styled';

type FilePreviewProps = {
  selectedFileIds: string[];
  section: GallerySection;
  values: GalleryFileInformation;
};

export const FilePreview: React.FC<FilePreviewProps> = ({
  selectedFileIds,
  section,
  values,
}) => {
  const { t } = useTranslation('createNewGallery');
  const numberFilesWithoutOne = selectedFileIds.length - 1 || '';

  const selectedFiles = useMemo(
    () => section?.files?.filter((item) => isArrayIncludeValue(selectedFileIds, item.id)),
    [selectedFileIds, section.files],
  );
  const selectedIdeas = useMemo(
    () => section?.ideas?.filter((item) => isArrayIncludeValue(selectedFileIds, item.id)),
    [selectedFileIds, section.ideas],
  );

  const firstSelectedItem = [...selectedFiles, ...selectedIdeas][0];
  const firstSelectedFile = getIdeaCoverOrFile(firstSelectedItem);

  const previewFileInContainer = useMemo(
    () => {
      if (selectedFileIds.length && firstSelectedItem) {
        return getFileInContainer(getIdeaCoverOrFile(firstSelectedItem));
      }
      return null;
    },
    [firstSelectedItem, selectedIdeas, selectedFileIds],
  );

  if (!selectedFileIds.length && !selectedIdeas.length) {
    return (
      <EmptyFilePreview>
        <span>{t('thirdStep.chooseFilePreview')}</span>
        <img src={previewImage} alt="file preview" />
      </EmptyFilePreview>
    );
  }

  return (
    <FilePreviewWrapper>
      <FilePreviewTextUpperWrapper>
        {t('thirdStep.filePreview')}
      </FilePreviewTextUpperWrapper>
      <FilePreviewImageContainer>
        <GradientCoverWithActions
          file={firstSelectedFile}
          id={firstSelectedItem.id}
          disabledAll
          isAllowCommenting={!!values.allowCommenting}
          isInstantlyDownloadable={!!values.instantlyDownloadable}
          isGradientVisible
        />
        {previewFileInContainer}
      </FilePreviewImageContainer>
      {numberFilesWithoutOne && (
        <TextAlignRight>
          <SimpleGreyText>
            <Trans t={t} i18nKey="thirdStep.plusFiles">
              +
              {' '}
              {{ numberFiles: numberFilesWithoutOne }}
              {' '}
              files
            </Trans>
          </SimpleGreyText>
        </TextAlignRight>
      )}
    </FilePreviewWrapper>
  );
};
