import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SpotsProgressLine } from '../../SpotsPopup/SpotsPopup';
import { LandingProgressContainer } from '../../SpotsPopup/styled';
import { PricingDescriptionSpotsCard, PricingSpotTooltip, PricingSpotText } from './styled';

export const LandingPricingDescriptionSpots: React.FC = () => {
  const { t } = useTranslation('landing');
  const spots = 50;

  return (
    <PricingDescriptionSpotsCard>
      <PricingSpotTooltip fontWeight={500}>
        <Trans t={t} i18nKey="pricing.spotsLeft">
          {{ spots }}
          {' '}
          spots left
        </Trans>
      </PricingSpotTooltip>
      <LandingProgressContainer>
        <SpotsProgressLine variant="determinate" value={spots} />
      </LandingProgressContainer>
      <PricingSpotText>{t('pricing.spotsText')}</PricingSpotText>
    </PricingDescriptionSpotsCard>
  );
};
