import React, { CSSProperties, ReactNode } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useStyles } from '../../hooks/style/modalWindow/useStyles';
import { getContainerBySize } from './getContainerBySize';
import { ModalWindowSize } from '../../types';

type ModalWindowContainerProps = {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  size?: ModalWindowSize;
  style?: CSSProperties;
  isWindowScroll?: boolean;
};

export const ModalWindowContainer: React.FC<ModalWindowContainerProps> = ({
  children,
  isOpen,
  handleClose,
  size,
  style,
  isWindowScroll,
}) => {
  const classes = useStyles();
  const container = getContainerBySize({ isOpen, children }, size, style);

  return (
    <>
      <Modal
        id="modalWindow"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        style={
          isWindowScroll
            ? {
              overflowY: 'scroll',
              padding: '30px',
              alignItems: 'flex-start',
              outline: 'none',
            }
            : { outline: 'none' }
        }
      >
        {container}
      </Modal>
    </>
  );
};
