import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { useGalleryContent } from '../../../../../../../GalleryView/hooks/useGalleryContent';
import { GalleryCover } from '../../../../../../../../shared/types';
import { ModalWindow } from '../ModalWindow';
import { PreviewCard, PreviewWrapper } from '../styled';
import { SelectedPreview } from '../types';
import {
  SixthInfoCardCoverPreview,
  SixthTitlePreview,
  SixthMediumTextPreview,
  ImageContainerSixthPreview,
} from './styled';
import { GalleryCoverSixth } from '../../../../../../../GalleryView/GalleryCovers/GallerySixth/GalleryCoverSixth';
import { GalleryDemoSixthMobile } from '../../../../../../../GalleryView/GalleryCovers/GallerySixth/GalleryDemoSixthMobile';
import { GalleryDemoSixthTablet } from '../../../../../../../GalleryView/GalleryCovers/GallerySixth/GalleryDemoSixthTablet';
import { MobileWrapper } from '../../../../../../../GalleryView/MobileWrapper';

export const PreviewSixth: React.FC<GalleryCover & SelectedPreview> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const refCardPreview = useRef<HTMLDivElement>(null);

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCard theme={selected ? 'selected' : ''}>
        <Grid
          style={{ marginTop: '-10px', marginBottom: '-10px' }}
          container
          justifyContent="center"
        >
          <Grid container item sm={12} justifyContent="center">
            <ImageContainerSixthPreview>
              {data.file}
              <SixthInfoCardCoverPreview
                currentHeight={refCardPreview?.current?.offsetHeight || 0}
                ref={refCardPreview}
              >
                <SixthTitlePreview>{data.name}</SixthTitlePreview>
                <SixthMediumTextPreview>{data.message}</SixthMediumTextPreview>
              </SixthInfoCardCoverPreview>
            </ImageContainerSixthPreview>
          </Grid>
          <Grid item sm={6}>
            <SixthMediumTextPreview>{data.date}</SixthMediumTextPreview>
            <SixthMediumTextPreview>{data.userName}</SixthMediumTextPreview>
          </Grid>
        </Grid>
      </PreviewCard>
      <ModalWindow>
        <>
          <GalleryCoverSixth
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
          />
          <Grid container item sm={10} justifyContent="space-between">
            <GalleryDemoSixthTablet
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
            <MobileWrapper>
              <GalleryDemoSixthMobile
                name={name}
                date={date}
                file={file}
                userName={userName}
                message={message}
                withCropper
              />
            </MobileWrapper>
          </Grid>
        </>
      </ModalWindow>
    </PreviewWrapper>
  );
};
