import React from 'react';
import { Grid } from '@material-ui/core';
import {
  GalleryData,
  GalleryFile,
  GalleryIdea,
} from '../../../../shared/types';
import { ImagesList } from './styled';
import { ImageOrVideoCard } from './ImageOrVideoCard';

type ImagesAndVideosListProps = {
  files: GalleryFile[];
  ideas: GalleryIdea[];
  sectionId: string;
  gallery: GalleryData;
};

export const ImagesAndVideosList: React.FC<ImagesAndVideosListProps> = ({
  files,
  ideas,
  sectionId,
  gallery,
}) => (
  <Grid container item sm={12}>
    <ImagesList id="client-gallery-content-step">
      {[...files, ...ideas].map((item, index) => (
        <ImageOrVideoCard
          gallery={gallery}
          key={item.id}
          fileOrIdea={item}
          sectionId={sectionId}
          fileIndex={index}
        />
      ))}
    </ImagesList>
  </Grid>
);
