import { BetaPlan, Plan } from '../shared/types';

export const plans: Plan[] = [
  {
    planType: 'Basic',
    current: true,
    priceMonthly: 0,
    priceAnnually: 0,
    description: 'Everything you need to try out Delivrable and earn more from your client projects.',
    details: [
      '3 gallery designs',
      '2GB storage',
      '10% commission on your sales',
      '$0 for 14 days',
    ],
  },
  {
    planType: 'Pro',
    current: false,
    priceMonthly: 29,
    priceAnnually: 290,
    description: 'The full Delivrable experience - 10x more storage, extremely low commissions, and premium features.',
    details: [
      '5 gallery designs',
      '20GB storage',
      '1% commission on your sales',
      'Testimonial call-to-action at gallery footer',
      '2 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
    ],
  },
  {
    planType: 'Pro +',
    current: false,
    priceMonthly: 39,
    priceAnnually: 390,
    description: 'Everything in Premium + an extra 20GB of storage and no commissions.',
    details: [
      '7 gallery designs',
      '40GB storage',
      'Testimonial call-to-action at gallery footer',
      'Up to 5 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
      'Premium + support',
    ],
  },
];

export const betaPlans: BetaPlan[] = [
  {
    planType: 'Basic',
    price: 297,
    discount: 100,
    code: 'FIRST100',
    details: [
      { main: '100GB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email' },
    ],
  },
  {
    planType: 'Pro',
    price: 397,
    discount: 100,
    code: 'FIRST100',
    details: [
      { main: '1000GB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email' },
      { main: 'Content licensing' },
    ],
  },
  {
    planType: 'Pro +',
    price: 497,
    discount: 100,
    code: 'FIRST100',
    details: [
      { main: '10TB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email + more' },
      { main: 'Content licensing' },
      { main: 'Multiple brands', extra: '2 user seats + $17/mo for ea. additional brand' },
    ],
  },
];
