import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const LandingPricingQuestionsWrp = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 88px;
  margin-bottom: 7px;
`;

export const LandingPricingQuestionsTitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.colorBlack};
`;
