import React, { useMemo, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { GalleryFile, GalleryIdea } from '../../../shared/types';
import { GallerySwiperButtonsAndDescription } from './GallerySwiperDescriptionAndBtns/GallerySwiperButtonsAndDescription';
import { GallerySwiperContainerWrp, GallerySwiperWrapper } from './styled';
import { GallerySwiperCarousel } from './GallerySwiperCarousel/GallerySwiperCarousel';
import { GallerySwiperArrowBtn } from './GallerySwiperCarousel/GallerySwiperArrowBtn';

type GallerySwiperContainerProps = {
  files: (GalleryFile | GalleryIdea)[];
  initialSlideIndex: number;
};

export const GallerySwiperContainer: React.FC<GallerySwiperContainerProps> = ({
  files,
  initialSlideIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialSlideIndex);
  const currentFile = useMemo(() => files[currentIndex], [currentIndex]);

  return (
    <>
      <GallerySwiperContainerWrp>
        <GallerySwiperCarousel
          handleChange={(index) => setCurrentIndex(index)}
          files={files}
          initialSlideIndex={initialSlideIndex}
          currentIndex={currentIndex}
        />
      </GallerySwiperContainerWrp>
      <GallerySwiperButtonsAndDescription currentFile={currentFile} />
    </>
  );
};
