import { Grid } from '@mui/material';
import React from 'react';
import {
  ContentDescriptionContainer,
  DescriptionText,
  DescriptionTitle,
} from './styled';
import { CommonPriceInputReadOnly } from '../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { TextTotalBlackLarge } from '../../../../shared/styles';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { CustomFile } from '../../../../shared/types';
import { GallerySwiperIdeaFiles } from './GallerySwiperIdeaFiles';
import { IdeaTooltip } from '../IdeaBigCard/IdeaTooltip';

type GallerySwiperContentDescriptionProps = {
  name: string;
  price: number;
  description: string;
  ideaFiles?: CustomFile[];
  isIdea?: boolean;
};

export const GallerySwiperContentDescription: React.FC<
  GallerySwiperContentDescriptionProps
> = ({
  name, price, description, ideaFiles, isIdea,
}) => {
  const { screenSize } = useCheckWindowSize();

  if (screenSize === 'mobile') {
    return (
      <>
        <DescriptionTitle>{name}</DescriptionTitle>
        {isIdea && <IdeaTooltip />}
        <DescriptionText>{description}</DescriptionText>
        {ideaFiles && <GallerySwiperIdeaFiles ideaFiles={ideaFiles} />}
        <TextTotalBlackLarge fontWeight={500}>
          <CommonPriceInputReadOnly value={price} textAlign="left" />
        </TextTotalBlackLarge>
      </>
    );
  }

  return (
    <ContentDescriptionContainer>
      <Grid container>
        <Grid item sm={3}>
          <TextTotalBlackLarge>{name}</TextTotalBlackLarge>
          <br />
          <TextTotalBlackLarge>
            <CommonPriceInputReadOnly value={price} textAlign="left" />
          </TextTotalBlackLarge>
        </Grid>
        <Grid item sm={9}>
          <TextTotalBlackLarge>{description}</TextTotalBlackLarge>
        </Grid>
      </Grid>
    </ContentDescriptionContainer>
  );
};
