import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../../../shared/components/GlobalContainer/styled';

export const NavigationWrapper = styled.div`
  position: sticky;
  top: 100px;
  box-sizing: border-box;
  margin-right: 40px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const NavigationTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${variables.colorBlack};
  margin-bottom: 3px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const SectionNavigationList = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  & > a,
  & > div,
  & > button {
    margin-top: 13px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const SectionListWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 120px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
`;

export const DocumentsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -30px;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-right: -8px;
  }
`;

export const FileOrCoverWrapper = styled.div<{fullWidth: boolean, areButtonsVisible: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;

  & > div > button {
    display: none;
    transition: 0.2s;
  }

  ${({ areButtonsVisible }) => (areButtonsVisible ? `
    & #cover-gradient {
      opacity: 1;
    }
  `
    : '')}

  &:hover {
    & > div > button {
      display: flex;
      transition: 0.2s;
    }
    & > #cover-gradient {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }

  & video {
    width: 100%;
    height: auto;
  }
`;

export const SectionsListWrapper = styled.div`
  margin-bottom: 18px;
`;

export const GalleryContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 80px;
  }
`;
