import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryData } from '../../../shared/types';
import { GalleryFooter } from './GalleryFooter/GalleryFooter';
import { Navigation } from './Navigation';
import { SectionsList } from './SectionsList';
import { GalleryContentWrapper } from './styled';

type GalleryContentProps = {
  gallery: GalleryData;
}

export const GalleryContent: React.FC<GalleryContentProps> = ({ gallery }) => {
  const sections = gallery?.sections || [];
  const removeBranding = !!gallery?.removeBranding;

  return (
    <GalleryContentWrapper>
      <Grid container spacing={0} id="galleryContent">
        <Grid item sm={2} xs={12}>
          <Navigation sections={sections} />
        </Grid>
        <Grid item sm={10} xs={12}>
          <SectionsList sections={sections} gallery={gallery} />
        </Grid>
        <GalleryFooter removeBranding={removeBranding} />
      </Grid>
    </GalleryContentWrapper>
  );
};
