import styled from '@emotion/styled';
import { PrimaryWhiteButton } from '../../../../shared/styles';

export const ContentActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
`;

export const PrimaryWhiteButtonMgR = styled(PrimaryWhiteButton)`
  margin-right: 60px;
`;

export const GallerySwiperBtnDownWrpMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;

  & button {
    &:first-of-type {
      margin-right: 7px;
    }
  }
`;
