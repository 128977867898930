import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { TextBlackLarge } from '../../../../../shared/styles';
import { CartSection } from '../../../../../shared/types/client';
import { CartSummaryFilesNumberPrice, CartSummarySectionWrapper, TextBlackLargeLimit } from './styled';

type CartSummarySectionProps = {
  section: CartSection;
};

export const CartSummarySection: React.FC<CartSummarySectionProps> = ({
  section,
}) => {
  const { t } = useTranslation('sales');
  const {
    sectionId, title, filesNumber, totalSum,
  } = section;

  return (
    <CartSummarySectionWrapper key={sectionId}>
      <TextBlackLargeLimit>
        <p>{title}</p>
      </TextBlackLargeLimit>
      <CartSummaryFilesNumberPrice>
        <TextBlackLarge style={{ whiteSpace: 'pre' }}>
          <Trans t={t} i18nKey="files">
            {{ files: filesNumber }}
            files
          </Trans>
        </TextBlackLarge>
        <TextBlackLarge>
          <CommonPriceInputReadOnly value={totalSum || 0} />
        </TextBlackLarge>
      </CartSummaryFilesNumberPrice>
    </CartSummarySectionWrapper>
  );
};
