/* eslint-disable implicit-arrow-linebreak */
import { CustomFile, GalleryFile, GalleryIdea } from '../types';

export const isArrayIncludeValue = <A>(arr: A[], value: A): boolean =>
  (arr ? arr.includes(value) : false);

export const isVideo = (file: CustomFile | File | null): boolean => (file ? file.type?.includes('video') : false);

export const isImage = (file: CustomFile | File | null): boolean => (file ? file?.type?.includes('image') : false);

export const isDocument = (file: CustomFile | File | null): boolean =>
  (file ? !isImage(file) && !isVideo(file) : false);

export const isIdea = (file: GalleryFile | GalleryIdea): boolean =>
  (file ? (!!(file as GalleryIdea).cover) : false);

export const getURLByFile = (file: File): string => URL.createObjectURL(file);

export const isFirstItemInArr = (index: number): boolean => index === 0;

export const isLastItemInArr = <T>(arr: T[], index: number): boolean => index === arr.length - 1;

export const isArrayEmpty = <A>(arr?: A[]): boolean => (arr ? arr?.length <= 0 : true);

export const getIdeaCoverOrFile = (fileOrIdea: GalleryFile | GalleryIdea): GalleryFile => {
  if (isIdea(fileOrIdea)) {
    return (fileOrIdea as GalleryIdea).cover!;
  }
  return fileOrIdea as GalleryFile;
};
