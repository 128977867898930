import React, { SyntheticEvent, useRef, useState } from 'react';
import { isFileAlignedByHeight } from '../../../utils/gallery/isFileAlignedByHeight';
import { ImageElement, ImageWrapper } from './styled';

type ImageContainerProps = {
  imageUrl: string;
  widthStretch?: boolean;
};

export const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrl,
  widthStretch,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAlignedByHeight, setIsAlignedByHeight] = useState(false);

  const handleOnLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const containerWidth = containerRef.current?.clientWidth || 1;
    const containerHeight = containerRef.current?.clientHeight || 0;
    setIsAlignedByHeight(
      isFileAlignedByHeight(
        containerWidth,
        containerHeight,
        e.currentTarget?.width,
        e.currentTarget?.height,
      ),
    );
  };

  return (
    <ImageWrapper ref={containerRef} widthStretch={!!widthStretch} hasImage={!!imageUrl}>
      {imageUrl && (
        <ImageElement
          isWidthBigger={isAlignedByHeight}
          onLoad={handleOnLoad}
          src={imageUrl}
          alt={imageUrl}
          loading="lazy"
        />
      )}
    </ImageWrapper>
  );
};
