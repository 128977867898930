import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getLandingSwiperIndicator } from './LandingSwiperIndicator/getLandingSwiperindecator';
import {
  SwiperBaseSlide,
  SwiperBaseSlideElementWrp,
  SwiperBaseSlideTitle,
  SwiperBaseSecondTitle,
  LandingSwiperBaseWrp,
} from './styled';
import { LandingSwiperSlide } from './types';

type LandingSwiperBaseProps = {
  slides: LandingSwiperSlide[];
  autoPlay?: boolean;
}

export const LandingSwiperBase: React.FC<LandingSwiperBaseProps> = ({
  slides, autoPlay,
}) => (
  <LandingSwiperBaseWrp>
    <Carousel
      showIndicators
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop
      axis="horizontal"
      emulateTouch
      autoPlay={autoPlay}
      renderIndicator={getLandingSwiperIndicator}
    >
      {slides.map((item) => (
        <SwiperBaseSlide key={item.title}>
          <SwiperBaseSlideTitle>{item.title}</SwiperBaseSlideTitle>
          <SwiperBaseSecondTitle>{item.secondTitle}</SwiperBaseSecondTitle>
          <SwiperBaseSlideElementWrp>
            {item.element}
          </SwiperBaseSlideElementWrp>
        </SwiperBaseSlide>
      ))}
    </Carousel>
  </LandingSwiperBaseWrp>
);
