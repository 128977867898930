import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const Header = styled.div<{isClient?: boolean, isCreator?: boolean}>`
  padding-top: 20px;
  margin-bottom: 50px;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isClient, isCreator }) => {
    if (isClient) return 'width: 118px; height: 40px;';
    if (isCreator) return 'width: 100px; height: 30px;';
    return 'width: 165px; height: 50px;';
  }}

  & > svg {
    ${({ isClient, isCreator }) => {
    if (isClient) return 'width: 118px; height: 40px;';
    if (isCreator) return 'width: 100px; height: 30px;';
    return 'width: 165px; height: 50px;';
  }}
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 150px;
    
    & > svg {
      width: 150px;
    }
  }
`;
