import { Grid } from '@material-ui/core';
import React from 'react';
import { useGalleryContent } from '../../../../../../../GalleryView/hooks/useGalleryContent';
import { GalleryCover } from '../../../../../../../../shared/types';
import { ModalWindow } from '../ModalWindow';
import { PreviewCardFirst, PreviewWrapper } from '../styled';
import { SelectedPreview } from '../types';
import {
  PreviewFirstContentWrapper,
  PreviewFirstHeaderWrapper,
  PreviewFirstImageContainer,
  PreviewFirstMediumText,
  PreviewFirstRowWrapper,
  PreviewFirstTitle,
} from './styled';
import { GalleryCoverFirst } from '../../../../../../../GalleryView/GalleryCovers/GalleryFirst/GalleryCoverFirst';
import { GalleryDemoFirstMobile } from '../../../../../../../GalleryView/GalleryCovers/GalleryFirst/GalleryDemoFirstMobile';
import { GalleryDemoFirstTablet } from '../../../../../../../GalleryView/GalleryCovers/GalleryFirst/GalleryDemoFirstTablet';
import { MobileWrapper } from '../../../../../../../GalleryView/MobileWrapper';
import { MobileWrapperFirst } from '../../../../../../../GalleryView/GalleryCovers/GalleryFirst/styled';

export const PreviewFirst: React.FC<GalleryCover & SelectedPreview> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCardFirst theme={selected ? 'selected' : ''} id="galleryCoverDesignFirstStep">
        <PreviewFirstContentWrapper>
          <PreviewFirstHeaderWrapper>
            <PreviewFirstTitle>{data.name}</PreviewFirstTitle>
            <PreviewFirstMediumText>{data.userName}</PreviewFirstMediumText>
          </PreviewFirstHeaderWrapper>
          <PreviewFirstRowWrapper>
            <PreviewFirstMediumText>{data.message}</PreviewFirstMediumText>
            <PreviewFirstImageContainer>
              {data.file}
              {data.date}
            </PreviewFirstImageContainer>
          </PreviewFirstRowWrapper>
        </PreviewFirstContentWrapper>
      </PreviewCardFirst>
      <ModalWindow>
        <>
          <GalleryCoverFirst
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
          />
          <Grid container item sm={10} justifyContent="space-between">
            <GalleryDemoFirstTablet
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
            <MobileWrapper>
              <MobileWrapperFirst>
                <GalleryDemoFirstMobile
                  name={name}
                  date={date}
                  file={file}
                  userName={userName}
                  message={message}
                  withCropper
                />
              </MobileWrapperFirst>
            </MobileWrapper>
          </Grid>
        </>
      </ModalWindow>
    </PreviewWrapper>
  );
};
