import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ModalWindowContainer } from '../../../shared/components/ModalWindow/ModalWindowContainer';
import { useTypedSelectorCommon } from '../../../shared/hooks/useTypedSelector';
import { useGetCommentWindowStyle } from '../hooks/useGetCommentWindowStyle';
import { fetchGetComments } from '../redux/comments/commentsThunk';
import { CommentContainer } from './CommentContainer';

type CommentAreaWindowProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const CommentAreaWindow: React.FC<CommentAreaWindowProps> = ({
  isOpen,
  handleClose,
}) => {
  const { styles } = useGetCommentWindowStyle();
  const comments = useTypedSelectorCommon((state) => state.comments.comments);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetComments());
    const modalWindow = document.getElementById('simple-window');
    modalWindow?.scrollTo({
      top: modalWindow?.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <ModalWindowContainer
      size="full"
      isOpen={isOpen}
      handleClose={handleClose}
      style={styles}
    >
      <CommentContainer handleClose={handleClose} comments={comments} />
    </ModalWindowContainer>
  );
};
