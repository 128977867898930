import React from 'react';
import { GalleryDataForm, GalleryStatus } from '../../../../../../../shared/types';
import { useUpdateGalleryMutation } from '../../../../../redux/gallery/galleryAPI';
import { getGalleryDataForRequest } from '../../../../../utils/getGalleryDataForRequest';

type ReturnObj = {
  handleUpdateGallery: (data: GalleryDataForm, status: GalleryStatus) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export const useDispatchUpdateGallery = (): ReturnObj => {
  const [updateGallery, { isLoading, isSuccess }] = useUpdateGalleryMutation();

  const handleUpdateGallery = (data: GalleryDataForm, status: GalleryStatus) => {
    const dataForRequest = getGalleryDataForRequest(data, status);
    updateGallery({ id: dataForRequest.id, gallery: dataForRequest });
  };

  return ({
    handleUpdateGallery,
    isLoading,
    isSuccess,
  });
};
