import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as urls from '../../../../../../../../constants/urls';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import { CloseIcon, EditIcon, DeleteIcon } from '../../../../../../../../shared/icons';
import { TitleBlack } from '../../../../../../../../shared/styles';
import {
  DashboardHeaderWrapper, CloseButton, DashboardHeaderButtons, DashboardHeaderButton,
} from './styled';
import { CopyIconButton } from '../../../../../../../../shared/components/CopyIconButton/CopyIconButton';
import { SimpleTooltip } from '../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import { useDeleteGalleryMutation } from '../../../../../../redux/gallery/galleryAPI';

type DashboardHeaderProps = {
  title: string;
  idGallery: string;
  handleCloseDashboard: () => void;
  galleryLink: string;
};

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  idGallery,
  handleCloseDashboard,
  galleryLink,
}) => {
  const { t } = useTranslation('gallery');
  const history = useHistory();
  const [deleteGallery] = useDeleteGalleryMutation();

  const handleDelete = (id: string) => {
    deleteGallery({ id });
  };

  const { setIsOpen: setIsOpenDialogWindow, dialogWindow } = useDialog<string>(
    idGallery,
    handleDelete,
    t('deleteGallery.question'),
    t('deleteGallery.title'),
  );

  const handleEdit = () => {
    history.push(`${urls.newGalleryUrl}/${idGallery}`);
  };

  return (
    <>
      <DashboardHeaderWrapper>
        <CloseButton type="button" onClick={handleCloseDashboard}>
          <CloseIcon />
        </CloseButton>
        <TitleBlack>{title}</TitleBlack>
        <DashboardHeaderButtons>
          <CopyIconButton value={galleryLink} />
          <DashboardHeaderButton type="button" onClick={handleEdit} disabled>
            <EditIcon />
            <SimpleTooltip relativeWidth={24} text={t('edit')} />
          </DashboardHeaderButton>
          <DashboardHeaderButton type="button" onClick={() => setIsOpenDialogWindow(true)} disabled>
            <DeleteIcon />
            <SimpleTooltip relativeWidth={24} text={t('galleryDelete')} />
          </DashboardHeaderButton>
        </DashboardHeaderButtons>
      </DashboardHeaderWrapper>
      {dialogWindow}
    </>
  );
};
