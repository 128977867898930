import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';

export const TabLabel = styled.div`
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  flex-direction: row;

  & svg {
    color: inherit;
    margin-right: 12px;
  }
`;

export const TabWrapper = styled.div`
  margin-right: 30px;
  position: relative;

  & div {
    width: 100%;
    margin-right: 0px;
  }
`;

export const ExtraTextWrapper = styled.div`
  position: absolute;
  top: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${variables.colorPink};
  z-index: 10;
  left: 20%;
`;
