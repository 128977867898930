import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TooltipRenderProps } from 'react-joyride';
import * as urls from '../../../../../../constants/urls';
import { CustomTooltipElement } from '../../../../../../shared/components/CustomJoyride/CustomTooltipElement';

const newId = uuidv4();

export const CreatorCustomTooltip: React.FC<TooltipRenderProps> = (props) => {
  const history = useHistory();
  const { index, step, primaryProps } = props;

  const handleClickNext = (e: MouseEvent<HTMLElement>) => {
    if (index === 4) {
      history.push(`${urls.newGalleryUrl}/${newId}`);
    }
    if ((step.target as string).includes('supportSidebar')) {
      history.push(`${urls.supportUrl}`);
    }
    primaryProps.onClick(e);
  };

  return (
    <CustomTooltipElement
      {...props}
      handleClickNext={handleClickNext}
    />
  );
};
