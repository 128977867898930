import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  LittleGreyText, SimpleBlackText, SimplePinkText, TextBlackLarge,
} from '../../../../../../../shared/styles';
import { HrLine } from '../../../../../../../shared/styles/Elements';
import { SalesCardWrapper, SalesWrapper } from './styled';

type SalesCardProps = {
  sales: number;
  salesPerWeek: number;
};

export const SalesCard: React.FC<SalesCardProps> = ({ sales, salesPerWeek }) => {
  const { t } = useTranslation('sales');

  const currentSales = sales || 0;
  const currentSalesPerWeek = salesPerWeek || 0;

  return (
    <SalesCardWrapper>
      <SalesWrapper>
        <SimpleBlackText>{t('sales')}</SimpleBlackText>
        <TextBlackLarge fontWeight={600}>
          <Trans t={t} i18nKey="salesSum">
            {{ sales: currentSales }}
          </Trans>
        </TextBlackLarge>
      </SalesWrapper>
      <HrLine />
      <Trans t={t} i18nKey="perWeek">
        <SimplePinkText fontWeight={500}>
          +
          {' '}
          {{ salesPerWeek: currentSalesPerWeek }}
        </SimplePinkText>
        {' '}
        <LittleGreyText>this week</LittleGreyText>
      </Trans>
    </SalesCardWrapper>
  );
};
