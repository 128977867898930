import React, {
  ChangeEvent, useMemo,
} from 'react';
import { CopyIcon } from '../../icons';
import { TextLabel } from '../FormElements/TextLabel';
import {
  TextCopied,
} from '../styled';
import {
  CommonCopyInputWrapper,
  CopyInput,
  CopyInputAndButtonWrapper,
  CustomRoundButton,
} from './styled';
import { useCopyValue } from './useCopyValue';

type CommonInputProps = {
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | any;
  name: string;
  label?: string;
  readonly?: boolean;
  direction?: 'row' | 'column';
  disabled?: boolean;
};

export const CommonCopyInput: React.FC<CommonInputProps> = ({
  value,
  name,
  label,
  readonly,
  direction,
  disabled = true,
  handleChange,
}) => {
  const { isCopied, handleCopy, wasCopiedText } = useCopyValue();

  const width = useMemo(() => ((value || '').length * 8), [value]);

  return (
    <CommonCopyInputWrapper
      direction={direction || 'column'}
    >
      <TextLabel name={name} label={label} />
      <CopyInputAndButtonWrapper>
        <CopyInput
          width={width}
          onChange={handleChange}
          readOnly={readonly}
          id={name}
          name={name}
          value={value}
          disabled={disabled}
        />
        <CustomRoundButton
          type="button"
          onClick={handleCopy(value)}
          disabled={!value}
        >
          <CopyIcon />
        </CustomRoundButton>
        <TextCopied isVisible={isCopied}>
          {wasCopiedText}
        </TextCopied>
      </CopyInputAndButtonWrapper>
    </CommonCopyInputWrapper>
  );
};
