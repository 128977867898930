import React, { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { PaymentPage } from './PaymentPage';
import {
  ClientPaymentOrder,
  ClientPaymentState,
} from '../../../../../../shared/types/payment';
import { CustomAlert } from '../../../../../../shared/components/CustomAlert/CustomAlert';
import { PaymentFilesList } from './PaymentFilesList';
import { PaymentGrid } from './styled';
import { useGetClientSecretKey } from '../../../../hooks/useGetClientSecretKey';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { GalleryFile, GalleryIdea } from '../../../../../../shared/types';

const stripePromise = loadStripe(
  'pk_test_51KdQRcCL5WYl6siZaQ8LUMHCvEpXCIF8YCYr1xDJSQ8QZPNQOMBrjJDuifm6DK7BcpRKvL1ikFaspzpk2HN5znIg00lx4oudQS',
);

const appearance: Appearance = {
  theme: 'stripe',
};

const getOrderData = (
  amount: number,
  files: (GalleryFile | GalleryIdea)[],
  galleryId: string,
  email: string,
): ClientPaymentOrder => {
  const orderedFiles = files.map((item) => ({
    id: item.id,
    price: +(item?.price || 0) * 100,
  }));

  const orderId = uuidv4();
  window.localStorage.setItem('orderId', orderId);

  return {
    id: orderId,
    amount: amount * 100,
    files: orderedFiles,
    galleryId,
    email,
  };
};

export const PaymentPageContainer: React.FC = () => {
  const {
    isLoading, error, setError, clientSecretKey, getClientSecretKey: getCLientSecretKey,
  } = useGetClientSecretKey();

  const location = useLocation<ClientPaymentState>();
  const {
    files, amount, email, galleryId,
  } = location.state;

  const orderData: ClientPaymentOrder = useMemo(
    () => getOrderData(amount, files, galleryId, email),
    [amount, files, galleryId, email],
  );

  useEffect(() => {
    getCLientSecretKey(orderData);
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: clientSecretKey,
    appearance,
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <PaymentFilesList files={files} amount={amount} />
        <PaymentGrid item sm={6}>
          {isLoading && <PinkLoader />}
          {clientSecretKey && (
            <Elements options={options} stripe={stripePromise}>
              <PaymentPage />
            </Elements>
          )}
        </PaymentGrid>
      </Grid>
      <CustomAlert
        isOpenWindow={!!error}
        message={error}
        title="Payment finished with errors"
        setIsOpen={() => setError('')}
        handleClick={() => setError('')}
        error
      />
    </>
  );
};
