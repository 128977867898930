import { Creator, PaymentSettings } from '../../shared/types';

export const creatorInitialValues = (data: Creator | null) => ({
  firstName: data?.firstName || '',
  lastName: data?.lastName || '',
  businessName: data?.businessName || '',
  email: '',
  password: '',
  confirmPassword: '',
  timezone: '(GMT-8:00) America, Los Angeles',
  dateFormat: 'mm/dd/yyyy',
  facebook: '',
  instagram: '',
  youtube: '',
  vimeo: '',
  linkedin: '',
  pinterest: '',
});

export const emailNotificationsAndBrandingValues = {
  downloadCart: false,
  downloadSingleDelivrable: false,
  createNewFavouritesList: false,
  leaveComment: false,
  makePurchase: false,
  purchaseIdea: false,
  reminderIdea: false,
  emailInvite: false,
  accountStorageIsFull: false,
  expireAlert: false,
  newFeature: false,
  monthlySales: false,
  receiveReferralReward: false,
  removeBranding: false,
};

export const initData: PaymentSettings = {
  storeCurrency: '$ US dollar',
  collectedBy: 'Delivrable',
  paypalEmail: '',
};

export const paymentSetupInitialValues = (data: PaymentSettings | null): PaymentSettings => ({
  storeCurrency: data?.storeCurrency || initData.storeCurrency,
  collectedBy: data?.collectedBy || initData.collectedBy,
  paypalEmail: data?.paypalEmail || initData.paypalEmail,
});
