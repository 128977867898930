import styled from '@emotion/styled';
import { Fade } from '@material-ui/core';
import * as variables from '../../../constants/styles';

export const ModalWindowMedium = styled.div`
  max-width: 674px !important;
  min-height: 612px !important;
  overflow: auto;
  padding: 35px 12px 35px 50px;
  position: relative;
  border-radius: 18px;
  outline: none;
`;

export const FadeMedium = styled(Fade)`
  max-width: 674px !important;
  max-height: 612px !important;
  background: ${variables.colorGreyLight};
  position: relative;
  outline: none;
`;

export const FadeFull = styled(Fade)`
  width: 100%;
  height: 100%;
  min-height: 100hv;
  background: ${variables.colorGreyLight};
  outline: none;
`;

export const ModalWindowFull = styled.div`
  width: 100%;
  min-height: 100hv;
  overflow: auto;
  position: relative;
  outline: none;
`;

export const FadeLarge = styled(Fade)`
  width: 60%;
  min-height: 636px !important;
  background: ${variables.colorGreyLight};
  box-sizing: border-box;
  outline: none;
`;

export const ModalWindowLarge = styled.div`
  width: 60%;
  overflow: auto;
  max-height: 636px !important;
  position: relative;
  padding: 60px 40px 0 50px;
  border-radius: 18px;
  box-sizing: border-box;
  outline: none;
`;

export const SimpleFade = styled(Fade)`
  width: object-fit;
  height: object-fit;
  outline: none;
`;

export const SimpleModalWindow = styled.div`
  width: object-fit;
  height: object-fit;
  overflow: auto;
  position: relative;
  border-radius: 18px;
  box-sizing: border-box;
  outline: none;
`;
