import * as Yup from 'yup';
import { errorMessages } from '../../utils/errorsMessages';
import { passwordMinLength, password } from '../../../constants/validationValues';

export const confirmPassword = Yup.object().shape({
  currentPassword: Yup.string()
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength)
    .matches(password, errorMessages.passwordCharacters),
});

export const changePassword = Yup.object().shape({
  password: Yup.string()
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength)
    .matches(password, errorMessages.passwordCharacters),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    errorMessages.passwordMustMatch,
  ).required(errorMessages.requiredField),
});

export const paymentSetup = Yup.object().shape({
  paypalEmail: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
  collectedBy: Yup.mixed()
    .nullable(false)
    .required(errorMessages.requiredField),
  storeCurrency: Yup.mixed()
    .nullable(false)
    .required(errorMessages.requiredField),
});

export const watermarkSchema = Yup.object().shape({
  watermark: Yup.string(),
  file: Yup.string().when(
    'watermark',
    {
      is: !'',
      then: Yup.string()
        .required(errorMessages.requiredField),
    },
  ),
  text: Yup.string().when(
    'watermark',
    {
      is: !'',
      then: Yup.string()
        .required(errorMessages.requiredField),
    },
  ),
});
