import React, { ChangeEvent, useMemo } from 'react';
import { MiniFileCardWrapper } from './styled';
import { CommonCheckbox } from '../FormElements/CommonCheckbox/CommonCheckbox';
import { CustomPositionCheckbox } from '../styled';
import { getFileInContainer } from '../../utils/gallery/getFileInContainer';
import { CustomFile } from '../../types';

type MiniFileCardProps = {
  file: CustomFile | null;
  handleCheck: (e: ChangeEvent<HTMLInputElement>, file: CustomFile) => void;
  isChecked: boolean;
  large?: boolean;
  id?: string;
  isSettingsApplied?: boolean;
  mobileStyle?: string;
};

export const MiniFileCard: React.FC<MiniFileCardProps> = ({
  file,
  handleCheck,
  isChecked,
  large,
  id,
  isSettingsApplied,
  mobileStyle,
}) => {
  const content = useMemo(() => getFileInContainer(file, {}, true), [file]);

  return (
    <MiniFileCardWrapper
      hasBorders={isSettingsApplied}
      large={large}
      mobileStyle={mobileStyle}
    >
      {file && (
        <>
          <CustomPositionCheckbox>
            <CommonCheckbox
              id={id}
              isChecked={isChecked}
              handleChange={(e) => handleCheck(e, file || file)}
              simple
              name=""
              positioned
            />
          </CustomPositionCheckbox>
          {content}
        </>
      )}
    </MiniFileCardWrapper>
  );
};
