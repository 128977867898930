import React, { ReactElement, useCallback } from 'react';
import { CustomMenuItem } from '../components/FormElements/CommonSelect/styled';
import { SelectorMenuItem } from '../types';

export const useGetSelectionItems = <ValueType, >(): ((
  data: SelectorMenuItem<ValueType>[]
) => ReactElement[]) => {
  const getSelectionItems = useCallback(
    (
      data: {
        id: string;
        value: ValueType;
      }[],
    ) => data.map((item) => (
      <CustomMenuItem key={item.id} value={item.id as any}>
        {item.value || ''}
      </CustomMenuItem>
    )),
    [],
  );
  return getSelectionItems;
};
