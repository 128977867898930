import React from 'react';
import { useTranslation } from 'react-i18next';
import { betaPlans } from '../../../../../constants';
import { LandingPricingPlanBlock } from './LandingPricingPlanBlock';
import { LandingContentContainer } from '../../LandingContentContainer';
import { LandingPricingPlansWrapper } from './styled';

export const LandingPricingPlans: React.FC = () => {
  const { t } = useTranslation('landing');

  return (
    <LandingContentContainer id="pricing-plans">
      <LandingPricingPlansWrapper>
        {betaPlans.map((item) => (
          <LandingPricingPlanBlock
            planDetails={item.details}
            price={item.price}
            code={item.code}
            discount={item.discount}
            extraText={t('pricing.plans.payOnce')}
            planType={item.planType}
          />
        ))}
      </LandingPricingPlansWrapper>
    </LandingContentContainer>
  );
};
