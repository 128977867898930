import React, { ReactNode } from 'react';
import { Container, Grid } from '@material-ui/core';

type GalleryGridContainerProps = {
  children: ReactNode;
}

export const GalleryGridContainer: React.FC<GalleryGridContainerProps> = ({ children }) => (
  <Container maxWidth="xl">
    <Grid
      container
      item
      sm={12}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {children}
    </Grid>
  </Container>
);
