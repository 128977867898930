import { AxiosResponse } from 'axios';
import { ClientSecretKey, ClientPaymentOrder } from '../../shared/types/payment';
import { clientInstance } from '../base/instance';

const paymentUrl = 'order/';

export const payment = {
  paymentOrderClient: (data: ClientPaymentOrder): Promise<AxiosResponse<ClientSecretKey>> => clientInstance.post(`${paymentUrl}`, data),
  downloadFilesClient: (orderId: string): Promise<AxiosResponse> => clientInstance.get(`${paymentUrl}download/${orderId}`),
};
