import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as urls from '../../../../../../../constants/urls';
import {
  GradientColorText,
  MediumBlackText,
  PrimaryWhiteButton,
  SimpleBlackText,
  TextPinkLarge,
} from '../../../../../../../shared/styles';
import {
  RowsWrapper, SettingsWrapper, TextMgB, TitleMgB,
} from '../styled';
import { ButtonMgT } from './styled';
import { WhiteButtonFitContent } from '../../Support/styled';
import { getFullName } from '../../../../../../../shared/utils';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';

export const Subscription: React.FC = () => {
  const { t } = useTranslation('settings');
  const history = useHistory();
  const days = 7;

  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik<{nameOnInvoice: string, taxNumber: string}>({
    // validationSchema: clientSchema,
    initialValues: {
      nameOnInvoice: getFullName(currentUser?.firstName, currentUser?.lastName) || '',
      taxNumber: '',
    },
    onSubmit: (data) => onSubmit(data),
  });

  const handleUpdatePaymentMethod = () => {
    history.push(`${urls.settingsUrl}${urls.paymentMethodUrl}`);
  };

  const handleOpenChangePlanPage = () => {
    history.push(`${urls.settingsUrl}${urls.changePlanUrl}`);
  };

  return (
    <>
      <SettingsWrapper>
        <TitleMgB>
          <SimpleBlackText>{t('subscription')}</SimpleBlackText>
        </TitleMgB>
      </SettingsWrapper>
      <RowsWrapper>
        <Grid container item sm={7} style={{ height: 'fit-content' }}>
          <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>{t('yourCurrentPlan')}</MediumBlackText>
            </TextMgB>
          </Grid>
          <Grid item sm={10}>
            <TextMgB>
              <TextPinkLarge fontWeight={600}>{t('freeTrial')}</TextPinkLarge>
            </TextMgB>
          </Grid>
          <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>
                <Trans t={t} i18nKey="endsInDays">
                  Ends in
                  {' '}
                  <span style={{ fontWeight: 600 }}>
                    {{ days }}
                    {' '}
                    days
                  </span>
                </Trans>
              </MediumBlackText>
            </TextMgB>
          </Grid>
          <Grid item sm={10}>
            <WhiteButtonFitContent type="button" onClick={handleOpenChangePlanPage}>
              <GradientColorText fontWeight={600}>{t('changePlan')}</GradientColorText>
            </WhiteButtonFitContent>
          </Grid>
        </Grid>
        <Grid container item sm={5} style={{ marginTop: '-12px' }}>
          <Grid item sm={10}>
            <CommonInput
              label={t('nameOnInvoice')}
              value={values.nameOnInvoice}
              name="nameOnInvoice"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.nameOnInvoice}
              touched={touched?.nameOnInvoice}
              type="text"
              withoutMargins
            />
          </Grid>
          <Grid item sm={10}>
            <CommonInput
              withoutMargins
              label={t('taxNumber')}
              value={values.taxNumber}
              name="taxNumber"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              error={errors?.taxNumber}
              touched={touched?.taxNumber}
              type="text"
            />
          </Grid>
          <Grid item sm={10}>
            <ButtonMgT>
              <PrimaryWhiteButton type="button" onClick={handleUpdatePaymentMethod}>
                {t('updatePayment')}
              </PrimaryWhiteButton>
            </ButtonMgT>
          </Grid>
        </Grid>
      </RowsWrapper>

    </>
  );
};
