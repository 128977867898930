import React, { ReactNode } from 'react';
import { ImageSizes } from '../../../../constants/galleryPreviewsAndCovers';
import { ImageSize, CustomFile } from '../../../../shared/types';
import { getFileInContainer } from '../../../../shared/utils/createGallery';
import { GalleryFileCropper } from '../../GalleryFileCropper';

type ExtraFileCropperOrContainer = {
  size: ImageSize;
  withCropper: boolean;
  fileName: 'extraCover1' | 'extraCover2';
  file: CustomFile | null;
};

export const getExtraFileInContainerOrCropper = ({
  size,
  withCropper,
  fileName,
  file,
}: ExtraFileCropperOrContainer): ReactNode => (withCropper ? (
  <GalleryFileCropper
    height={ImageSizes.extraCover[size || 'desktop'].height}
    width={ImageSizes.extraCover[size || 'desktop'].width}
    size={size || 'desktop'}
    fileName={fileName}
  />
) : (
  getFileInContainer(file)
));
