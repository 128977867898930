import { v4 as uuidv4 } from 'uuid';
import gallery7 from '../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../assets/image/walkThroughCards/gallery8.jpg';
import gallery9 from '../../assets/image/walkThroughCards/gallery9.jpg';
import gallery10 from '../../assets/image/walkThroughCards/gallery10.jpg';
import gallery11 from '../../assets/image/walkThroughCards/gallery11.jpg';
import activeGallery from '../../assets/image/walkThroughCards/activeGallery.png';
import { Gallery, GalleryDataForm } from '../../shared/types';
import { fakeComments } from '../../api/gallery/comments/fakeComment';

export const fakeGallery: GalleryDataForm = {
  id: 'study',
  status: 'draft',
  removeBranding: false,
  hasWhitelistEmails: false,
  hasReminder: false,
  isAllRegisteredEmails: false,
  whenRemind: [],
  whoRecipients: [],
  whoFromWhitelisted: [],
  name: 'Your gallery name',
  galleryUrl: 'timbremediahouse.delivrable.com/yourgalleryname',
  step: 1,
  coverFile: {
    original: {
      file: {
        url: gallery7,
        type: 'image',
        name: 'gallery7',
        id: uuidv4(),
      },
    },
    mobile: { file: null },
    tablet: { file: null },
    desktop: { file: null },
  },
  date: new Date(),
  message:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  galleryDesign: 1,
  sections: [
    {
      id: uuidv4(),
      title: 'Section 1',
      files: [
        {
          id: uuidv4(),
          url: gallery7,
          type: 'image',
          name: 'gallery7',
          instantlyDownloadable: true,
          price: '30',
          description: 'Anna',
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          url: gallery8,
          type: 'image',
          name: 'gallery8',
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'image',
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          url: gallery10,
          type: 'image',
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          url: gallery11,
          type: 'image',
          name: 'gallery11',
        },
      ],
      ideas: [],
      hasFreeFiles: true,
      numberFreeFiles: 15,
    },
    {
      id: uuidv4(),
      title: 'Section 2',
      files: [
        {
          id: uuidv4(),
          url: gallery7,
          type: 'image',
          name: 'gallery7',
          instantlyDownloadable: true,
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          url: gallery11,
          type: 'image',
          name: 'gallery11',
        },
        {
          id: uuidv4(),
          url: gallery10,
          type: 'image',
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'image',
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          url: gallery8,
          type: 'image',
          name: 'gallery8',
        },
      ],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    },
  ],
  hasRegistrationEmail: false,
  registrationEmail: [],
  password: 'newcontent2021',
  sharing: true,
  assist: true,
  hasExpiryDate: false,
  expiryDate: null,
  autoAlert: false,
  branding: false,
  testimonialCall: false,
};

export const fakeGalleries: Gallery[] = [
  {
    id: '1d1d11-safe21',
    name: 'Gallery name 1',
    galleryUrl: 'businessName.delivrable.com/Galleryname1',
    likes: { numberLikes: 0, likes: [] },
    views: 20,
    shares: 10,
    sales: 350,
    salesPerWeek: 95,
    comments: [fakeComments[0]],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: activeGallery,
    wasWatched: true,
    status: 'active',
    password: 'passworD123',
    expiryDate: `${new Date(2023, 10, 1, 22, 12)}`,
  },
  {
    id: 'wewq1qe-wq564q',
    name: 'Gallery name 2',
    galleryUrl: 'businessName.delivrable.com/Galleryname2',
    likes: { numberLikes: 0, likes: [] },
    views: 2,
    shares: 1,
    sales: 35,
    salesPerWeek: 9,
    comments: [fakeComments[0]],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: gallery7,
    wasWatched: false,
    status: 'draft',
    password: 'passworD123',
    expiryDate: `${new Date(2021, 10, 1, 22, 12)}`,
  },
];
