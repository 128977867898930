import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CoverWindow } from './styled';
import { SimpleWhiteText } from '../../styles';
import * as urls from '../../../constants/urls';
import { TickIcon } from '../../icons';
import { NotificationWrapper } from '../SuccessNotification/styled';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';

type LoadingForRequest = {
  isLoading?: boolean;
  isSuccess?: boolean;
  text: string;
};

export const LoadingForRequest: React.FC<LoadingForRequest> = ({
  isLoading,
  isSuccess,
  text,
}) => {
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!isLoading && isSuccess) {
    setTimeout(() => {
      history.push(`${urls.creatorRootUrl}`);
    }, 570);
  }

  return (
    <CoverWindow>
      {isLoading && (<PinkLoader />)}
      {isSuccess && (
      <NotificationWrapper>
        <TickIcon isGradient={false} />
        <SimpleWhiteText>{text}</SimpleWhiteText>
      </NotificationWrapper>
      )}
    </CoverWindow>
  );
};
