import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileVersionWrapper } from './styled';

type MobileWrapperProps = { children: ReactElement; }

export const MobileWrapper: React.FC<MobileWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation('createNewGallery');
  return (
    <Grid container item sm={5} justifyContent="flex-end">
      <MobileVersionWrapper>
        <span>{t('mobile')}</span>
        <div>
          {children}
        </div>
      </MobileVersionWrapper>
    </Grid>
  );
};
