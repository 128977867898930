import React from 'react';
import { styled, Tab } from '@material-ui/core';
import { TabWrapper } from './styled';
import * as variables from '../../../../constants/styles';

export const BaseUnderlineTab = styled(
  (props: any) => (
    <TabWrapper>
      <Tab disableRipple {...props} />
    </TabWrapper>
  ),
)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: variables.mainFont,
  marginRight: '0px',
  marginLeft: '0px',
  width: 'fit-content',
  height: '110%',
  minWidth: '0px',
  fontSize: '16px',
  lineHeight: '140%',
  paddingLeft: '0px',
  paddingRight: '0px',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: variables.textGrey,
  whiteSpace: 'pre',
  background: variables.colorGreyLight,
  '&.MuiTab-wrapper': {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: 'fit-content',
    minWidth: '30px',
    whiteSpace: 'pre',
  },
  '&.Mui-selected': {
    color: variables.colorPink,
  },
  '&.Mui-focusVisible': {
    backgroundColor: variables.colorPink,
  },
  '&.MuiTabs-indicator': {
    backgroundColor: variables.colorPink,
  },
  indicator: {
    backgroundColor: variables.colorPink,
  },
}));
