import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRoundButton } from '../../../../../../../../shared/components/CommonCopyInput/styled';
import { useCopyValue } from '../../../../../../../../shared/components/CommonCopyInput/useCopyValue';
import { CommonInputElement } from '../../../../../../../../shared/components/FormElements/CommonInput/styled';
import { InputAndLabelWrapper } from '../../../../../../../../shared/components/FormElements/styled';
import { TextLabel } from '../../../../../../../../shared/components/FormElements/TextLabel';
import {
  CopyIcon,
  CrossedEyeIcon,
  EyeIcon,
} from '../../../../../../../../shared/icons';
import {
  CopyInputAndButtonWrapper,
  CopyPasswordButtonWrapper,
  CopyPasswordInputWrapper,
  CopyPasswordTextCopied,
  CopyPasswordWrapper,
  EyeButton,
} from './styled';

type CopyPasswordProps = {
  password: string;
};

export const CopyPassword: React.FC<CopyPasswordProps> = ({ password }) => {
  const { t } = useTranslation('gallery');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isCopied, handleCopy, wasCopiedText } = useCopyValue();

  const handleEyeClick = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <CopyPasswordWrapper>
      <InputAndLabelWrapper direction="column">
        <TextLabel name="password" label={t('galleryPassword')} />
        <CopyInputAndButtonWrapper>
          <CopyPasswordInputWrapper>
            <CommonInputElement
              hasErrors={false}
              readOnly
              id="password"
              name="password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              style={{ color: '#656565' }}
              disabled={!password}
            />
            <EyeButton type="button" onClick={handleEyeClick} disabled={!password}>
              {isPasswordVisible ? <EyeIcon /> : <CrossedEyeIcon />}
            </EyeButton>
          </CopyPasswordInputWrapper>
          <CopyPasswordButtonWrapper>
            <CopyPasswordTextCopied isVisible={isCopied}>{wasCopiedText}</CopyPasswordTextCopied>
            <CustomRoundButton
              type="button"
              onClick={handleCopy(password)}
              disabled={!password}
            >
              <CopyIcon />
            </CustomRoundButton>
          </CopyPasswordButtonWrapper>
        </CopyInputAndButtonWrapper>
      </InputAndLabelWrapper>
    </CopyPasswordWrapper>
  );
};
