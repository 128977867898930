import React, { MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as urls from '../../../../../constants/urls';
import {
  LogoutButton,
  SidebarLinkIcon,
  SidebarMenuWrapper,
  SidebarMenuItem,
} from './styled';
import {
  ActiveGalleriesIcon,
  DraftGalleriesIcon,
  LogoutIcon,
  PlusIcon,
  SalesIcon,
  SettingsIcon,
  SupportIcon,
} from '../../../../../shared/icons';
import { CreatorStateContext } from '../../../context/CreatorStateContext';
import { fetchLogOutCreator } from '../../../redux/userCreator/signIn/signInCreatorThunk';
import { useCreateGalleryByIdMutation } from '../../../redux/gallery/galleryAPI';

export const SidebarMenu: React.FC = () => {
  const { t } = useTranslation('sidebar');
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { setIsCreatorSignIn } = useContext(CreatorStateContext);
  const [createGalleryById] = useCreateGalleryByIdMutation();

  const handleClick = () => {
    dispatch(fetchLogOutCreator());
    setIsCreatorSignIn(false);
  };

  const handleAddNewGallery = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newId = uuidv4();
    createGalleryById({ id: newId });
    history.push(`${urls.newGalleryUrl}/${newId}`);
  };

  return (
    <>
      <SidebarMenuWrapper>
        <SidebarMenuItem isActive={pathname.includes(urls.newGalleryUrl)}>
          <button type="button" onClick={handleAddNewGallery} id="createNewGallerySidebar">
            <SidebarLinkIcon><PlusIcon /></SidebarLinkIcon>
            <span>{t('createNewGallery')}</span>
          </button>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.salesUrl} id="salesSidebar" style={{ width: 'fit-content' }}>
            <SidebarLinkIcon><SalesIcon /></SidebarLinkIcon>
            <span>{t('sales')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.activeGalleriesUrl} id="activeGalleriesSidebar" style={{ width: 'fit-content' }}>
            <SidebarLinkIcon><ActiveGalleriesIcon /></SidebarLinkIcon>
            <span>{t('activeGalleries')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.draftGalleriesUrl} id="draftGalleriesSidebar" style={{ width: 'fit-content' }}>
            <SidebarLinkIcon><DraftGalleriesIcon /></SidebarLinkIcon>
            <span>{t('draftGalleries')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.settingsUrl}>
            <SidebarLinkIcon><SettingsIcon /></SidebarLinkIcon>
            <span>{t('settings')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.supportUrl} id="supportSidebar" style={{ width: 'fit-content' }}>
            <SidebarLinkIcon><SupportIcon /></SidebarLinkIcon>
            <span>{t('support')}</span>
          </NavLink>
        </SidebarMenuItem>
      </SidebarMenuWrapper>
      <LogoutButton type="button" onClick={handleClick}>
        <LogoutIcon />
        <span>{t('logout')}</span>
      </LogoutButton>
    </>
  );
};
