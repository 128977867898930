import React from 'react';
import { useTranslation } from 'react-i18next';
import checkout from '../../../../../../assets/image/landing/swipers/features/checkout.png';
import dashboards from '../../../../../../assets/image/landing/swipers/features/dashboards.png';
import motivate from '../../../../../../assets/image/landing/swipers/features/motivate.png';
import privacy1 from '../../../../../../assets/image/landing/swipers/features/privacy1.png';
import privacy2 from '../../../../../../assets/image/landing/swipers/features/privacy2.png';
import mobileWalkthrough from '../../../../../../assets/image/landing/swipers/features/mobileWalkthrough.gif';
import { LandingSwiperSlide } from '../LandingSwiperBase/types';
import {
  FeaturesSwiperPrivacySlide,
  FeaturesWalkthroughSlide,
  PrivacySlideImgPositioned,
} from './styled';

type ReturnObj = {
  slides: LandingSwiperSlide[];
};

export const useGetFeaturesSlides = (): ReturnObj => {
  const { t } = useTranslation('landing');

  const slides = [
    {
      title: t('featuresSwiper.dashboardsTitle'),
      secondTitle: t('featuresSwiper.dashboardsText'),
      element: <img src={dashboards} alt="Dashboards" />,
    },
    {
      title: t('featuresSwiper.checkoutTitle'),
      secondTitle: t('featuresSwiper.checkoutText'),
      element: <img src={checkout} alt="Check-out" />,
    },
    {
      title: t('featuresSwiper.motivateTitle'),
      secondTitle: t('featuresSwiper.motivateText'),
      element: <img src={motivate} alt="Motivate sales and downloads" />,
    },
    {
      title: t('featuresSwiper.walkThroughTitle'),
      secondTitle: t('featuresSwiper.walkThroughText'),
      element: (
        <FeaturesWalkthroughSlide>
          <img src={mobileWalkthrough} alt="Walk-through cards" />
        </FeaturesWalkthroughSlide>
      ),
    },
    {
      title: t('featuresSwiper.privacyTitle'),
      secondTitle: t('featuresSwiper.privacyText'),
      element: (
        <FeaturesSwiperPrivacySlide>
          <img src={privacy1} alt="Privacy controls1" />
          <PrivacySlideImgPositioned src={privacy2} alt="Privacy controls2" />
        </FeaturesSwiperPrivacySlide>
      ),
    },
  ];

  return {
    slides,
  };
};
