import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTip } from '../../../../../../../shared/components/HelpTip/HelpTip';
import { CalendarIcon } from '../../../../../../../shared/icons';
import { MediumBlackText, PinkLink } from '../../../../../../../shared/styles';
import { getFormattedDateOrDateName } from '../../../../../../../shared/utils';
import { StatusAndDateWrapper } from './styled';
import * as urls from '../../../../../../../constants/urls';
import { GalleryStatus } from '../../../../../../../shared/types';

type StatusAndDateProps = {
  expireDate: string;
  galleryId: string;
  galleryStatus: GalleryStatus;
};

export const StatusAndDate: React.FC<StatusAndDateProps> = ({
  expireDate,
  galleryId,
  galleryStatus = 'inactive',
}) => {
  const { t } = useTranslation('gallery');
  const date = expireDate ? new Date(expireDate) : null;
  const isExpire = galleryStatus === 'inactive';
  const dateFormat = 'mm/dd/yyyy';
  const formattedDate = date ? getFormattedDateOrDateName(expireDate, dateFormat, true) : '';

  const dateElement = date ? `${t('until')} ${formattedDate}` : null;

  return (
    <StatusAndDateWrapper isExpire={isExpire}>
      <CalendarIcon />
      <MediumBlackText>
        {isExpire ? (
          t('expire')
        ) : (
          <>
            {t('active')}
            {' '}
            {dateElement}
          </>
        )}
      </MediumBlackText>
      {isExpire ? (
        <HelpTip
          text={(
            <PinkLink href={`${urls.newGalleryUrl}/${galleryId}`}>
              {t('setNewExpiryDate')}
            </PinkLink>
          )}
          withMgLeft
        />
      ) : (
        <HelpTip
          text={(
            <PinkLink href={`${urls.newGalleryUrl}/${galleryId}`}>
              {t('changeNewExpiryDate')}
            </PinkLink>
        )}
          withMgLeft
        />
      )}
    </StatusAndDateWrapper>
  );
};
