import React from 'react';
import { useTranslation } from 'react-i18next';
import { BulbIcon } from '../../../../shared/icons';
import { IdeaTooltipWrapper } from './styled';

export const IdeaTooltip: React.FC = () => {
  const { t } = useTranslation('gallery');

  return (
    <IdeaTooltipWrapper>
      <BulbIcon />
      <span>{t('ideas.tooltip')}</span>
    </IdeaTooltipWrapper>
  );
};
