import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { MainLogo } from '../../../../../../../../shared/icons/MainLogo';
import { useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import previewEmailImg from '../../../../../../../../assets/image/previewEmail.png';
import { PreviewEmailTextarea } from './PreviewEmailTextarea';
import { PreviewEmailFooter } from './PreviewEmailFooter';
import {
  PreviewEmailImage,
  PreviewEmailLogo,
  PreviewEmailPrimaryWhiteButton,
  PreviewEmailTitle,
  PreviewEmailWrapper,
  PreviewEmailWrapperContent,
} from './styled';
import { LittleBlackText } from '../../../../../../../../shared/styles';
import { emailPreviewSchema } from '../../../../../../../../shared/validation/gallery/emailReminder';

export const PreviewEmailContent: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  const businessName = useTypedSelectorCreator(
    (state) => state.creator.currentUser?.businessName,
  );

  const onSubmit = (
    data: { emailMessage: string },
    bag: FormikHelpers<{ emailMessage: string }>,
  ) => {
    bag.setSubmitting(false);
  };

  const {
    values, errors, handleChange,
  } = useFormik({
    initialValues: { emailMessage: t('fourthStep.reminder.previewEmail.defaultText') },
    validationSchema: emailPreviewSchema,
    onSubmit: (data: { emailMessage: string }, bag) => onSubmit(data, bag),
  });

  return (
    <PreviewEmailWrapper>
      <PreviewEmailWrapperContent>
        <PreviewEmailLogo>
          <MainLogo />
        </PreviewEmailLogo>
        <PreviewEmailTitle fontWeight={700}>
          <Trans
            t={t}
            i18nKey="fourthStep.reminder.previewEmail.yourGallery"
          >
            Your gallery from
            {' '}
            {{ businessName }}
            {' '}
            is about to expire
          </Trans>
        </PreviewEmailTitle>
        <LittleBlackText>
          {t('fourthStep.reminder.previewEmail.lastChance')}
        </LittleBlackText>
        <PreviewEmailImage>
          <img src={previewEmailImg} alt="Preview email" />
        </PreviewEmailImage>
        <PreviewEmailTextarea
          name="emailMessage"
          value={values.emailMessage}
          handleChange={handleChange}
          error={errors.emailMessage}
          placeholder="Message"
        />
        <PreviewEmailPrimaryWhiteButton type="button">
          {t('fourthStep.reminder.previewEmail.viewGalleryBtn')}
        </PreviewEmailPrimaryWhiteButton>
      </PreviewEmailWrapperContent>
      <PreviewEmailFooter />
    </PreviewEmailWrapper>
  );
};
