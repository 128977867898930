import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelectorClient } from '../../../shared/hooks/useTypedSelector';
import {
  GradientColorText,
  RoundButton,
  SimpleBlackText,
  TitleText,
} from '../../../shared/styles';
import {
  ShareButtonsWrapper,
  ShareCloseButtonPositioned,
  ShareCopyInputWrapper,
  ShareLinkButtonWrapper,
  ShareTitleWrapper,
  ShareWindowWrapper,
} from './styled';
import {
  CloseIcon,
  FacebookIcon,
  InstagramFillIcon,
  TwitterIcon,
} from '../../../shared/icons';
import { CommonCopyInput } from '../../../shared/components/CommonCopyInput/CommonCopyInput';
import { PrimaryWhiteButton } from '../../../shared/styles/Buttons';
import { ShareDialog } from './ShareDialog';
import { SlideTransition } from '../../../shared/components/Transitions/SlideTransition';

type ShareWindowProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const ShareWindow: React.FC<ShareWindowProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('gallery');

  const url = useTypedSelectorClient(
    (state) => state?.signIn?.gallery?.galleryUrl,
  ) || '';

  const handleClose = () => setIsOpen(false);

  return (
    <ShareDialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={SlideTransition}
    >
      <ShareCloseButtonPositioned color="black" type="button" onClick={handleClose}>
        <CloseIcon />
      </ShareCloseButtonPositioned>
      <ShareWindowWrapper>
        <ShareTitleWrapper>
          <TitleText>{t('share.shareTitle')}</TitleText>
        </ShareTitleWrapper>
        <SimpleBlackText>{t('share.link')}</SimpleBlackText>
        <ShareCopyInputWrapper>
          <CommonCopyInput value={url} name="galleryUrl" disabled />
        </ShareCopyInputWrapper>
        <ShareLinkButtonWrapper>
          <PrimaryWhiteButton>
            <GradientColorText fontWeight={600}>
              {t('share.copyGalleryLink')}
            </GradientColorText>
          </PrimaryWhiteButton>
        </ShareLinkButtonWrapper>
        <SimpleBlackText>{t('share.otherMedia')}</SimpleBlackText>
        <ShareButtonsWrapper>
          <RoundButton
            type="button"
            onClick={() => console.log('facebook')}
            large
          >
            <TwitterIcon color="pink" />
          </RoundButton>
          <RoundButton
            type="button"
            onClick={() => console.log('facebook')}
            large
          >
            <FacebookIcon color="pink" />
          </RoundButton>
          <RoundButton
            type="button"
            onClick={() => console.log('facebook')}
            large
          >
            <InstagramFillIcon color="pink" />
          </RoundButton>
        </ShareButtonsWrapper>
      </ShareWindowWrapper>
    </ShareDialog>
  );
};
