import styled from '@emotion/styled';

export const ImageWrapper = styled.div<{widthStretch: boolean, hasImage: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  background: ${({ hasImage }) => (hasImage ? 'transparent' : '#F0F1F2')};
`;

export const ImageElement = styled.img<{isWidthBigger: boolean}>`
  ${(props) => {
    if (props.isWidthBigger) {
      return `height: 100% !important;
          width: auto !important;`;
    } return `height: auto !important;
        width: 100% !important;`;
  }};
`;
