import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeFavouritesRequests } from '../../../../api/client/favourites/fakeFavourites';

export const fetchGetClientFavourites = createAsyncThunk(
  'favouritesClient/fetchGetClientFavourites',
  async (_, thunkApi) => {
    try {
      const response = await fakeFavouritesRequests.getFavourites();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchGetTotalNumberFiles = createAsyncThunk(
  'favouritesClient/fetchGetTotalNumberFiles',
  async (_, thunkApi) => {
    try {
      const response = await fakeFavouritesRequests.getFavouritesNumberFiles();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToFavourites = createAsyncThunk(
  'favouritesClient/fetchAddToFavourites',
  async (fileId: string, thunkApi) => {
    try {
      const response = await fakeFavouritesRequests.addFileToFavourites(fileId);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchRemoveFromFavourites = createAsyncThunk(
  'favouritesClient/fetchRemoveFromFavourites',
  async (fileIds: string[], thunkApi) => {
    try {
      const response = await fakeFavouritesRequests.removeItemsFromFavourites(fileIds);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
