import { getFormatDateForDob } from '..';
import { SignUpInProcess, SignUpRequest } from '../../types';
import { getDecodedToken } from './getDecodedToken';

export const getSubmittedSignUp = (data: SignUpInProcess, search: string): SignUpRequest => ({
  firstName: data.firstName,
  lastName: data.lastName,
  businessName: data.businessName,
  password: data.password,
  country: data.country,
  dob: getFormatDateForDob(new Date(data.dob)),
  occupation: data.occupation,
  promoCode: data.promoCode,
  subscribed: data.subscribed,
  token: getDecodedToken(search),
});
