import React, { useContext } from 'react';
import {
  HeartIcon, CommentIcon, InstantDownload, CartIcon,
} from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import { useAddToCart } from '../../hooks/buttons/useAddToCart';
import { useComment } from '../../hooks/buttons/useComment';
import { useLike } from '../../hooks/buttons/useLike';
import { GallerySwiperRightBtnsCont, GallerySwiperRightBtnsWrMobile, GallerySwiperRightButtonsWrapper } from './styled';
import { CustomBadge } from '../../../../shared/components/CustomBadge/CustomBadge';
import { useDownload } from '../../hooks/buttons/useDownload';
import { GallerySwiperDetailsBtn } from './GallerySwiperDetailsBtn';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { ClientStateContext } from '../../../Client/context/ClientStateContext';

type GallerySwiperButtonsRightProps = {
  id: string;
  isInstantDownloadable: boolean;
  isAllowCommenting: boolean;
}

export const GallerySwiperButtonsRight: React.FC<GallerySwiperButtonsRightProps> = ({
  id,
  isInstantDownloadable,
  isAllowCommenting,
}) => {
  const { screenSize } = useCheckWindowSize();
  const { isLiked, handleLike } = useLike(false);
  const { handleDownload } = useDownload();
  const {
    isInCart, handleClickCart, hoverElement, isVisible,
  } = useAddToCart(false);
  const {
    handleOpenComments, commentArea, isCommentsOpen, numberOfComments,
  } = useComment({});
  const { isClientSignIn } = useContext(ClientStateContext);

  const isDisabled = !isClientSignIn;

  return (
    <>
      <GallerySwiperRightBtnsCont>
        <GallerySwiperRightBtnsWrMobile id="client-right-buttons-step">
          <GallerySwiperDetailsBtn />
          <GallerySwiperRightButtonsWrapper>
            {(isInstantDownloadable && screenSize !== 'mobile')
        && (
        <RoundButton
          color="grey"
          onClick={handleDownload}
          disabled={isDisabled}
        >
          <InstantDownload />
        </RoundButton>
        )}
            <RoundButton
              color={isLiked ? 'pink' : 'grey'}
              onClick={handleLike(id)}
              id="client-like-button-swiper-step"
              disabled={isDisabled}
            >
              <HeartIcon />
            </RoundButton>
            <RoundButton
              color={isInCart ? 'pink' : 'grey'}
              onClick={handleClickCart(id)}
              id="client-download-cart-button-step-mobile"
              disabled={isDisabled}
            >
              {hoverElement}
              <CartIcon />
            </RoundButton>
            {isAllowCommenting
        && (
          <CustomBadge badgeContent={numberOfComments}>
            <RoundButton
              disabled={isDisabled}
              color="grey"
              onClick={handleOpenComments}
              className={isCommentsOpen ? ' active ' : ''}
              id="client-comment-button-step"
            >
              <CommentIcon />
            </RoundButton>
          </CustomBadge>
        )}
          </GallerySwiperRightButtonsWrapper>
        </GallerySwiperRightBtnsWrMobile>
      </GallerySwiperRightBtnsCont>
      {commentArea}
    </>
  );
};
