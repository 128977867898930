import React, { ChangeEvent, ReactNode } from 'react';
import { ErrorMessage } from '../../styled';
import {
  InputAndErrorWrapper,
  InputAndLabelWrapper,
} from '../styled';
import { TextLabel } from '../TextLabel';
import { CommonInputElement } from './styled';

type CommonInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type: 'password' | 'email' | 'date' | 'text' | 'file';
  placeholder?: string;
  touched?: boolean | any;
  width?: number;
  label?: string | ReactNode;
  readonly?: boolean;
  direction?: 'row' | 'column';
  disabled?: boolean;
  id?: string;
  withoutMargins?: boolean;
  helpText?: string;
};

export const CommonInput: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  type,
  placeholder,
  handleBlur,
  touched,
  width,
  label,
  readonly,
  direction = 'column',
  disabled,
  id,
  withoutMargins,
  helpText,
}) => (
  <InputAndLabelWrapper
    direction={direction}
    id={id || name}
    withoutMargins={withoutMargins}
    width={width}
  >
    <TextLabel name={name} label={label} helpText={helpText} />
    <InputAndErrorWrapper>
      <CommonInputElement
        hasErrors={error && touched}
        readOnly={readonly}
        id={name}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </InputAndErrorWrapper>
  </InputAndLabelWrapper>
);
