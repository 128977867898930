/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  BasePaymentDiv, PayButton, PaymentForm, PaymentLabelText, PaymentLineWithText, PaymentRowWrapper,
} from './styled';
import { mainFont } from '../../../../../../../constants/styles';
import PaymentRequestForm from './PaymentRequestForm';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: mainFont,
          background: 'white',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};
export const StripeComponent: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://my-site.com/order/123/complete',
      },
    });

    if (result.error) {
      console.log(result.error.message);
    }
  };
  return (
    <>
      <PaymentRequestForm />
      <PaymentForm onSubmit={handleSubmit}>
        <div><PayButton black type="submit">Pay</PayButton></div>
        <PaymentRequestForm />
        <PaymentLineWithText><span>Or pay another way</span></PaymentLineWithText>
        <PaymentLabelText>
          Email
          <BasePaymentDiv>
            <input
              name="email"
              type="email"
              placeholder="example@gmail.com"
              required
            />
          </BasePaymentDiv>
        </PaymentLabelText>
        <PaymentLabelText>
          Card information
          <BasePaymentDiv>
            <CardNumberElement
              options={options}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={(event) => {
                console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                console.log('CardNumberElement [focus]');
              }}
            />
          </BasePaymentDiv>
        </PaymentLabelText>
        <PaymentRowWrapper>
          <BasePaymentDiv>
            <CardExpiryElement
              options={options}
              // onReady={() => {
              //   console.log('CardNumberElement [ready]');
              // }}
              // onChange={(event) => {
              //   console.log('CardNumberElement [change]', event);
              // }}
              // onBlur={() => {
              //   console.log('CardNumberElement [blur]');
              // }}
              // onFocus={() => {
              //   console.log('CardNumberElement [focus]');
              // }}
            />
          </BasePaymentDiv>
          <BasePaymentDiv>
            <CardCvcElement
              options={options}
              // onReady={() => {
              //   console.log('CardNumberElement [ready]');
              // }}
              // onChange={(event) => {
              //   console.log('CardNumberElement [change]', event);
              // }}
              // onBlur={() => {
              //   console.log('CardNumberElement [blur]');
              // }}
              // onFocus={() => {
              //   console.log('CardNumberElement [focus]');
              // }}
            />
          </BasePaymentDiv>
        </PaymentRowWrapper>
        <PaymentLabelText>
          Name on card
          <BasePaymentDiv>
            <input name="name" type="text" placeholder="Jane Doe" required />
          </BasePaymentDiv>
        </PaymentLabelText>
        <PaymentLabelText>
          Country or region
          <BasePaymentDiv>
            <input name="name" type="text" placeholder="Country" required />
          </BasePaymentDiv>
        </PaymentLabelText>
        <PayButton type="submit">Pay</PayButton>
      </PaymentForm>

    </>
  );
};
