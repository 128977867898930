import styled from '@emotion/styled';
import landingWavesGroup from '../../../../../assets/image/landing/landingWavesGroup.png';

export const LandingMainVideoSectionWrp = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${landingWavesGroup}), linear-gradient(274.28deg, #E6934F -24.21%, #E186B0 51.92%, #78C0EA 128.62%);
  background-position: 0% 80%;
  background-repeat: no-repeat;
  background-size: contain; 
  box-sizing: border-box;
  padding: 450px 0px 270px; 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
`;

export const LandingMainVideoWrp = styled.div`
  width: 1095px;
  height: 572px;
  filter: drop-shadow(0px 0px 61px rgba(255, 255, 255, 0.4));
  border-radius: 30px;
  background: white;
`;
