import React, { useMemo, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { today } from '../../../constants';
import { ImageSizes } from '../../../constants/galleryPreviewsAndCovers';
import { getFormattedDateOrDateName } from '../../../shared/utils/dateOperations';
import { getFileInContainer } from '../../../shared/utils/createGallery';
import { GalleryFileCropper } from '../GalleryFileCropper';
import {
  GalleryCover,
  ImageSize,
  NumberOfGallery,
} from '../../../shared/types';

type ReturnObj = {
  file: ReactNode | null;
  name: ReactNode;
  date: ReactNode;
  userName: ReactNode;
  message: ReactNode;
  extraFile: ReactNode | null;
};

type GalleryCoverTypeWithSize = GalleryCover & {
  extraFile?: ReactNode;
  size?: ImageSize;
  galleryNumber?: NumberOfGallery;
};

const getFileInContainerOrCropper = (
  data: GalleryCoverTypeWithSize,
): ReactNode => (data.withCropper ? (
  <GalleryFileCropper
    height={
        ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].height
      }
    width={
        ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].width
      }
    size={data.size || 'desktop'}
  />
) : (
  getFileInContainer(data.file)
));

export const useGalleryContent = (
  data: GalleryCoverTypeWithSize,
): ReturnObj => {
  const { t } = useTranslation('createNewGallery');
  const { userName } = data;
  const dateFormat = 'mm/dd/yyyy';

  const formattedDate = data.date
    ? getFormattedDateOrDateName(`${data.date}`, dateFormat)
    : getFormattedDateOrDateName(`${today}`, dateFormat);

  const fileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data.galleryNumber, data.file],
  );
  const extraFileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data.galleryNumber, data.file],
  );
  return {
    extraFile: extraFileContent,
    file: fileContent,
    name: <h3>{data.name || 'Gallery name'}</h3>,
    date: (
      <span id="client-date-step">
        {formattedDate}
      </span>
    ),
    userName: (
      <p>
        <Trans t={t} i18nKey="by">
          by
          {' '}
          {{ userName }}
        </Trans>
      </p>
    ),
    message: <p id="client-description-step">{data.message || ''}</p>,
  };
};
