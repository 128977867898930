import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ListItemWithIcon } from '../../../../../shared/components/ListItemWithPinkTick/ListItemWithIcon';
import {
  LittleBlackText,
  MediumBlackText,
  PrimaryWhiteButton,
} from '../../../../../shared/styles';
import { PlanDetail, PlanTypes } from '../../../../../shared/types';
import { getPlanTitle } from '../../../../Creator/containers/CreatorAccount/Tabs/Settings/ChangePlanPage/getPlanTitle';
import {
  LandingPricingPlanBadge,
  LandingPricingPlanBadgeText,
  LandingPricingPlanBlockItem,
  LandingPricingPlanBlockPrice,
  LandingPricingPlanBlockWrp,
  LandingPricingPlanDetailsWrp,
  LandingPricingPlanPriceWrp,
} from './styled';

type LandingPricingPlanBlockProps = {
  planDetails: PlanDetail[];
  price: number;
  code: string;
  discount: number;
  extraText: string;
  planType: PlanTypes;
};

export const LandingPricingPlanBlock: React.FC<
  LandingPricingPlanBlockProps
> = ({
  planDetails, price, code, discount, extraText, planType,
}) => {
  const { t } = useTranslation('landing');
  const title = getPlanTitle(planType);

  const currentPrice = price - discount;

  const handleChoosePlan = () => {
    console.log('click');
  };

  return (
    <LandingPricingPlanBlockWrp>
      <LandingPricingPlanBadge>
        <Trans t={t} i18nKey="pricing.plans.discountBadge">
          <LandingPricingPlanBadgeText>
            $
            {{ discount }}
          </LandingPricingPlanBadgeText>
          {' '}
          discount
        </Trans>
      </LandingPricingPlanBadge>
      <LandingPricingPlanBlockItem>
        {title}
        <LandingPricingPlanPriceWrp>
          {discount && (
          <LandingPricingPlanBlockPrice isOld>
            $
            {price}
          </LandingPricingPlanBlockPrice>
          )}
          <LandingPricingPlanBlockPrice>
            $
            {currentPrice}
          </LandingPricingPlanBlockPrice>
        </LandingPricingPlanPriceWrp>
        {code && (
          <MediumBlackText>
            <Trans t={t} i18nKey="pricing.plans.useCode">
              (Use code:
              {' '}
              <MediumBlackText fontWeight={500} style={{ display: 'inline-block' }}>
                {{ code }}
              </MediumBlackText>
              )
            </Trans>
          </MediumBlackText>
        )}
        <LandingPricingPlanDetailsWrp>
          {planDetails.map((item) => (
            <ListItemWithIcon item={item} />
          ))}
        </LandingPricingPlanDetailsWrp>
      </LandingPricingPlanBlockItem>
      <LandingPricingPlanBlockItem>
        <PrimaryWhiteButton type="button" onClick={handleChoosePlan}>
          {t('pricing.plans.choosePlan')}
        </PrimaryWhiteButton>
        <div>
          <LittleBlackText>{extraText}</LittleBlackText>
        </div>
      </LandingPricingPlanBlockItem>
    </LandingPricingPlanBlockWrp>
  );
};
