import React, { MouseEvent } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { CloseIcon } from '../../icons';
import { MediumBlackText } from '../../styles';
import {
  TooltipBody,
  TooltipContent,
  TooltipFooter,
  FormattedMessage,
  TooltipCloseButton,
  TooltipNextButton,
  TooltipBackButton,
} from './styled';

const getPosition = (placement: string): string => {
  if (placement.includes('top')) return 'top';
  if (placement.includes('bottom')) return 'bottom';
  if (placement.includes('right')) return 'right';
  return 'left';
};

type CustomTooltipElementProps = TooltipRenderProps & {
  handleClickNext: (e: MouseEvent<HTMLElement>) => void;
}

export const CustomTooltipElement: React.FC<CustomTooltipElementProps> = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
  handleClickNext,
}) => {
  const topPosition = step?.styles?.tooltip?.top;
  const leftPosition = step?.styles?.tooltip?.left;
  const tooltipPosition = getPosition(step?.placement || '');

  return (
    <TooltipBody
      {...tooltipProps}
      tooltipPosition={tooltipPosition}
      style={{ top: topPosition, left: leftPosition }}
    >
      <TooltipContent>
        <MediumBlackText>{step.content}</MediumBlackText>
      </TooltipContent>
      {!step.hideFooter && (
        <TooltipFooter isFirstCard={index === 0}>
          {index > 0 && (
            <TooltipBackButton {...backProps}>
              {backProps.title}
              <FormattedMessage id="back" />
            </TooltipBackButton>
          )}
          {continuous && (
            <TooltipNextButton {...primaryProps} onClick={handleClickNext}>
              {primaryProps.title}
              <FormattedMessage id="next" />
            </TooltipNextButton>
          )}
          <TooltipCloseButton {...skipProps} id="skip">
            <CloseIcon />
            <FormattedMessage id="skip" />
          </TooltipCloseButton>
        </TooltipFooter>
      )}
    </TooltipBody>
  );
};
