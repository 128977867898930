import React, { useContext } from 'react';
import { CustomBadge } from '../../../../shared/components/CustomBadge/CustomBadge';
import {
  HeartIcon, CommentIcon, InstantDownload,
} from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import { ClientStateContext } from '../../../Client/context/ClientStateContext';
import { useDownload } from '../../hooks/buttons/useDownload';
import { useLike } from '../../hooks/buttons/useLike';
import { ContentActionsButtonsWrapper } from '../styled';

type GallerySwiperRoundButtonsProps = {
    id: string;
    isInstantDownloadable: boolean;
    isAllowCommenting: boolean;
    numberOfComments: number;
    handleOpenComments: () => void;
    isCommentsOpen: boolean;
  };

export const GallerySwiperRoundButtons: React.FC<
    GallerySwiperRoundButtonsProps
  > = ({
    id,
    isInstantDownloadable,
    isAllowCommenting,
    numberOfComments,
    handleOpenComments,
    isCommentsOpen,
  }) => {
    const { isLiked, handleLike } = useLike(false);
    const { handleDownload } = useDownload();
    const { isClientSignIn } = useContext(ClientStateContext);

    const isDisabled = !isClientSignIn;

    return (
      <ContentActionsButtonsWrapper>
        <RoundButton disabled={isDisabled} color={isLiked ? 'pink' : 'grey'} onClick={handleLike(id)}>
          <HeartIcon />
        </RoundButton>
        {isAllowCommenting && (
          <CustomBadge badgeContent={numberOfComments}>
            <RoundButton
              disabled={isDisabled}
              color="grey"
              onClick={handleOpenComments}
              className={isCommentsOpen ? ' active ' : ''}
            >
              <CommentIcon />
            </RoundButton>
          </CustomBadge>
        )}
        {isInstantDownloadable && (
          <RoundButton disabled={isDisabled} onClick={handleDownload} color="grey">
            <InstantDownload />
          </RoundButton>
        )}
      </ContentActionsButtonsWrapper>
    );
  };
