import React from 'react';
import { LandingMainFirstSection } from './LandingMainFirstSection/LandingMainFirstSection';
import { LandingMainVideoSection } from './LandingMainVideoSection/LandingMainVideoSection';
import { LandingMainComparing } from './LandingMainComparing/LandingMainComparing';
import { LandingMainFunctionalitiesSwiper } from './LandingMainSwipers/LandingMainFunctionalitiesSwiper/LandingMainFunctionalitiesSwiper';
import { LandingSwiperReviews } from './LandingMainSwipers/LandingSwiperReviews/LandingSwiperReviews';
import { LandingMainFeaturesSwiper } from './LandingMainSwipers/LandingMainFeaturesSwiper/LandingMainFeaturesSwiper';
import { LandingMainLastSection } from './LandingMainLastSection/LandingMainLastSection';
import { LandingColorfulBackground } from './styled';

export const LandingMainPage: React.FC = () => (
  <>
    <LandingColorfulBackground>
      <LandingMainFirstSection />
      <LandingMainVideoSection />
    </LandingColorfulBackground>
    <LandingMainComparing />
    <LandingMainFunctionalitiesSwiper />
    <LandingSwiperReviews />
    <LandingMainFeaturesSwiper />
    <LandingMainLastSection />
  </>
);
