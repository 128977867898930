import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { ImageContainerThird, TextContainerThird } from '../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/PreviewGalleries/styled';
import { GalleryCoverGrid, MobileVersionWrapper, TabletVersionWrapper } from '../../styled';
import { TextGallerySecondMobile } from '../GallerySecond/styled';

export const GalleryCoverGridThird = styled(GalleryCoverGrid)`
  margin-top: 25px !important;
`;

export const TabletVersionWrapperThird = styled(TabletVersionWrapper)`
  & > div {
    padding: 66px 0 0 0;
    width: 533px;
    height: 710px;
    border: 1px solid ${variables.colorBleak};
    border-radius: 20px;
  }
`;

export const MobileVersionWrapperThird = styled(MobileVersionWrapper)`
  & > div {
    padding: 61px 22px 0;
  }
`;
export const GalleryThirdWrapper = styled.div`
  position: relative !important;
  padding-top: 66px;
  padding-bottom: 66px;
`;

export const TextGalleryThird = styled(TextContainerThird)`
  font-size: 14px;
  line-height: 150%;
  width: 335px;
  height: 600px;
  padding: 100px 30px 0;

  & h3 {
    font-weight: normal;
    margin-top: 0;
    font-size: 28px;
    line-height: 42px;
  }
`;
export const ImageGalleryThird = styled(ImageContainerThird)`
  width: 100%;
  z-index: 0;
  height: 600px;
`;

export const ImageContainerThirdTablet = styled(ImageGalleryThird)`
  width: 531px;
  height: 576px;
`;
export const TextGalleryThirdTablet = styled(TextGalleryThird)`
  width: 47%;
  height: 576px;
  padding: 140px 27px 0;
  font-size: 10px;
  line-height: 15px;
  max-width: none;

  & p {
    margin: 10px 0 20px 0;
  }

  & h3 {
    font-size: 27.7604px;
    line-height: 42px;
  }
`;

export const ImageContainerThirdMobile = styled(ImageGalleryThird)`
  width: 100%;
  height: 235px;
  margin-right: 0;
  position: relative;
`;
export const TextGalleryThirdMobile = styled(TextGalleryThird)`
  width: 100%;
  height: 72px !important;
  font-size: 7px;
  line-height: 130%;
  position: absolute;
  z-index: 100;
  text-align: center;
  display: flex;
  align-items: center;
  bottom: 0;
  padding: 0;
`;
export const TextGalleryThirdMobileCustom = styled(TextGallerySecondMobile)`
  text-align: end;
  margin-bottom: 10px;

  & h3 {
    text-align: center;
  }
`;

export const GalleryDemoThirdMobileWrapper = styled.div`
  padding: 54px 0;
`;
