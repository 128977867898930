import React from 'react';
import { LandingSwiperBase } from '../LandingSwiperBase/LandingSwiperBase';
import {
  LandingMainFunctionalitiesWrp,
} from './styled';
import { useGetFunctionalitySlides } from './useGetFunctionalitySlides';

export const LandingMainFunctionalitiesSwiper: React.FC = () => {
  const { slides } = useGetFunctionalitySlides();

  return (
    <LandingMainFunctionalitiesWrp>
      <LandingSwiperBase slides={slides} autoPlay />
    </LandingMainFunctionalitiesWrp>
  );
};
