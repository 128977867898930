import React, { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import { LandingSwiperReviewsSlide } from './LandingSwiperReviewsSlide';
import {
  LandingSwiperReviewsSliderWrp,
  LandingSwiperReviewsTitle,
  LandingSwiperReviewsWrapper,
  SwiperContentWrapper,
} from './styled';
import { GallerySwiperArrowBtn } from '../../../../../GalleryView/GallerySwiper/GallerySwiperCarousel/GallerySwiperArrowBtn';

export const reviews = [
  {
    userImage: '',
    userName: '0 Lily',
    profession: 'professional photographer',
    comment:
      'I wish I had Delivrable years ago. Once you know how to use it, it pays for itself.',
  },
  {
    userImage: '',
    userName: '1 Adam',
    profession: 'professional photographer',
    comment:
      'Complete gamechanger. I’m earning more from every shoot and my clients are happier because they can see more than just the content I was initially hired to create.',
  },
  {
    userImage: '',
    userName: '2 Thomas',
    profession: 'filmmaker and blogger',
    comment:
      'I woke up to an extra $200 by selling some extra content that was left over from a shoot. I’m still shocked that creators haven’t been able to do this easily until now.',
  },
  {
    userImage: '',
    userName: '3 Jennifer',
    profession: 'amateur photographer',
    comment:
      'Delivrable has SOLVED file delivery for content creators! I couldn’t recommend it more highly.',
  },
];

const settings: Settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  focusOnSelect: true,
};

export const LandingSwiperReviews: React.FC = () => {
  const customSlider = useRef<Slider | null>();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const { t } = useTranslation('landing');
  return (
    <LandingSwiperReviewsWrapper>
      <LandingSwiperReviewsTitle>
        {t('reviews.title')}
      </LandingSwiperReviewsTitle>
      <LandingSwiperReviewsSliderWrp currentSlideIndex={currentSlideIndex}>
        <GallerySwiperArrowBtn
          direction="left"
          handleClick={() => customSlider.current?.slickPrev()}
          id="left"
          disabled={false}
        />
        <SwiperContentWrapper>
          <Slider
            {...settings}
            variableWidth
            ref={(slider) => (customSlider.current = slider)}
            beforeChange={(current: number, next: number) => setCurrentSlideIndex(next)}
          >
            {reviews.map((item) => (
              <div
                style={{ width: '300px', maxWidth: '300px' }}
                id="slide-wrapper"
              >
                <LandingSwiperReviewsSlide
                  key={item.userName}
                  userImage={item.userImage}
                  userName={item.userName}
                  profession={item.profession}
                  comment={item.comment}
                />
              </div>
            ))}
          </Slider>
        </SwiperContentWrapper>
        <GallerySwiperArrowBtn
          direction="right"
          handleClick={() => customSlider.current?.slickNext()}
          id="right"
          disabled={false}
        />
      </LandingSwiperReviewsSliderWrp>
    </LandingSwiperReviewsWrapper>
  );
};
