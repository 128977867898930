import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FirstMediumTextTablet,
  FirstTitleTablet,
  ImageContainerFirstTablet,
} from './styled';
import { GalleryCover } from '../../../../shared/types';
import { TabletWrapper } from '../../TabletWrapper';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import { PreviewFirstHeaderWrapper } from '../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/PreviewGalleries/PreviewFirst/styled';

export const GalleryDemoFirstTablet: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'tablet',
    galleryNumber: 'first',
  });
  return (
    <TabletWrapper>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        style={{ padding: '113px 13px 0' }}
      >
        <Grid item sm={12}>
          <PreviewFirstHeaderWrapper>
            <FirstTitleTablet>{data.name}</FirstTitleTablet>
            <FirstMediumTextTablet>{data.userName}</FirstMediumTextTablet>
          </PreviewFirstHeaderWrapper>
        </Grid>
        <Grid item sm={3}>
          <FirstMediumTextTablet>{data.message}</FirstMediumTextTablet>
        </Grid>
        <Grid container item sm={9} justifyContent="flex-end">
          <ImageContainerFirstTablet>
            {data.file}
            {data.date}
          </ImageContainerFirstTablet>
        </Grid>
      </Grid>
    </TabletWrapper>
  );
};
