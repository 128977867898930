import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const LandingPricingPlanBlockWrp = styled.div`
  box-sizing: border-box;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${variables.colorBleak};
  border-radius: 16px;
  width: 345px;
  height: 623px;
  min-height: 623px;
  max-width: 345px;
  margin-right: 24px;
  position: relative;

  &:last-child {
    margin-right: 0px;
  }
`;

export const LandingPricingPlanBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & > * {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const LandingPricingPlanPriceWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin: 0px 4px;
  }
`;

export const LandingPricingPlanBlockPrice = styled.div<{isOld?: boolean}>`
  font-weight: ${({ isOld }) => (isOld ? '400' : '600')};
  font-size: 20px;
  line-height: 30px;
  color: ${({ isOld }) => (isOld ? variables.textGrey : variables.colorPink)};
  text-decoration: ${({ isOld }) => (isOld ? 'line-through' : 'none')};
`;

export const LandingPricingPlanDetailsWrp = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
  width: 100%;
`;

export const LandingPricingPlansWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 144px;
`;

export const LandingPricingPlanBadge = styled.div`
  background: ${variables.colorPink};
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 78.03px;
  height: 78.03px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(21.62deg);
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
`;

export const LandingPricingPlanBadgeText = styled.div`
  font-size: 20px;
  line-height: 30px; 
`;
