import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CloseButtonPositioned,
  ColorfulCard,
  TitleGreyLightText,
  WhiteButtonWithoutShadow,
} from '../../../../../shared/styles';
import { useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';
import {
  PopupContainer,
} from './styled';
import { CloseIcon } from '../../../../../shared/icons';
import { BackgroundWithShapes } from '../../../../../shared/components/BackgroundWithShapes/BackgroundWithShapes';
import { ModalWindowContainer } from '../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { getUrlDestination } from './getUrlDestination';
import { DestinationType } from '../../../../../shared/types';
import { PopupContent } from './PopupContent';
import { fetchGetStatistics } from '../../../redux/sales/statistics/statisticsThunk';

type NewsPopupWindowProps = {
  id: string;
};

export const NewsPopupWindow: React.FC<NewsPopupWindowProps> = ({ id }) => {
  const [isModalWindowOpen, setIsModalWindowOpen] = useState(false);
  const { t } = useTranslation([
    'notifications',
    'basicComponents',
  ]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetStatistics(id));
  }, []);

  const statistics = useTypedSelectorCreator((state) => state.statistics.statistics);
  const isLoading = useTypedSelectorCreator((state) => state.statistics.loading);

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    setIsModalWindowOpen(false);
    history.push(getUrlDestination(e.currentTarget.name as DestinationType));
  };

  useEffect(() => {
    if (statistics) {
      setIsModalWindowOpen(true);
    }
  }, [isLoading]);

  return (
    <ModalWindowContainer
      isOpen={isModalWindowOpen}
      handleClose={() => setIsModalWindowOpen(false)}
    >
      <ColorfulCard>
        <BackgroundWithShapes />
        <PopupContainer>
          <TitleGreyLightText>
            {t('notifications:popup.popupTitle')}
          </TitleGreyLightText>
          <PopupContent
            statistics={statistics}
            handleClose={handleClose}
          />
          <WhiteButtonWithoutShadow type="button" onClick={handleClose}>
            {t('basicComponents:buttons.awesome')}
          </WhiteButtonWithoutShadow>
        </PopupContainer>
        <CloseButtonPositioned
          color="white"
          type="button"
          onClick={handleClose}
        >
          <CloseIcon />
        </CloseButtonPositioned>
      </ColorfulCard>
    </ModalWindowContainer>
  );
};
