import styled from '@emotion/styled';
import { galleryGap } from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const PositionedElementWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;

  & svg {
    z-index: 1;
    color: white;
  }
`;

export const ExpandAndShrinkWrapper = styled.button`
  background: white;
  padding: 10px 17px;
  border-radius: 53px;
  z-index: 10;
`;

export const MasonryContainer = styled.div`
  margin: auto;
  margin-top: 10px;
  width: calc(100% + 20px);
  margin-right: -20px;

  & img {
    width: calc(100% - 20px);
    height: auto;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  & p {
    text-align: center;
    padding: 4px;
    font-family: monospace;
    background: #222;
    color: #efefef;
  }
`;

export const MasonryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
`;

export const ColumnMasonry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  width: 100%;
  flex-grow: 1;
`;

export const ImagesList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 ${galleryGap}px;

  & div {
    margin-bottom: 5px;
  }

  & img {
    width: 100%;
  }
  & video {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 0 8px;

    & div {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 415px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
`;

export const ImageOrVideoCardWrapper = styled.div<{spans: number, fullWidth: boolean}>`
  grid-row-end: span ${(props) => props.spans};
  grid-column-end: ${(props) => (props.fullWidth ? 'span 3' : 'auto')};
`;

export const LikeAndInstDownloadableWrapper = styled(PositionedElementWrapper)`
  & #client-like-button-step {
    display: none;
  }
  & #inst-downloadable {
    display: flex;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    top: 3px;
    right: 1px;

    & #inst-downloadable {
      display: none;
    }

    & #client-like-button-step {
      display: flex;

      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
