import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Creator, ErrorType } from '../../../../../shared/types';
import { fetchCurrentUserCreator } from './creatorThunk';

type initialState = {
  currentUser: Creator | null;
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  currentUser: null,
  loading: true,
  error: null,
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserCreator.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchCurrentUserCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.currentUser = null;
      },
    );
    builder.addCase(fetchCurrentUserCreator.pending, (state) => {
      state.loading = true;
    });
  },
});

export default creatorSlice.reducer;
