import { LinearProgress, linearProgressClasses, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../../shared/icons';
import { LittleBlackText, PrimaryWhiteButton } from '../../../../shared/styles';
import { scrollToElement } from '../../../GalleryView/utils/scrollToElement';
import {
  SpotsPopupContainer,
  SpotsPopupWrapper,
  LandingProgressContainer,
  LandingProgressLinesContainer,
  SpotsPopupCloseWrapper,
} from './styled';

export const SpotsProgressLine = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 43,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
    // backgroundColor: 'linear-gradient(270deg, #E6934F -102.32%, #E186B0 -1.53%, #78C0EA 100%)',
    // background: 'linear-gradient(270deg, #E6934F -102.32%, #E186B0 -1.53%, #78C0EA 100%)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 43,
    backgroundColor:
      'linear-gradient(270deg, #E6934F -102.32%, #E186B0 -1.53%, #78C0EA 100%)',
    background:
      'linear-gradient(270deg, #E6934F -102.32%, #E186B0 -1.53%, #78C0EA 100%)',
  },
}));

export const SpotsPopup: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [close, setClose] = useState(false);
  const { t } = useTranslation('landing');
  const spots = 50;

  const handleClose = () => {
    setClose(true);
  };

  const handleChoosePlan = () => {
    scrollToElement('pricing-plans', undefined, 100);
  };

  const onScroll = () => {
    if (Math.round(window.scrollY) > 1300) {
      setVisible(true);
    }
    if (Math.round(window.scrollY) < 1300) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (close || !visible) {
    return null;
  }

  return (
    <Slide direction="up" in={visible || !close} mountOnEnter unmountOnExit>
      <SpotsPopupContainer>
        <SpotsPopupWrapper>
          <SpotsPopupCloseWrapper type="button" onClick={handleClose}>
            <CloseIcon color="black" />
          </SpotsPopupCloseWrapper>
          <div>
            <LittleBlackText fontWeight={500}>
              <Trans t={t} i18nKey="stickyBlock.text">
                Only
                {' '}
                {{ spots }}
                {' '}
                spots left!
              </Trans>
            </LittleBlackText>
          </div>
          <LandingProgressLinesContainer>
            <LandingProgressContainer>
              <SpotsProgressLine variant="determinate" value={spots} />
            </LandingProgressContainer>
          </LandingProgressLinesContainer>
          <PrimaryWhiteButton type="button" onClick={handleChoosePlan}>
            {t('pricing.plans.choosePlan')}
          </PrimaryWhiteButton>
        </SpotsPopupWrapper>
      </SpotsPopupContainer>
    </Slide>
  );
};
