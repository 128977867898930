import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as variables from '../../../../../../constants/styles';
import { ColorfulButton } from '../../../../../../shared/styles';
import { BaseCartFile } from '../../CartItem/styled';

export const PaymentSuccessfulWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentSuccessfulTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.textBlack};
  margin-bottom: 28px;
`;

export const PaymentSuccessfulZipDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 66px;
`;

export const PaymentSuccessfulZipFolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;

  & * {
    margin-right: 8px;
  }
`;

export const PaymentSuccessfulButton = styled(ColorfulButton)`
  margin: 0;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;

export const TextWithCheckmark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-right: 3px;
    color: ${variables.colorPink};
    width: 16px;
    height: 13px;
  }
`;

export const PaymentSuccessfulFilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -30px;
`;

export const PaymentPositionedElement = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DownloadableFileCard = styled(BaseCartFile)`
  margin-right: 30px;
  margin-bottom: 30px;

  & img {
    max-width: none;
  }
`;

export const CardElementFormWrapper = styled.form`
  max-width: 380px;
  display: flex;
  flex-direction: column;

  & button {
    margin: auto;
    margin-top: 20px;
    width: fit-content;
  }
`;

export const CardElementFormPrice = styled.div`
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
`;

export const PaymentGrid = styled(Grid)`
  box-shadow: -5px -5px 10px #e8e8e8 !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
`;

export const PaymentForm = styled.form`
  width: 100%;
  box-sizing: border-box;
  padding: 100px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PaymentElementWrapper = styled.div`width: 100% !important;`;

export const PaymentFilesListWrapper = styled.div`
  margin-top: 100px;
`;

export const PaymentBackToCartBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  padding: 0;

  & div {
    &:first-of-type {
      width: 11px;
    }
  }
`;

export const PaymentMainLogoWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;

  & svg {
    width: 118px;
  }
`;

export const PaymentPayBtnWrapper = styled.div`
  margin-top: 30px;
  width: 100%;

  & button {
    width: 100% !important;
  }
`;
