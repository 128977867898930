import { useFormikContext } from 'formik';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GalleryDataForm } from '../../../../../../../shared/types';
import { FormGalleryWrapper } from '../styled';
import { Section } from './Section';
import { ButtonAdd } from './ButtonAdd';

export const NewGalleryCreatorSecondStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { values, setValues } = useFormikContext<GalleryDataForm>();

  const handleClick = () => {
    const data = values;
    const sectionNumber = values?.sections?.length + 1;
    data?.sections?.push({
      id: uuidv4(),
      title: `Section ${sectionNumber}`,
      files: [],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    });
    setValues(data);
  };
  return (
    <>
      <FormGalleryWrapper>
        {values?.sections?.map((item) => (
          <Section key={item.id} section={item} />
        ))}
      </FormGalleryWrapper>
      <ButtonAdd
        id="addSectionSecondStep"
        handleClick={handleClick}
        size="large"
        text={t('secondStep.addSection')}
      />
    </>
  );
};
