import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeCartRequests } from '../../../../api/client/cart/fakeCart';
import { FileAndSectionId } from '../../../../shared/types/client';

export const fetchGetClientCart = createAsyncThunk(
  'cartClient/fetchGetClientCart',
  async (_, thunkApi) => {
    try {
      const response = await fakeCartRequests.getCart();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchGetTotalNumberFiles = createAsyncThunk(
  'cartClient/fetchGetTotalNumberFiles',
  async (_, thunkApi) => {
    try {
      const response = await fakeCartRequests.getCartNumberFiles();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToCart = createAsyncThunk(
  'cartClient/fetchAddToCart',
  async (data: FileAndSectionId, thunkApi) => {
    try {
      const response = await fakeCartRequests.addFileToCart(data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchRemoveFromCart = createAsyncThunk(
  'cartClient/fetchRemoveFromCart',
  async (fileIds: string[], thunkApi) => {
    try {
      const response = await fakeCartRequests.removeItemsFromCart(fileIds);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchAddItemsToCart = createAsyncThunk(
  'cartClient/fetchAddToCart',
  async (ids: string[], thunkApi) => {
    try {
      const response = await fakeCartRequests.addToCartMultipleItems(ids);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
