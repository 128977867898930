import { DestinationType } from '../../../../../shared/types';
import * as urls from '../../../../../constants/urls';

export const getUrlDestination = (
  destination: DestinationType = '',
): string => {
  switch (destination) {
    case 'activeGallery':
      return urls.activeGalleriesUrl;
    case 'sales':
      return urls.salesUrl;
    default:
      return urls.creatorRootUrl;
  }
};
