import { Grid } from '@material-ui/core';
import React from 'react';
import { DeleteIcon } from '../../../../../../../../../shared/icons';
import { RegisteredEmail } from '../../../../../../../../../shared/types';
import { getFormattedDateOrDateName } from '../../../../../../../../../shared/utils';
import { MediumBlackTextMgTB, RegisteredEmailDelete } from './styled';

type RegisteredEmailRowProps = {
  row: RegisteredEmail;
};

export const RegisteredEmailRow: React.FC<RegisteredEmailRowProps> = ({
  row,
}) => {
  const dateFormat = 'mm/dd/yyyy';
  const formattedDate = getFormattedDateOrDateName(row.lastVisit, dateFormat, true);

  return (
    <Grid container item sm={12}>
      <Grid item sm={6}>
        <MediumBlackTextMgTB>{row?.email}</MediumBlackTextMgTB>
      </Grid>
      <Grid item sm={4}>
        <MediumBlackTextMgTB>
          {formattedDate}
        </MediumBlackTextMgTB>
      </Grid>
      <Grid item sm={2}>
        <RegisteredEmailDelete>
          <DeleteIcon />
        </RegisteredEmailDelete>
      </Grid>
    </Grid>
  );
};
