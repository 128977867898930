import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleTooltip } from '../../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import { DownloadIcon, HeartIcon } from '../../../../../../../../../shared/icons';
import { LikesOrDownloadsFileWrapper, LikesOrDownloadsIconWrapper, LikesOrDownloadsWrapper } from './styled';
import { ImageContainer } from '../../../../../../../../../shared/components/Files/ImageContainer/ImageContainer';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';

type LikesOrDownloadsProps = {
  likesOrDownloads: any[];
  type: 'like' | 'download';
}

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const LikesOrDownloadsTab: React.FC<LikesOrDownloadsProps> = ({
  likesOrDownloads,
  type,
}) => {
  const { t } = useTranslation('gallery');

  if (!likesOrDownloads?.length) return <SimpleBlackText>{t('noLikes')}</SimpleBlackText>;

  return (
    <LikesOrDownloadsWrapper>
      {likesOrDownloads.map((item) => (
        <LikesOrDownloadsFileWrapper key={item}>
          <ImageContainer imageUrl="" />
          <LikesOrDownloadsIconWrapper>
            {type === 'download' ? <DownloadIcon /> : <HeartIcon />}
            <SimpleTooltip text="example@gmai.com" relativeWidth={20} />
          </LikesOrDownloadsIconWrapper>
        </LikesOrDownloadsFileWrapper>
      ))}
    </LikesOrDownloadsWrapper>
  );
};
