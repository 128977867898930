import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  CustomSlider,
  FrontWrapper,
  PreviewSwiperContainer,
  PreviewSwiperWrapper,
} from './styled';
import {
  GalleryCover,
  SetFieldValue,
} from '../../../../../../../shared/types';
import { SwiperHeader } from './SwiperHeader';
import {
  PreviewFirst,
  PreviewFourth,
  PreviewFifth,
} from '../PreviewGalleries';
import { settings } from '../../../../../../../constants/galleryPreviewsAndCovers';
import { PreviewSixth } from '../PreviewGalleries/PreviewSixth/PreviewSixth';

type PreviewSwiperType = GalleryCover & {
  value: number | null;
  setFieldValue: SetFieldValue;
};

export const PreviewSwiper: React.FC<PreviewSwiperType> = ({
  setFieldValue,
  value,
  name,
  date,
  file,
  message,
  userName,
}) => {
  const customSlider = useRef<Slider | null>();
  return (
    <PreviewSwiperContainer>
      <SwiperHeader customSlider={customSlider} />
      <PreviewSwiperWrapper>
        <FrontWrapper />
        <CustomSlider {...settings} ref={(slider) => (customSlider.current = slider)}>
          <button
            key={1}
            type="button"
            onClick={() => setFieldValue('galleryDesign', 1)}
          >
            <PreviewFirst
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              selected={value === 1}
            />
          </button>
          <button
            key={4}
            type="button"
            onClick={() => setFieldValue('galleryDesign', 4)}
          >
            <PreviewFourth
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              selected={value === 4}
            />
          </button>
          <button
            key={5}
            type="button"
            onClick={() => setFieldValue('galleryDesign', 5)}
          >
            <PreviewFifth
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              selected={value === 5}
            />
          </button>
          <button
            key={6}
            type="button"
            onClick={() => setFieldValue('galleryDesign', 6)}
          >
            <PreviewSixth
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              selected={value === 6}
            />
          </button>
        </CustomSlider>
      </PreviewSwiperWrapper>
    </PreviewSwiperContainer>
  );
};
