import { getIn, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { EditIcon, CloseIcon } from '../../../../../../../shared/icons';
import { GalleryDataForm, GallerySection } from '../../../../../../../shared/types';
import {
  SectionTitle,
  CloseButtonWrapper,
  TextUploadedWrapper,
} from './styled';

type SectionHeaderProps = {
  section: GallerySection;
};

const uploadingProgress = 100;

export const SectionHeader: React.FC<SectionHeaderProps> = ({ section }) => {
  const { t } = useTranslation('createNewGallery');
  const {
    values, handleChange, setFieldValue, errors, touched,
  } = useFormikContext<GalleryDataForm>();

  const index = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [values.sections, section],
  );

  const handleDeleteSection = (id: string) => {
    setFieldValue(
      'sections',
      values?.sections?.filter((item) => item.id !== id),
    );
  };

  const { setIsOpen: setIsOpenDialogWindow, dialogWindow } = useDialog<string>(
    section.id,
    handleDeleteSection,
    t('secondStep.deleteSectionDialog.question'),
    t('secondStep.deleteSectionDialog.title'),
  );

  const error = useMemo(
    () => (getIn(errors, 'sections') ? getIn(errors, 'sections')[index] : null),
    [errors?.sections],
  );
  const wasTouched = useMemo(
    () => (getIn(touched, 'sections') ? getIn(touched, 'sections')[index] : null),
    [touched?.sections],
  );
  const filesNumber = useMemo(
    () => section?.files?.length + section?.ideas.length,
    [section?.files?.length, section?.ideas.length],
  );

  return (
    <>
      <SectionTitle error={error?.title && wasTouched?.title}>
        <div>
          <input
            name={`sections[${index}].title`}
            value={section.title}
            onChange={handleChange}
            style={{ width: `${section.title.length * 11}px` }}
          />
          <EditIcon />
          {error?.title && wasTouched?.title ? (
            <ErrorMessage>{error?.title}</ErrorMessage>
          ) : null}
          {section?.files?.length > 0 && (
          <>
            <Trans i18nKey="secondStep.files" t={t}>
              {{ filesNumber }}
              {' '}
              files
            </Trans>
            <TextUploadedWrapper theme="success">
              <Trans i18nKey="secondStep.uploaded" t={t}>
                {{ uploadingProgress }}
                % uploaded
              </Trans>
            </TextUploadedWrapper>
          </>
          )}
        </div>
        {values?.sections?.length > 1 && (
        <CloseButtonWrapper>
          <span>{t('secondStep.deleteSection')}</span>
          <button type="button" onClick={() => setIsOpenDialogWindow(true)}>
            <CloseIcon />
          </button>
        </CloseButtonWrapper>
        )}
      </SectionTitle>
      {dialogWindow}
    </>
  );
};
