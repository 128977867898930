import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { FileInputBase } from '../../../../../../../../shared/styles';

export const ChangePhotoWindowContainer = styled.div`
  width: 870px;
  height: 452px;
  min-width: 380px;
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  position: relative;
  padding: 48px 20px;
  box-sizing: border-box;
`;

export const ProfilePictureWrapper = styled(FileInputBase)<{
  hasError: boolean;
  hasFile: boolean;
  imageUrl: string;
}>`
  box-sizing: border-box;
  border-radius: 50%;
  width: 134px;
  height: 134px;
  overflow: hidden;
  margin: 10px 0;
  padding: 0;
  position: relative;
  ${(props) => (props.hasError
    ? `
    border: 0.5px solid ${variables.colorError};
  `
    : 'border: 0.5px solid transparent;')}

  background: ${(props) => (props.hasFile ? `url(${String(props.imageUrl)})` : '#86868666')};
  background-size: cover;

  & input {
    top: 0;
    left: 0;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 10;
    background: rgba(134, 134, 134, 0.4);
  }

  &:hover {
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(134, 134, 134, 0.4);
      z-index: 9;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 2px;
      background: black;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
      z-index: 10;
      background: white;
    }
  
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 16px;
      background: black;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
      background: white;
      z-index: 10;
    }
    & svg {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      color: white;
      z-index: 10;
    }
  }
`;

export const ProfilePhotoUpload = styled.div`
  width: 284px;
  height: 355px;
  overflow: hidden;
  position: relative;
  object-fit: cover;

  & > div {
    height: auto;
    width: 100%;
    object-fit: cover;
  
    & > img {
      height: auto;
      width: 100%;
    }
  }
`;

export const ChangePhotoWindowWrapper = styled.div`
  padding-left: calc(50% - 142px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
