import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeStatisticsRequests } from '../../../../../api/sales/statistics/fakeStatisticsRequests';

export const fetchGetStatistics = createAsyncThunk(
  'notifications/fetchGetStatistics',
  async (id: string) => {
    try {
      const response = await fakeStatisticsRequests.getStatistics(id);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
