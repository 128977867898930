import React, { ReactElement } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import galleryEditor from '../../../../../../assets/image/landing/swipers/functionality/galleryEditor.gif';
import ideas from '../../../../../../assets/image/landing/swipers/functionality/ideas.png';
import modernUI from '../../../../../../assets/image/landing/swipers/functionality/modernUI.png';
import payment from '../../../../../../assets/image/landing/swipers/functionality/payment.png';
import { SwiperBaseSlidesPinkText } from '../LandingSwiperBase/styled';
import { FunctionalitiesSwiperGifWrapper } from './styled';

type ReturnObj = {
  slides: {
    title: string;
    secondTitle: string | ReactElement;
    element: ReactElement;
  }[];
};

export const useGetFunctionalitySlides = (): ReturnObj => {
  const { t } = useTranslation('landing');

  const functionalitySlides = [
    {
      title: t('functionalities.modernUITitle'),
      secondTitle: t('functionalities.modernUIText'),
      element: <img src={modernUI} alt="Modern UI" />,
    },
    {
      title: t('functionalities.simpleEditorTitle'),
      secondTitle: t('functionalities.simpleEditorText'),
      element: (
        <FunctionalitiesSwiperGifWrapper>
          <img src={galleryEditor} alt="Modern UI" />
        </FunctionalitiesSwiperGifWrapper>
      ),
    },
    {
      title: t('functionalities.ideasTitle'),
      secondTitle: (
        <Trans t={t} i18nKey="functionalities.ideasText">
          Have another video edit you can offer to your client but don’t want to
          spend hours creating it first? Use “
          <SwiperBaseSlidesPinkText>Ideas</SwiperBaseSlidesPinkText>
          ” to pitch
          and sell content to your client before you create it.
        </Trans>
      ),
      element: <img src={ideas} alt="Modern UI" />,
    },
    {
      title: t('functionalities.stripeTitle'),
      secondTitle: t('functionalities.stripeText'),
      element: <img src={payment} alt="Modern UI" />,
    },
  ];

  return {
    slides: functionalitySlides,
  };
};
