/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export const baseURL = 'https://delivrable.com/api/';

export const instance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json' },
  baseURL,
});

export const clientInstance = axios.create({
  baseURL,
});

export const getHeader = (headers: Headers) => {
  headers.set('Access-Control-Allow-Origin', '*');
  headers.set(
    'Access-Control-Allow-Methods',
    'GET, POST, PUT, PATCH, DELETE, OPTIONS',
  );
  headers.set('Content-Type', 'application/json');
  return headers;
};

instance.interceptors.response.use((config) => config, async (error) => {
  try {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.get(`${baseURL}auth/refresh`, { withCredentials: true });
        return instance.request(originalRequest);
      } catch (e) {
        console.log('Unauthorized');
      }
    }
    throw error;
  } catch (err) {
    console.log(err);
    throw error;
  }
});
