import React, { Dispatch, SetStateAction } from 'react';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import {
  IdeaContainer,
  IdeaHeader, IdeaHeaderTitle, MiniTitleText, PrimaryWhiteButtonMgT,
} from './styled';
import { GalleryDataForm, GalleryIdea } from '../../../../../../../../shared/types';
import { IdeaForm } from './IdeaForm';
import { ModalWindowContainer } from '../../../../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { CloseIcon } from '../../../../../../../../shared/icons/CommonIcons';
import { HelpTip } from '../../../../../../../../shared/components/HelpTip/HelpTip';
import { PinkLink } from '../../../../../../../../shared/styles';

type IdeaModalWindowProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  idea: GalleryIdea;
  indexSection: number;
  indexIdea: number;
  removeCreatedIdea?: (id: string) => void;
};

export const IdeaModalWindow: React.FC<IdeaModalWindowProps> = ({
  setIsOpen,
  idea,
  indexIdea,
  indexSection,
  isOpen,
  removeCreatedIdea,
}) => {
  const { t } = useTranslation(['createNewGallery', 'basicComponents']);
  const { handleSubmit, isValid } = useFormikContext<GalleryDataForm>();

  const handleClose = () => {
    setIsOpen((prev) => !prev);
    if (removeCreatedIdea) {
      removeCreatedIdea(idea.id);
    }
  };

  const handleSaveAndClose = () => {
    handleSubmit();
    if (isValid) {
      setIsOpen(false);
    }
  };

  const helpIdeaText = (
    <Trans t={t} i18nKey="createNewGallery:secondStep.ideaHelpText">
      See community
      {' '}
      <PinkLink href="/">best examples</PinkLink>
    </Trans>
  );

  return (
    <ModalWindowContainer
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <IdeaContainer>
        <IdeaHeader>
          <IdeaHeaderTitle>
            <MiniTitleText>
              {t('createNewGallery:secondStep.ideas')}
            </MiniTitleText>
            <HelpTip text={helpIdeaText} withMgLeft />
          </IdeaHeaderTitle>
          <button
            type="button"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </IdeaHeader>

        <IdeaForm
          key={idea?.id}
          idea={idea}
          ideaField={`sections[${indexSection}].ideas[${indexIdea}]`}
          indexSection={indexSection}
          indexIdea={indexIdea}
          submitButton={(
            <PrimaryWhiteButtonMgT type="submit" onClick={handleSaveAndClose}>
              {t('basicComponents:buttons.saveClose')}
            </PrimaryWhiteButtonMgT>
        )}
        />
      </IdeaContainer>
    </ModalWindowContainer>
  );
};
