/* eslint-disable implicit-arrow-linebreak */
import {
  GalleryFile,
  GalleryFileInformation,
  GalleryIdea,
  GallerySection,
} from '../../types';

const findOneCommonValue = <A>(arr: Set<A>) => {
  if (arr.size === 1 && arr?.values()) {
    return Array.from(arr)[0];
  }
  return '';
};

const createNewSet = <A>(arr: A[], field: keyof GalleryFileInformation) =>
  new Set(
    arr.map((item) => {
      const valueOfField = Object.entries(item)?.find((value) => value[0] === field) || [];
      return valueOfField[1];
    }),
  );

export const getCommonValuesOfFileInfo = (
  section: GallerySection,
  selectedFileIds: string[],
): GalleryFileInformation => {
  const selectedFiles = section?.files?.filter((item: GalleryFile) =>
    selectedFileIds.includes(item.id));
  const selectedIdeas = section?.ideas?.filter((item: GalleryIdea) =>
    selectedFileIds.includes(item.id));
  const selected = [...selectedFiles, ...selectedIdeas];
  return {
    description: findOneCommonValue(createNewSet(selected, 'description')),
    price: findOneCommonValue(createNewSet(selected, 'price')),
    instantlyDownloadable: !!findOneCommonValue(
      createNewSet(selected, 'instantlyDownloadable'),
    ),
    watermark: !!findOneCommonValue(createNewSet(selected, 'watermark')),
    allowCommenting: !!findOneCommonValue(
      createNewSet(selected, 'allowCommenting'),
    ),
    fullWidth: !!findOneCommonValue(
      createNewSet(selected, 'fullWidth'),
    ),
  };
};
