import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabContentWrapper,
  TabWrapper,
} from '../../../../../../shared/styles';
import { ViewAllLink } from '../../../../../../shared/components/ViewAllLink/ViewAllLink';
import * as url from '../../../../../../constants/urls';
import { SalesPart } from './SalesPart';
import { GalleriesList } from './GalleriesList';

export const MainDashboard: React.FC = () => {
  const { t } = useTranslation('basicComponents');

  return (
    <TabWrapper>
      <TabContentWrapper>
        <SalesPart />
        <ViewAllLink
          label={t('basicComponents:viewStatistics')}
          to={url.salesUrl}
        />
      </TabContentWrapper>
      <GalleriesList />
    </TabWrapper>
  );
};
