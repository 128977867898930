import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { GalleryCard } from '../../../../../../shared/components/GalleryCard/GalleryCard';
import { GalleryForRequest } from '../../../../../../shared/types';
import * as urls from '../../../../../../constants/urls';
import { useDeleteGalleryMutation } from '../../../../redux/gallery/galleryAPI';

type DraftGalleriesCardsProps = {
  galleries: GalleryForRequest[];
};

export const DraftGalleriesCards: React.FC<DraftGalleriesCardsProps> = ({
  galleries,
}) => {
  const { t } = useTranslation('draftGalleries');
  const history = useHistory();
  const [deleteGallery] = useDeleteGalleryMutation();

  const handleClick = (id: string) => () => {
    history.push(`${urls.newGalleryUrl}/${id}`);
  };

  const handleDelete = (galleryId: string) => {
    deleteGallery({ id: galleryId });
  };

  return (
    <>
      {galleries.length ? (
        galleries.map((item) => (
          <Grid item sm={6} key={item.id}>
            <GalleryCard
              title={item.name}
              coverFile={{
                id: item.coverFile,
                type: 'image',
                name: item.coverFile,
                url: item.coverFile,
              }}
              buttonText={t('continueEditing')}
              handleClick={handleClick(item.id)}
              handleDelete={handleDelete}
              galleryId={item.id}
            />
          </Grid>
        ))
      ) : (
        <Grid item sm={6}>
          <SimpleBlackText>{t('noDraftGalleries')}</SimpleBlackText>
        </Grid>
      )}
    </>
  );
};
