import React, { ChangeEventHandler, useEffect, useRef } from 'react';
import { MaxLengthText } from '../../../../../../../../shared/components/FormElements/CommonTextarea/MaxLengthText';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';
import { EditIcon } from '../../../../../../../../shared/icons';
import {
  PreviewEmailTextareaEditIcon,
  PreviewEmailTextareaElement,
  PreviewEmailTextareaWrapper,
} from './styled';

type CommonInputProps = {
  error: string | any;
  value: string | any;
  name: string;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  maxTextLength?: number;
};

const maxEmailMessageLength = 387;

export const PreviewEmailTextarea: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  placeholder,
}) => {
  const refTextarea = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (refTextarea && refTextarea.current) {
      refTextarea.current.style.height = '0px';
      const { scrollHeight } = refTextarea.current;
      refTextarea.current.style.height = `${scrollHeight}px`;
    }
  }, [value]);

  return (
    <PreviewEmailTextareaWrapper isSuccess={!error}>
      <PreviewEmailTextareaEditIcon>
        <EditIcon />
      </PreviewEmailTextareaEditIcon>
      <PreviewEmailTextareaElement
        ref={refTextarea}
        id={name}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        rows={3}
        isSuccess={!error}
        contentEditable
        maxLength={maxEmailMessageLength}
      />
      <MaxLengthText
        isSuccess={!error}
        maxLength={maxEmailMessageLength}
        currentLength={value?.length}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </PreviewEmailTextareaWrapper>
  );
};
