import { GalleryFile, GalleryIdea } from '../../../shared/types';
import { Favourites } from '../../../shared/types/client';
import { galleryForCLient } from '../../gallery/fakeGalleryRequests';

const favourites: Favourites = {
  files: [],
  totalFiles: 0,
};

export const fakeFavouritesRequests = {
  getFavouritesNumberFiles: () => Promise.resolve({
    data: { status: 200, totalNumber: favourites.totalFiles },
  }),
  getFavourites: (): Promise<Favourites> => Promise.resolve(favourites),
  addFileToFavourites: (
    fileId: string,
  ): Promise<{ data: Favourites }> => {
    try {
      let files: (GalleryFile | GalleryIdea)[] = [];
      galleryForCLient?.sections?.forEach((item) => {
        files = [...files, ...item.files, ...item.ideas];
      });
      const file = files.find(
        (item) => item.id === fileId,
      );
      if (file) {
        try {
          let copyFiles = [...favourites.files];
          copyFiles = [...copyFiles, file];
          favourites.files = [...copyFiles];
          favourites.totalFiles += 1;
          return Promise.resolve({ data: favourites });
        } catch (e) {
          console.log(e);
        }
      }
      return Promise.reject(new Error('No such file'));
    } catch (e) {
      return Promise.reject(new Error('Smth goes wrong'));
    }
  },
  removeItemsFromFavourites: (
    fileIds: string[],
  ): Promise<{ data: Favourites }> => {
    try {
      const changeableFiles = [...favourites.files];
      favourites.totalFiles -= fileIds.length;

      const newSections = changeableFiles.filter(
        (file) => !fileIds.includes(file.id),
      );

      favourites.files = [...newSections];

      return Promise.resolve({ data: favourites });
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
