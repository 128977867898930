import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const LandingMainLastSectionWrp = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 123px;
`;

export const LastSectionTitle = styled.div`
  font-size: 40px;
  line-height: 110%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 36px;
`;

export const LastSectionGradientTitle = styled.span`
  background: linear-gradient(274.28deg, #E6934F -20%, #E186B0 128.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const LastSectionTextWrp = styled.div`
  max-width: 845px;
  margin-bottom: 60px;
  text-align: center;
`;
