import { createAsyncThunk } from '@reduxjs/toolkit';
import { creator } from '../../../../../api/creator/creator';

export const fetchCurrentUserCreator = createAsyncThunk(
  'creator/fetchCurrentUserCreator',
  async (_, thunkApi) => {
    try {
      const response = await creator.getCurrentCreator();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);
