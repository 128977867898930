import React, { useContext, useEffect, useState } from 'react';
import { ClientOnboardingStepIndexContext } from '../../../modules/Client/context/ClientOnboardingStepIndexContext';
import { ClientOnboardingContext } from '../../../modules/Client/context/CreatorOnboardingContext';
import { GallerySwiperWindow } from '../../../modules/GalleryView/GallerySwiper/GallerySwiperWindow';
import { GalleryIdea, GalleryFile, GalleryData } from '../../types';
import { InvisibleButton } from './styled';

type InvisibleOpenFileButtonProps = {
  file: GalleryFile | GalleryIdea;
  fileIndex?: number;
  gallery: GalleryData;
};

const openSwiperDuringOnboarding = (
  isStudy: boolean,
  fileIndex = 0,
  stepNumber: number,
) => isStudy && fileIndex === 0 && (stepNumber === 13 || stepNumber === 16);

const closeSwiperDuringOnboarding = (
  isStudy: boolean,
  fileIndex = 0,
  stepNumber: number,
) => (isStudy && stepNumber === 17)
  || !isStudy
  || (isStudy && fileIndex === 0 && stepNumber === 12);

export const InvisibleOpenFileButton: React.FC<
  InvisibleOpenFileButtonProps
> = ({ file, fileIndex, gallery }) => {
  const [isSwiperOpen, setIsSwiperOpen] = useState(false);
  const { isStudy } = useContext(ClientOnboardingContext);
  const { stepIndex } = useContext(ClientOnboardingStepIndexContext);

  useEffect(() => {
    if (openSwiperDuringOnboarding(isStudy, fileIndex, stepIndex)) {
      setIsSwiperOpen(true);
    }
    if (closeSwiperDuringOnboarding(isStudy, fileIndex, stepIndex)) {
      setIsSwiperOpen(false);
    }
  }, [fileIndex, stepIndex, isStudy]);

  return (
    <>
      <InvisibleButton type="button" onClick={() => setIsSwiperOpen(true)} />
      <GallerySwiperWindow
        gallery={gallery}
        file={file}
        isOpen={isSwiperOpen}
        setIsOpen={setIsSwiperOpen}
      />
    </>
  );
};
