import styled from '@emotion/styled';
import { Styles } from 'react-burger-menu';
import { RoundButton } from '../../../../shared/styles';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const burgerMenuStyles: Partial<Styles> = {
  bmBurgerButton: {
    display: 'flex',
  },
  bmCrossButton: {
    top: '43px',
    right: '47px',
  },
  bmMenu: {
    background: variables.colorGreyLight,
    top: '0',
  },
  bmOverlay: {
    top: '0',
    width: '100%',
    background: 'transparent',
  },
  bmMenuWrap: {
    background: variables.colorGreyLight,
    top: '0',
    padding: '100px 47px 30px',
    boxSizing: 'border-box',
  },
  bmItemList: {
    margin: 'auto',
  },
};

export const HeaderWrapper = styled.div<{ isSignIn: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ isSignIn }) => (isSignIn ? '24' : '15')}px;
  margin-bottom: ${({ isSignIn }) => (isSignIn ? '20' : '13')}px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    box-sizing: border-box;
    padding: 40px 16px;
    margin-bottom: ${({ isSignIn }) => (isSignIn ? '0' : '13')}px;

    & > svg {
      width: 150px !important;
    }
  }
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > button,
  & > span,
  & > div {
    margin-left: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const RoundButtonWithPopup = styled(RoundButton)`
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;

    & > div {
      display: flex;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 100px;
    }
  }
`;

export const RoundButtonSupportWrapper = styled.div`
  position: relative;
`;

export const CloseMenuIconWrapper = styled.div`
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const MenuMainLogoWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;

  & svg {
    width: 150px;
  }
`;

export const MenuSocialMedias = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & svg {
    width: 24px;
    height: 24px;

    &:not(:last-child) {
      margin-right: 23px;
    }
  }
`;

export const MenuPrivacyTermsWrapper = styled.div`
  font-size: 14px;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${variables.textGrey};

  & div {
    margin-bottom: 11px;
  }
`;

export const MenuCopyright = styled.div`
  font-size: 12px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${variables.textGrey};
`;

export const ClientMenuListWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
  height: 100%;
`;

export const ClientMenuLinksAndButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & .menu-item {
    width: fit-content;
    text-align: center;
    margin: auto;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-bottom: 50px;
  }
`;
