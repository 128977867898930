import React, {
  MutableRefObject, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { PauseIcon, PlayIcon } from '../../../icons';
import {
  ButtonWrapper, PlayOrPauseButton, VideoElement, VideoWrapper,
} from './styled';

type VideoContainerProps = {
  videoUrl: string;
  autoPlay?: boolean;
  widthStretch?: boolean;
  setSpans?: (ref: MutableRefObject<any>) => void;
};

export const VideoContainer: React.FC<VideoContainerProps> = ({
  videoUrl,
  autoPlay,
  widthStretch,
  setSpans,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  const handleOnLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    setIsWidthBigger(e.currentTarget.videoHeight <= e.currentTarget.videoWidth);
    if (setSpans) {
      setSpans(videoRef);
    }
  };

  const handlePlayOrPause = () => {
    setIsPlay((prev) => !prev);
    if (isPlay) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  };

  useEffect(() => {
    if (setSpans) {
      setSpans(videoRef);
    }
  }, [widthStretch]);

  return (
    <VideoWrapper>
      {videoUrl && (
        <VideoElement
          // autoPlay={autoPlay}
          loop
          muted
          id={videoUrl}
          preload="auto"
          ref={videoRef}
          onLoadedData={handleOnLoad}
          widthStretch={!!widthStretch}
          isWidthBigger={isWidthBigger}
        >
          <source src={videoUrl} type="video/mkv" />
          <source src={videoUrl} type="video/ogg" />
          <source src={videoUrl} type="video/avi" />
          <source src={videoUrl} type="video/mp4" />
        </VideoElement>
      )}
      <ButtonWrapper isPlay={isPlay}>
        <PlayOrPauseButton type="button" onClick={handlePlayOrPause}>
          {isPlay ? <PauseIcon /> : <PlayIcon />}
        </PlayOrPauseButton>
      </ButtonWrapper>
    </VideoWrapper>
  );
};
