import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PointingRightIcon } from '../../../../../shared/icons/Landing';
import { BaseColors } from '../../../../../shared/types';
import {
  LandingMainBtnAndText,
  JoinEarlyProgramBtn,
  LandingMainGradientColorText,
  LandingMainLittleText,
} from './styled';
import { ButtonTheme, ShadowTheme } from './types';
import * as urls from '../../../../../constants/urls/landingUrls';

type JoinEarlyProgramButtonProps = {
  btnTheme: ButtonTheme;
  shadowTheme: ShadowTheme;
  textColor?: BaseColors;
  withoutBottomText?: boolean;
  onClick?: () => void;
};

export const JoinEarlyProgramButton: React.FC<JoinEarlyProgramButtonProps> = ({
  btnTheme,
  shadowTheme,
  textColor = 'white',
  withoutBottomText,
  onClick,
}) => {
  const { t } = useTranslation('landing');
  const history = useHistory();

  const handleClick = () => {
    history.push(urls.pricingUrl);
  };

  return (
    <LandingMainBtnAndText>
      <JoinEarlyProgramBtn
        btnTheme={btnTheme}
        shadowTheme={shadowTheme}
        type="button"
        onClick={onClick || handleClick}
      >
        <LandingMainGradientColorText fontWeight={600}>
          <PointingRightIcon />
          {t('mainButton')}
        </LandingMainGradientColorText>
      </JoinEarlyProgramBtn>
      {!withoutBottomText && (
        <LandingMainLittleText textColor={textColor}>
          {t('lifetimeAccess')}
        </LandingMainLittleText>
      )}
    </LandingMainBtnAndText>
  );
};
