import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainLogo } from '../../../../shared/icons/MainLogo';
import { GalleryFooterWrapper, GalleryFooterPoweredBy } from './styled';

type GalleryFooterProps = {
  removeBranding: boolean;
}

export const GalleryFooter: React.FC<GalleryFooterProps> = ({
  removeBranding,
}) => {
  const { t } = useTranslation('gallery');

  if (removeBranding) return null;

  return (
    <GalleryFooterWrapper>
      <GalleryFooterPoweredBy>
        {t('poweredBy')}
        {' '}
        <MainLogo />
      </GalleryFooterPoweredBy>
    </GalleryFooterWrapper>
  );
};
