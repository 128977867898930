import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import * as urls from '../../../../constants/urls';
import { CustomBadge } from '../../../../shared/components/CustomBadge/CustomBadge';
import { useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';
import { HeartIcon, ShareIcon, CartIcon } from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import {
  fetchGetClientCart,
  fetchGetTotalNumberFiles,
} from '../../redux/cart/cartClientThunk';
import { RoundButtonWithPopup } from './styled';
import { useShare } from '../../../GalleryView/hooks/buttons/useShare';
import { CartPopupWindow } from '../Cart/CartPopup/CartPopupWindow';

export const NavButtons: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { handleOpenClose, shareWindow } = useShare();

  const totalNumberFiles = useTypedSelectorClient(
    (state) => state.cart.totalFiles,
  );
  const totalNumberFavourites = useTypedSelectorClient(
    (state) => state.favourites.totalFiles,
  );

  useEffect(() => {
    dispatch(fetchGetTotalNumberFiles());
  }, []);

  useEffect(() => {
    dispatch(fetchGetClientCart());
  }, []);

  const handleClickToCart = () => {
    history.push(urls.clientCartUrl);
  };

  const handleClickToFavourites = () => {
    history.push(urls.clientFavouritesUrl);
  };

  return (
    <>
      <CustomBadge badgeContent={totalNumberFavourites}>
        <Link to={urls.clientFavouritesUrl}>
          <RoundButton
            className={
              pathname.includes(urls.clientFavouritesUrl) ? ' active ' : ''
            }
            color="grey"
            onClick={handleClickToFavourites}
            id="client-favourites-button-step"
          >
            <HeartIcon />
          </RoundButton>
        </Link>
      </CustomBadge>
      <CustomBadge badgeContent={totalNumberFiles}>
        <RoundButtonWithPopup
          className={pathname.includes(urls.clientCartUrl) ? ' active ' : ''}
          color="grey"
          onClick={handleClickToCart}
          id="client-download-cart-header-button-step"
        >
          <CartIcon />
          <CartPopupWindow />
        </RoundButtonWithPopup>
      </CustomBadge>
      <RoundButton
        onClick={handleOpenClose}
        color="grey"
        id="client-share-header-button-step"
      >
        <ShareIcon />
      </RoundButton>
      {shareWindow}
    </>
  );
};
