import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelectorCommon } from '../../../../shared/hooks/useTypedSelector';
import { CustomFile } from '../../../../shared/types';
import { CommentAreaWindow } from '../../CommentArea/CommentAreaWindow';
import { FileAndCommentsWindowContainer } from '../../CommentArea/FileAndCommentsWindowContainer';
import { fetchGetComments } from '../../redux/comments/commentsThunk';

type ReturnObj = {
  handleOpenComments: () => void;
  commentArea: ReactElement | null;
  isCommentsOpen: boolean;
  numberOfComments: number;
};

type UseCommentProps = {
  openFile?: boolean;
  file?: CustomFile | null;
};

export const useComment = ({ openFile, file }: UseCommentProps): ReturnObj => {
  const [isCommentsOpen, setIsCommentOpen] = useState(false);
  const dispatch = useDispatch();
  const comments = useTypedSelectorCommon((state) => state?.comments?.comments) || [];

  // useEffect(() => {
  //   dispatch(fetchGetComments());
  // }, []);

  const handleOpenComments = () => {
    setIsCommentOpen(true);
  };

  const handleCloseComments = () => {
    setIsCommentOpen(false);
  };

  const commentArea = useMemo(() => {
    if (isCommentsOpen) {
      return ((openFile ? (
        <FileAndCommentsWindowContainer
          isOpen={isCommentsOpen}
          handleClose={handleCloseComments}
          file={file}
        />
      ) : (
        <CommentAreaWindow handleClose={handleCloseComments} isOpen={isCommentsOpen} />
      )));
    }
    return null;
  }, [openFile, isCommentsOpen]);

  return {
    handleOpenComments,
    commentArea,
    isCommentsOpen,
    numberOfComments: comments.length,
  };
};
