import styled from '@emotion/styled';

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    width: 100%;
    margin: 0;
  }

  & > img {
    margin-right: 16px;
    margin-bottom: 18px;
  }
`;

export const SwitchAndLabelWrapper = styled.div<{spaceBetween?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-start')};
`;
