import React, { ElementType } from 'react';
import Joyride, {
  CallBackProps, Step, TooltipRenderProps, Locale,
  ACTIONS, EVENTS, STATUS,
} from 'react-joyride';
import { useHistory } from 'react-router-dom';
import * as variables from '../../../constants/styles';

type CustomJoyrideProps = {
  stepIndex: number;
  customTooltip: ElementType<TooltipRenderProps>;
  runTraining: boolean;
  steps: Step[];
  locale?: Locale;
  endUrl: string;
  setStepIndex: (stepIndex: number) => void;
  setIsStudy: (_: boolean) => void;
};

export const CustomJoyride: React.FC<CustomJoyrideProps> = ({
  stepIndex,
  customTooltip,
  runTraining,
  steps,
  locale,
  endUrl,
  setStepIndex,
  setIsStudy,
}) => {
  const history = useHistory();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {
      action, index, status, type, step,
    } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setIsStudy(false);

      // to start from 0 step next time if onboarding was finished
      if (steps.length === index + 1) {
        setStepIndex(0);
      }

      history.push(endUrl);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      disableOverlayClose
      disableScrolling
      continuous
      stepIndex={stepIndex}
      tooltipComponent={customTooltip}
      scrollOffset={100}
      run={runTraining}
      scrollToFirstStep={false}
      showProgress={false}
      steps={steps}
      floaterProps={{
        styles: {
          arrow: {
            marginBottom: '40px',
          },
        },
      }}
      spotlightClicks
      locale={locale}
      styles={{
        spotlight: {
          borderRadius: '10px',
        },
        options: {
          zIndex: 100000,
          arrowColor: variables.colorGreyLight,
          backgroundColor: variables.colorGreyLight,
          beaconSize: 22,
          overlayColor: 'rgba(0, 0, 0, 0.3)',
          primaryColor: '#f04',
          spotlightShadow: 'none',
          textColor: '#333',
          width: '260px',
        },
      }}
    />
  );
};
