import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TabContentWrapper,
  TabWrapper,
  TitleTextPink,
} from '../../../../../../shared/styles';
import { NotificationsList } from './NotificationsList';
import { useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { TitleTextCustom } from './styled';
import { fetchGetNotifications, setEmptyArrNotifications } from '../../../../redux/notifications/notificationsThunk';

export const Notifications: React.FC = () => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  const numberNotReadNotifications = useTypedSelectorCreator(
    (state) => state.notifications.numberNotRead,
  );
  const isLoading = useTypedSelectorCreator((state) => state.notifications.loading);

  const numberNotReadNotificationsText = numberNotReadNotifications
    ? (
      <Trans t={t} i18nKey="notReadNotificationsNumber">
        {' '}
        (
        {{ notReadNotificationsNumber: numberNotReadNotifications }}
        )
      </Trans>
    ) : null;

  useEffect(() => {
    dispatch(fetchGetNotifications({ page: 1 }));
    return () => { dispatch(setEmptyArrNotifications()); };
  }, []);

  return (
    <TabWrapper>
      <TitleTextCustom>
        {t('title')}
        <TitleTextPink>
          {numberNotReadNotificationsText}
        </TitleTextPink>
      </TitleTextCustom>
      <TabContentWrapper>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <NotificationsList />
        )}
      </TabContentWrapper>
    </TabWrapper>
  );
};
