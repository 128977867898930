import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientSignIn } from '../../../../shared/types/client';
import { fakeActiveGallery } from '../../../../api/gallery/fakeGalleryRequests';

export const fetchSignInClient = createAsyncThunk(
  'signInClient/fetchSignInClient',
  async (data: ClientSignIn) => {
    const response = await fakeActiveGallery.getGalleryByCred(data);
    window.localStorage.setItem('token', data.password);
    return response;
  },
);

export const fetchGetClientGallery = createAsyncThunk(
  'signInClient/fetchGetClientGallery',
  async (_, thunkApi) => {
    try {
      const token = window.localStorage.getItem('token');
      if (!token) {
        return thunkApi.rejectWithValue('Not auth');
      }
      const response = await fakeActiveGallery.getGalleryByToken(token);
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);
