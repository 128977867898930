import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { ImageSizes } from '../../../../constants/galleryPreviewsAndCovers';
import { PreviewFirstHeaderWrapper } from '../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/PreviewGalleries/PreviewFirst/styled';

export const FirstTitle = styled.div`
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: ${variables.textBlack};

  & h3 {
    margin: 0;
  }
`;

export const FirstMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;
`;

export const FirstImageContainer = styled.div`
  width: ${ImageSizes.first.desktop.width}px;
  height: ${ImageSizes.first.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;
  display: flex;

  & span {
    width: 80px;
    text-align: center;
    position: absolute;
    font-weight: normal;
    transform: rotate(-90deg);
    font-size: 14px;
    line-height: 21px;
    left: -80px;
    bottom: 30px;
  }
`;

export const ImageContainerFirstTablet = styled(FirstImageContainer)`
  width: ${ImageSizes.first.tablet.width}px;
  height: ${ImageSizes.first.tablet.height}px;

  & span {
    left: -36px;
    bottom: 15px;
    font-size: 11px;
    line-height: 16px;
  }
`;

export const FirstTitleTablet = styled(FirstTitle)`
  font-size: 33px;
  line-height: 50px;
`;

export const FirstMediumTextTablet = styled(FirstMediumText)`
  font-size: 9.6px;

  & p {
    margin: 0;
  }
`;

export const ImageContainerFirstMobile = styled(FirstImageContainer)`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
`;

export const FirstTitleMobile = styled(FirstTitle)`
  font-size: 30px;
  line-height: 140%;
`;

export const FirstMediumTextMobile = styled(FirstMediumText)`
  font-size: 16px;
  margin: 0px 16px 24px;

  & p {
    margin: 0;
  }
`;

export const MobileWrapperFirst = styled.div`
  margin: 30px 10px 0 10px;
`;

export const FirstHeaderMobileWrapper = styled(PreviewFirstHeaderWrapper)`
  margin: 0px 16px 24px;
  width: fit-content;
`;
