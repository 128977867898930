import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';
import { SimpleBlackText, TextTotalBlackLarge } from '../../../../shared/styles';

export const GallerySwiperButtonsAndDescriptionWrapper = styled.div`
  margin: 40px 153px 100px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 60px 16px 60px;
  }
`;

export const ContentDescriptionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionTitle = styled(TextTotalBlackLarge)`
  margin-bottom: 14px;
`;

export const DescriptionText = styled(SimpleBlackText)`
  margin-bottom: 30px;
  display: block;
`;

export const SwiperIdeaFileSlide = styled.div`
  width: 320px;
  height: 320px;
  min-width: 320px;
  min-height: 320px;
  border-radius: 10px;

  & img {
    max-width: none;
  }

  @media (max-width: 352px) {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;  
  }
`;

export const GallerySwiperIdeaFilesWrp = styled.div`
  margin: 32px 0px 40px;
`;
