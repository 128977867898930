import styled from '@emotion/styled';
import { InputBase } from '@material-ui/core';
import * as variables from '../../constants/styles';
import { theme } from './GlobalContainer/styled';

export const AdornmentItem = styled.div`
  position: absolute;
  left: 15px;
  bottom: 11.3px;
  color: ${variables.textGrey};
  font-size: 15px;
`;

export const InputWrapper = styled.div`
  position: relative;

  & > span {
    position: absolute;
    top: 100%;
    left: 24px;
    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }
`;

export const BaseInputWrapper = styled.div<{isSuccess: boolean}>`
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  border: 0.5px solid #e0e0e0;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  padding: 12px 10px 12px 24px;
  height: 100%;
  width: 100%;
  ${({ isSuccess }) => (!isSuccess
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
  `
    : '')}

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
  }
`;

export const TextInput = styled.div`
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  border: 0.5px solid #e0e0e0;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  padding: 12px 10px 12px 24px;
  height: 100%;
  width: 100%;
  ${({ color }) => (color === 'error'
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
  `
    : '')}

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
  }

  & > textarea {
    background: transparent;
    display: flex;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    align-items: flex-start;
    box-sizing: border-box;
    resize: none;
    font-size: 15px;
    border: none;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${variables.colorBleak};
      border-radius: 100px !important;
    }

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: ${variables.colorBleak};
      line-height: 140%;
    }
  }
`;
export const MaxLengthTextWrapper = styled.div<{isSuccess: boolean}>`
  position: absolute;
  bottom: -24px;
  right: 0;
  background: transparent;
  opacity: 1;

  & span {
    font-size: 13px;
    line-height: 120%;
    color: ${({ isSuccess }) => (isSuccess ? variables.textGrey : variables.colorError)};
  }
`;

export const TextLabelWrapper = styled.div<{ short?: boolean; disabled?: boolean, wrapperDirection?: 'row' | 'column'}>`
  margin-top: 0;
  margin-right: 30px;
  margin-bottom: ${({ wrapperDirection }) => {
    if (wrapperDirection === 'row') return '0';
    return '14px !important';
  }};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${(props) => props.disabled && `color: ${variables.textGrey};`}
  ${(props) => props.short && 'white-space: pre-wrap !important; max-width: 203px;'}

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-right: 22px;
  }
`;

export const ErrorMessage = styled.span<{ bottom?: number; left?: number }>`
  color: ${variables.colorError};
  line-height: 18px;
  font-size: 12px;
  max-width: 300px;
  height: 18px;
`;

export const CopyInputWrapper = styled(InputBase)`
  position: relative;

  & > input,
  textarea,
  > div > input {
    padding: 12px 54px 12px 24px;

    &:read-only {
      color: ${variables.textGrey};
    }
  }

  & button {
    position: absolute;
    right: 14px;
    bottom: 3px;
  }
`;

export const BaseTextCopied = styled.div<{ isVisible: boolean }>`
  transition: all 0.4s ease-in-out;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-right: 10px;
  }
`;

export const TextCopied = styled(BaseTextCopied)`
  margin-left: 35px;
  left: 0;
`;

export const CustomPositionCheckbox = styled.div`
  & > label {
    & > span, & #checkbox {
      top: 7px;
      left: 8px;
      margin-top: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    & #checkbox {
      width: 14px;
      height: 14px;

      &:after {
        left: 4px;
        top: 2px;
      }
    }
  }
`;
export const HelpWrapper = styled.span`
  position: absolute;
`;

const getDeg = (direction: string) => {
  switch (direction) {
    case 'right':
      return '225';
    case 'left':
      return '45';
    case 'up':
      return '135';
    case 'down':
      return '315';
    default:
      return '225';
  }
};

export const ArrowWrapper = styled.span<{large?: boolean, direction: 'left' | 'right' | 'up' | 'down'}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    width: fit-content;
    height: fit-content;
  }

  & svg {
    width: ${(props) => (props.large ? '19px' : '14px')};
    height: ${(props) => (props.large ? '27px' : '19px')};
    margin-top: 4px;
  }
`;

export const CSSArrowIcon = styled.span<{
  large?: boolean;
  theme: 'left' | 'right' | 'up' | 'down';
}>`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: ${(props) => (props.large ? '12px' : '8px')};
    height: ${(props) => (props.large ? '12px' : '8px')};
    margin-top: ${(props) => (props.large ? '-6px' : '-4px')};
    margin-left: ${(props) => (props.large ? '-6px' : '-3.5px')};
    border-bottom: 1px solid ${variables.colorBleak};
    border-left: 1px solid ${variables.colorBleak};
    font-size: 14px;
    background: #e0e0e000 !important;
    transform: rotate(${(props) => getDeg(props.theme)}deg);
  }
`;
