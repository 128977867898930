import styled from '@emotion/styled';
import { MediumBlackText } from '../../../../../../../../../shared/styles';

export const IdeaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const IdeaFileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    margin-bottom: 8px;
  }
`;

export const IdeaInfoAndButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

export const IdeaInfoTitle = styled(MediumBlackText)`
  margin-bottom: 12px;
`;

export const IdeaInfoButtonWrapper = styled.div`
  margin-top: 24px;
`;

export const MediumBlackTextWithDots = styled(MediumBlackText)`
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  margin: 0;
`;
