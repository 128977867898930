import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ChangeEvent } from 'react';
import { GalleryCover, GalleryData } from '../../../../shared/types';
import { getURLByFile } from '../../../../shared/utils';
import { PlusIcon } from '../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorSecondStep/styled';
import { useExtraFilesGalleryFourth } from '../../hooks/useExtraFilesGalleryFourth';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import {
  CreatorCell,
  GalleryCoverGridFourth,
  ImageGalleryFourth,
  SquareBoxes,
  TextGalleryFourth,
  ImageFirstCell,
  ImageSecondCell,
  DateCell,
  FileUploaderWrapper,
} from './styled';

export const GalleryCoverFourth: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper = false,
}) => {
  const { setFieldValue } = useFormikContext<GalleryData>();

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    galleryNumber: 'fourth',
  });
  const { extraFile1, extraFile2 } = useExtraFilesGalleryFourth(withCropper, 'desktop');

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    if (event.currentTarget.files) {
      const newCoverImage = event.currentTarget.files || [];
      setFieldValue(`${id}.original.file`, {
        name: newCoverImage[0].name,
        url: getURLByFile(newCoverImage[0]),
        type: newCoverImage[0].type,
      });
      setFieldValue(`${id}.desktop.file`, null);
      setFieldValue(`${id}.tablet.file`, null);
      setFieldValue(`${id}.mobile.file`, null);
    }
  };

  return (
    <GalleryCoverGridFourth
      container
      item
      sm={12}
      justifyContent="space-between"
    >
      <Grid container item sm={3} alignItems="flex-start">
        <TextGalleryFourth>
          {data.name}
          {data.message}
        </TextGalleryFourth>
      </Grid>
      <Grid container item sm={5} alignItems="flex-start">
        <ImageGalleryFourth>{data.file}</ImageGalleryFourth>
      </Grid>
      <Grid item sm={4}>
        <SquareBoxes>
          <CreatorCell>{data.userName}</CreatorCell>
          <ImageFirstCell>
            {extraFile1 || (
              <FileUploaderWrapper>
                <PlusIcon theme="small" />
                <input
                  id="extraCover1"
                  name="extraCover1"
                  type="file"
                  accept="*/image"
                  onChange={handleUploadFile}
                />
              </FileUploaderWrapper>
            )}
          </ImageFirstCell>
          <ImageSecondCell>
            {extraFile2 || (
              <FileUploaderWrapper>
                <PlusIcon theme="small" />
                <input
                  id="extraCover2"
                  name="extraCover2"
                  type="file"
                  accept="*/image"
                  onChange={handleUploadFile}
                />
              </FileUploaderWrapper>
            )}
          </ImageSecondCell>
          <DateCell>{data.date}</DateCell>
        </SquareBoxes>
      </Grid>
    </GalleryCoverGridFourth>
  );
};
