import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ViewsLikesDownloadsShares } from '../../../../../shared/components/ViewsLikesDownloadsShares/ViewsLikesDownloadsShares';
import {
  EyeIcon, HeartIcon, DownloadIcon, ShareIcon,
} from '../../../../../shared/icons';
import { FeedBackWrapper } from './styled';

type ViewsLikesDownloadsSharesProps = {
  likes?: number;
  shares?: number;
  downloads?: number;
  views?: number;
};

export const ViewsLikesDownloadsSharesContainer: React.FC<ViewsLikesDownloadsSharesProps> = ({
  likes = 0,
  downloads = 0,
  views = 0,
  shares = 0,
}) => (
  <FeedBackWrapper>
    <div>
      <ViewsLikesDownloadsShares icon={<EyeIcon />} numberOfData={views} type="views" />
      <ViewsLikesDownloadsShares icon={<HeartIcon />} numberOfData={likes} type="likes" />
    </div>
    <div>
      <ViewsLikesDownloadsShares icon={<DownloadIcon />} numberOfData={downloads} type="downloads" />
      <ViewsLikesDownloadsShares icon={<ShareIcon />} numberOfData={shares} type="shares" />
    </div>
  </FeedBackWrapper>

);
