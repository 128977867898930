import React, { ReactElement } from 'react';
import { BaseColors } from '../../types';
import { TextIconButtonWithHoverWrp } from './styled';

type TextIconButtonWithHoverProps = {
  textElement: ReactElement;
  hoverColor?: BaseColors;
  originalColor?: BaseColors;
  handleClick: () => void;
  icon: ReactElement;
};

export const TextIconButtonWithHover: React.FC<
  TextIconButtonWithHoverProps
> = ({
  textElement,
  hoverColor = 'black',
  originalColor = 'grey',
  handleClick,
  icon,
}) => (
  <TextIconButtonWithHoverWrp
    hoverColor={hoverColor}
    originalColor={originalColor}
    type="button"
    onClick={handleClick}
  >
    <div id="hover-button-icon">{icon}</div>
    <div id="hover-button-text">{textElement}</div>
  </TextIconButtonWithHoverWrp>
);
