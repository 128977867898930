import { v4 as uuidv4 } from 'uuid';
import {
  CustomFile,
  GalleryData,
  GalleryFile,
  GalleryForRequest,
  GalleryStatus,
} from '../../../shared/types';
import {
  getFormatDateForDob,
  getURLByFile,
  isVideo,
} from '../../../shared/utils';

export const getGalleryDataForRequest = (
  data: GalleryData & { step?: number },
  status: GalleryStatus,
): GalleryForRequest => {
  const dataCopy: GalleryData & { step?: number } = JSON.parse(
    JSON.stringify(data),
  );
  delete dataCopy.step;

  (dataCopy as unknown as GalleryForRequest).coverFile = dataCopy?.coverFile?.original?.file?.id || '';
  (dataCopy as unknown as GalleryForRequest).expiryDate = dataCopy.expiryDate
    ? getFormatDateForDob(new Date(dataCopy.expiryDate))
    : '';
  (dataCopy as unknown as GalleryForRequest).date = dataCopy.date
    ? getFormatDateForDob(new Date(dataCopy.date))
    : '';
  (dataCopy as unknown as GalleryForRequest).status = status;

  return dataCopy as unknown as GalleryForRequest;
};

export const getFormDataAndFileObjects = (
  files: FileList | File[],
): { formData: FormData; ids: string[] } => {
  const formData = new FormData();
  const ids: string[] = [];

  for (let i = 0; i < files.length; i++) {
    const id = uuidv4();

    formData.append(id, files[i]);
    ids.push(id);
  }

  return { formData, ids };
};

export const getCoverFileObject = (fileId: string, file: File): CustomFile => ({
  name: file.name,
  url: getURLByFile(file),
  type: file.type,
  id: fileId,
});

export const getFilesArr = (
  fileIds: string[],
  files: File[],
): GalleryFile[] => {
  const resultFiles: GalleryFile[] = [];

  for (let i = 0; i < fileIds.length; i++) {
    resultFiles.push({
      name: files[i].name,
      url: getURLByFile(files[i]),
      type: files[i].type,
      id: fileIds[i],
      fullWidth: isVideo(files[i]),
    });
  }

  return resultFiles;
};

export const getFilteredUploadingFiles = (
  uploadingFiles: File[],
  alreadyUploadedFiles: GalleryFile[],
): File[] => {
  const filteredFiles = uploadingFiles.filter(
    (item) => !alreadyUploadedFiles.find((file) => file?.name === item?.name),
  );

  return filteredFiles;
};

type FileWithoutUrl = { name: string, fullWidth: boolean, type: string, id: string };

export const getFilesWithoutUrl = (uploadingFiles: File[], ids: string[]): FileWithoutUrl[] => {
  const resultFiles: FileWithoutUrl[] = [];

  for (let i = 0; i < ids.length; i++) {
    resultFiles.push({
      name: uploadingFiles[i].name,
      type: uploadingFiles[i].type,
      id: ids[i],
      fullWidth: isVideo(uploadingFiles[i]),
    });
  }

  return resultFiles;
};

type AllDateForUploadingFiles = {
  ids: string[];
  preUploadingFiles: FileWithoutUrl[];
  formData: FormData;
}

// export const getAllDateForUploadingFiles = (
//   uploadingFiles: File[],
//   alreadyUploadedFiles: GalleryFile[],
// ): AllDateForUploadingFiles => {

// }
