import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const DropDownContainer = styled.div`
  width: fit-content;
  position: relative;
`;

export const DropDownHeader = styled.div`
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: ${variables.textBlack};
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & > p {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 111px;
  }

  & svg {
    margin-top: 3px;
    margin-left: 12px;
    margin-right: 12px;
  }
`;

export const DropDownListContainer = styled.div<{ isSelectOpen: boolean }>`
  display: ${({ isSelectOpen }) => (isSelectOpen ? 'flex' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  height: fit-content;
  z-index: 15;
  margin-top: 12px;
  width: 220px;
  max-width: 220px;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  padding: 8px;
  background: ${variables.colorGreyLight};
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(34, 34, 34, 0.34);
  width: 100%;
`;

export const ListItem = styled.button<{ isActive: boolean }>`
  padding: 8px 10px;
  background: ${variables.colorGreyLight};
  transition: color background 0.2s ease-in-out;
  color: ${variables.textGrey};
  border-radius: 8px;
  text-align: start;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  & > p {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 220px;
  }

  &:hover {
    border-radius: 8px;
    background: ${variables.colorBlueLight};
    color: ${variables.textBlack};
    transition: color background 0.2s ease-in-out;
  }

  ${({ isActive }) => (isActive
    ? `
    border-radius: 8px;
    background: ${variables.colorBlueLight};
    color: ${variables.textBlack};
    transition: color background 0.2s ease-in-out;
  `
    : '')}

  &:active {
    border-radius: 8px;
    background: ${variables.colorBlueLight};
    color: ${variables.textBlack};
    transition: color background 0.2s ease-in-out;
  }
`;
