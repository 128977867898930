import styled from '@emotion/styled';
import {
  MenuItemProps, Select, MenuItem, SelectProps,
} from '@mui/material';
import * as variables from '../../../../constants/styles';

export const StyledSelect = styled(Select)<SelectProps>(() => ({
  '&.MuiSelect-root': {
    '&.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    color: variables.textGrey,
    fontFamily: variables.mainFont,
    margin: '0px',
    fontSize: '15px',
    width: '100%',
    padding: '0px',
    border: '0.5px solid #e0e0e0',
    height: '44px',
    boxSizing: 'border-box',
    boxShadow: 'inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7',
    borderRadius: '163px',
    borderColor: '#e0e0e0',
  },
}));

export const CustomSelect = styled(StyledSelect)`
& div {
  background: transparent !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 23px 12px 20px;
}
& svg {
  right: 14px;
}
& input {
  border: none;
  padding: 0px;
}
& select {
  & li {
    &:hover {
      width: calc(100% + 10px);
    }
  }
}
`;

export const SelectWithErrorMessage = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column; 

  & > span {
    padding-left: 24px;
  }
`;

export const CustomMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  '&.MuiMenuItem-root': {
    fontFamily: variables.mainFont,
    color: variables.textGrey,
    fontSize: '15px',
    lineHeight: '140%',
    borderRadius: '8px',
    padding: '6px 10px',
    transition: 'none',
    width: '100%',
  },
  '&.Mui-selected': {
    backgroundColor: variables.colorBlueLight,
    color: variables.textBlack,
    transition: 'none',
    '&:hover': {
      backgroundColor: variables.colorBlueLight,
      color: variables.textBlack,
      transition: 'none',
    },
  },
  '&:hover': {
    backgroundColor: variables.colorBlueLight,
    color: variables.textBlack,
    transition: 'none',
  },
}));

export const PlaceholderWrapper = styled(MenuItem)`
  font-family: ${variables.mainFont} !important;
  color: ${variables.colorBleak} !important;
  display: none;
`;

export const PlaceholderText = styled.span`
  font-family: ${variables.mainFont};
  color: ${variables.colorBleak};
`;
