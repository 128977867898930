import React, {
  useState, ChangeEvent, SetStateAction, Dispatch,
} from 'react';

type ReturnObj = {
  handleSelectAll: (totalCount: number, arrChosen: string[]) => void;
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  removeAllSelected: () => void;
  isItemSelected: (id: string) => boolean;
  numberOfSelected: number;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}

export const useChooseItems = (): ReturnObj => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleSelectAll = (totalCount: number, arrChosen: string[]) => {
    if (selected.length === totalCount) {
      setSelected([]);
    } else {
      const newSet = new Set([
        ...selected,
        ...arrChosen,
      ]);
      const newArr = Array.from(newSet);
      setSelected(newArr);
    }
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target.id;
    const selectedIndex = selected.indexOf(element);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, element);
      setSelected(newSelected);
    }
    if (selectedIndex >= 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      setSelected(newSelected);
    }
  };

  const removeAllSelected = () => setSelected([]);

  const isItemSelected = (id: string) => selected.includes(id);

  return ({
    handleSelectAll,
    handleSelect,
    removeAllSelected,
    isItemSelected,
    numberOfSelected: selected.length,
    selected,
    setSelected,
  });
};
