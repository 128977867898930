import React, { ReactNode } from 'react';
import { HelpTip } from '../HelpTip/HelpTip';
import { TextLabelWrapper } from '../styled';

type TextLabelProps = {
  name: string;
  label?: string | ReactNode;
  disabled?: boolean;
  short?: boolean;
  wrapperDirection?: 'row' | 'column';
  helpText?: string;
  littleText?: boolean;
};

export const TextLabel: React.FC<TextLabelProps> = ({
  name,
  label,
  disabled,
  short,
  wrapperDirection = 'column',
  helpText,
  littleText,
}) => (label ? (
  <TextLabelWrapper
    wrapperDirection={wrapperDirection}
    id="text-label"
    disabled={disabled}
    short={short}
  >
    <div style={{ fontSize: littleText ? '14px' : '16px', width: '100%' }}>{label}</div>
    <div>{helpText && <HelpTip text={helpText} withMgLeft />}</div>
  </TextLabelWrapper>
) : null);
