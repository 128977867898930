import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  TabContentWrapper,
  TabWrapper,
  TitleText,
} from '../../../../../../shared/styles';
import { DraftGalleriesCards } from './DraftGalleriesCards';
import { useGetAllGalleriesQuery } from '../../../../redux/gallery/galleryAPI';

export const DraftGalleries: React.FC = () => {
  const { t } = useTranslation('draftGalleries');
  const { data, isLoading } = useGetAllGalleriesQuery(undefined);
  const galleries = data?.draftGalleries || [];

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {/* {isLoading ? (
          <span>Loading...</span>
        ) : ( */}
        <Grid container spacing={4}>
          <DraftGalleriesCards galleries={galleries} />
        </Grid>
        {/* )} */}
      </TabContentWrapper>
    </TabWrapper>
  );
};
