import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { CustomFile } from '../../../../shared/types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getFileInContainer } from '../../../../shared/utils/gallery/getFileInContainer';
import { GallerySwiperIdeaFilesWrp, SwiperIdeaFileSlide } from './styled';
import { useCenterSlidePercentage } from '../../hooks/useCenterSlidePercentage';

type GallerySwiperIdeaFilesProps = {
  ideaFiles: CustomFile[];
}

export const GallerySwiperIdeaFiles: React.FC<GallerySwiperIdeaFilesProps> = ({
  ideaFiles,
}) => {
  const { centerSlidePercentage } = useCenterSlidePercentage();
  const slides = useMemo(() => ideaFiles.map((item) => {
    const file = getFileInContainer(item);
    return (
      <SwiperIdeaFileSlide key={item.id}>
        {file}
      </SwiperIdeaFileSlide>
    );
  }), [ideaFiles]);

  return (
    <GallerySwiperIdeaFilesWrp>
      <Carousel
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        centerMode
        centerSlidePercentage={centerSlidePercentage}
      >
        {slides}
      </Carousel>
    </GallerySwiperIdeaFilesWrp>
  );
};
