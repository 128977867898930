import { AxiosResponse } from 'axios';
import { ResetPassword, SignIn, SignUpRequest } from '../../shared/types';
import { instance } from '../base/instance';

const authPath = 'auth/';
const signUpPath = `${authPath}signup/`;
const resetPasswordPath = `${authPath}resetpw/`;

export const creatorSignUp = {
  signUpInitial: (email: string): Promise<AxiosResponse<any>> => instance.post(`${signUpPath}initial`, { email }),
  signUpInitialResend: (email: string): Promise<AxiosResponse<any>> => instance.post(`${signUpPath}resend`, { email }),
  signUp: (data: SignUpRequest): Promise<AxiosResponse<any>> => instance.post(`${signUpPath}confirm`, data),
};

export const creatorSignIn = {
  signIn: (data: SignIn): Promise<AxiosResponse<any>> => instance.post(`${authPath}login`, data),
  resetPasswordInitial: (email: string): Promise<AxiosResponse<any>> => instance.post(`${resetPasswordPath}/initial`, { email }),
  resetPassword: (data: ResetPassword): Promise<AxiosResponse<any>> => instance.post(`${resetPasswordPath}/confirm`, data),
};

export const creatorLogOut = {
  logout: () => instance.get(`${authPath}logout`),
};
