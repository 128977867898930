import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, GalleryFile, GalleryIdea } from '../../../../shared/types';
import { Favourites } from '../../../../shared/types/client';
import {
  fetchAddToFavourites,
  fetchGetClientFavourites,
  fetchGetTotalNumberFiles,
  fetchRemoveFromFavourites,
} from './cartClientThunk';

type initialState = {
  totalFiles: number;
  files: (GalleryFile | GalleryIdea)[];
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  totalFiles: 0,
  files: [],
  loading: false,
  error: null,
};

const favouritesClientSlice = createSlice({
  name: 'favouritesClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientFavourites.fulfilled,
      (state, action: PayloadAction<Favourites>) => {
        state.totalFiles = action.payload.totalFiles;
        state.error = null;
        state.loading = false;
        state.files = action.payload.files;
      },
    );
    builder.addCase(
      fetchGetClientFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetClientFavourites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAddToFavourites.fulfilled,
      (state, action: PayloadAction<Favourites>) => {
        state.totalFiles = action.payload.totalFiles;
        state.error = null;
        state.loading = false;
        state.files = action.payload.files;
      },
    );
    builder.addCase(
      fetchAddToFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchAddToFavourites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetTotalNumberFiles.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.totalFiles = action.payload.totalFiles;
        state.error = null;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchGetTotalNumberFiles.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetTotalNumberFiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchRemoveFromFavourites.fulfilled,
      (state, action: PayloadAction<Favourites>) => {
        state.totalFiles = action.payload.totalFiles;
        state.error = null;
        state.loading = false;
        state.files = action.payload.files;
      },
    );
    builder.addCase(
      fetchRemoveFromFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchRemoveFromFavourites.pending, (state) => {
      state.loading = true;
    });
  },
});

export default favouritesClientSlice.reducer;
