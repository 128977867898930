import React from 'react';
import { RoundAvatar } from '../../../shared/components/RoundAvatar/RoundAvatar';
import { GalleryComment } from '../../../shared/types';
import {
  CommentDateAndTimeWrapper,
  CommentItemContainer,
  CommentMessageAndDateWrapper,
  CommentMessageWrapper,
} from './styled';
import {
  getFormattedDateOrDateName,
  getFormattedTime,
} from '../../../shared/utils';
import { LittleGreyText } from '../../../shared/styles';

type CommentItemProps = {
  comment: GalleryComment;
};

export const CommentItem: React.FC<CommentItemProps> = ({ comment }) => {
  const dateFormat = 'mm/dd/yyyy';
  const formattedDate = getFormattedDateOrDateName(
    comment.date,
    dateFormat,
    true,
  );
  const formattedTime = getFormattedTime(comment.date);

  return (
    <CommentItemContainer>
      <RoundAvatar
        userName={comment.user.name}
        urlPhoto={comment.user.userPhoto}
        size="medium"
      />
      <CommentMessageAndDateWrapper>
        <CommentMessageWrapper>{comment.message}</CommentMessageWrapper>
        <CommentDateAndTimeWrapper>
          <LittleGreyText style={{ marginRight: '8px' }}>{formattedTime}</LittleGreyText>
          <LittleGreyText>{formattedDate}</LittleGreyText>
        </CommentDateAndTimeWrapper>
      </CommentMessageAndDateWrapper>
    </CommentItemContainer>
  );
};
