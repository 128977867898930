import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const GalleryFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 108px;
  margin-bottom: -25px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0px 16px;
    margin-top: 52px;
    margin-bottom: -15px;
  }
`;

export const GalleryFooterPoweredBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
  color: ${variables.textGrey};

  & > svg {
    width: 120px;
    margin-left: 8px;
  }
`;
