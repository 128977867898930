import React, { ReactNode, useContext } from 'react';
import {
  FilesOrIdeas,
  GalleryData,
  GalleryFile,
  GalleryIdea,
} from '../../../../shared/types';
import { PositionedElementWrapper } from './styled';
import { ExpandAndShrinkFileButton } from './ExpandAndShrinkFileButton';
import { GradientCoverWithActions } from '../../../../shared/components/GradientCoverWithActions/GradientCoverWithActions';
import { InvisibleOpenFileButton } from '../../../../shared/components/InvisibleOpenFileButton/InvisibleOpenFileButton';
import { getIdeaCoverOrFile } from '../../../../shared/utils';
import { InstantlyDownloadableOrLike } from './InstantlyDownloadableOrLike';
import { ClientStateContext } from '../../../Client/context/ClientStateContext';
import { CreatorStateContext } from '../../../Creator/context/CreatorStateContext';

type ElementsOverFileProps = {
  sectionId: string;
  id: string;
  fileType: FilesOrIdeas;
  fullWidth: boolean;
  children: ReactNode;
  instantlyDownloadable: boolean;
  allowCommenting: boolean;
  isFileLiked?: boolean;
  isFileInCart?: boolean;
  file: GalleryFile | GalleryIdea;
  fileIndex?: number;
  gallery: GalleryData;
};

export const ElementsOverFile: React.FC<ElementsOverFileProps> = ({
  sectionId,
  id,
  fileType,
  fullWidth,
  children,
  instantlyDownloadable,
  allowCommenting,
  isFileLiked,
  isFileInCart,
  file,
  fileIndex,
  gallery,
}) => {
  const { isClientSignIn } = useContext(ClientStateContext);
  const { isCreatorSignIn } = useContext(CreatorStateContext);

  return (
    <>
      {!isCreatorSignIn && (
        <>
          <InvisibleOpenFileButton gallery={gallery} file={file} fileIndex={fileIndex} />
          <GradientCoverWithActions
            file={getIdeaCoverOrFile(file)}
            sectionId={sectionId}
            id={id}
            isAllowCommenting={!!allowCommenting}
            isInstantlyDownloadable={!!instantlyDownloadable}
            isFileLiked={!!isFileLiked}
            isFileInCart={!!isFileInCart}
            disabledAll={!(isClientSignIn || isCreatorSignIn)}
          />
        </>
      )}
      {children}
      <InstantlyDownloadableOrLike
        instantlyDownloadable={!!instantlyDownloadable}
        disabledAll={!isClientSignIn}
        fileId={id}
      />
      <PositionedElementWrapper>
        {isCreatorSignIn && (
          <ExpandAndShrinkFileButton
            sectionId={sectionId}
            id={id}
            fileType={fileType}
            fullWidth={fullWidth}
          />
        )}
      </PositionedElementWrapper>
    </>
  );
};
