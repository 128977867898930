import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { DemoGalleryButton } from './DemoGalleryButton';
import demo1 from '../../../../assets/image/landing/demoGalleries/demo1.png';
import demo2 from '../../../../assets/image/landing/demoGalleries/demo2.png';
import demo3 from '../../../../assets/image/landing/demoGalleries/demo3.png';

export const DemoGalleries: React.FC = () => {
  const { t } = useTranslation('landing');

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item md={8}>
        <DemoGalleryButton galleryDesignNumber={1} title={t('demoGalleries.demo1')} thumbnail={demo1} />
      </Grid>
      <Grid item md={8}>
        <DemoGalleryButton galleryDesignNumber={5} title={t('demoGalleries.demo2')} thumbnail={demo2} />
      </Grid>
      <Grid item md={8}>
        <DemoGalleryButton galleryDesignNumber={6} title={t('demoGalleries.demo3')} thumbnail={demo3} />
      </Grid>
    </Grid>
  );
};
