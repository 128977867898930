import React from 'react';
import { TabPanelColumnWrapper } from '../styled';
import { Payments } from './Payments/Payments';
import { RecentBillingHistory } from './RecentBillingHistory';
import { Storage } from './Storage';
import { Subscription } from './Subscription';
import { UpgradeToPremium } from './UpgradeToPremium';

export const UsageAndBilling: React.FC = () => (
  <TabPanelColumnWrapper>
    <Storage />
    <Subscription />
    <Payments />
    <RecentBillingHistory />
    <UpgradeToPremium />
  </TabPanelColumnWrapper>
);
