import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const FilterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > button {
    &:not(:first-of-type) {
      margin-left: 7px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: flex-start;

    & > button {
      &:not(:first-of-type) {
        margin-left: 0px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

export const LineGrey = styled.div`
  width: 100%;
  padding-bottom: 1px;
  background: #d7d7d7;
  margin: 28px 0;
`;

export const SectionTitleLarge = styled.div`
  font-size: 28px;
  line-height: 26px;
  color: ${variables.colorBlack};
`;

export const GallerySectionHeader = styled.div`
  margin-bottom: 30px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0px 16px;
    margin-bottom: 20px;
  }
`;

export const GallerySectionTitle = styled.div`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const GallerySectionFreeFiles = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const SectionTitleSelectWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const FiltersAndFreeFilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NavigationContainerMobile = styled.div<{isFixed: boolean}>`
  padding: 0px 16px;
  visibility: hidden;
  display: none;
  box-sizing: border-box;
  width: 100%;
  ${({ isFixed }) => (isFixed ? 'position: fixed; top: 0; bottom: 0; z-index: 1000;' : '')}

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    visibility: visible;
    display: flex;
  }
`;

export const NavigationWrapperMobile = styled.div<{isFixed: boolean}>`
  background: ${variables.colorGreyLight};
  box-sizing: border-box;
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationLinkButtons = styled.div`
  display: none;
  flex-direction: row;
  
  & > * {
    margin-left: 12px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: flex;
  }
`;

export const SectionItemItemsList = styled.div`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0px 16px;
  }
`;
