import { isFileAlignedByHeight } from '../gallery/isFileAlignedByHeight';

export const getObjectFit = (
  fileUrl: string,
  cropperWidth: number,
  cropperHeight: number,
): string => {
  const file = new Image();
  file.src = fileUrl || '';

  if (isFileAlignedByHeight(cropperWidth, cropperHeight, file?.width, file?.height)) {
    return 'vertical-cover';
  }
  return 'horizontal-cover';
};
