import React from 'react';
import { CommentAreaWrapper } from './styled';
import { CommentItem } from './CommentItem';
import { CommentItemWithTextarea } from './CommentItemWithTextarea';
import { GalleryComment } from '../../../shared/types';

type CommentAreaProps = {
  comments: GalleryComment[];
}

export const CommentArea: React.FC<CommentAreaProps> = ({
  comments,
}) => (
  <CommentAreaWrapper>
    {comments?.map((item) => <CommentItem key={item.id} comment={item} />)}
    <CommentItemWithTextarea urlUserAvatar="" />
  </CommentAreaWrapper>
);
