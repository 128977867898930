import React, { useContext } from 'react';
import { BasicDocumentCard } from '../../../../shared/components/Files/BasicDocumentCard/BasicDocumentCard';
import { InvisibleOpenFileButton } from '../../../../shared/components/InvisibleOpenFileButton/InvisibleOpenFileButton';
import { Filters, GalleryData, GalleryFile } from '../../../../shared/types';
import { isArrayEmpty } from '../../../../shared/utils';
import { ClientStateContext } from '../../../Client/context/ClientStateContext';
import { InstantlyDownloadableOrLike } from '../ImagesAndVideosList/InstantlyDownloadableOrLike';
import { DocumentsListWrapper } from '../styled';
import { PositionedDocItems } from './styled';

type DocumentsListProps = {
  chosenFilters: Filters[];
  arrDocuments: GalleryFile[];
  gallery: GalleryData;
};

export const DocumentsList: React.FC<DocumentsListProps> = ({
  chosenFilters,
  arrDocuments,
  gallery,
}) => {
  const { isClientSignIn } = useContext(ClientStateContext);

  return (
    <DocumentsListWrapper>
      {isArrayEmpty(chosenFilters) || chosenFilters.includes('other')
        ? arrDocuments?.map((item, index) => (
          <div key={item.id}>
            <PositionedDocItems>
              <InvisibleOpenFileButton
                gallery={gallery}
                file={item}
                key={item.id}
                fileIndex={index}
              />
              <div id="doc-like">
                <InstantlyDownloadableOrLike
                  instantlyDownloadable={false}
                  disabledAll={!isClientSignIn}
                  fileId={item.id}
                  iconsColor="grey"
                />
              </div>
              <BasicDocumentCard file={item} />
            </PositionedDocItems>
            <PositionedDocItems>
              <InvisibleOpenFileButton
                gallery={gallery}
                file={item}
                key={item.id}
                fileIndex={index}
              />
              <div id="doc-like">
                <InstantlyDownloadableOrLike
                  instantlyDownloadable={false}
                  disabledAll={!isClientSignIn}
                  fileId={item.id}
                  iconsColor="grey"
                />
              </div>
              <BasicDocumentCard file={item} />
            </PositionedDocItems>
          </div>
        ))
        : null}
    </DocumentsListWrapper>
  );
};
