import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreatorStateContext } from '../context/CreatorStateContext';
import { useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import { CreatorOnboardingContext } from '../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../context/CreatorOnboardingStepIndexContext';
import { Creator } from './Creator';
import { fetchCurrentUserCreator } from '../redux/userCreator/creator/creatorThunk';
import { MainLoader } from '../../../shared/components/Loaders/MainLoader/MainLoader';

export const CreatorContainer: React.FC = () => {
  const [isLoadingComponent, setIsLoadingComponent] = useState<boolean>(true);
  const [isCreatorSignIn, setIsCreatorSignIn] = useState<boolean>(false);
  const [isStudy, setIsStudy] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const dispatch = useDispatch();

  const currentCreatorIsSignIn = useTypedSelectorCreator(
    (state) => state.signInCreator.isSignIn,
  );
  const isSignInAfterSigningUp = useTypedSelectorCreator(
    (state) => state.signUpCreator.isSignUp,
  );
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);
  const isLoadingCurrentUser = useTypedSelectorCreator(
    (state) => state.creator.loading,
  );

  useEffect(() => {
    dispatch(fetchCurrentUserCreator());
  }, [currentCreatorIsSignIn, isSignInAfterSigningUp]);

  useEffect(() => {
    const isSignIn = currentCreatorIsSignIn || isSignInAfterSigningUp || !!currentUser;
    setIsCreatorSignIn(isSignIn);
  }, [currentUser, isLoadingCurrentUser]);

  useEffect(() => {
    if (!isLoadingCurrentUser) {
      setIsLoadingComponent(false);
    }
  }, [isCreatorSignIn, isLoadingCurrentUser]);

  if (isLoadingCurrentUser || isLoadingComponent) {
    return <MainLoader />;
  }

  return (
    <CreatorStateContext.Provider
      value={{ isCreatorSignIn, setIsCreatorSignIn }}
    >
      <CreatorOnboardingContext.Provider value={{ isStudy, setIsStudy }}>
        <CreatorOnboardingStepIndexContext.Provider
          value={{ stepIndex, setStepIndex }}
        >
          <Creator currentUser={currentUser} />
        </CreatorOnboardingStepIndexContext.Provider>
      </CreatorOnboardingContext.Provider>
    </CreatorStateContext.Provider>
  );
};
