import { useFormikContext } from 'formik';
import React from 'react';
import { ShrinkIcon, ExpandIcon } from '../../../../shared/icons';
import {
  FilesOrIdeas, GalleryData, GalleryFile, GalleryIdea, GallerySection,
} from '../../../../shared/types';
import { getIndex } from '../../../../shared/utils/createGallery';
import { ExpandAndShrinkWrapper } from './styled';

type ExpandAndShrinkFileButtonProps = {
  sectionId: string;
  id: string;
  fileType: FilesOrIdeas;
  fullWidth: boolean;
};

export const ExpandAndShrinkFileButton: React.FC<ExpandAndShrinkFileButtonProps> = ({
  sectionId,
  id,
  fileType,
  fullWidth,
}) => {
  const { values, setFieldValue } = useFormikContext<GalleryData>();
  const indexSection = getIndex<GallerySection>(values.sections, sectionId);

  const handleExpandShrink = (itemId: string, type: FilesOrIdeas) => () => {
    const indexFile = getIndex<GalleryFile | GalleryIdea>(
      values.sections[indexSection][type],
      itemId,
    );
    const isFullWidth = !values.sections[indexSection][type][indexFile].fullWidth;

    setFieldValue(
      `sections[${indexSection}].${type}[${indexFile}].fullWidth`,
      isFullWidth,
    );
  };

  return (
    <ExpandAndShrinkWrapper
      type="button"
      onClick={handleExpandShrink(
        id,
        fileType,
      )}
    >
      {fullWidth ? <ShrinkIcon /> : <ExpandIcon />}
    </ExpandAndShrinkWrapper>
  );
};
