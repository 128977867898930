import { Grid } from '@material-ui/core';
import { FormikErrors, useFormikContext, FormikTouched } from 'formik';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFile, GalleryData, GalleryIdea } from '../../../../../../../../shared/types';
import {
  CoverContainer,
  CoverWrapper,
  MiniFileCardsContainer,
} from './styled';
import { PlusIcon } from '../styled';
import { MiniFileCard } from '../../../../../../../../shared/components/GalleryCreator/MiniFileCard';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';
import { getFileInContainer } from '../../../../../../../../shared/utils/createGallery';
import { MiniChooseFileCard } from '../../../../../../../../shared/components/GalleryCreator/styled';
import { useSendAndSetFiles } from '../../../../../../hooks/useSendAndSetFiles';

type AttachedFilesProps = {
  idea: GalleryIdea;
  ideaField: string;
  errors: FormikErrors<GalleryIdea> | null;
  touched: FormikTouched<GalleryIdea> | null;
};
const arr = [null, null, null];

export const AttachedFiles: React.FC<AttachedFilesProps> = ({
  idea,
  ideaField,
  errors,
  touched,
}) => {
  const { t } = useTranslation('createNewGallery');
  const [isChecked, setIsChecked] = useState<string | null>(idea?.cover?.id || '');
  const { setFieldValue, values } = useFormikContext<GalleryData>();
  const { sendAndSetFiles } = useSendAndSetFiles();

  const arrFiles = useMemo(
    () => {
      const endIndex = idea.files.length > 3 ? 0 : 3 - idea.files.length;
      return [...idea?.files, ...arr.slice(0, endIndex)];
    },
    [idea?.files],
  );

  const handleClick = (files: File[]) => {
    sendAndSetFiles(`${ideaField}.files`, idea?.files, files);
  };

  const handleSetCoverFile = (e: ChangeEvent<HTMLInputElement>, file: CustomFile) => {
    setIsChecked(e.target.id);
    if (typeof file !== 'number') setFieldValue(`${ideaField}.cover`, file);
  };

  const coverFile = useMemo(
    () => getFileInContainer(idea?.cover),
    [idea?.cover],
  );

  return (
    <Grid container item sm={4} justifyContent="flex-end" alignContent="flex-start">
      <CoverContainer>
        <CoverWrapper>
          {idea.cover ? coverFile : <p>{t('secondStep.chooseIdeaCover')}</p>}
        </CoverWrapper>
        <ErrorMessage>
          {errors?.cover && touched?.cover ? errors?.cover : null}
        </ErrorMessage>
      </CoverContainer>
      <MiniFileCardsContainer width={((arrFiles?.length + 1) * 122) / 2}>
        <div>
          <MiniChooseFileCard>
            <input
              name={`${ideaField}.cover`}
              type="file"
              multiple
              onChange={(e) => handleClick(Array.from(e.target.files || []))}
            />
            <PlusIcon theme="small" />
          </MiniChooseFileCard>
          {arrFiles.map((item) => (
            <MiniFileCard
              key={typeof item === 'number' ? item : item?.id}
              file={item}
              handleCheck={handleSetCoverFile}
              isChecked={typeof item !== 'number' && isChecked === item?.id}
              id={item?.id}
            />
          ))}
        </div>
      </MiniFileCardsContainer>
    </Grid>
  );
};
