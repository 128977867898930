import React from 'react';
import { RoundAvatar } from '../../../../../../shared/components/RoundAvatar/RoundAvatar';
import { QuoteIcon } from '../../../../../../shared/icons/Landing';
import {
  LandingSwiperReviewsSlideContainer,
  SwiperReviewsIconWrapper,
  SwiperReviewsSlideComment,
  SwiperReviewsSlideUserInfo,
  SwiperReviewsSlideUserWrp,
  SwiperReviewsText,
  SwiperReviewsTitle,
} from './styled';

type LandingSwiperReviewsSlideProps = {
  userImage: string;
  userName: string;
  profession: string;
  comment: string;
};

export const LandingSwiperReviewsSlide: React.FC<
  LandingSwiperReviewsSlideProps
> = ({
  userImage, userName, profession, comment,
}) => (
  <LandingSwiperReviewsSlideContainer
    id="slide-container"
  >
    <SwiperReviewsSlideUserWrp id="slide-user-wrapper">
      <div id="slide-avatar">
        <RoundAvatar
          size="large"
          urlPhoto={userImage}
          userName={userName}
        />
      </div>
      <SwiperReviewsSlideUserInfo>
        <SwiperReviewsTitle>{userName}</SwiperReviewsTitle>
        <SwiperReviewsText>{profession}</SwiperReviewsText>
      </SwiperReviewsSlideUserInfo>
    </SwiperReviewsSlideUserWrp>
    <SwiperReviewsIconWrapper id="slide-icon-wrapper">
      <QuoteIcon />
    </SwiperReviewsIconWrapper>
    <SwiperReviewsSlideComment id="slide-comment-wrapper">
      <SwiperReviewsText style={{ textAlign: 'center' }}>
        {comment}
      </SwiperReviewsText>
    </SwiperReviewsSlideComment>
  </LandingSwiperReviewsSlideContainer>
);
