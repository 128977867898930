import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumBlackText, SimpleBlackText } from '../../../../../../../shared/styles';
import { DevicesAndBrowsers as DAndB } from '../../../../../../../shared/types';
import { MarginTopWrapper } from '../styled';
import { DevicesAndBrowsersRow } from './DeviceAndBrowsersRow';
import {
  DevicesAndBrowsersTable, DevicesAndBrowsersWrapper,
} from './styled';

export const fakeDevicesAndBrowsers: DAndB[] = [
  {
    id: 'Mac',
    device: 'Mac 10, Chrome 95',
    lastActive: '10 hours ago',
    ipAddress: '103.100.225.170',
  },
  {
    id: 'Lenovo',
    device: 'Lenovo Yoga 535-IKB, Chrome 95',
    lastActive: '1 week ago',
    ipAddress: '103.100.225.170',
  },
];

export const DevicesAndBrowsers: React.FC = () => {
  const { t } = useTranslation('settings');
  const [data, setData] = useState(fakeDevicesAndBrowsers);

  const handleDelete = (id: string) => {
    const newData = fakeDevicesAndBrowsers.filter((item) => item.id !== id);
    setData(newData);
  };

  return (
    <MarginTopWrapper style={{ width: '100%' }}>
      <DevicesAndBrowsersWrapper style={{ width: '100%' }}>
        <div><SimpleBlackText>{t('devicesBrowsers')}</SimpleBlackText></div>
        <DevicesAndBrowsersTable>
          <Grid container spacing={2} sm={10}>
            <Grid container item sm={12}>
              <Grid item sm={5}>
                <MediumBlackText fontWeight={500}>{t('device')}</MediumBlackText>
              </Grid>
              <Grid item sm={3}>
                <MediumBlackText fontWeight={500}>
                  {t('lastActive')}
                </MediumBlackText>
              </Grid>
              <Grid item sm={3}>
                <MediumBlackText fontWeight={500}>{t('IPAddress')}</MediumBlackText>
              </Grid>
              <Grid item sm={1} />
            </Grid>
            {data.map((item) => (
              <DevicesAndBrowsersRow row={item} handleDelete={handleDelete} />
            ))}
          </Grid>
        </DevicesAndBrowsersTable>
      </DevicesAndBrowsersWrapper>
    </MarginTopWrapper>
  );
};
