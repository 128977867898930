import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import waves from '../../../../../assets/image/landing/pricing/waves.png';

export const LandingPriceExplanationsWrp = styled.div`
  background-image: url(${waves});
  background-position: 0% 0px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 134px;
`;

export const LandingPriceExplanationsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
`;

export const LandingPriceExplanationsItemTitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.colorBlack};
  margin-bottom: 40px;
`;

export const LandingPriceExplanationsItemList = styled.div`
  width: 100%;
`;

export const LandingPriceExplanationsItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.colorBlack};
  margin-bottom: 8px;
`;

export const LandingPricingIcon = styled.svg`
  display: flex;
  width: 6px;
  height: 6px;
  min-height: 6px;
  min-width: 6px;
  border-radius: 50%;
  background: ${variables.colorPink};
`;

export const LandingPriceExplanationsLists = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 429px;

  & > div {
    &:last-child {
      margin-top: 84px;
    }
  }
`;
