import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { AfterAddToCart } from '../../../../shared/styles/Elements';
import { fetchAddToCart } from '../../../Client/redux/cart/cartClientThunk';

type ReturnObj = {
  isInCart: boolean;
  handleClickCart: (id: string, sectionId?: string) => () => void;
  hoverElement: ReactElement;
  isVisible: boolean;
};

export const useAddToCart = (isFileInCart: boolean): ReturnObj => {
  const { t } = useTranslation('gallery');
  const [isInCart, setIsInCart] = useState(isFileInCart);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const showElement = () => {
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 3000);
  };

  const handleClickCart = useCallback(
    (id: string, sectionId?: string) => () => {
      if (!isInCart && id) {
        dispatch(fetchAddToCart({ sectionId, fileId: id }));
        setIsInCart(true);
        showElement();
      }
    },
    [],
  );

  return {
    isInCart,
    handleClickCart,
    hoverElement: (
      <AfterAddToCart isVisible={isVisible}>
        <Trans t={t} i18nKey="moreFreeFiles">
          You have
          {' '}
          {{ files: 1 }}
          {' '}
          more free files in this section!
        </Trans>
      </AfterAddToCart>
    ),
    isVisible,
  };
};
