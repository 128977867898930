import * as React from 'react';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { AccordionExpandIconWrapper } from './styled';
import { PlusCssIcon } from '../../../../../../../shared/styles/Elements';

export const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={(
      <AccordionExpandIconWrapper>
        <PlusCssIcon color="pink" />
      </AccordionExpandIconWrapper>
    )}
    {...props}
  />
))(() => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    '& > div': {
      '& div': {
        '&:before': {
          transition: 'transform 0.3s',
          transform: 'rotate(90deg)',
        },
      },
      boxShadow: 'inset -2px -2px 4px #FFFFFF, inset 0.8px 0.8px 2px #E7E7E7',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(0deg)',
    '& > div': {
      '& div': {
        '&:before': {
          transition: 'transform 0.3s',
          transform: 'rotate(0deg)',
        },
      },
    },
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 0,
  },
}));
