import React, { ReactNode, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShareWindow } from '../../ShareWindow/ShareWindow';

type ReturnObj = {
  handleOpenClose: () => void;
  shareWindow: ReactNode;
}

export const useShare = (): ReturnObj => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenClose = useCallback(() => {
    setIsOpen((prev) => !prev);
  },
  []);

  return ({
    shareWindow: <ShareWindow isOpen={isOpen} setIsOpen={setIsOpen} />,
    handleOpenClose,
  });
};
