import styled from '@emotion/styled';

export const LoaderWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  animation: animation-61bdi0 1.4s linear infinite;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-61bdi0;

  & .wrapper {
    height: 100vh;
    max-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  & .message {
    text-align: center;
    margin-top: 0.25em;
  }

  & #spinner {
    width: 40px;

    & #sGD{
      & stop {
        stop-color: #F180B2;
      }
    } 

    & .path-solid {
      stroke: #F180B2;
    }

    &.path-gradient {
    }
  }
`;
