import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeCommentsRequests } from '../../../../api/gallery/comments/fakeComment';

export const fetchGetComments = createAsyncThunk(
  'comments/fetchGetComments',
  async () => {
    try {
      const response = await fakeCommentsRequests.getComments();
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const fetchAddComment = createAsyncThunk(
  'comments/fetchAddComment',
  async (data: { idUser: string, comment: string }) => {
    try {
      const response = await fakeCommentsRequests.addComment(data);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
