import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const SpotsPopupContainer = styled.div`
  border: 1px solid rgba(235, 235, 235, 0.8);
  border-radius: 16px;
  background: ${variables.colorGreyLight};
  width: 270px; 
  max-width: 270px;
  min-width: 270px;
  margin-right: 40px;
  box-sizing: border-box;
  position: sticky;
  bottom: 39px;
  left: 100%;
  z-index: 1000;
  box-shadow: 0px 5px 10px #E8E8E8;
`;

export const SpotsPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  position: relative;
  box-sizing: border-box;
  padding: 32px 20px 24px;
`;

export const LandingProgressLinesContainer = styled.div`
  width: 230px;
  min-width: 230px;
  max-width: 230px;
  margin-bottom: 24px;
`;

export const LandingProgressContainer = styled.div`
  margin-top: 12px;
  width: 100%;
  max-width: 100%;
  background: ${variables.colorGreyLight};
  box-shadow: 2px 2px 5px #D8D8D8, inset -5px -5px 10px #FFFFFF, inset 2px 2px 5px #E7E7E7;
  border-radius: 43px;
  padding: 4px 2px;
  box-sizing: border-box;
`;

export const SpotsPopupCloseWrapper = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0px; 
`;
