import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MediumGreyText,
  SimpleBlackText,
  WhiteButtonWithoutShadow,
} from '../../../../../../../../../shared/styles';
import { GalleryComment } from '../../../../../../../../../shared/types';
import { getFormattedDateOrDateName } from '../../../../../../../../../shared/utils';
import { FileAndCommentsWindow } from '../../../../../../../../GalleryView/CommentArea/FileAndCommentsWindow';
import {
  CommentItemContainer,
  CommentItemInfoWrapper,
  CommentItemFileWrapper,
  CommentItemReplyButton,
  CommentItemMessage,
} from './styled';

type CommentsItemProps = {
  comment: GalleryComment;
};

export const CommentsItem: React.FC<CommentsItemProps> = ({ comment }) => {
  const { t } = useTranslation('basicComponents');
  const [isOpenComment, setIsOpenComment] = useState(false);
  const dateFormat = 'mm/dd/yyyy';

  const handleClose = () => {
    setIsOpenComment(false);
  };
  const handleOpen = () => {
    setIsOpenComment(true);
  };

  const {
    file, user, message, date: time,
  } = comment;

  const formattedDate = getFormattedDateOrDateName(time, dateFormat, true);

  return (
    <>
      <CommentItemContainer>
        <CommentItemInfoWrapper>
          <SimpleBlackText fontWeight={600}>{user.email}</SimpleBlackText>
          <CommentItemMessage>{message}</CommentItemMessage>
          <MediumGreyText>{formattedDate}</MediumGreyText>
        </CommentItemInfoWrapper>
        <CommentItemFileWrapper>
          <CommentItemReplyButton>
            <WhiteButtonWithoutShadow type="button" onClick={handleOpen}>
              {t('buttons.reply')}
            </WhiteButtonWithoutShadow>
          </CommentItemReplyButton>
          <img src={file} alt={user.name} />
        </CommentItemFileWrapper>
      </CommentItemContainer>
      <FileAndCommentsWindow
        isOpen={isOpenComment}
        handleClose={handleClose}
        comments={[comment]}
        file={{
          name: file, url: file, type: 'image', id: file,
        }}
      />
    </>
  );
};
