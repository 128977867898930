import styled from '@emotion/styled';
import { createTheme, Grid } from '@material-ui/core';
import * as variables from '../../../constants/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 250,
      sm: 500, // mobile
      md: 786,
      lg: 1000,
      xl: 1400, // desktop
    },
  },
});
// margin-left: calc(100vw - 100%);

export const GlobalStyle = `
  html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  body {
    background: ${variables.colorGreyLight};
    background-color: ${variables.colorGreyLight};
    margin: 0;
    padding: 0;
    font-family: ${variables.mainFont};
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-weight: normal;
    font-style: normal;

    & #root {
      box-sizing: border-box;
      background-color: ${variables.colorGreyLight};
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;  
      min-height: 100vh;
      
      & > div {
        box-sizing: border-box;

        height: 100%;  
      }
    }
    & p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    & #modal-root {
      position: relative;
      z-index: 999;
      & > div {
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    & a {
      outline: none;
      transition: 0.3s;
      text-decoration: none;
      cursor: pointer;
      color: ${variables.textBlack};
    }
    & button {
      outline: none;
      cursor: pointer;
      border: none;
      background: transparent;
      font-family: ${variables.mainFont};
    }
    & input, & textarea {
      outline: none;
      font-family: ${variables.mainFont};
      display: flex;
      align-items: center;
      font-size: 15px;
      margin: 0;
      color: ${variables.textGrey};
    }
    & svg {
      color: ${variables.textGrey};
    }
}
& img {
  max-width: 100%;
}
`;

export const GridFullHeight = styled(Grid)`
  height: 100% !important;
  min-height: 100% !important;
  min-height: 100hv !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
export const GridItemFullHeight = styled(Grid)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;
