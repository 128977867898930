import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, SupportQuestions } from '../../../../shared/types';
import { fetchGetSupport } from './supportThunk';

type InitialState = {
  loading: boolean;
  error: ErrorType;
  questions: SupportQuestions[];
};

export const initialState: InitialState = {
  questions: [],
  loading: true,
  error: null,
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetSupport.fulfilled, (state, action) => {
      state.questions = action.payload.questions;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchGetSupport.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetSupport.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default supportSlice.reducer;
