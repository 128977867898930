import React from 'react';
import { ChangeColorButton } from '../../../../shared/components/GradientCoverWithActions/styled';
import { HeartIcon, InstantDownload } from '../../../../shared/icons';
import { BaseColors } from '../../../../shared/types';
import { getBaseColor } from '../../../../shared/utils/style/getBaseColor';
import { useLike } from '../../hooks/buttons/useLike';
import { LikeAndInstDownloadableWrapper } from './styled';

type InstantlyDownloadableOrLikeProps = {
  instantlyDownloadable: boolean;
  disabledAll: boolean;
  fileId: string;
  iconsColor?: BaseColors;
};

export const InstantlyDownloadableOrLike: React.FC<
  InstantlyDownloadableOrLikeProps
> = ({
  instantlyDownloadable, disabledAll, fileId, iconsColor,
}) => {
  const { isLiked, handleLike } = useLike(false);

  return (
    <LikeAndInstDownloadableWrapper>
      <span id="inst-downloadable">
        {instantlyDownloadable && <InstantDownload />}
      </span>
      <LikeAndInstDownloadableWrapper>
        <ChangeColorButton
          isClicked={isLiked}
          disabled={!!disabledAll}
          type="button"
          onClick={handleLike(fileId)}
          id="client-like-button-step"
          baseColor={iconsColor ? getBaseColor(iconsColor) : ''}
        >
          <HeartIcon />
        </ChangeColorButton>
      </LikeAndInstDownloadableWrapper>
    </LikeAndInstDownloadableWrapper>
  );
};
