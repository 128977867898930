import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../GlobalContainer/styled';

export const TextCard = styled.div<{
  withChildren: boolean;
  inheritSize?: boolean;
}>`
  margin-right: 30px;
  width: ${(props) => (props.inheritSize ? '100%' : '82px')};
  height: ${(props) => (props.inheritSize ? '100%' : '82px')};
  font-size: 6.73684px;
  line-height: 140%;
  padding: 10px;
  border: 1px solid rgba(235, 235, 235, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${variables.textBlack};
  margin-bottom: 25px;
  position: relative;
  background: #f5f5f5;

  & span {
    word-wrap: break-word;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
    display: -moz-box;
    display: -webkit-box;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
  }

  & button {
    position: absolute;
    cursor: pointer;
    display: none;
    color: ${variables.textBlack};
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: inherit;
    background: ${variables.colorGreyLight};
    justify-content: center;
    align-items: center;

    & > svg {
      color: black;
      z-index: 9999;
    }
  }
  ${(props) => props.withChildren
    && `&:hover {
      color: transparent;
  
      & button {
        display: flex;
      }
    }`}

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-right: 8px;
    margin-bottom: 8px;
    width: ${(props) => (props.inheritSize ? '100%' : '150px')};
    height: ${(props) => (props.inheritSize ? '100%' : '150px')};
    border-radius: 16px;
    font-size: 10px;

    & > svg {
      width: 35px;
      height: 40px;
    }
  }
`;
