import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import {
  SixthMediumTextTablet,
  SixthTitleTablet,
  ImageContainerSixthTablet,
  SixthInfoCardCoverTablet,
  WrapperMarginBTablet,
} from './styled';
import { GalleryCover } from '../../../../shared/types';
import { TabletWrapper } from '../../TabletWrapper';
import { useGalleryContent } from '../../hooks/useGalleryContent';

export const GalleryDemoSixthTablet: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const refCardTablet = useRef<HTMLDivElement>(null);

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'tablet',
    galleryNumber: 'sixth',
  });
  return (
    <TabletWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingLeft: '13px', paddingRight: '13px', height: '100%' }}
      >
        <Grid item sm={12}>
          <ImageContainerSixthTablet>
            {data.file}
            <SixthInfoCardCoverTablet
              ref={refCardTablet}
              currentHeight={refCardTablet?.current?.offsetHeight || 0}
            >
              <SixthTitleTablet>{data.name}</SixthTitleTablet>
              <WrapperMarginBTablet>
                <SixthMediumTextTablet>{data.message}</SixthMediumTextTablet>
                <SixthMediumTextTablet>{data.userName}</SixthMediumTextTablet>
              </WrapperMarginBTablet>
              <SixthMediumTextTablet>{data.date}</SixthMediumTextTablet>
            </SixthInfoCardCoverTablet>
          </ImageContainerSixthTablet>
        </Grid>
      </Grid>
    </TabletWrapper>
  );
};
