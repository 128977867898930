import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonMoveToTop } from '../../shared/components/ButtonMoveToTop/ButtonMoveToTop';
import { GalleryCover, GalleryData, WhoSignedIn } from '../../shared/types';
import { useGetGalleryCover } from './hooks/useGetGalleryCover';
import { GalleryContent } from './GalleryContent/GalleryContent';
import { useCheckWindowSize } from '../../shared/hooks/useCheckWindowSize';

type GalleryViewProps = {
  gallery: GalleryData;
  galleryDesignNumber?: number;
}

export const GalleryView: React.FC<GalleryViewProps> = ({
  gallery,
  galleryDesignNumber,
}) => {
  const { screenSize } = useCheckWindowSize();

  const props: GalleryCover = {
    name: gallery?.name || '',
    date: new Date(gallery?.date || ''),
    file: (gallery?.coverFile[screenSize]
      ? gallery?.coverFile[screenSize].file
      : gallery?.coverFile.original.file) || null,
    userName: 'John Doe',
    message: gallery?.message || '',
  };

  const galleryCover = useGetGalleryCover(props, galleryDesignNumber || gallery?.galleryDesign);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginBottom: '40px', position: 'relative' }}
      >
        {galleryCover}
        <GalleryContent gallery={gallery} />
        <ButtonMoveToTop />
      </Grid>
    </>
  );
};
