export const clientRootUrl = '';

export const clientSignInUrl = `${clientRootUrl}/signin`;
export const clientCartUrl = `${clientRootUrl}/cart`;
export const clientFavouritesUrl = `${clientRootUrl}/favourites`;
export const clientSupportUrl = `${clientRootUrl}/support`;

export const clientPaymentUrl = `${clientRootUrl}/payment`;
export const clientPaymentSuccessUrl = `${clientPaymentUrl}/success`;
export const clientPaymentCanceledUrl = `${clientPaymentUrl}/canceled`;
