import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { TextWhiteLarge } from '../../../../../shared/styles';

export const LandingMainComparingWrp = styled.div`
  background: ${variables.colorBlack};
  box-sizing: border-box;
  padding: 152px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LandingMainComparingTitle = styled.div`
  text-align: center;
  color: ${variables.colorGreyLight};
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  max-width: 862px;
  margin-bottom: 80px;
`;

export const LandingMainComparingListWrp = styled.div`
  background: transparent;
  border: 1px solid ${variables.textGrey};
  border-radius: 30px;
  box-sizing: border-box;
  padding: 48px 40px;
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 532px;
`;

export const LandingMainComparingListsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 64px;
`;

export const ComparingTitleWrapper = styled.div`
  margin-bottom: 8px;
`;

export const ComparingListTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${variables.colorBleak};
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.01em;
`;

export const ComparingListWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ComparingListItem = styled.div<{isIconTick: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;

  & svg {
    margin-right: 8px;
    min-width: ${({ isIconTick }) => (isIconTick ? '13px' : '20px')};
  }
`;

export const ComparingSecondTitle = styled(TextWhiteLarge)`
  text-align: center;
`;

export const ComparingWhiteTick = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 2px;

  & svg {
    width: 13px;
    height: 11px;
    color: ${variables.colorGreyLight};
  }
`;

export const ComparingPinkText = styled.span`
  color: ${variables.colorPink};
  font-weight: 600;
`;

export const ComparingBlueText = styled.span`
  color: ${variables.colorBlue};
  font-weight: 600;
`;
