import React, { useContext, useEffect, useState } from 'react';
import { ArrowSimpleIcon } from '../../../../../shared/icons';
import { ClientOnboardingStepIndexContext } from '../../../../Client/context/ClientOnboardingStepIndexContext';
import { ClientOnboardingContext } from '../../../../Client/context/CreatorOnboardingContext';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
} from './styled';

type MenuType = { id: string; value: string };

type NavigationSelectProps = {
  menuItems: MenuType[];
  action: (id: string) => void;
  currentMenuItem: MenuType;
};

export const NavigationSelect: React.FC<NavigationSelectProps> = ({
  menuItems,
  action,
  currentMenuItem,
}) => {
  const { isStudy } = useContext(ClientOnboardingContext);
  const { stepIndex } = useContext(ClientOnboardingStepIndexContext);

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    const arrIndex = menuItems.findIndex((item) => item.id === currentMenuItem.id);
    if (isStudy && stepIndex === 4 && arrIndex === 0) {
      setIsSelectOpen(true);
    } else {
      setIsSelectOpen(false);
    }
  }, [isStudy, stepIndex]);

  const handleClickHeader = () => {
    setIsSelectOpen((prev) => !prev);
  };

  const handleClickListItem = (data: MenuType) => () => {
    action(data.id);
    setTimeout(() => {
      handleClickHeader();
    }, 400);
  };

  return (
    <DropDownContainer>
      <DropDownHeader onClick={handleClickHeader}>
        <p>{currentMenuItem.value}</p>
        {' '}
        <ArrowSimpleIcon
          direction={isSelectOpen ? 'up' : 'down'}
          color="black"
        />
      </DropDownHeader>
      <DropDownListContainer isSelectOpen={isSelectOpen} id="client-navigation-step">
        <DropDownList>
          {menuItems.map((item) => (
            <ListItem
              isActive={currentMenuItem.value === item.value}
              onClick={handleClickListItem(item)}
              key={item.id}
            >
              <p>{item.value}</p>
            </ListItem>
          ))}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  );
};
