import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainLogoNoWhiteSpace } from '../../../../shared/icons';
import * as urls from '../../../../constants/urls';
import {
  LandingHeaderWrapper,
  LandingNavToCreatorWrapper,
  LandingNavInsideWrapper,
  LandingNavWrapper,
  LandingHeaderPinkBtn,
  HeaderMenuItem,
  LandingHeaderLogoLink,
} from './styled';
import { LandingMainImg } from '../LandingMainPage/LandingMainImg';

export const LandingHeader: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('landing');

  const isMainPage = pathname === '/';

  return (
    <LandingHeaderWrapper isMainPage={isMainPage}>
      {isMainPage ? <LandingMainImg /> : null}
      <LandingHeaderLogoLink to={urls.rootUrl}><MainLogoNoWhiteSpace /></LandingHeaderLogoLink>
      <LandingNavWrapper>
        <LandingNavInsideWrapper>
          <HeaderMenuItem isMainPage={isMainPage}>
            <NavLink to={urls.demoGalleriesUrl}>
              {t('header.demoGalleries')}
            </NavLink>
          </HeaderMenuItem>
          <HeaderMenuItem isMainPage={isMainPage}>
            <NavLink to={urls.pricingUrl}>
              {t('header.pricing')}
            </NavLink>
          </HeaderMenuItem>
        </LandingNavInsideWrapper>
        <LandingNavToCreatorWrapper>
          <HeaderMenuItem isMainPage={isMainPage} fontWeight={600} isUppercase>
            <NavLink to={urls.creatorSignInUrl}>
              {t('header.logIn')}
            </NavLink>
          </HeaderMenuItem>
          <LandingHeaderPinkBtn isGradient={!isMainPage}>
            <NavLink to={urls.signUpInitialUrl}>{t('header.singUp')}</NavLink>
          </LandingHeaderPinkBtn>
        </LandingNavToCreatorWrapper>
      </LandingNavWrapper>
    </LandingHeaderWrapper>
  );
};
