import React from 'react';
import { useFormikContext } from 'formik';
import { ModalCloseButtons } from '../../../../../../../shared/styles';
import { GalleryDataForm } from '../../../../../../../shared/types';
import { SaveAndCloseGallery } from '../NewGalleryCreatorButtons/SaveAndCloseGallery/SaveAndCloseGallery';
import { ButtonsGroupWrapper } from './styled';
import { NextAndBackButtons } from '../NewGalleryCreatorButtons/NextAndBackButtons';

type GalleryPreviewButtonsGroupProps = {
  handleClose: () => void;
};

export const GalleryPreviewButtonsGroup: React.FC<GalleryPreviewButtonsGroupProps> = ({
  handleClose,
}) => {
  const {
    setFieldValue, values, handleSubmit,
  } = useFormikContext<GalleryDataForm>();

  const { step } = values;

  const handleLeftClick = () => {
    if (step === 5) {
      const prevStage = step - 1;
      setFieldValue('step', prevStage);
      handleSubmit();
      handleClose();
    } else {
      handleClose();
    }
  };
  const handleRightClick = () => {
    if (step === 4) {
      const prevStage = step + 1;
      setFieldValue('step', prevStage);
      handleSubmit();
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <ModalCloseButtons>
      <ButtonsGroupWrapper>
        <NextAndBackButtons
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
        <SaveAndCloseGallery />
      </ButtonsGroupWrapper>
    </ModalCloseButtons>
  );
};
