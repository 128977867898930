import { Grid } from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { CommonPriceInput } from '../../../../../../../shared/components/FormElements/CommonPriceInput/CommonPriceInput';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import {
  GalleryFileInformation,
  GalleryIdea,
  GallerySection,
  SetFieldValue,
} from '../../../../../../../shared/types';
import { isArrayIncludeValue } from '../../../../../../../shared/utils/commonFunctions';

type FilesFormProps = {
  selected: string[];
  values: GalleryFileInformation;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: SetFieldValue;
  errors: FormikErrors<GalleryFileInformation>;
  touched: FormikTouched<GalleryFileInformation>;
  section: GallerySection;
};

export const FilesForm: React.FC<FilesFormProps> = ({
  selected,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  section,
  setFieldValue,
}) => {
  const { t } = useTranslation('createNewGallery');

  const selectedIdeas = useMemo(() => {
    const ideas = section.ideas as GalleryIdea[];
    return ideas.filter((item) => isArrayIncludeValue(selected, item.id));
  }, [selected, section.ideas]);

  const isAllIdeas = selected.length === selectedIdeas.length;

  return (
    <Grid
      container
      item
      sm={4}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid container item sm={10} id="filesSettingsThirdStep">
        <Grid item sm={12}>
          <CommonInput
            error={isAllIdeas ? '' : errors.description}
            value={values.description}
            name="description"
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            touched={touched.description}
            placeholder={t('thirdStep.description')}
            disabled={selected?.length === 0 || isAllIdeas}
          />
        </Grid>
        <Grid item sm={10}>
          <CommonPriceInput
            label={t('thirdStep.price')}
            value={values.price}
            name="price"
            setFieldValue={setFieldValue}
            // handleChange={handleChange}
            // handleBlur={handleBlur}
            direction="row"
            error={isAllIdeas ? '' : errors.price}
            touched={touched.price}
            disabled={selected?.length === 0 || isAllIdeas}
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.instantlyDownloadable}
            handleChange={handleChange}
            name="instantlyDownloadable"
            label={t('thirdStep.downloadable')}
            disabled={selected?.length === 0}
            shortLabel
            helpText={t('thirdStep.helpTexts.downloadable')}
            withoutMargins
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.watermark}
            handleChange={handleChange}
            name="watermark"
            label={t('thirdStep.watermark')}
            helpText={t('thirdStep.helpTexts.watermark')}
            disabled={selected?.length === 0}
            withoutMargins
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.allowCommenting}
            handleChange={handleChange}
            name="allowCommenting"
            label={t('thirdStep.allowCommenting')}
            disabled={selected?.length === 0}
            helpText={t('thirdStep.helpTexts.allowCommenting')}
            withoutMargins
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
