import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../shared/icons';
import { GalleryComment } from '../../../shared/types';
import { CommentArea } from './CommentArea';
import { CloseButtonWrapper, CloseCommentsButton, CommentContainerWrapper } from './styled';

type CommentAreaProps = {
  comments: GalleryComment[];
  handleClose: () => void;
}

export const CommentContainer: React.FC<CommentAreaProps> = ({
  comments,
  handleClose,
}) => {
  const { t } = useTranslation('gallery');
  return (
    <CommentContainerWrapper id="comments-container">
      <CloseButtonWrapper>
        <CloseCommentsButton
          type="button"
          onClick={handleClose}
        >
          <CloseIcon />
          <div>{t('closeComments')}</div>
        </CloseCommentsButton>
      </CloseButtonWrapper>
      <CommentArea comments={comments} />
    </CommentContainerWrapper>
  );
};
