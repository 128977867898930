import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProfileIcon,
  PaymentIcon,
  AdvancedSettingsIcon,
  FlameIcon,
} from '../../../../../../../shared/icons';
import { TabLabel } from '../SettingsTabs/styled';

export const useGetTabsSettings = (): { label: ReactNode, id: string }[] => {
  const { t } = useTranslation('settings');
  const tabs = [
    {
      label: (
        <TabLabel>
          <ProfileIcon />
          {t('profile')}
        </TabLabel>
      ),
      id: 'comments',
    },
    {
      label: (
        <TabLabel>
          <PaymentIcon />
          {t('usageBilling')}
        </TabLabel>
      ),
      id: 'likes',
    },
    {
      label: (
        <TabLabel>
          <AdvancedSettingsIcon />
          {t('advancedSettings')}
        </TabLabel>
      ),
      id: 'downloads',
    },
    {
      label: (
        <TabLabel>
          <FlameIcon />
          {t('referFriend')}
        </TabLabel>
      ),
      id: 'ideas',
    },
  ];
  return tabs;
};
