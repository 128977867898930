import React, {
  useContext,
} from 'react';
import { Locale } from 'react-joyride';
import { useGetCreatorSteps } from './useGetCreatorSteps';
import { CreatorCustomTooltip } from './CreatorCustomTooltip/CreatorCustomTooltip';
import * as urls from '../../../../../constants/urls';
import { CreatorOnboardingContext } from '../../../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../../../context/CreatorOnboardingStepIndexContext';
import { CustomJoyride } from '../../../../../shared/components/CustomJoyride/CustomJoyride';

export const CreatorWalkThroughCards: React.FC = () => {
  const { setIsStudy, isStudy } = useContext(CreatorOnboardingContext);
  const {
    setStepIndex, stepIndex,
  } = useContext(CreatorOnboardingStepIndexContext);

  const steps = useGetCreatorSteps();

  const customLocale: Locale = {
    back: 'Back',
    close: 'Close',
    last: 'Done!',
    next: stepIndex === 4 ? 'Let\'s go!' : 'Next',
    open: 'Open the dialog',
    skip: 'Close',
  };

  return (
    <>
      {/* <WalkThroughStart setIsStudy={setIsStudy} mainImageUrl={mainImage} /> */}
      <CustomJoyride
        locale={customLocale}
        stepIndex={stepIndex}
        customTooltip={CreatorCustomTooltip}
        runTraining={isStudy}
        steps={steps}
        endUrl={urls.creatorRootUrl}
        setIsStudy={setIsStudy}
        setStepIndex={setStepIndex}
      />
    </>
  );
};
