import React, { MutableRefObject } from 'react';
import { CustomFile } from '../../types';
import { isImage } from '../../utils';
import { VideoContainer } from '../Files/VideoContainer/VideoContainer';

type FileForMasonryProps = {
  fileRef: MutableRefObject<any>;
  currentFile: CustomFile | null;
  currentFileUrl: string;
  fileId: string;
  fileName: string;
  handleSetSpans: (ref: React.MutableRefObject<any>) => void;
}

export const FileForMasonry: React.FC<FileForMasonryProps> = ({
  fileId,
  fileName,
  fileRef,
  currentFile,
  handleSetSpans,
  currentFileUrl,
}) => (
  isImage(currentFile) ? (
    <img
      id={fileId}
      ref={fileRef}
      src={currentFileUrl}
      data-src={currentFileUrl}
      alt={fileName}
    />
  ) : (
    <VideoContainer
      videoUrl={currentFileUrl}
      setSpans={handleSetSpans}
    />
  )
);
