import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { ModalWindowContainer } from '../../../shared/components/ModalWindow/ModalWindowContainer';
import { ArrowIcon } from '../../../shared/icons';
import { GalleryData, GalleryFile, GalleryIdea } from '../../../shared/types';
import { getAllFiles } from '../../../shared/utils/gallery/getAllFiles';
import { GallerySwiperContainer } from './GallerySwiperContainer';
import { GallerySwiperCloseButton } from './styled';

type GallerySwiperWindowProps = {
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  file: GalleryFile | GalleryIdea;
  gallery: GalleryData;
};

export const GallerySwiperWindow: React.FC<GallerySwiperWindowProps> = ({
  isOpen = false,
  setIsOpen,
  file,
  gallery,
}) => {
  const files = useMemo(() => (gallery ? getAllFiles(gallery) : []), [gallery]);
  const initialSlideIndex = useMemo(
    () => files?.findIndex((item) => file.id === item.id),
    [file, files],
  );

  const handleClose = () => setIsOpen(false);

  return (
    <ModalWindowContainer size="full" isOpen={isOpen} handleClose={handleClose}>
      <GallerySwiperCloseButton type="button" onClick={handleClose}>
        <ArrowIcon direction="left" />
      </GallerySwiperCloseButton>
      <GallerySwiperContainer files={files} initialSlideIndex={initialSlideIndex} />
    </ModalWindowContainer>
  );
};
