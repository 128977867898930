import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import ellipse from '../../../../../assets/image/landing/pricing/ellipse.png';
import {
  LittleBlackText,
  MediumBlackText,
  SimpleBlackText,
} from '../../../../../shared/styles';

export const LandingPricingDescriptionWrp = styled.div`
  background-image: url(${ellipse});
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50% 100%;
  margin-top: 48px;
  margin-bottom: 122px;
`;

export const PricingDescriptionTitle = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  color: ${variables.colorBlack};
  margin-bottom: 24px;
`;

export const PricingDescriptionText = styled(SimpleBlackText)`
  display: block;
  max-width: 419px;
  margin-bottom: 76px;

  & .pink {
    color: ${variables.colorPink};
    font-weight: 600;
  }

  & .pink-highlight {
    padding: 0px 8px;
    color: ${variables.colorGreyLight};
    font-weight: 600;
    background: ${variables.colorPink};
  }
`;

export const PricingDescriptionReason = styled.div`
  max-width: 420px;
  border-top: 1px solid rgba(130, 141, 149, 0.2);
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid rgba(130, 141, 149, 0.2);
  }
`;

export const PricingDescriptionReasonNumber = styled.div`
  font-size: 56px;
  font-weight: 300;
  line-height: 110%;
  color: ${variables.colorBleak};
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.34;
  width: 33px;
  min-width: 33px;
`;

export const PricingDescriptionReasons = styled.div`
  margin-bottom: 124px;
`;

export const PricingDescriptionSpotsCard = styled.div`
  position: absolute;
  left: 0;
  top: 150px;
  padding: 40px 48px;
  position: absolute;
  width: 533px;
  height: 216px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(13px);
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PricingSpotTooltip = styled(MediumBlackText)`
  text-align: center;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  box-shadow: 3px 3px 6px #e7e7e7;
  background: ${variables.colorGreyLight};

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.9em;
    bottom: -18px;
    left: 50%;
    box-sizing: border-box;

    border: 9px solid black;
    border-color: transparent transparent ${variables.colorGreyLight}
      ${variables.colorGreyLight};

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: -3px 3px 3px 0 #e7e7e7;
  }
`;

export const PricingSpotText = styled(LittleBlackText)`
  display: block;
  text-align: center;
  margin-top: 20px;
  max-width: 360px;
`;
