import React, { useContext } from 'react';
import { ChangeColorButton, GradientCover, IconsWrapper } from './styled';
import {
  CartIcon,
  HeartIcon,
  InstantDownload,
  CommentIcon,
} from '../../icons/FileIcons';
import { useLike } from '../../../modules/GalleryView/hooks/buttons/useLike';
import { useAddToCart } from '../../../modules/GalleryView/hooks/buttons/useAddToCart';
import { CustomFile } from '../../types';
import { useComment } from '../../../modules/GalleryView/hooks/buttons/useComment';
import { ClientOnboardingContext } from '../../../modules/Client/context/CreatorOnboardingContext';
import { useDownload } from '../../../modules/GalleryView/hooks/buttons/useDownload';

type GradientCoverWithActionsProps = {
  isInstantlyDownloadable: boolean;
  isAllowCommenting: boolean;
  disabledAll?: boolean;
  id: string;
  sectionId?: string;
  isFileLiked?: boolean;
  isFileInCart?: boolean;
  file: CustomFile | null;
  isGradientVisible?: boolean;
};

export const GradientCoverWithActions: React.FC<
  GradientCoverWithActionsProps
> = ({
  isInstantlyDownloadable,
  isAllowCommenting,
  disabledAll,
  id,
  sectionId,
  isFileLiked,
  isFileInCart,
  file,
  isGradientVisible,
}) => {
  const { isLiked, handleLike } = useLike(!!isFileLiked);
  const { handleDownload } = useDownload();
  const { isInCart, handleClickCart, hoverElement } = useAddToCart(
    !!isFileInCart,
  );
  const { handleOpenComments, commentArea } = useComment({
    openFile: true,
    file,
  });
  const { isStudy: isStudyClient } = useContext(ClientOnboardingContext);

  return (
    <>
      <GradientCover
        id="cover-gradient"
        isStudy
        isGradientVisible={!!isGradientVisible}
      >
        <IconsWrapper id="client-buttons-over-item-step">
          {(isInstantlyDownloadable || isStudyClient) && (
            <button
              disabled={!!disabledAll}
              type="button"
              id="client-instantly-downloadable-step"
              onClick={handleDownload}
            >
              <InstantDownload />
            </button>
          )}
          <ChangeColorButton
            isClicked={isLiked}
            disabled={!!disabledAll}
            type="button"
            onClick={handleLike(id)}
            id="client-like-button-step"
          >
            <HeartIcon />
          </ChangeColorButton>
          <ChangeColorButton
            isClicked={isInCart}
            disabled={!!disabledAll}
            type="button"
            onClick={handleClickCart(id, sectionId)}
            style={{ position: 'relative' }}
            id="client-download-cart-button-step"
          >
            {hoverElement}
            <CartIcon />
          </ChangeColorButton>
          {(isAllowCommenting || isStudyClient) && (
            <button
              disabled={!!disabledAll}
              type="button"
              style={{ position: 'relative' }}
              onClick={handleOpenComments}
              id="client-comment-button-step"
            >
              <CommentIcon />
            </button>
          )}
        </IconsWrapper>
      </GradientCover>
      {!disabledAll && (
        <>
          <>{commentArea}</>
        </>
      )}
    </>
  );
};
