import {
  format, isYesterday, isToday,
} from 'date-fns';

// transform date from Date format to "yyyy-mm-dd"
export const getFormatDateForDob = (date: Date): string => format(date, 'yyyy-MM-dd');

export const getFormattedDateOrDateName = (
  date: string,
  dateFormat: string,
  withName?: boolean,
): string => {
  let resDate = '';
  const dateFormatUpperCase = dateFormat
    .toUpperCase()
    .replaceAll('D', 'd')
    .replaceAll('Y', 'y');

  try {
    const d = new Date(date);

    if (dateFormatUpperCase) {
      if (isYesterday(d) && withName) {
        resDate = 'Yesterday';
      } else if (isToday(d) && withName) {
        resDate = 'Today';
      } else {
        resDate = format(d, dateFormatUpperCase);
      }
    }

    return resDate;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getFormattedTime = (date: string, timeFormat?: string): string => {
  let resTime = '';

  try {
    const d = new Date(date);

    resTime = format(d, timeFormat || 'hh:mm a');

    return resTime.toLowerCase();
  } catch (e) {
    console.log(e);
    return '';
  }
};
