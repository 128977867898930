import React, {
  Dispatch, SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  GalleryFileInformation,
  GalleryIdea,
  GallerySection,
} from '../../../../../../../../shared/types';
import {
  ActionButton,
  SectionActionsWrapper,
  SectionContentWrapper,
  SectionsActionsWithErrorWrapper,
} from '../styled';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { ArrowIcon } from '../../../../../../../../shared/icons';
import { ApplyAndChosenButtons } from './ApplyAndChosenButtons';
import { SimpleGreyText } from '../../../../../../../../shared/styles';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';
import { useApplyFileSettings } from '../../hooks/useApplyFileSettings';

type SectionActionsProps = {
  section: GallerySection;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
  isFormHidden: boolean;
  values: GalleryFileInformation;
  handleSubmit: () => void;
  isValid: boolean;
  resetForm: () => void;
  handleSelectAll: (totalCount: number, arrChosen: string[]) => void;
  removeAllSelected: () => void;
  isItemSelected: (id: string) => boolean;
  numberOfSelected: number;
};

export const SectionActions: React.FC<SectionActionsProps> = ({
  section,
  handleSelectAll,
  setIsHidden,
  isFormHidden,
  values,
  handleSubmit,
  isValid,
  resetForm,
  numberOfSelected,
  removeAllSelected,
  isItemSelected,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { formError, handleApplySettings, settingsApplied } = useApplyFileSettings({
    handleSubmit,
    isValid,
    values,
    isItemSelected,
    removeAllSelected,
  });

  const ideas = section.ideas as GalleryIdea[];
  const numberFiles = section?.files?.length + ideas?.length;
  const buttonsVisible = settingsApplied || numberOfSelected > 0;

  const onClickApply = (e: any) => {
    handleApplySettings(e, section);
  };

  const handleCustomSelectAll = () => {
    const totalNumberOfFiles = section?.files?.length + ideas?.length;
    const arrOfIdeasFiles = [
      ...section?.files?.map((item) => item.id),
      ...ideas?.map((item) => item.id),
    ];
    handleSelectAll(totalNumberOfFiles, arrOfIdeasFiles);
  };

  const handleUncheckedChosen = () => {
    removeAllSelected();
    resetForm();
  };

  return (
    <SectionContentWrapper
      isFormOpened={isFormHidden}
      item
      sm={buttonsVisible ? 8 : 12}
    >
      <SectionsActionsWithErrorWrapper>
        <SectionActionsWrapper>
          <CommonCheckbox
            isChecked={numberOfSelected === numberFiles}
            handleChange={handleCustomSelectAll}
            simple
            name=""
            withoutMargins
            label={(
              <SimpleGreyText>
                {t('thirdStep.all')}
                {' '}
                {numberOfSelected ? ` (${numberOfSelected})` : ''}
              </SimpleGreyText>
            )}
          />
          {buttonsVisible && (
            <ApplyAndChosenButtons
              handleApplySettings={onClickApply}
              settingsApplied={settingsApplied}
              numberChosenFiles={numberOfSelected}
              isFormHidden={isFormHidden}
              handleUncheckedChosen={handleUncheckedChosen}
            />
          )}
          <ActionButton
            type="button"
            onClick={() => setIsHidden((prev) => !prev)}
          >
            <span>
              {!isFormHidden
                ? t('thirdStep.collapseSettings')
                : t('thirdStep.expandSettings')}
            </span>
            <ArrowIcon direction={isFormHidden ? 'right' : 'left'} />
          </ActionButton>
        </SectionActionsWrapper>
        {!!formError && <ErrorMessage>{formError}</ErrorMessage>}
      </SectionsActionsWithErrorWrapper>
    </SectionContentWrapper>
  );
};
