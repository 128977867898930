import React, { useEffect } from 'react';

export const ScrollToTop: React.FC<{ history: any, location: any }> = ({ history, location }) => {
  const dontScrollIntoViewOnPaths = ['/this/that'];

  useEffect(() => {
    if (history.action === 'POP') {
      return;
    }

    const { hash, pathname } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    } else if (!dontScrollIntoViewOnPaths.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  });

  return null;
};
