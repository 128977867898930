import { v4 as uuidv4 } from 'uuid';
import { ClientGalleryData, Gallery, GalleryStatus } from '../../shared/types';
import gallery7 from '../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../assets/image/walkThroughCards/gallery8.jpg';
import desktopGal1 from '../../assets/image/gallery1/desktopGal1.jpg';
import mobileGal1 from '../../assets/image/gallery1/mobileGal1.jpg';
import mobileGal2 from '../../assets/image/gallery2/mobileGal2.jpg';
import mobileGal3 from '../../assets/image/gallery3/mobileGal3.jpg';
import gallery9 from '../../assets/image/walkThroughCards/gallery9.jpg';
import gallery10 from '../../assets/image/walkThroughCards/gallery10.jpg';
import gallery11 from '../../assets/image/walkThroughCards/gallery11.jpg';
import { ClientSignIn } from '../../shared/types/client';
import { fakeComments } from './comments/fakeComment';

export const fakeActiveGalleries: Gallery[] = [
  {
    id: uuidv4(),
    name: 'Gallery name 1',
    galleryUrl: 'businessName.delivrable.com/Galleryname1',
    likes: { numberLikes: 0, likes: [] },
    views: 20,
    shares: 10,
    sales: 350,
    salesPerWeek: 95,
    comments: [fakeComments[0]],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: gallery7,
    wasWatched: true,
    status: 'active',
    password: 'passworD123',
    expiryDate: `${new Date(2023, 10, 1, 22, 12)}`,
  },
  {
    id: uuidv4(),
    name: 'Gallery name 2',
    galleryUrl: 'businessName.delivrable.com/Galleryname2',
    likes: { numberLikes: 0, likes: [] },
    views: 2,
    shares: 1,
    sales: 35,
    salesPerWeek: 9,
    comments: [],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: gallery8,
    wasWatched: false,
    status: 'active',
    password: 'passworD123',
    expiryDate: `${new Date(2021, 10, 1, 22, 12)}`,
  },
];

export const galleryForCLient: ClientGalleryData = {
  id: uuidv4(),
  name: 'Gallery name',
  removeBranding: false,
  galleryDesign: 1, // 1, 4 - two uploaded file, 5, 6
  coverFile: {
    original: {
      file: {
        id: uuidv4(),
        url: gallery8,
        type: 'image',
        name: 'ideafile3',
      },
    },
    desktop: {
      file: {
        id: uuidv4(),
        url: desktopGal1,
        type: 'image',
        name: 'ideafile3',
      },
    },
    tablet: {
      file: {
        id: uuidv4(),
        url: gallery8,
        type: 'image',
        name: 'ideafile3',
      },
    },
    mobile: {
      file: {
        id: uuidv4(),
        url: mobileGal1,
        type: 'image',
        name: 'ideafile3',
      },
    },
  },
  status: 'active',
  date: `${new Date(2021, 10, 1, 22, 12)}` as unknown as Date,
  message:
    'A short description of the gallery  A short description of the gallery A short description of the',
  galleryUrl: 'popova.delivrable.io/galleryname',
  sections: [
    {
      id: 'section1',
      numberFreeFiles: 1,
      title: 'Wedding',
      files: [
        {
          id: uuidv4(),
          url: gallery8,
          type: 'image',
          name: 'sectionfile1',
          price: '10',
          instantlyDownloadable: true,
          allowCommenting: true,
          isLiked: false,
          isInCart: false,
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'image',
          name: 'sectionfile1ideafile2',
          price: '10',
          isLiked: false,
          isInCart: false,
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'doc',
          name: 'file-name',
          price: '0',
          isLiked: false,
          isInCart: false,
        },
      ],
      ideas: [
        {
          id: uuidv4(),
          name: 'Idea 1',
          description: 'Idea description',
          allowCommenting: true,
          price: '12',
          files: [
            {
              id: uuidv4(),
              url: gallery8,
              type: 'image',
              name: 'ideafile3',
            },
            {
              id: uuidv4(),
              url: gallery9,
              type: 'image',
              name: 'ideafile3 sd idea',
            },
            {
              id: uuidv4(),
              url: gallery8,
              type: 'doc',
              name: 'ideafile doc',
            },
          ],
          cover: {
            id: uuidv4(),
            url: gallery7,
            type: 'image',
            name: 'ideafile3',
          },
          isLiked: false,
          isInCart: false,
        },
      ],
      hasFreeFiles: true,
    },
    {
      id: 'section2',
      numberFreeFiles: 1,
      title: 'Raw footage',
      files: [
        {
          id: uuidv4(),
          url: gallery11,
          type: 'image',
          name: 'sectionfile5',
          price: '10',
          instantlyDownloadable: true,
          isLiked: false,
          isInCart: false,
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          url: gallery10,
          type: 'image',
          name: 'ideafile6',
          price: '10',
          isLiked: false,
          isInCart: false,
        },
      ],
      ideas: [],
      hasFreeFiles: true,
    },
  ],
  hasRegistrationEmail: false,
  password: '123',
  sharing: false,
  assist: false,
  autoAlert: false,
  branding: false,
  testimonialCall: false,
};

export const fakeActiveGallery = {
  getGalleries: (typeGallery?: GalleryStatus) => Promise.resolve({
    data: typeGallery
      ? fakeActiveGalleries.filter((item) => item.status === typeGallery)
      : fakeActiveGalleries,
  }),
  getGalleryByCred: (data: ClientSignIn): Promise<ClientGalleryData> => {
    if (data.email === 'example@gmail.com' || data.password === '123') {
      return Promise.resolve(galleryForCLient);
    }
    return Promise.reject();
  },
  getGalleryByToken: (token: string): Promise<ClientGalleryData> => {
    if (token === '123') {
      return Promise.resolve(galleryForCLient);
    }
    return Promise.reject();
  },
};
