import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const ReadOnlyPriceInput = styled.div<{textAlign: 'right' | 'left'}>`
  font-size: inherit;
  font-weight: inherit;
  width: 100%;

  & input {
    border: none;
    background: ${variables.colorGreyLight};
    color: ${variables.textBlack};
    width: 100%;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    text-align: ${({ textAlign }) => (textAlign === 'right' ? 'right' : 'left')};
    max-width: 150px;
  }
`;
