import React, {
  Dispatch, SetStateAction, ReactNode,
} from 'react';
import { useFormikContext } from 'formik';
import { ModalWindowContainer } from '../../../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { ButtonMoveToTop } from '../../../../../../../shared/components/ButtonMoveToTop/ButtonMoveToTop';
import { useGetGalleryCover } from '../../../../../../GalleryView/hooks/useGetGalleryCover';
import { GalleryGridContainer } from '../../../../../../../shared/components/GalleryGridContainer';
import { GalleryPreviewButtonsGroup } from './GalleryPreviewButtonsGroup';
import { GalleryPreviewHeader } from './GalleryPreviewHeader';
import { GalleryContent } from '../../../../../../GalleryView/GalleryContent/GalleryContent';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { GalleryData, GalleryCover } from '../../../../../../../shared/types';
import { getFullName } from '../../../../../../../shared/utils';

type ModalWindowProps = {
  isOpen?: boolean;
  button?: ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const ModalWindowGalleryPreview: React.FC<ModalWindowProps> = ({
  isOpen = false,
  button,
  setIsOpen,
}) => {
  const user = useTypedSelectorCreator((state) => state.creator.currentUser);
  const { values } = useFormikContext<GalleryData>();
  const props: GalleryCover = {
    name: values.name,
    date: values.date,
    file: values.coverFile.desktop
      ? values.coverFile.desktop.file
      : values.coverFile.original.file,
    userName: getFullName(user?.firstName, user?.lastName),
    message: values.message,
  };
  const galleryCover = useGetGalleryCover(props, values.galleryDesign || 1);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      {button}
      <ModalWindowContainer
        size="full"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <GalleryPreviewHeader>
          <GalleryPreviewButtonsGroup handleClose={handleClose} />
        </GalleryPreviewHeader>
        <GalleryGridContainer>
          {galleryCover}
          <GalleryContent gallery={values} />
          <ButtonMoveToTop />
        </GalleryGridContainer>
      </ModalWindowContainer>
    </>
  );
};
