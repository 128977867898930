import React, { ReactElement } from 'react';
import { LandingSwiperIndicatorElement } from './styled';

export const getLandingSwiperIndicator = (
  clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  isSelected: boolean,
  index: number,
  label: string,
): ReactElement => (
  <LandingSwiperIndicatorElement
    key={index}
    role="button"
    tabIndex={0}
    aria-label={`${label} ${index + 1}`}
    onClick={clickHandler}
    isSelected={isSelected}
  />
);
