import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { ImageSizes } from '../../../../constants/galleryPreviewsAndCovers';

export const SixthTitle = styled.div`
  font-size: 28px;
  line-height: 42px;
  color: ${variables.textBlack};
  width: 100%;
  margin-bottom: 7px;

  & h3 {
    width: 100%;
    font-weight: 400;
    margin: 0;
  }
`;

export const SixthMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;
`;

export const SixthMediumTextCenter = styled(SixthMediumText)`
  text-align: center;
`;

export const SixthImageContainer = styled.div`
  width: 100%;
  height: ${ImageSizes.sixth.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  object-fit: cover;
  display: flex; 
`;

export const SixthInfoCardCover = styled.div<{ currentHeight: number }>`
  position: absolute;
  top: calc(50% - ${(props) => (props.currentHeight ? (props.currentHeight / 2) : '114')}px);
  left: calc(50% - 210px);
  box-sizing: border-box;
  width: 420px;
  min-height: 228px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background: #F8F8F8C2;

  & > div,
  & > span {
    text-align: center;
  }
`;

export const SixthInfoCardCoverTablet = styled(SixthInfoCardCover)`
  top: calc(50% - ${(props) => (props.currentHeight ? (props.currentHeight / 2) : '89')}px);
  left: calc(50% - 187px);
  width: 374px;
  min-height: 178px;
  padding: 20px 46px;
`;

export const ImageContainerSixthTablet = styled(SixthImageContainer)`
  width: ${ImageSizes.sixth.tablet.width}px;
  height: ${ImageSizes.sixth.tablet.height}px;
`;

export const SixthTitleTablet = styled(SixthTitle)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
`;

export const SixthMediumTextTablet = styled(SixthMediumTextCenter)`
  font-size: 11px;
  line-height: 140%;

  & p {
    margin: 6px;
  }
`;

export const WrapperMarginBTablet = styled.div`
  margin-bottom: 8px;
`;

export const ImageContainerSixthMobile = styled(SixthImageContainer)`
  width: fit-content;
  height: fit-content;

  &:hover {
    & > div {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

export const SixthInfoCardCoverMobile = styled(SixthInfoCardCover)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px 20px 24px;
  justify-content: center;
  
  &:hover {
    display: none;
  }
`;

export const SixthTitleMobile = styled(SixthTitle)`
  font-size: 28px;
  line-height: 140%;

  & h3 {
    margin-bottom: 16px;
  }
`;

export const SixthMediumTextMobile = styled(SixthMediumTextCenter)`
  font-size: 14px;
  line-height: 140%;

  & p {
    margin: 5px;
  }
`;

export const MobileWrapperFifth = styled.div`
  margin: 30px 10px 0 10px;
`;

export const SixthDateAndNameWrapper = styled.div`
  position: absolute;
  bottom: 24px;
`;
