import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCover } from '../../../../shared/types';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import {
  FirstHeaderMobileWrapper,
  FirstMediumTextMobile,
  FirstTitleMobile,
  ImageContainerFirstMobile,
} from './styled';

export const GalleryDemoFirstMobile: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'first',
  });
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <FirstHeaderMobileWrapper>
          <FirstTitleMobile>{data.name}</FirstTitleMobile>
        </FirstHeaderMobileWrapper>
      </Grid>
      <Grid item xs={12}>
        <ImageContainerFirstMobile>
          {data.file}
        </ImageContainerFirstMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile>{data.message}</FirstMediumTextMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile style={{ marginBottom: '16px' }}>{data.userName}</FirstMediumTextMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile>{data.date}</FirstMediumTextMobile>
      </Grid>
    </Grid>
  );
};
