import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const GalleryContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100hv;
  min-height: 100%;
  padding: 0px 59px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    padding: 0px;
    margin-bottom: 70px;
    margin-top: 0px;
    height: calc(100% - 150px);
    min-height: calc(100% - 150px);
  }
`;

export const GallerySwiperFileWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 100%;
    height: auto;  
  }
`;
