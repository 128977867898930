import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { CreatorStateContext } from '../context/CreatorStateContext';
import { GridFullHeight, GridItemFullHeight } from '../../../shared/components/GlobalContainer/styled';
import { HeaderWithLogo } from '../../../shared/components/HeaderWithLogo/HeaderWithLogo';
import { CreatorRoutes } from '../routers/CreatorRoutes';
import { NewsPopupWindow } from './CreatorAccount/NewsPopupWindow/NewsPopupWindow';
import { Sidebar } from './CreatorAccount/Sidebar/Sidebar';
import { CreatorWalkThroughCards } from './CreatorAccount/CreatorWalkThroughCards/CreatorWalkThroughCards';
import { Creator as CreatorUser } from '../../../shared/types';

type CreatorProps = {
  currentUser: CreatorUser | null;
}

export const Creator: React.FC<CreatorProps> = ({
  currentUser,
}) => {
  const { isCreatorSignIn } = useContext(CreatorStateContext);

  return (
    !isCreatorSignIn ? (
      <>
        <HeaderWithLogo isCreator={isCreatorSignIn} />
        <CreatorRoutes />
      </>
    ) : (
      <>
        <GridFullHeight container spacing={4}>
          <GridItemFullHeight item sm={3}>
            <Sidebar />
          </GridItemFullHeight>
          <Grid item sm={9}>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid item sm={11}>
                <CreatorRoutes />
              </Grid>
            </Grid>
          </Grid>
        </GridFullHeight>
        {currentUser && <NewsPopupWindow id={currentUser?.id} />}
        <CreatorWalkThroughCards />
      </>
    )
  );
};
