import { Grid } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { GalleryData } from '../../../../../../../shared/types';
import { FormGalleryWrapper, GridWrapper } from '../styled';
import { CustomDatePicker } from '../../../../../../../shared/components/FormElements/CustomDatePicker/CustomDatePicker';
import { CommonFileUpload } from '../../../../../../../shared/components/FormElements/CommonFileUpload/CommonFileUpload';
import { CommonTextarea } from '../../../../../../../shared/components/FormElements/CommonTextarea/CommonTextarea';
import { PreviewSwiper } from '../PreviewSwiper/PreviewSwiper';
import { getFullName } from '../../../../../../../shared/utils/getFullName';
import { CommonCopyInput } from '../../../../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { dilivrableGalleryURL } from '../../../../../../../constants/urls';
import { deleteSizesFromLocalStorage } from '../../../../../../../shared/utils/createGallery/deleteSizesFromLocalStorage';
import { getFormDataAndFileObjects } from '../../../../../utils/getGalleryDataForRequest';
import { useUploadForm } from '../../../../../hooks/useUploadForm';

export const NewGalleryCreatorFirstStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const {
    values, errors, handleChange, handleBlur, touched, setFieldValue,
  } = useFormikContext<GalleryData>();
  const { uploadForm, loadingFiles } = useUploadForm();

  const user = useTypedSelectorCreator((state) => state.creator.currentUser);

  useEffect(
    () => setFieldValue(
      'galleryUrl',
      `${user?.businessName}.${dilivrableGalleryURL}${values.name.toLowerCase().replace(/\s/g, '')}`,
    ),
    [values.name],
  );

  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files?.length) {
      setFieldValue('coverFile.original.file', null);
      const newCoverImage = event.currentTarget.files || [];
      const { formData, ids } = getFormDataAndFileObjects(newCoverImage);
      setFieldValue('coverFile.original.file', {
        id: ids[0],
      });
      const files = await uploadForm(formData, values.id, ids);
      setFieldValue('coverFile.original.file', {
        ...files[0],
        file: newCoverImage[0],
      });
      setFieldValue('coverFile.desktop', null);
      setFieldValue('coverFile.tablet', null);
      setFieldValue('coverFile.mobile', null);
      deleteSizesFromLocalStorage(['desktop', 'tablet', 'mobile']);
    }
  };

  return (
    <FormGalleryWrapper>
      <GridWrapper container spacing={0} direction="row" justifyContent="space-between">
        <Grid container item sm={4}>
          <div id="galleryNameDateFirstStep">
            <Grid item sm={12}>
              <CommonInput
                label={t('firstStep.galleryName')}
                error={errors.name}
                value={values.name}
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                touched={touched.name}
              />
            </Grid>
            <Grid item sm={8}>
              <CustomDatePicker
                name="date"
                data={values.date}
                setFieldValue={setFieldValue}
                touched={touched.date}
                label={t('firstStep.projectDate')}
                error={errors.date}
              />
            </Grid>
          </div>
        </Grid>
        <Grid container item sm={6}>
          <Grid item sm={8}>
            <CommonFileUpload
              id="coverFileFirstStep"
              name="coverFile"
              handleChange={handleUploadFile}
              touched={touched.coverFile?.original?.file}
              label={t('firstStep.choosePhotoVideo')}
              value={values.coverFile.original.file}
              error={errors.coverFile?.original?.file}
              accept="image/*, video/*"
              isLoading={loadingFiles.includes(values.coverFile.original.file?.id || '')}
            />
          </Grid>
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={0} direction="row" justifyContent="space-between">
        <Grid item sm={5}>
          <CommonTextarea
            name="message"
            value={values.message}
            handleChange={handleChange}
            touched={touched.message}
            label={t('firstStep.message')}
            error={errors.message}
            placeholder="Message"
          />
        </Grid>
        <Grid item sm={6}>
          <CommonCopyInput
            label={t('firstStep.galleryUrl')}
            value={values.galleryUrl}
            name="galleryUrl"
            readonly
          />
        </Grid>
      </GridWrapper>
      <PreviewSwiper
        file={
            values?.coverFile?.desktop?.file
              ? values?.coverFile?.desktop?.file
              : values?.coverFile?.original?.file
          }
        setFieldValue={setFieldValue}
        value={values.galleryDesign}
        date={values.date}
        name={values.name}
        userName={getFullName(user?.firstName, user?.lastName)}
        message={values.message}
      />
    </FormGalleryWrapper>
  );
};
