import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SectionAndIndex,
} from '../../../../../../../shared/types';
import { ButtonCard } from './styled';
import { useSendAndSetFiles } from '../../../../../hooks/useSendAndSetFiles';

export const DragAndDropFiles: React.FC<SectionAndIndex> = ({
  section,
  indexSection,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { sendAndSetFiles } = useSendAndSetFiles();
  // to change color, when user drags files
  const [isDragProcess, setIsDragProcess] = useState(false);

  const handleClick = async (files: File[]) => {
    sendAndSetFiles(`sections[${indexSection}].files`, section.files, files);
  };

  return (
    <ButtonCard isDragProcess={isDragProcess}>
      <input
        name={`sections[${indexSection}].files`}
        multiple
        type="file"
        onDragEnter={() => setIsDragProcess(true)}
        onDragLeave={() => setIsDragProcess(false)}
        onChange={(e) => handleClick(Array.from(e.target.files || []))}
      />
      <span>
        <Trans i18nKey="secondStep.addContent" t={t}>
          Drag and drop to add files
          <p>or</p>
          <u>upload from your computer</u>
        </Trans>
      </span>
    </ButtonCard>
  );
};
