import React, {
  useContext,
  useMemo, useRef, useState,
} from 'react';
import { NavigationSelect } from './NavigationSelect/NavigationSelect';
import { GallerySection } from '../../../../shared/types';
import { NavButtons } from '../../../Client/containers/ClientHeader/NavButtons';
import { scrollToElement } from '../../utils/scrollToElement';
import {
  NavigationContainerMobile,
  NavigationLinkButtons,
  NavigationWrapperMobile,
} from './styled';
import { ClientStateContext } from '../../../Client/context/ClientStateContext';

type SectionNavigationSelectProps = {
  sections: GallerySection[];
  currentSection: GallerySection;
};

export const SectionNavigationSelect: React.FC<
  SectionNavigationSelectProps
> = ({ sections, currentSection }) => {
  const { isClientSignIn } = useContext(ClientStateContext);
  const [isFixed, setIsFixed] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleClick = (id: string) => {
    scrollToElement(id, 'scrolled-container');
  };

  const currentMenuItem = useMemo(
    () => ({ id: currentSection.id, value: currentSection.title }),
    [currentSection],
  );

  // const headerRef = useRef<HTMLDivElement>(null);

  // const scrollHandler = () => {
  //   const bcr = headerRef?.current?.getBoundingClientRect();
  //   const y = bcr?.y || 1;
  //   if ((y < 0 || y === 0) && !isFixed) {
  //     setIsFixed(true);
  //   }
  //   if (y > 0 && isFixed) {
  //     setIsFixed(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollHandler, false);
  //   return () => window.removeEventListener('scroll', scrollHandler, false);
  // }, []);

  const sectionTitles = sections?.map((item) => ({
    id: item.id,
    value: item.title,
  }));

  return (
    <NavigationContainerMobile
      isFixed={isFixed}
      ref={headerRef}
    >
      <NavigationWrapperMobile isFixed={isFixed}>
        <NavigationSelect
          currentMenuItem={currentMenuItem}
          action={handleClick}
          menuItems={sectionTitles}
        />
        {isClientSignIn && (
          <NavigationLinkButtons>
            <NavButtons />
          </NavigationLinkButtons>
        )}
      </NavigationWrapperMobile>
    </NavigationContainerMobile>
  );
};
