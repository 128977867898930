import React, { ChangeEvent } from 'react';
import { Grid } from '@material-ui/core';
import {
  GalleryIdea,
  GallerySection,
} from '../../../../../../../shared/types';
import { SectionFilesWrapper } from './styled';
import { MiniFileCard } from '../../../../../../../shared/components/GalleryCreator/MiniFileCard';
import { isItemWithAppliedSettings } from './isItemWithAppliedSettings';

type FilesSectionProps = {
  section: GallerySection;
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  isItemSelected: (id: string) => boolean;
  isFormHidden: boolean;
};

export const FilesSection: React.FC<FilesSectionProps> = ({
  section,
  handleSelect,
  isItemSelected,
  isFormHidden,
}) => {
  const ideas = section.ideas as GalleryIdea[];

  return (
    <Grid item sm={isFormHidden ? 12 : 2}>
      <SectionFilesWrapper limitedWidth={!isFormHidden} id="checkboxesFilesThirdStep">
        <div>
          {section?.files?.map((item) => (
            <MiniFileCard
              large
              file={item}
              handleCheck={handleSelect}
              isChecked={isItemSelected(item.id)}
              id={item.id}
              key={item.id}
              isSettingsApplied={isItemWithAppliedSettings(item)}
            />
          ))}
          {ideas.map((item) => (
            <MiniFileCard
              large
              file={item.cover}
              handleCheck={handleSelect}
              isChecked={isItemSelected(item.id)}
              id={item.id}
              key={item.id}
              isSettingsApplied={isItemWithAppliedSettings(item)}
            />
          ))}
        </div>
      </SectionFilesWrapper>
    </Grid>
  );
};
