import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCover } from '../../../../shared/types';
import { PreviewFirstHeaderWrapper } from '../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/PreviewGalleries/PreviewFirst/styled';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import { FirstImageContainer, FirstMediumText, FirstTitle } from './styled';

export const GalleryCoverFirst: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    galleryNumber: 'first',
  });

  return (
    <Grid
      style={{ marginTop: '20px', marginBottom: '150px' }}
      container
      spacing={4}
      justifyContent="space-between"
    >
      <Grid item sm={12}>
        <PreviewFirstHeaderWrapper>
          <FirstTitle>{data.name}</FirstTitle>
          <FirstMediumText>{data.userName}</FirstMediumText>
        </PreviewFirstHeaderWrapper>
      </Grid>
      <Grid item sm={4}>
        <FirstMediumText>{data.message}</FirstMediumText>
      </Grid>
      <Grid container item sm={8} justifyContent="flex-end">
        <FirstImageContainer>
          {data.file}
          {data.date}
        </FirstImageContainer>
      </Grid>
    </Grid>
  );
};
