import React, {
  MutableRefObject, useEffect, useState,
} from 'react';

type ReturnObj = {
    spans: number;
    handleSetSpans: (ref: MutableRefObject<any>) => void;
  }

export const useMasonry = (fileRef: MutableRefObject<any>): ReturnObj => {
  const [spans, setSpans] = useState(0);

  const handleSetSpans = (ref: MutableRefObject<any>) => {
    let height = 0;
    height = ref?.current?.height || ref?.current?.clientHeight || 0;
    const newSpans = Math.ceil(height / 10);
    setSpans(newSpans);
  };

  useEffect(() => {
    fileRef?.current?.addEventListener('load', () => handleSetSpans(fileRef)); // for image
    return () => {
      fileRef?.current?.removeEventListener('load', () => handleSetSpans(fileRef));
    };
  }, []);

  return {
    spans,
    handleSetSpans,
  };
};
