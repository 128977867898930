import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SimpleBlackText,
  WhiteCardWithShadows,
} from '../../../../../../shared/styles';
import {
  LimitTextWrapper,
  PercentText,
  SalesMonthContainer,
  SalesMonthText,
} from '../Sales/styled';
import { SalesChart } from '../Sales/SalesChart/SalesChart';
import {
  FullHeightWidth,
  GridWithMgBt,
  WrapperColumn,
} from './styled';
import { getDataByScale } from '../../../../../../shared/utils/sales/getDataByScale';
import { useGetTabsSales } from '../Sales/useGetTabsSales';
import { BaseTabs } from '../../../../../../shared/components/Tabs/BaseTabs/BaseTabs';
import { TabsWrapper } from '../Sales/SalesChart/styled';

export const SalesPart: React.FC = () => {
  const { t } = useTranslation('sales');

  const [value, setValue] = useState(0);
  const tabs = useGetTabsSales();
  const dataForChart = useMemo(() => getDataByScale(value), [value]);

  const sales = 2564;

  return (
    <GridWithMgBt container spacing={4}>
      <Grid item sm={8}>
        <WhiteCardWithShadows>
          <SalesChart
            chartHeight={180}
            dataForChart={dataForChart}
          >
            <TabsWrapper
              tabButtonWidth={80}
              tabButtonHeight={40}
            >
              <BaseTabs tabs={tabs} value={value} setValue={setValue} />
            </TabsWrapper>
          </SalesChart>
        </WhiteCardWithShadows>
      </Grid>
      <Grid item sm={4}>
        <WhiteCardWithShadows fullHeight>
          <FullHeightWidth>
            <WrapperColumn>
              <SalesMonthText>
                <Trans t={t} i18nKey="salesSum">
                  $
                  {{ sales }}
                </Trans>
              </SalesMonthText>
              <LimitTextWrapper>
                <SimpleBlackText>{dataForChart.length ? t('salesGrowth') : t('averageSales')}</SimpleBlackText>
              </LimitTextWrapper>
              <SalesMonthContainer>
                <PercentText>+6% ($177.6)</PercentText>
              </SalesMonthContainer>
            </WrapperColumn>
          </FullHeightWidth>
        </WhiteCardWithShadows>
      </Grid>
    </GridWithMgBt>
  );
};
