import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ButtonsWrapper,
  CustomHeaderDatePicker,
} from './styled';
import { CustomSelectYear } from './CustomSelectYear';
import { CSSArrowIcon } from '../../styled';

type DatePickerHeaderProps = {
  date: Date;
  changeYear: any;
  decreaseMonth: any;
  increaseMonth: any;
  prevMonthButtonDisabled: any;
  nextMonthButtonDisabled: any;
};

export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <CustomHeaderDatePicker>
    <CustomSelectYear
      date={date}
      changeYear={changeYear}
    />
    <ButtonsWrapper>
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <CSSArrowIcon theme="left" />
      </button>
      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <CSSArrowIcon theme="right" />
      </button>
    </ButtonsWrapper>
  </CustomHeaderDatePicker>
);
