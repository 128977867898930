import { combineReducers } from '@reduxjs/toolkit';
import commentsSlice from '../../GalleryView/redux/comments/commentsSlice';
import cartClientSlice from './cart/cartClientSlice';
import favouritesClientSlice from './favourites/favouritesClientSlice';
import signInClientSlice from './signIn/signInClientSlice';

export const rootReducer = combineReducers({
  cart: cartClientSlice,
  favourites: favouritesClientSlice,
  signIn: signInClientSlice,
  comments: commentsSlice,
});
