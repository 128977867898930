import { useFormikContext } from 'formik';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumGreyText } from '../../../../../../../shared/styles';
import { GalleryDataForm } from '../../../../../../../shared/types';
import { NextAndBackButtonsWrapper, ButtonNextOrBack, ArrowBoldIcon } from './styled';

type NextAndBackButtonsProps = {
  handleLeftClick: () => void;
  handleRightClick: () => void;
  buttonStyles?: CSSProperties;
};

export const NextAndBackButtons: React.FC<NextAndBackButtonsProps> = ({
  handleLeftClick,
  handleRightClick,
  buttonStyles,
}) => {
  const { t } = useTranslation('basicComponents');
  const { values } = useFormikContext<GalleryDataForm>();

  const noNextButton = values.step === 5;
  const noBackButton = values.step <= 1;

  return (
    <NextAndBackButtonsWrapper>
      {!noBackButton && (
        <ButtonNextOrBack
          direction="back"
          type="submit"
          onClick={handleLeftClick}
          style={buttonStyles}
        >
          <ArrowBoldIcon direction="left" />
          <MediumGreyText style={{ textTransform: 'uppercase' }} fontWeight={500}>
            {t('buttons.back')}
          </MediumGreyText>
        </ButtonNextOrBack>
      )}
      {!noNextButton && (
        <ButtonNextOrBack
          direction="next"
          type="submit"
          onClick={handleRightClick}
          style={buttonStyles}
        >
          <MediumGreyText style={{ textTransform: 'uppercase' }} fontWeight={500}>
            {t('buttons.next')}
          </MediumGreyText>
          <ArrowBoldIcon direction="right" />
        </ButtonNextOrBack>
      )}
    </NextAndBackButtonsWrapper>
  );
};
