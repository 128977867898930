import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomFile, ErrorType } from '../../../../../shared/types';
import { fetchUploadFiles } from './uploadFilesThunk';

type initialState = {
  isSuccess: boolean;
  loadingFiles: string[];
  error: ErrorType;
  uploadedFiles: CustomFile[];
  progress: number;
};

export const initialState: initialState = {
  isSuccess: false,
  loadingFiles: [],
  error: null,
  uploadedFiles: [],
  progress: 0,
};

const uploadFilesSlice = createSlice({
  name: 'uploadFiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUploadFiles.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.error = null;
      state.loadingFiles = [];
      state.uploadedFiles = action.payload;
    });
    builder.addCase(
      fetchUploadFiles.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingFiles = [];
      },
    );
    builder.addCase(fetchUploadFiles.pending, (state, action) => {
      state.loadingFiles = action.meta.arg.filesIds;
    });
  },
});

export default uploadFilesSlice.reducer;
