import React from 'react';

export const BulbIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.5" r="12" fill="#F8F8F8" />
    <path
      d="M14.1094 19.1094H9.89062C9.81328 19.1094 9.75 19.1727 9.75 19.25V19.8125C9.75 20.1236 10.0014 20.375 10.3125 20.375H13.6875C13.9986 20.375 14.25 20.1236 14.25 19.8125V19.25C14.25 19.1727 14.1867 19.1094 14.1094 19.1094ZM12 4.625C8.8166 4.625 6.23438 7.20723 6.23438 10.3906C6.23438 12.5246 7.39453 14.3879 9.11719 15.3846V17.4219C9.11719 17.733 9.36855 17.9844 9.67969 17.9844H14.3203C14.6314 17.9844 14.8828 17.733 14.8828 17.4219V15.3846C16.6055 14.3879 17.7656 12.5246 17.7656 10.3906C17.7656 7.20723 15.1834 4.625 12 4.625ZM14.2482 14.2895L13.6172 14.6551V16.7188H10.3828V14.6551L9.75176 14.2895C8.36836 13.4896 7.5 12.0166 7.5 10.3906C7.5 7.90508 9.51445 5.89062 12 5.89062C14.4855 5.89062 16.5 7.90508 16.5 10.3906C16.5 12.0166 15.6316 13.4896 14.2482 14.2895Z"
      fill="#F180B2"
    />
  </svg>
);

export const DocumentIcon: React.FC = () => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7808 3.98778C11.4834 3.57883 11.0686 3.09985 10.6134 2.64076C10.1583 2.18166 9.68342 1.7632 9.27799 1.46319C8.58713 0.952224 8.25199 0.893433 8.05999 0.893433H1.41713C0.82656 0.893433 0.345703 1.37846 0.345703 1.97416V13.646C0.345703 14.2417 0.82656 14.7268 1.41713 14.7268H11.2743C11.8648 14.7268 12.3457 14.2417 12.3457 13.646V5.21635C12.3457 5.02268 12.2874 4.68463 11.7808 3.98778ZM10.0066 3.25288C10.418 3.66788 10.7403 4.04138 10.9786 4.35177H8.91627V2.27244C9.22399 2.5128 9.59513 2.83788 10.0057 3.25288H10.0066ZM11.4886 13.646C11.4886 13.7636 11.3908 13.8622 11.2743 13.8622H1.41713C1.36051 13.8615 1.30639 13.8385 1.26635 13.7981C1.2263 13.7577 1.20351 13.7032 1.20285 13.646V1.97416C1.20285 1.85744 1.30142 1.75802 1.41713 1.75802H8.05999V4.78406C8.05999 4.89871 8.10514 5.00866 8.18551 5.08973C8.26589 5.1708 8.3749 5.21635 8.48856 5.21635H11.4886V13.646Z"
      fill="url(#paint0_linear_4516_18692)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4516_18692"
        x1="12.3457"
        y1="7.8101"
        x2="0.345703"
        y2="7.8101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6934F" />
        <stop offset="0.498191" stopColor="#E186B0" />
        <stop offset="1" stopColor="#78C0EA" />
      </linearGradient>
    </defs>
  </svg>
);

export const ExpandIcon: React.FC = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0475 5.23508L19.5046 5.93011C19.3112 5.95377 19.2321 6.19038 19.3669 6.32938L20.9694 7.94719L16.4723 12.4871C16.4287 12.5316 16.4043 12.5916 16.4043 12.6542C16.4043 12.7168 16.4287 12.7768 16.4723 12.8213L17.7936 14.1552C17.8845 14.2469 18.0339 14.2469 18.1247 14.1552L22.6247 9.61231L24.2272 11.2301C24.2581 11.2613 24.2971 11.2831 24.3398 11.2929C24.3824 11.3028 24.4269 11.3003 24.4682 11.2858C24.5094 11.2713 24.5458 11.2453 24.5732 11.2109C24.6005 11.1765 24.6177 11.1349 24.6227 11.0911L25.3083 5.4983C25.3131 5.46258 25.3097 5.42622 25.2983 5.39205C25.287 5.35788 25.2679 5.32684 25.2426 5.30134C25.2174 5.27585 25.1866 5.25661 25.1528 5.24511C25.1189 5.23361 25.0829 5.23018 25.0475 5.23508ZM12.2038 17.1305C12.1597 17.0865 12.1003 17.0618 12.0383 17.0618C11.9763 17.0618 11.9168 17.0865 11.8727 17.1305L7.37567 21.6734L5.77313 20.0556C5.74221 20.0244 5.70321 20.0026 5.66059 19.9927C5.61798 19.9829 5.57348 19.9854 5.5322 19.9999C5.49092 20.0144 5.45452 20.0403 5.42718 20.0748C5.39984 20.1092 5.38266 20.1507 5.37762 20.1946L4.68914 25.7874C4.67156 25.9412 4.79754 26.0683 4.94988 26.0506L10.4929 25.3556C10.6862 25.3319 10.7653 25.0953 10.6305 24.9563L9.02801 23.3385L13.528 18.7956C13.6188 18.7039 13.6188 18.5531 13.528 18.4614L12.2038 17.1305Z"
      fill="#051B2C"
    />
  </svg>
);

export const ShrinkIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1825:13202)">
      <g clipPath="url(#clip1_1825:13202)">
        <path
          d="M10.36 14.5022L4.81707 15.1972C4.62371 15.2209 4.54461 15.4575 4.67937 15.5965L6.28191 17.2143L1.78484 21.7542C1.74123 21.7986 1.71676 21.8587 1.71676 21.9213C1.71676 21.9839 1.74123 22.0439 1.78484 22.0884L3.10613 23.4223C3.19695 23.5139 3.34637 23.5139 3.43719 23.4223L7.93719 18.8794L9.53973 20.4972C9.57064 20.5284 9.60965 20.5502 9.65226 20.56C9.69487 20.5699 9.73937 20.5674 9.78065 20.5529C9.82194 20.5384 9.85834 20.5124 9.88568 20.478C9.91302 20.4435 9.93019 20.402 9.93523 20.3582L10.6208 14.7654C10.6256 14.7297 10.6222 14.6933 10.6108 14.6591C10.5995 14.625 10.5804 14.5939 10.5551 14.5684C10.5299 14.5429 10.4991 14.5237 10.4653 14.5122C10.4314 14.5007 10.3954 14.4973 10.36 14.5022ZM-2.48371 26.3976C-2.52776 26.3536 -2.58725 26.3289 -2.64924 26.3289C-2.71123 26.3289 -2.77071 26.3536 -2.81476 26.3976L-7.31183 30.9404L-8.91437 29.3226C-8.94529 29.2914 -8.98429 29.2697 -9.02691 29.2598C-9.06952 29.25 -9.11402 29.2525 -9.1553 29.267C-9.19658 29.2815 -9.23298 29.3074 -9.26032 29.3419C-9.28766 29.3763 -9.30484 29.4178 -9.30988 29.4617L-9.99836 35.0545C-10.0159 35.2082 -9.88996 35.3354 -9.73762 35.3177L-4.19465 34.6226C-4.00129 34.599 -3.92219 34.3624 -4.05695 34.2234L-5.65949 32.6056L-1.15949 28.0627C-1.06867 27.971 -1.06867 27.8202 -1.15949 27.7285L-2.48371 26.3976Z"
          fill="#051B2C"
        />
      </g>
      <g clipPath="url(#clip2_1825:13202)">
        <path
          d="M32.36 -9.49783L26.8171 -8.8028C26.6237 -8.77914 26.5446 -8.54253 26.6794 -8.40353L28.2819 -6.78572L23.7848 -2.24583C23.7412 -2.20136 23.7168 -2.1413 23.7168 -2.07872C23.7168 -2.01614 23.7412 -1.95609 23.7848 -1.91162L25.1061 -0.577745C25.197 -0.48606 25.3464 -0.48606 25.4372 -0.577745L29.9372 -5.1206L31.5397 -3.5028C31.5706 -3.4716 31.6096 -3.44983 31.6523 -3.43999C31.6949 -3.43014 31.7394 -3.43261 31.7807 -3.44712C31.8219 -3.46163 31.8583 -3.48759 31.8857 -3.52202C31.913 -3.55646 31.9302 -3.59797 31.9352 -3.64181L32.6208 -9.23461C32.6256 -9.27033 32.6222 -9.30669 32.6108 -9.34086C32.5995 -9.37503 32.5804 -9.40607 32.5551 -9.43157C32.5299 -9.45706 32.4991 -9.47631 32.4653 -9.4878C32.4314 -9.4993 32.3954 -9.50273 32.36 -9.49783ZM19.5163 2.39759C19.4722 2.35356 19.4128 2.32885 19.3508 2.32885C19.2888 2.32885 19.2293 2.35356 19.1852 2.39759L14.6882 6.94045L13.0856 5.32265C13.0547 5.29145 13.0157 5.26968 12.9731 5.25983C12.9305 5.24998 12.886 5.25245 12.8447 5.26696C12.8034 5.28147 12.767 5.30743 12.7397 5.34187C12.7123 5.3763 12.6952 5.41782 12.6901 5.46165L12.0016 11.0545C11.9841 11.2082 12.11 11.3354 12.2624 11.3177L17.8054 10.6226C17.9987 10.599 18.0778 10.3624 17.943 10.2234L16.3405 8.60557L20.8405 4.06271C20.9313 3.97103 20.9313 3.82019 20.8405 3.7285L19.5163 2.39759Z"
          fill="#051B2C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1825:13202">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1825:13202">
        <rect
          width="12"
          height="10"
          fill="white"
          transform="translate(-2 14.5)"
        />
      </clipPath>
      <clipPath id="clip2_1825:13202">
        <rect
          width="12"
          height="11"
          fill="white"
          transform="translate(12 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PlayIcon: React.FC = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 6.5625C25.3645 6.5625 20.8331 7.93709 16.9788 10.5124C13.1246 13.0878 10.1205 16.7482 8.34658 21.0309C6.57265 25.3135 6.10851 30.026 7.01286 34.5724C7.9172 39.1189 10.1494 43.295 13.4272 46.5728C16.705 49.8506 20.8812 52.0828 25.4276 52.9872C29.974 53.8915 34.6865 53.4274 38.9692 51.6534C43.2518 49.8795 46.9122 46.8755 49.4876 43.0212C52.0629 39.1669 53.4375 34.6355 53.4375 30C53.4305 23.7861 50.9589 17.8288 46.5651 13.4349C42.1712 9.04106 36.2139 6.56951 30 6.5625ZM30 51.5625C25.7354 51.5625 21.5665 50.2979 18.0205 47.9286C14.4746 45.5592 11.7109 42.1916 10.0789 38.2516C8.44684 34.3116 8.01983 29.9761 8.85183 25.7934C9.68382 21.6106 11.7375 17.7686 14.753 14.753C17.7686 11.7374 21.6107 9.68381 25.7934 8.85182C29.9761 8.01982 34.3116 8.44683 38.2516 10.0788C42.1917 11.7109 45.5593 14.4746 47.9286 18.0205C50.2979 21.5665 51.5625 25.7353 51.5625 30C51.5561 35.7167 49.2822 41.1975 45.2399 45.2399C41.1975 49.2822 35.7168 51.556 30 51.5625ZM38.0201 29.22L26.7701 21.72C26.6289 21.6259 26.4648 21.5718 26.2953 21.5636C26.1258 21.5554 25.9573 21.5934 25.8077 21.6734C25.6581 21.7535 25.533 21.8727 25.4458 22.0182C25.3586 22.1638 25.3125 22.3303 25.3125 22.5V37.5C25.3125 37.6697 25.3586 37.8362 25.4458 37.9818C25.533 38.1273 25.6581 38.2465 25.8077 38.3266C25.9573 38.4066 26.1258 38.4446 26.2953 38.4364C26.4648 38.4282 26.6289 38.3741 26.7701 38.28L38.0201 30.78C38.1485 30.6944 38.2538 30.5784 38.3266 30.4423C38.3994 30.3063 38.4375 30.1543 38.4375 30C38.4375 29.8457 38.3994 29.6937 38.3266 29.5577C38.2538 29.4216 38.1485 29.3056 38.0201 29.22ZM27.1875 35.7483V24.2517L35.8102 30L27.1875 35.7483Z"
      fill="#F8F8F8"
    />
  </svg>
);

export const PauseIcon: React.FC = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5 52C41.9264 52 52 41.9264 52 29.5C52 17.0736 41.9264 7 29.5 7C17.0736 7 7 17.0736 7 29.5C7 41.9264 17.0736 52 29.5 52Z"
      stroke="#F8F8F8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.875 22V37"
      stroke="#F8F8F8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.125 22V37"
      stroke="#F8F8F8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendIcon: React.FC = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9252 8.17463L1.51971 1.45348C1.46522 1.42623 1.40272 1.41982 1.34343 1.43425C1.20721 1.4679 1.12227 1.60572 1.15593 1.74354L2.53734 7.38777C2.55817 7.47271 2.62067 7.54162 2.704 7.56886L5.07099 8.38136L2.70561 9.19386C2.62227 9.22271 2.55977 9.29002 2.54054 9.37495L1.15593 15.0272C1.1415 15.0865 1.14791 15.149 1.17516 15.2019C1.23766 15.3285 1.3915 15.3798 1.51971 15.3173L14.9252 8.63457C14.9748 8.61053 15.0149 8.56886 15.0405 8.52079C15.103 8.39258 15.0518 8.23873 14.9252 8.17463ZM2.73605 13.4214L3.54214 10.1266L8.27291 8.50316C8.30977 8.49034 8.34022 8.46149 8.35304 8.42303C8.37548 8.35572 8.34022 8.28361 8.27291 8.25957L3.54214 6.63777L2.73926 3.35572L12.8034 8.4022L2.73605 13.4214Z"
      fill="#F180B2"
    />
  </svg>
);

export const FolderIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.625 6.99375H12.2109L9.46172 4.36406C9.42674 4.33132 9.38073 4.31291 9.33281 4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V18.9375C2.625 19.3523 2.96016 19.6875 3.375 19.6875H20.625C21.0398 19.6875 21.375 19.3523 21.375 18.9375V7.74375C21.375 7.32891 21.0398 6.99375 20.625 6.99375ZM19.6875 18H4.3125V6H8.73047L11.5336 8.68125H19.6875V18Z"
      fill="black"
    />
  </svg>
);
