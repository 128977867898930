import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styles';
import { SimpleBlackText } from '../../../../../../shared/styles';

export const LandingSwiperBaseWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  & .carousel-root {
    width: 100%;
  }

  & .slide {
    min-height: 870px;
  }

  & .control-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const SwiperBaseSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 870px;

  & img {
    object-fit: none;
  }
`;

export const SwiperBaseSlideTitle = styled.div`
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

export const SwiperBaseSlideElementWrp = styled.div`
  margin-top: 22px;
`;

export const SwiperBaseSlidesPinkText = styled.span`
  color: ${variables.colorPink};
  font-weight: 600;
`;

export const SwiperBaseSecondTitle = styled(SimpleBlackText)`
  max-width: 642px;
  text-align: center;
`;
