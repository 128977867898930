import React, { CSSProperties, useMemo } from 'react';
import { useCheckWindowSize } from '../../../shared/hooks/useCheckWindowSize';

export const useGetCommentWindowStyle = (): { styles: CSSProperties } => {
  const { screenSize } = useCheckWindowSize();
  const styles = useMemo(
    () => (screenSize === 'mobile' ? ({
      backgroundBlendMode: 'multiply',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(10px)',
    }) : ({
      backgroundBlendMode: 'multiply',
      backgroundColor: '#141E2473',
      backdropFilter: 'none',
    })),
    [screenSize],
  );
  return ({ styles });
};
