import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { GalleryComment } from '../../../../../../../../../shared/types';
import { CommentsItem } from './CommentItem';
import { CommentsOrIdeasListWrapper } from './styled';

type CommentsTab = {
  comments: GalleryComment[];
}

export const CommentsTab: React.FC<CommentsTab> = ({
  comments,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <CommentsOrIdeasListWrapper>
      {comments?.length ? (
        comments.map((item) => <CommentsItem key={item.id} comment={item} />)
      ) : (
        <SimpleBlackText>{t('noComments')}</SimpleBlackText>
      )}
    </CommentsOrIdeasListWrapper>
  );
};
