import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { CloseIcon } from '../../../../../../../shared/icons';
import { ButtonWithIcon, MediumBlackText } from '../../../../../../../shared/styles';
import { DevicesAndBrowsers as DAndB } from '../../../../../../../shared/types';

type DevicesAndBrowsersRowProps = {
  row: DAndB;
  handleDelete: (id: string) => void;
};

export const DevicesAndBrowsersRow: React.FC<DevicesAndBrowsersRowProps> = ({
  row,
  handleDelete,
}) => {
  const { t } = useTranslation('settings');

  const { setIsOpen, dialogWindow } = useDialog(
    row.id,
    handleDelete,
    t('confirmDelete.question'),
    t('confirmDelete.title'),
  );

  return (
    <Grid container item sm={12}>
      <Grid item sm={5}>
        <MediumBlackText>{row.device}</MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText>{row.lastActive}</MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText>{row.ipAddress}</MediumBlackText>
      </Grid>
      <Grid item sm={1}>
        <ButtonWithIcon type="button" onClick={() => setIsOpen(true)}>
          <CloseIcon />
        </ButtonWithIcon>
      </Grid>
      {dialogWindow}
    </Grid>
  );
};
