import styled from '@emotion/styled';
import { galleryGap } from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const mobileStyleMiniFileCard = `
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-right: 8px;
    margin-bottom: 8px;
    width: 150px;
    height: 150px;
    border-radius: 16px;
    font-size: 10px;

    & > svg {
      width: 35px;
      height: 40px;
    }
  }
`;

export const FavouritesList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 ${galleryGap}px;
  grid-auto-rows: 10px;

  & div {
    margin-bottom: ${galleryGap}px;
  }

  & video {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-template-rows: 187px 187px; 
    grid-gap: 8px 8px;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    margin-bottom: 8px;

    & img {
      max-width: none;
    }

    & div {
      margin-bottom: 0px;
    }
  }
`;

export const FileMasonryContainer = styled.div<{spans: number}>`
  grid-row-end: span ${(props) => props.spans};
  grid-column-end: auto;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    grid-row-end: auto;
    grid-column-end: auto;
  }
`;

export const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;  
  }

  & video {
    width: 100%;
    height: auto;
  }
`;

export const CustomCheckboxPosition = styled.div`
  position: absolute;
  top: 4px;
  left: 6px;
`;
