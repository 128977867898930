import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Placement, Step } from 'react-joyride';
import { styleForLeftAndRight, styleForTopAndBottom } from '../../../../../constants/walkThroughCards/styles';
import { PinkLink } from '../../../../../shared/styles';

export const useGetCreatorSteps = (): Step[] => {
  const { t } = useTranslation('walkThroughCards');

  return [
    {
      target: '#createNewGallerySidebar',
      content: t('creator.steps.createNewGallery'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      disableBeacon: true,
    },
    {
      target: '#salesSidebar',
      content: t('creator.steps.monitorSales'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#activeGalleriesSidebar',
      content: t('creator.steps.activeGalleries'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#draftGalleriesSidebar',
      content: t('creator.steps.draftGalleries'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#createNewGallerySidebar',
      content: t('creator.steps.learnCreateNewGallery'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#galleryNameDateFirstStep',
      content: t('creator.steps.giveGalleryName'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#coverFileFirstStep',
      content: t('creator.steps.uploadCoverImage'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#galleryCoverDesignFirstStep',
      content: t('creator.steps.chooseGalleryDesign'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
    },
    {
      target: '#back-to-1-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#to-2-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '.progress-dot-2',
      content: 'Move to the next step',
      placement: 'bottom-start' as Placement,
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: false,
      styles: {
        options: {
          zIndex: 100000,
        },
      },
    },
    {
      target: '#dragAndDropFilesSecondStep',
      content: t('creator.steps.addContent'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#addIdeaSecondStep',
      content: (
        <Trans t={t} i18nKey="creator.steps.addIdeas">
          There’s so many wonderful ways to use Ideas to provide extra value to
          your client. Learn more
          {' '}
          <PinkLink style={{ textDecoration: 'none' }} href="/" target="_blank">in this quick video</PinkLink>
        </Trans>
      ),
      placement: 'right-start' as Placement,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      spotlightClicks: false,
    },
    {
      target: '#addSectionSecondStep',
      content: t('creator.steps.addSection'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#moveFirstSectionsSecondStep',
      content: t('creator.steps.changeOrder'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#to-3-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#back-to-2-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '.progress-dot-3',
      content: 'Move to the next step',
      placement: 'bottom-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
    },
    {
      target: '#checkboxesFilesThirdStep',
      content: t('creator.steps.checkboxFileSettings'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#filesSettingsThirdStep',
      content: t('creator.steps.fileSettings'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#limitFreeFilesThirdStep',
      content: t('creator.steps.freeFiles'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
    },
    {
      target: '#to-4-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#back-to-3-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '.progress-dot-4',
      content: 'Move to the next step',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '.access-to-gallery-fourth-step',
      content: t('creator.steps.accessToGallery'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#gallery-assist-fourth-step',
      content: t('creator.steps.galleryAssist'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#premium-fourth-step',
      content: t('creator.steps.premium'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '.progress-dot-5',
      content: t('creator.steps.checkGalleryPreview'),
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      // 28
      target: '#to-5-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      // 29
      target: '#back-to-4-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#moveToTheNext',
      content: t('creator.steps.moveToThe5thStep'),
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      // 31
      target: '#to-5-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      // 32
      target: '#back-to-4-stage',
      content: 'Please, wait...',
      placement: 'bottom-end' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#progress-bar',
      content: t('creator.steps.backToStages'),
      placement: 'bottom-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#sharable-link-fifth-step',
      content: t('creator.steps.generateLink'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
    },
    {
      target: '#supportSidebar',
      content: t('creator.steps.goToSupport'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        options: {
          zIndex: 1000000,
        },
      },
    },
    {
      target: '#training-course',
      content: t('creator.steps.buttonToReturnToTraining'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
    },
  ];
};
