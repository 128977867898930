import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type ReturnObj = {
  handleDownload: () => void;
}

export const useDownload = (): ReturnObj => {
  const dispatch = useDispatch();

  const handleDownload = useCallback(() => {
    console.log('download');
  },
  []);

  return ({
    handleDownload,
  });
};
