import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';

export const SalesChartWrapper = styled.div`
  margin-top: 40px;
  width: 100%;

  & div {
    font-size: 0.6rem;
  }
`;

export const GiantText = styled.div`
  font-size: 46px;
  line-height: 69px;
  display: flex;
  align-items: center;
  color: ${variables.colorBleak};
`;

export const TabsWrapper = styled.div<{tabButtonHeight?: number, tabButtonWidth?: number }>`
  width: ${(props) => (props.tabButtonWidth ? props.tabButtonWidth * 3 : '390')}px;
  height: ${(props) => (props.tabButtonHeight ? props.tabButtonHeight : '34')}px !important;
`;
