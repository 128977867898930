import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../../../shared/components/GlobalContainer/styled';
import { CloseButtonPositioned } from '../../../shared/styles';

export const ShareWindowWrapper = styled.div`
  padding: 60px 71px 69px 64px;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column;
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  min-width: 560px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 200px;
    padding: 40px 16px 50px;
    border-radius: 10px 0px 0px 10px;
  }
`;

export const ShareTitleWrapper = styled.div`
  margin-bottom: 42px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 36px;

    & div, span {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const ShareCopyInputWrapper = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    display: flex;
    margin: 10px 0px 26px 0px;
  }
`;

export const ShareButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;

  & > * {
    width: 44px;
    height: 44px;
    margin-right: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 20px;
  }
`;

export const ShareLinkButtonWrapper = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 32px;

    & button {
      width: 100%; 
    }
  }
`;

export const ShareCloseButtonPositioned = styled(CloseButtonPositioned)`
  right: 24px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    right: 18px;
  }
`;
