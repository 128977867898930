import React from 'react';
import { ArrowWrapper } from '../../../../shared/components/styled';
import { ArrowSimpleIcon } from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import { GallerySwiperRoundBtnWr } from './styled';

type GallerySwiperArrowBtnProps = {
  direction: 'right' | 'left';
  handleClick: () => void;
  id: string;
  disabled: boolean;
}

export const GallerySwiperArrowBtn: React.FC<GallerySwiperArrowBtnProps> = ({
  direction,
  handleClick,
  id,
  disabled,
}) => (
  <GallerySwiperRoundBtnWr
    side={direction}
    id={id}
  >
    <RoundButton
      large
      type="button"
      onClick={handleClick}
      disabled={disabled}
    >
      <ArrowWrapper large direction={direction}>
        <ArrowSimpleIcon direction={direction} />
      </ArrowWrapper>
    </RoundButton>
  </GallerySwiperRoundBtnWr>
);
