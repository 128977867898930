import React from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState as RootStateClient } from '../../modules/Client/redux/configureStore';
import { RootState as RootStateCreator } from '../../modules/Creator/redux/configureStore';

export const useTypedSelectorClient: TypedUseSelectorHook<RootStateClient> = useSelector;
export const useTypedSelectorCreator: TypedUseSelectorHook<RootStateCreator> = useSelector;
export const useTypedSelectorCommon: TypedUseSelectorHook<
  RootStateCreator | RootStateClient
> = useSelector;
