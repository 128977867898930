import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../../../constants/urls';
import {
  ButtonSubmitSignUp, ButtonWrapper, LittleText, TextNote, TitleText,
} from './styled';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import { SignUpEmail } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import {
  CustomGridWithoutPadding,
  FormBase,
  SignInUpCardColorful,
} from '../../../../shared/styles';
import { CreatorStateContext } from '../../context/CreatorStateContext';
import { useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { fetchSignUpInitialCreator } from '../../redux/userCreator/signUp/signUpCreatorThunk';

export const SignUpEmailConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['signUp', 'basicComponents']);
  const { isCreatorSignIn } = useContext(CreatorStateContext);

  const signUpError = useTypedSelectorCreator((state) => state.signUpCreator.error);
  const wasSent = useTypedSelectorCreator((state) => state.signUpCreator.wasSent);
  const isLoading = useTypedSelectorCreator((state) => state.signUpCreator.loading);

  const onSubmit = (data: { email: string }) => {
    dispatch(fetchSignUpInitialCreator(data.email));
    window.localStorage.setItem('email', data.email);
  };

  const {
    values,
    handleSubmit,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SignUpEmail,
    onSubmit: (data) => onSubmit(data),
  });

  useEffect(() => {
    const emailError = signUpError?.errors?.find((item) => item.field === 'email');
    if (emailError) {
      setFieldError('email', emailError.message);
    }
  }, [signUpError]);

  if (isCreatorSignIn) {
    return <Redirect to={urls.creatorRootUrl} />;
  }

  if (wasSent) {
    history.push(urls.signUpConfirmWasSentUrl);
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCardColorful>
          <TitleText>{t('callForSignUp')}</TitleText>
          <LittleText>
            Already have an account?
            <Link to={urls.creatorSignInUrl}>{t('basicComponents:signIn')}</Link>
          </LittleText>
          <FormBase theme="row">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container>
                <CustomGridWithoutPadding item sm={11}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                    handleBlur={handleBlur}
                  />
                </CustomGridWithoutPadding>
              </Grid>
              <ButtonWrapper>
                <ButtonSubmitSignUp type="submit" disabled={isLoading}>
                  {t('basicComponents:signUp')}
                </ButtonSubmitSignUp>
              </ButtonWrapper>
            </form>
          </FormBase>
        </SignInUpCardColorful>
      </Grid>
      <Grid container justifyContent="center">
        <TextNote>{t('noCreditCard')}</TextNote>
      </Grid>
    </Grid>
  );
};
