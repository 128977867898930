import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const FavouritesHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 10px;
    justify-content: flex-start;

    & * {
      font-size: 16px !important;
      line-height: 24px !important;  
    }
  }
`;

export const FavouritesHeaderSelectButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & button {
    padding: 0;
  }

  & > div {
    &:last-child {
      margin-right: 0px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;

    & > div {
      margin-bottom: 10px;
    }
  }
`;

export const FavouritesHeaderItemWrapper = styled.div`
  margin-right: 30px;
`;

export const LittlePinkLoader = styled.div`
  & svg {
    width: 25px !important;
  }
`;

export const FavouritesDeleteBtnWrapper = styled.div`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: flex;
    margin-bottom: 10px;

    & button {
      padding: 0;
    }
  }
`;
