import React, { useState } from 'react';
import { instance } from '../../../api/base/instance';
import { CustomFile } from '../../../shared/types';

const galleryBaseUrl = 'gallery';

type ReturnObj = {
  uploadForm: (formData: FormData, galleryId: string, filesIds: string[]) => Promise<CustomFile[]>;
  isSuccess: boolean;
  progress: number;
  loadingFiles: string[];
};

export const useUploadForm = (): ReturnObj => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadingFiles, setLoadingFiles] = useState<string[]>([]);

  const uploadForm = async (
    formData: FormData,
    galleryId: string,
    filesIds: string[],
  ): Promise<CustomFile[]> => {
    setLoadingFiles(filesIds);
    try {
      const response = await instance.post(
        `${galleryBaseUrl}/upload/${galleryId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const progressPercent = (progressEvent.loaded / progressEvent.total) * 50;
            setProgress(progressPercent);
          },
        },
      );
      setLoadingFiles([]);
      setIsSuccess(true);

      const files: CustomFile[] = response.data;

      return files;
    } catch (e: any) {
      setLoadingFiles([]);
      setIsSuccess(false);
      return [];
    }
  };

  return {
    uploadForm,
    isSuccess,
    progress,
    loadingFiles,
  };
};
