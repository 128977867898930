import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styles';

export const LandingSwiperReviewsWrapper = styled.div`
  margin-top: 160px;
  box-sizing: border-box;
  padding: 0px 140px;
`;

export const LandingSwiperReviewsSliderWrp = styled.div<{currentSlideIndex: number}>`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  max-height: 500px;
  min-height: 500px;
  height: 500px;

  & .slick-track {
    ${({ currentSlideIndex }) => {
    if (currentSlideIndex === 1) {
      return 'transform: translate3d(-1720px, 0px, 0px) !important;';
    }
    if (currentSlideIndex === 2) {
      return 'transform: translate3d(-2066px, 0px, 0px) !important;';
    }
    if (currentSlideIndex === 3) {
      return 'transform: translate3d(-2412px, 0px, 0px) !important;';
    }
    return '';
  }}
  }

  & #left, #right {
    margin: 0;
  }

  & .slick-list {
    padding: 12px;
    max-height: 500px;
  }

  & .carousel {
    max-height: 500px;
  }

  & .slick-slide, & .slide {
    margin-right: 46px;
    width: 300px !important;
    min-width: 300px !important;

    & #slide-wrapper {
      width: 300px !important;
    }

    & #slide-container {

      & #slide-user-wrapper {
        margin-bottom: 16px;

        & #slide-avatar {
          & > div {
            max-width: 45px;
            max-height: 45px;  
            width: 45px;
            height: 45px;
          }
        }
      }

      & #slide-icon-wrapper {
        & svg {
          width: 13px;
          height: 13px;
        }
      }
      
      & #slide-comment-wrapper {
        margin-top: 9px;
      }
    }
  }
  
  & .slick-current, & .selected {
    width: 532px !important;
    min-width: 532px !important;

    & #slide-wrapper {
      width: 532px !important;
    }

    & #slide-container {
      padding: 48px 40px;
      box-shadow: -19px -19px 27px #FFFFFF, 19px 19px 38px #E3E3E3;
      border-radius: 16px;
      width: 532px;
      max-width: 532px;
      font-size: 16px !important;
      margin: 60px 0px;

      & #slide-user-wrapper {
        margin-bottom: 24px;

        & #slide-avatar {
          & > div {
            max-width: 83px;
            max-height: 83px;
            width: 83px;
            height: 83px;
          }
        }
      }

      & #slide-icon-wrapper {
        & svg {
          width: 24px;
          height: 24px;
        }
      }
      
      & #slide-comment-wrapper {
        margin-top: 16px;
      }
    }
  }

`;

export const LandingSwiperReviewsSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 27px 22px;
  box-shadow: -10px -10px 15px #FFFFFF, 10px 10px 21px #E3E3E3;
  border-radius: 9px;
  width: 300px;
  max-width: 300px;
  margin: 60px 0px;
  background: ${variables.colorGreyLight};
  box-sizing: border-box;
  font-size: 9px;
`;

export const SwiperReviewsText = styled.div`
  line-height: 140%;
  font-size: 1em;
`;

export const SwiperReviewsTitle = styled.div`
  line-height: 140%;
  font-size: 1.125em;
  font-weight: 600;
`;

export const SwiperReviewsIconWrapper = styled.div`
`;

export const SwiperReviewsSlideUserWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

export const SwiperReviewsSlideUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 16px;
`;

export const SwiperReviewsSlideComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  text-align: center;
`;

export const LandingSwiperReviewsTitle = styled.div`
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

export const SwiperContentWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  width: calc(100%);
  padding: 0px 40px;

  & .carousel-root {
    display: inline-grid;
  }

  @media (max-width: 500px) {
    width: 100%;

    & .carousel-root {
      display: block;
    }
  }
`;
