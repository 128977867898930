import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { RowsWrapper, TextMgB } from '../styled';
import {
  WatermarkTooltipText,
  WatermarkTooltipWrapper,
} from './styled';
import { InfoIcon } from '../../../../../../../shared/icons';
import { WatermarkForm } from './WatermarkForm';

export const Watermark: React.FC = () => {
  const { t } = useTranslation('settings');

  return (
    <>
      <TextMgB>
        <RowsWrapper>
          <SimpleBlackText>{t('watermark')}</SimpleBlackText>
        </RowsWrapper>
      </TextMgB>
      <WatermarkTooltipWrapper>
        <InfoIcon />
        <WatermarkTooltipText>
          <Trans t={t} i18nKey="watermarkTooltip">
            Upload a
            {' '}
            <b>PNG file</b>
            {' '}
            to be used as a watermark for content in
            your galleries. You&apos;ll be able to turn watermark display
            &apos;on&apos; and &apos;off&apos; when you&apos;re creating a
            gallery. Please ensure your PNG file is transparent to ensure
            optimal viewing of gallery content. If you don&apos;t have a
            suitable PNG file ready, you can simply use the &quot;use
            text&quot; option
          </Trans>
        </WatermarkTooltipText>
      </WatermarkTooltipWrapper>
      <WatermarkForm />
    </>
  );
};
