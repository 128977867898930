import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LandingPricingInvitationContainer,
  LandingPricingInvitationTitle,
  LandingPricingInvitationText,
  LandingPricingInvitationWrp,
} from './styled';
import { JoinEarlyProgramButton } from '../../LandingMainPage/JoinEarlyProgramButton/JoinEarlyProgramButton';
import { LandingContentContainer } from '../../LandingContentContainer';
import { scrollToElement } from '../../../../GalleryView/utils/scrollToElement';

export const LandingPricingInvitation: React.FC = () => {
  const { t } = useTranslation('landing');

  const handleClick = () => {
    scrollToElement('pricing-plans', undefined, 100);
  };

  return (
    <LandingPricingInvitationContainer>
      <LandingContentContainer>
        <LandingPricingInvitationWrp>
          <LandingPricingInvitationTitle>
            {t('pricing.invitation.title')}
          </LandingPricingInvitationTitle>
          <LandingPricingInvitationText>
            {t('pricing.invitation.text')}
          </LandingPricingInvitationText>
          <JoinEarlyProgramButton
            btnTheme="white"
            shadowTheme="white"
            withoutBottomText
            onClick={handleClick}
          />
        </LandingPricingInvitationWrp>
      </LandingContentContainer>
    </LandingPricingInvitationContainer>
  );
};
