import { useFormikContext } from 'formik';
import React, { ReactNode } from 'react';
import { ImageSize, GalleryData } from '../../../shared/types';
import { getExtraFileInContainerOrCropper } from '../GalleryCovers/GalleryFourth/getExtraFileInContainerOrCropper';

type ReturnData = {
  extraFile1: ReactNode;
  extraFile2: ReactNode;
};

export const useExtraFilesGalleryFourth = (
  withCropper: boolean,
  size: ImageSize,
): ReturnData => {
  const { values } = useFormikContext<GalleryData>();

  const extra1File = values.extraCover1
    ? values.extraCover1[size]?.file || values.extraCover1?.original.file
    : null;
  const extra2File = values.extraCover2
    ? values.extraCover2[size]?.file || values.extraCover2?.original.file
    : null;

  const extra1FileContainer = extra1File
    ? getExtraFileInContainerOrCropper({
      size,
      withCropper,
      fileName: 'extraCover1',
      file: extra1File,
    })
    : null;
  const extra2FileContainer = extra2File
    ? getExtraFileInContainerOrCropper({
      size,
      withCropper,
      fileName: 'extraCover2',
      file: extra2File,
    })
    : null;

  return { extraFile1: extra1FileContainer, extraFile2: extra2FileContainer };
};
