import * as Yup from 'yup';
import { galleryPasswordMaxLength } from '../../../../constants/validationValues';
import { errorMessages } from '../../../utils/errorsMessages';

export const clientSignInSchema = Yup.object().shape(
  {
    email: Yup.string().when('password', {
      is: (v: string) => !v,
      then: Yup.string()
        .email(errorMessages.invalidEmail)
        .required(errorMessages.requiredField),
      otherwise: Yup.string().test(
        'emailFilledIn',
        errorMessages.leaveOnlyPasswordOrEmail,
        (value: any) => !value,
      ),
    }),
    password: Yup.string().when('email', {
      is: (v: string) => !v,
      then: Yup.string()
        .required(errorMessages.requiredField)
        .max(galleryPasswordMaxLength, errorMessages.tooLong),
      otherwise: Yup.string().test(
        'passwordFilledIn',
        errorMessages.leaveOnlyPasswordOrEmail,
        (value: any) => !value,
      ),
    }),
  },
  [['email', 'password']],
);
