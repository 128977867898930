import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { useTypedSelectorClient } from '../../../../../shared/hooks/useTypedSelector';
import { MediumBlackText, SimpleBlackText } from '../../../../../shared/styles';
import { HrLine } from '../../../../../shared/styles/Elements';
import { fetchGetClientCart } from '../../../redux/cart/cartClientThunk';
import { CartSummarySection } from '../CartSummary/CartSummarySection';
import {
  CartPopupContainer, CartPopupSectionsWrapper, CartPopupSubtotalWrapper, CartPopupWrapper,
} from './styled';

export const CartPopupWindow: React.FC = () => {
  const { t } = useTranslation(['gallery', 'sales']);
  const dispatch = useDispatch();

  const cartSections = useTypedSelectorClient((state) => state.cart.sections);
  const totalNumberFiles = useTypedSelectorClient(
    (state) => state.cart.totalFiles,
  );

  const subtotal = useMemo(() => {
    let result = 0;
    cartSections.forEach((item) => {
      result += +item.totalSum;
    });
    return result;
  }, [cartSections]);

  useEffect(() => {
    dispatch(fetchGetClientCart());
  }, []);

  return (
    <CartPopupContainer>
      <CartPopupWrapper>
        <SimpleBlackText fontWeight={600}>
          {totalNumberFiles
            ? (
              <Trans t={t} i18nKey="pageTitle">
                {{ title: t('cart.title') }}
                {' '}
                {{ files: totalNumberFiles }}
              </Trans>
            )
            : t('cart.title')}
        </SimpleBlackText>
        <div style={{ width: '100%' }}>
          <CartPopupSectionsWrapper>
            {cartSections.map((item) => (
              <CartSummarySection section={item} key={item.sectionId} />
            ))}
          </CartPopupSectionsWrapper>
          <HrLine />
          <CartPopupSubtotalWrapper>
            <MediumBlackText fontWeight={500}>{t('sales:subTotal')}</MediumBlackText>
            <MediumBlackText fontWeight={500}>
              <CommonPriceInputReadOnly value={subtotal || 0} />
            </MediumBlackText>
          </CartPopupSubtotalWrapper>
        </div>
      </CartPopupWrapper>
    </CartPopupContainer>
  );
};
