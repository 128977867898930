import { createAsyncThunk } from '@reduxjs/toolkit';
import { creatorSignIn } from '../../../../../api/auth/creatorAuth';
import { ResetPassword } from '../../../../../shared/types';

export const fetchResetPasswordInitialCreator = createAsyncThunk(
  'signInCreator/fetchResetPasswordInitialCreator',
  async (email: string) => {
    const response = await creatorSignIn.resetPasswordInitial(email);
    return response.data;
  },
);

export const fetchResetPasswordCreator = createAsyncThunk(
  'signInCreator/fetchResetPasswordCreator',
  async (data: ResetPassword) => {
    const response = await creatorSignIn.resetPassword(data);
    return response.data;
  },
);
