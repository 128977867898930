import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageContainer } from '../../../../../../../../../shared/components/Files/ImageContainer/ImageContainer';
import {
  IdeaContainer,
  IdeaFileWrapper,
  IdeaInfoButtonWrapper,
  IdeaInfoTitle,
  IdeaInfoAndButton,
  MediumBlackTextWithDots,
} from './styled';
import {
  PrimaryWhiteButton,
  TinyText,
} from '../../../../../../../../../shared/styles';
import { ActiveGalleryFileWrapper } from '../styled';
import { getFormattedDateOrDateName } from '../../../../../../../../../shared/utils';

type IdeaProps = {
  idea: any;
};

export const Idea: React.FC<IdeaProps> = ({ idea }) => {
  const { t } = useTranslation('gallery');
  const dateFormat = 'mm/dd/yyyy';
  const formattedDate = getFormattedDateOrDateName(idea.date, dateFormat);

  return (
    <>
      <IdeaContainer key={idea.id}>
        <IdeaFileWrapper>
          <ActiveGalleryFileWrapper>
            <ImageContainer imageUrl="" />
          </ActiveGalleryFileWrapper>
          <TinyText color="pink">{t('ideas.purchased')}</TinyText>
          <TinyText color="grey">
            {t('ideas.on')}
            {' '}
            {formattedDate}
          </TinyText>
        </IdeaFileWrapper>
        <IdeaInfoAndButton>
          <IdeaInfoTitle>{idea.title}</IdeaInfoTitle>
          <MediumBlackTextWithDots>{idea.description}</MediumBlackTextWithDots>
          <IdeaInfoButtonWrapper>
            <PrimaryWhiteButton>{t('ideas.deliverIdea')}</PrimaryWhiteButton>
          </IdeaInfoButtonWrapper>
        </IdeaInfoAndButton>
      </IdeaContainer>
    </>
  );
};
