import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const LandingMainFirstSectionWrp = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 0px 40px;
`;

export const LandingMainTitle = styled.div`
  max-width: 532px;
  font-weight: 700;
  font-size: 50px;
  line-height: 117%;
  color: ${variables.colorGreyLight};
  margin-top: 128px;
  margin-bottom: 36px;
  text-align: end;
  z-index: 100;
`;

export const LandingMainText = styled.div`
  max-width: 410px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${variables.colorGreyLight};
  margin-bottom: 32px;
  text-align: end;
  z-index: 100;
`;
