import React, { ChangeEvent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { GalleryData, GallerySection } from '../../../../../../../shared/types';
import { CommonCheckbox } from '../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { getSectionErrorsAndTouched } from '../../../../../../../shared/utils/createGallery/getErrorsAndTouched';
import {
  CheckboxTextWrapper, CheckboxWrapper, FormLimitFreeFilesGrid, LabelCheckboxWrapper,
} from './styled';
import { useResetField } from '../hooks/useResetField';

type FormLimitFreeFilesProps = {
  section: GallerySection;
};

export const FormLimitFreeFiles: React.FC<FormLimitFreeFilesProps> = ({
  section,
}) => {
  const { t } = useTranslation('createNewGallery');
  const resetField = useResetField();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = useFormikContext<GalleryData>();

  const sectionIndex = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [values.sections],
  );

  const sectionErrorsAndTouched = useMemo(
    () => getSectionErrorsAndTouched(errors, touched, sectionIndex),
    [errors, touched, sectionIndex],
  );

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (section.numberFreeFiles) {
      resetField(`sections[${sectionIndex}].numberFreeFiles`);
    }
  };

  return (
    <FormLimitFreeFilesGrid id="limitFreeFilesThirdStep">
      <CheckboxWrapper>
        <CommonCheckbox
          isChecked={section.hasFreeFiles}
          name={`sections[${sectionIndex}].hasFreeFiles`}
          handleChange={handleCheck}
        />
      </CheckboxWrapper>
      <LabelCheckboxWrapper>
        <Trans t={t} i18nKey="thirdStep.checkbox">
          <CheckboxTextWrapper>Make all files from this section free until</CheckboxTextWrapper>
          {' '}
          <CommonInput
            value={section.numberFreeFiles || ''}
            name={`sections[${sectionIndex}].numberFreeFiles`}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            direction="row"
            width={80}
            error={sectionErrorsAndTouched.error?.numberFreeFiles}
            touched={sectionErrorsAndTouched.wasTouched?.numberFreeFiles}
            disabled={!section.hasFreeFiles}
            withoutMargins
          />
          {' '}
          <CheckboxTextWrapper>files have been added to download cart</CheckboxTextWrapper>
        </Trans>
      </LabelCheckboxWrapper>
    </FormLimitFreeFilesGrid>
  );
};
