import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { TextIconButtonWithHover } from '../../../../../shared/components/TextIconButtonWithHover/TextIconButtonWithHover';
import { ArrowSimpleIcon } from '../../../../../shared/icons';
import { MediumGreyText } from '../../../../../shared/styles';
import { DemoGalleryContainer } from './styled';
import * as urls from '../../../../../constants/urls/landingUrls';
import { galleryForCLient } from '../../../../../api/gallery/fakeGalleryRequests';
import { GalleryData } from '../../../../../shared/types';
import { GalleryView } from '../../../../GalleryView/GalleryView';

export const DemoGallery: React.FC = () => {
  const { t } = useTranslation('landing');
  const { state } = useLocation<{ galleryDesignNumber: number }>();
  const history = useHistory();

  const handleBackToGalleries = () => {
    history.push(urls.demoGalleriesUrl);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <DemoGalleryContainer>
      <TextIconButtonWithHover
        icon={<ArrowSimpleIcon color="grey" direction="left" />}
        textElement={
          <MediumGreyText>{t('demoGalleries.backToGalleries')}</MediumGreyText>
        }
        handleClick={handleBackToGalleries}
      />
      <GalleryView
        galleryDesignNumber={state.galleryDesignNumber}
        gallery={galleryForCLient as GalleryData}
      />
    </DemoGalleryContainer>
  );
};
