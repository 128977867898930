import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { TitleText } from '../../../../../shared/styles';

export const ClientContentWrapper = styled.div`
  margin-top: 30px;
`;

export const ClientContentTitleText = styled(TitleText)`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;
