import React, { useEffect, useState } from 'react';

export const useCenterSlidePercentage = (): { centerSlidePercentage: number } => {
  const [centerSlidePercentage, setCenterSlidePercentage] = useState<number>(94);

  useEffect(() => {
    if (window.screen.width <= 381) {
      setCenterSlidePercentage(100);
    } else {
      setCenterSlidePercentage(94);
    }
  }, [window.screen.width]);

  function detectWindowSize() {
    if (window.screen.width <= 381) {
      setCenterSlidePercentage(100);
    } else {
      setCenterSlidePercentage(94);
    }
  }

  window.onresize = detectWindowSize;

  return ({
    centerSlidePercentage,
  });
};
