import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { LittleGreyText } from '../../../../shared/styles';

export const LandingFooterWrapper = styled.div`
  box-sizing: border-box;
  padding: 64px 152px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(235, 235, 235, 0.8);
  margin-top: 40px;
  filter: drop-shadow(0px -5px 15px #fff);
  box-shadow: 0px -5px 15px #fff;
  max-height: 272px;
`;

export const LandingFooterLogoWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 215px;
    height: 34px;
    margin-bottom: 24px;
    
    & path {
      width: 215px;
    }
  }
`;

export const LandingFooterTextsWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LandingFooterLinkWrp = styled.div`
  margin-bottom: 24px;
`;

export const LandingFooterRightsWrp = styled(LittleGreyText)`
  margin-top: 72px;
`;

export const LandingFooterLogoText = styled(LittleGreyText)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    display: flex;
    height: fit-content;
    margin: 0px 5px;
  }
`;

export const LandingFooterMediaWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  & * {
    margin-left: 5px;
    color: ${variables.textGrey};

    &:first-of-type {
      margin-right: 2px;
    }
  }
`;
