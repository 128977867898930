import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';

export const LandingSwiperIndicatorElement = styled.div<{isSelected: boolean}>`
  background: ${({ isSelected }) => (isSelected ? variables.colorPink : '#eaeaea')};
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;
