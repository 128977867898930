import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { GalleryCover } from '../../../../shared/types';
import { useGalleryContent } from '../../hooks/useGalleryContent';
import {
  SixthImageContainer,
  SixthInfoCardCover,
  SixthMediumTextCenter,
  SixthTitle,
} from './styled';

export const GalleryCoverSixth: React.FC<GalleryCover> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const refCard = useRef<HTMLDivElement>(null);
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    galleryNumber: 'sixth',
  });

  return (
    <Grid
      style={{ marginTop: '20px', marginBottom: '150px' }}
      container
      item
      sm={10}
      spacing={4}
      justifyContent="center"
    >
      <Grid container item sm={12} justifyContent="center">
        <SixthImageContainer>
          {data.file}
          <SixthInfoCardCover ref={refCard} currentHeight={refCard?.current?.offsetHeight || 0}>
            <SixthTitle>{data.name}</SixthTitle>
            <SixthMediumTextCenter>{data.message}</SixthMediumTextCenter>
          </SixthInfoCardCover>
        </SixthImageContainer>
      </Grid>
      <Grid item sm={6}>
        <SixthMediumTextCenter>{data.date}</SixthMediumTextCenter>
        <SixthMediumTextCenter>{data.userName}</SixthMediumTextCenter>
      </Grid>
    </Grid>
  );
};
