import styled from '@emotion/styled';

export const PositionedImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const LandingColorfulBackground = styled.div`
  background-image: linear-gradient(274.28deg, #E6934F -24.21%, #E186B0 51.92%, #78C0EA 128.62%);
`;
