import styled from '@emotion/styled';

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  position: relative;
`;

export const VideoElement = styled.video<{
  widthStretch: boolean;
  isWidthBigger?: boolean;
}>`
  object-fit: cover;

  ${(props) => (props.widthStretch
    ? `height: auto;
    width: 100%;`
    : `height: 100%;
    width: auto;`)}
  ${(props) => (props.isWidthBigger
    ? `height: 100%;
      width: auto;`
    : `height: auto;
      width: 100%;`)}
`;

export const ButtonWrapper = styled.div<{isPlay: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  ${({ isPlay }) => (isPlay
    ? `background: transparent;
    transition: all 0.3s ease-in-out;

    & > button {
      display: none;
    }
    &:hover {
      background: #00000045;
      transition: all 0.3s ease-in-out;

      & > button {
        display: block;
      }
    }
    `
    : `background: #00000045;

    & > button {
      display: block;
    }
    `)}
`;

export const PlayOrPauseButton = styled.button`
  padding: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
  }
`;
