import { useFormikContext } from 'formik';
import React, {
  useRef, useState, MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LittleTickIcon } from '../../../../../../shared/icons';
import { GalleryDataForm } from '../../../../../../shared/types';
import { ModalWindowGalleryPreview } from './NewGalleryCreatorFifthStep/ModalWindowGalleryPreview';
import {
  ProgressBarContainer,
  ProgressBarDot,
  ProgressBarDotWrapper,
  ProgressBarText,
  ProgressBarWrapper,
  ProgressLineInside,
} from './styled';

type ProgressBarProps = {
  step: number;
};

type ProgressBarElement = {
  label: string;
};

export const useProgressBarLabels = (): ProgressBarElement[] => {
  const { t } = useTranslation('createNewGallery');
  return [
    { label: t('progressBar.firstStep') },
    { label: t('progressBar.secondStep') },
    { label: t('progressBar.thirdStep') },
    { label: t('progressBar.fourthStep') },
  ];
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ step }) => {
  const { t } = useTranslation('createNewGallery');
  const [isOpenGalleryPreview, setIsOpenGalleryPreview] = useState(false);
  const refLastMaxStep = useRef<number>(1);
  const { values, setFieldValue } = useFormikContext<GalleryDataForm>();
  const stageLabels = useProgressBarLabels();

  if (step > refLastMaxStep.current) {
    refLastMaxStep.current = step;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const clickedStep = +e.currentTarget.id;
    const isNextOrAllPreviousStep = clickedStep < refLastMaxStep.current
    || values.step === clickedStep - 1;

    if (
      isNextOrAllPreviousStep
      || clickedStep === refLastMaxStep.current
      || clickedStep === refLastMaxStep.current + 1
    ) {
      if (clickedStep < 5) setFieldValue('step', clickedStep);
      if (clickedStep === 5) setIsOpenGalleryPreview(true);
    }
  };

  const buttons = stageLabels.map((item, index) => {
    const stepNumber = index + 1;
    return (
      <div key={item.label}>
        <div style={{ height: 'fit-content', width: 'fit-content' }}>
          <ProgressBarDot
            id={`${stepNumber}`}
            stageNumber={stepNumber}
            status={`${refLastMaxStep.current === stepNumber ? 'active' : ''} ${
              stepNumber < refLastMaxStep.current ? 'done' : ''
            }`}
          >
            <ProgressBarDotWrapper
              type="submit"
              id={`${stepNumber}`}
              onClick={handleClick}
              className={`progress-dot  progress-dot-${stepNumber}`}
            />
            {stepNumber < refLastMaxStep.current ? (
              <LittleTickIcon />
            ) : (
              <span>{stepNumber}</span>
            )}
          </ProgressBarDot>
          <ProgressBarText theme={`${stepNumber}`}>
            {item.label}
          </ProgressBarText>
        </div>
      </div>
    );
  });

  return (
    <ProgressBarWrapper id="progress-bar">
      <ProgressBarContainer>
        <ProgressLineInside stepNumber={refLastMaxStep.current} />
        {buttons}
        <ModalWindowGalleryPreview
          setIsOpen={setIsOpenGalleryPreview}
          isOpen={isOpenGalleryPreview}
          button={(
            <ProgressBarDot
              id="5"
              stageNumber={5}
              status={`${refLastMaxStep.current === 5 ? 'active' : ''} ${
                refLastMaxStep.current > 5 ? 'done' : ''
              }`}
            >
              <ProgressBarDotWrapper
                id="5"
                onClick={handleClick}
                type="submit"
                className="progress-dot  progress-dot-5"
              />
              {refLastMaxStep.current > 5 ? <LittleTickIcon /> : <span>5</span>}
            </ProgressBarDot>
          )}
        />
        <ProgressBarText theme="5">
          {t('progressBar.fifthStep')}
        </ProgressBarText>
      </ProgressBarContainer>
    </ProgressBarWrapper>
  );
};
