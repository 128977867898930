import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { SimpleBlackText, MediumBlackText, PrimaryWhiteButton } from '../../../../../../../shared/styles';
import { CancelPanelWrapper } from './styled';
import { MarginTopWrapper } from '../styled';

export const CancelPanel: React.FC = () => {
  const { t } = useTranslation('settings');

  const { setIsOpen, dialogWindow } = useDialog(
    'account',
    (data: any) => console.log(),
    t('confirmCancel.question'),
    t('cancelAccount'),
    t('confirmButton'),
  );

  return (
    <MarginTopWrapper>
      <CancelPanelWrapper>
        <SimpleBlackText>{t('cancelAccount')}</SimpleBlackText>
        <MediumBlackText>{t('cancelAccountText')}</MediumBlackText>
        <PrimaryWhiteButton type="button" onClick={() => setIsOpen(true)}>
          {t('cancelAccount')}
        </PrimaryWhiteButton>
      </CancelPanelWrapper>
      {dialogWindow}
    </MarginTopWrapper>
  );
};
