import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const Slide = styled.div`
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  & #gallery-content-container {
    padding: 0px !important;
  }

  @media (max-width: 500px) {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
  }
`;

export const SwiperContentWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  width: calc(100% - 118px);

  & .carousel-root {
    display: inline-grid;
  }

  @media (max-width: 500px) {
    width: 100%;

    & .carousel-root {
      display: block;
    }
  }
`;

export const GallerySwiperRoundBtnWr = styled.div<{side: 'right' | 'left'}>`
  display: flex;
  ${({ side }) => (side === 'right' ? 'margin-left: 59px;' : 'margin-right: 59px;')}

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
    margin: 0px;
  }
`;
