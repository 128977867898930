import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  GalleryFileInformation,
  GallerySection,
} from '../../../../../../../shared/types';
import {
  SectionWrapper,
  SectionTitle,
} from '../NewGalleryCreatorSecondStep/styled';
import { FormLimitFreeFiles } from './FormLimitFreeFiles';
import { FilesSection } from './FilesSection';
import { FilesForm } from './FilesForm';
import { SectionActions } from './SectionActions/SectionActions';
import { getCommonValuesOfFileInfo } from '../../../../../../../shared/utils/createGallery/checkCommonValues';
import { galleryFileInformationSchema } from '../../../../../../../shared/validation/gallery/galleryFileInformationSchema';
import { FilePreview } from './FilePreview';
import { CreatorOnboardingContext } from '../../../../../context/CreatorOnboardingContext';
import { useChooseItems } from '../../../../../../../shared/hooks/useChooseItems';
import { getGalleryFileInfo } from '../../../../../../../constants/initialValues';

type SectionThirdStepProps = {
  section: GallerySection;
};

export const SectionThirdStep: React.FC<SectionThirdStepProps> = ({
  section,
}) => {
  const [isFormHidden, setIsFormHidden] = useState<boolean>(true);
  const { isStudy } = useContext(CreatorOnboardingContext);
  const {
    handleSelectAll,
    handleSelect,
    removeAllSelected,
    isItemSelected,
    numberOfSelected,
    selected,
    setSelected,
  } = useChooseItems();

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik<GalleryFileInformation>({
    validationSchema: galleryFileInformationSchema,
    initialValues: getGalleryFileInfo(section, selected),
    onSubmit: (data: GalleryFileInformation) => {
      resetForm();
    },
  });

  useEffect(() => {
    if (isStudy) {
      setSelected([section.files[0].id]);
    }
  }, []);

  useEffect(() => {
    setValues(getCommonValuesOfFileInfo(section, selected));
  }, [selected]);
  useEffect(() => {
    if (selected.length === 1) setIsFormHidden(false);
    if (selected.length === 0) setIsFormHidden(true);
  }, [selected]);

  return (
    <SectionWrapper>
      <SectionTitle>{section.title}</SectionTitle>
      <Grid container item>
        <SectionActions
          resetForm={resetForm}
          section={section}
          setIsHidden={setIsFormHidden}
          isFormHidden={isFormHidden}
          values={values}
          handleSubmit={handleSubmit}
          isValid={isValid}
          handleSelectAll={handleSelectAll}
          removeAllSelected={removeAllSelected}
          isItemSelected={isItemSelected}
          numberOfSelected={numberOfSelected}
        />
        <Grid
          container
          item
          sm={12}
          direction="row"
          justifyContent="space-between"
        >
          <FilesSection
            section={section}
            isFormHidden={isFormHidden}
            handleSelect={handleSelect}
            isItemSelected={isItemSelected}
          />
          {!isFormHidden && (
            <>
              <FilesForm
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                selected={selected}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                section={section}
              />
              <Grid item sm={4}>
                <FilePreview
                  selectedFileIds={selected}
                  section={section}
                  values={values}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item sm={6} />
        <FormLimitFreeFiles section={section} />
      </Grid>
    </SectionWrapper>
  );
};
