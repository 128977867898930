import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { invalidPasswordOrEmail } from '../../../../constants/validationValues';
import { ErrorType, GalleryData } from '../../../../shared/types';
import { fetchGetClientGallery, fetchSignInClient } from './signInClientThunk';

type initialState = {
  isSignIn: boolean;
  gallery: GalleryData | null;
  clientEmail: string;
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  isSignIn: false,
  gallery: null,
  clientEmail: '',
  loading: false,
  error: null,
};

const signInClientSlice = createSlice({
  name: 'signInClient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignInClient.fulfilled, (state, action: PayloadAction<any>) => {
      state.isSignIn = true;
      state.error = null;
      state.loading = false;
      state.gallery = action.payload;
      state.clientEmail = 'example@gmail.com';
    });
    builder.addCase(
      fetchSignInClient.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = invalidPasswordOrEmail;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchSignInClient.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
    });
    builder.addCase(fetchGetClientGallery.fulfilled, (state, action: PayloadAction<any>) => {
      state.isSignIn = true;
      state.error = null;
      state.loading = false;
      state.gallery = action.payload;
      state.clientEmail = 'example@gmail.com';
    });
    builder.addCase(
      fetchGetClientGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = invalidPasswordOrEmail;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchGetClientGallery.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
    });
  },
});

export default signInClientSlice.reducer;
