import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';
import { Direction } from '../../../../../../../shared/types';

export const NextAndBackButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

export const ButtonNextOrBack = styled.button<{direction: 'back' | 'next'}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  ${({ direction }) => (direction === 'back' ? 'margin-right: 20px;' : '')}

  & > div {
    ${({ direction }) => (direction === 'next' ? 'margin-right: 6px;' : 'margin-left: 6px;')}
  }

  & svg {
    color: ${variables.textGrey};
    width: 10px;
    height: 14px;
    font-weight: 500;
  }

  & * {
    transition: 0.2 ease-in-out;
  }

  &:hover {
    & * {
      color: ${variables.textBlack};
      transition: 0.2 ease-in-out;
    }

    & i {
      border: solid ${variables.textBlack};
      border-width: 0 2px 2px 0;
      transition: 0.2 ease-in-out;
    }
  }
`;

export const ArrowBoldIcon = styled.i<{direction: Direction}>`
  border: solid ${variables.textGrey};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transition: 0.2 ease-in-out;

  ${({ direction }) => {
    if (direction === 'right') return 'transform: rotate(-45deg);';
    if (direction === 'left') return 'transform: rotate(135deg);';
    if (direction === 'up') return 'transform: rotate(-135deg);';
    return 'transform: rotate(45deg);';
  }}
`;

export const NewGalleryCreatorButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 80px;
`;
