import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { TextBlackLarge } from '../../styles';

export const GalleryCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GalleryCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GalleryCardTitle = styled(TextBlackLarge)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
`;

export const GalleryCardHeaderButton = styled.button`
  padding: 0;
  display: flex;

  & svg {
    color: ${variables.textBlack};
  }
`;

export const GalleryCardCover = styled.div`
  min-width: 380px;
  width: 100%;
  height: 281px;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  margin-top: 20px;
  position: relative;
  background: ${variables.textGrey};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-blend-mode: multiply;
  }
`;

export const EyePositionWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 20px;
  
  & svg {
    color: ${variables.colorPink};
  }
`;

export const ButtonWrapperWithOpacity = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    display: none;
  }

  &:hover {
    background: rgba(25, 25, 25, 0.25);
    transition: 0.3s;

    & button {
      display: flex;
    }
  }
`;
