import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TitleText } from '../../../../../shared/styles';
import { JoinEarlyProgramButton } from '../JoinEarlyProgramButton/JoinEarlyProgramButton';
import {
  LandingMainLastSectionWrp, LastSectionTitle, LastSectionGradientTitle, LastSectionTextWrp,
} from './styled';

export const LandingMainLastSection: React.FC = () => {
  const { t } = useTranslation('landing');

  return (
    <LandingMainLastSectionWrp>
      <LastSectionTitle>
        <Trans t={t} i18nKey="lastSection.title">
          File delivery for creators has been
          {' '}
          <LastSectionGradientTitle>re-imagined</LastSectionGradientTitle>
        </Trans>
      </LastSectionTitle>
      <LastSectionTextWrp>
        <TitleText>
          {t('lastSection.text')}
        </TitleText>
      </LastSectionTextWrp>
      <JoinEarlyProgramButton textColor="black" btnTheme="white" shadowTheme="white" />
    </LandingMainLastSectionWrp>
  );
};
