import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Trans, useTranslation } from 'react-i18next';
import {
  CustomFile,
  GalleryDataForm,
  GalleryIdea,
  SectionAndIndex,
} from '../../../../../../../shared/types';
import { IdeaModalWindow } from './Ideas/IdeaModalWindow';
import {
  ButtonCard, CardBorder, CardContainer, PositionedHelpTip,
} from './styled';
import IdeaImage from '../../../../../../../assets/image/galleryImages/IdeaImage.png';
import { HelpTip } from '../../../../../../../shared/components/HelpTip/HelpTip';
import { DragAndDropFiles } from './DragAndDropFiles';
import { PinkLink } from '../../../../../../../shared/styles';
import { getIndex } from '../../../../../../../shared/utils/createGallery';

const currentIdeaId = uuidv4();
const newIdea: GalleryIdea = {
  id: currentIdeaId,
  name: '',
  description: '',
  price: '',
  files: [],
  cover: null as unknown as CustomFile,
};

export const SectionAddContent: React.FC<SectionAndIndex> = ({
  section,
  indexSection,
}) => {
  const { t } = useTranslation('createNewGallery');
  const [isOpenIdeaModal, setIsOpenIdeaModal] = useState(false);
  const {
    setFieldValue, values, setFieldError, setFieldTouched,
  } = useFormikContext<GalleryDataForm>();

  const { ideas } = section;

  const handleCreateIdea = () => {
    setFieldValue(`sections[${indexSection}].ideas`, [
      ...ideas,
      newIdea,
    ]);
    setIsOpenIdeaModal(true);
  };
  const lastIdea = ideas[ideas?.length - 1];

  const removeCreatedIdea = (id: string) => {
    const remainIdeas = values.sections[indexSection].ideas.filter(
      (item) => item.id !== id,
    );
    setFieldError(`sections[${indexSection}].ideas`, '');
    setFieldTouched(`sections[${indexSection}].ideas`, false);
    setFieldValue(`sections[${indexSection}].ideas`, remainIdeas);
  };

  const helpIdeaText = (
    <Trans t={t} i18nKey="secondStep.helpTexts.ideas">
      See community
      {' '}
      <PinkLink href="/">best examples</PinkLink>
    </Trans>
  );

  return (
    <>
      <CardContainer item sm={3}>
        <CardBorder id="dragAndDropFilesSecondStep">
          <DragAndDropFiles indexSection={indexSection} section={section} />
        </CardBorder>
      </CardContainer>
      <CardContainer item sm={3}>
        <CardBorder id="addIdeaSecondStep">
          <ButtonCard onClick={handleCreateIdea}>
            <PositionedHelpTip id="positioned-help-tip">
              <HelpTip text={helpIdeaText} />
            </PositionedHelpTip>
            <img src={IdeaImage} alt="idea" />
            {t('secondStep.ideas')}
          </ButtonCard>
          <IdeaModalWindow
            removeCreatedIdea={removeCreatedIdea}
            isOpen={isOpenIdeaModal}
            setIsOpen={setIsOpenIdeaModal}
            idea={lastIdea}
            indexSection={indexSection}
            indexIdea={ideas?.length - 1}
          />
        </CardBorder>
      </CardContainer>
    </>
  );
};
