import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CopyIcon } from '../../../../../../../shared/icons';
import { MediumBlackText } from '../../../../../../../shared/styles';
import { BillingHistory } from '../../../../../../../shared/types';
import { TextAndIconWrapper } from './styled';

type RecentBillingHistoryRowProps = {
  row: BillingHistory;
};

export const RecentBillingHistoryRow: React.FC<RecentBillingHistoryRowProps> = ({
  row,
}) => {
  const { t } = useTranslation('sales');

  return (
    <Grid container item sm={12}>
      <Grid item sm={3}>
        <MediumBlackText>{row.date}</MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText>
          <TextAndIconWrapper>
            <CopyIcon />
            {' '}
            {row.document}
          </TextAndIconWrapper>
        </MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText>
          <Trans t={t} i18nKey="salesSum">
            $
            {{ sales: row.sum }}
          </Trans>
        </MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText fontWeight={500}>{row.status}</MediumBlackText>
      </Grid>
    </Grid>
  );
};
