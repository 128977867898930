import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { BaseSecondaryButton } from '../../../../../../../../shared/styles';
import { GalleryDataForm } from '../../../../../../../../shared/types';
import { LoadingForRequest } from '../../../../../../../../shared/components/LoadingForRequest/LoadingForRequest';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import { useDispatchUpdateGallery } from '../../hooks/useDispatchUpdateGallery';

type SaveAndCloseGalleryProps = {
  disabled?: boolean;
};

export const SaveAndCloseGallery: React.FC<SaveAndCloseGalleryProps> = ({ disabled }) => {
  const { t } = useTranslation(['basicComponents', 'gallery']);
  const { values } = useFormikContext<GalleryDataForm>();
  const { handleUpdateGallery, isLoading, isSuccess } = useDispatchUpdateGallery();

  const isLastStep = values.step >= 5;
  const buttonText = isLastStep ? t('buttons.saveClose') : t('buttons.saveToDrafts');

  const handleSave = () => {
    const status = isLastStep ? 'active' : 'draft';
    handleUpdateGallery(values, status);
  };
  const { dialogWindow, setIsOpen } = useDialog<GalleryDataForm>(
    values,
    handleSave,
    isLastStep ? t('gallery:saveGallery.textActive') : t('gallery:saveGallery.textDraft'),
    t('gallery:saveGallery.question'),
  );

  useEffect(() => () => {
    document.body.style.overflow = 'auto';
  },
  []);

  return (
    <div>
      <BaseSecondaryButton
        type="button"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        {buttonText}
      </BaseSecondaryButton>
      {(isLoading || isSuccess) && (
        <LoadingForRequest
          text={t('changesSaved')}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      )}
      {dialogWindow}
    </div>
  );
};
